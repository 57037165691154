import { useEffect } from 'react';
import { getUserName } from 'src/helpers';
import { useAppSelector } from 'src/store';

export const usePendo = () => {
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (!(window as any).pendo) {
      return;
    }

    if (user) {
      (window as any).pendo.identify({
        visitor: {
          id: user.id,
          email: user.email,
          full_name: getUserName(user),
          role: user.role,
          is_admin: user.is_admin,
          is_wl: user.is_wl,
          created_at: (user as any).created_at,
        },
        account: {
          id: user.id,
          advertiser_id: user.dsp_advertiser_id,
          agency_id: user.dsp_agency_id,
          name: user.company_name,
          is_paying: !!user.billing,
          payment: user.payment,
          monthly_value: user.level?.price_monthly,
          planLevel: user.workspace?.plan,
          created_at: user.workspace?.created_at,
        },
      });
    } else {
      (window as any).pendo.initialize({
        visitor: {
          id: 'VISITOR-UNIQUE-ID',
        },
      });
    }
  }, [user]);
};
