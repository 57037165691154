import { DataTable } from 'src/components/DataTable';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { growthApi } from 'src/services';
import { formatCount, formatMarkups } from 'src/utils';
import { PageTemplate } from 'src/components/Template';
import { Action, Button, ControlledInput, Switch } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { useDataTable, useRole } from 'src/hooks';
import { Role, User } from 'src/types';
import { useState } from 'react';
import { EditMarkupsModal } from 'src/components/EditMarkupModal';
import { EditAgencyModal } from './EditAgencyModal';
import { ArchiveNew } from 'src/components/Archive';
import { isUndefined } from 'lodash';
import { EditDataCreditModal } from 'src/components/EditDataCreditModal';

const { useAgenciesQuery, useUpdateAgencyExtMutation, useArchiveAgencyMutation } = growthApi;

type AgenciesFormValues = {
  search?: string;
};

export const Agencies = () => {
  const { canCreateAgency } = useRole();
  const { data, error, isFetching, refetch } = useAgenciesQuery({
    with_data_credits: true,
  });
  const [updateAgencyExt] = useUpdateAgencyExtMutation();
  const { watch, control } = useForm<AgenciesFormValues>();
  const values = watch();
  const [archiveAgency, { isLoading: isArchiving }] = useArchiveAgencyMutation();
  const onArchive = async (row: any) => {
    await archiveAgency({
      id: row.id,
    });
    refetch();
  };
  const { dataTableProps, selectedRow, setSelectedRow, rowStatus, changeRowStatus } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'is_white_label', 'markup', 'data_credits'],
    onChangeRowStatus: async (id: any, field: string, value: any) => {
      await updateAgencyExt({
        agency_id: id,
        [field]: value,
      }).unwrap();
    },
    columns: [
      { header: 'ID', accessor: 'id', sortable: true },
      { header: 'Agency', accessor: 'name', sortable: true },
      {
        header: 'White Label',
        accessor: 'is_white_label',
        render: (value: any, row: any) => (
          <Switch
            value={rowStatus[row.id]?.is_white_label ?? Boolean(value)}
            onChange={(value) => changeRowStatus(row.id, 'is_white_label', value)}
          />
        ),
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'Default Markup',
        accessor: 'markups',
        render: (value: any, row: any) => {
          if (!row.is_white_label) {
            return '-';
          }
          return (
            <Flex gap="sm">
              <Text>{formatMarkups(value)}</Text>
              <Action
                icon="edit"
                onClick={() => {
                  setSelectedRow(row);
                  setEditMarkupIsOpen(true);
                }}
              />
            </Flex>
          );
        },
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'Data Credits',
        accessor: 'data_credits',
        render: (value: any, row: any) => (
          <Flex gap="sm">
            <Text>{!isUndefined(value) ? formatCount(value) : '-'}</Text>
            <Action
              icon="edit"
              onClick={() => {
                setSelectedRow(row);
                setEditDataCreditsIsOpen(true);
              }}
            />
          </Flex>
        ),
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        render: (_, row) => (
          <Flex gap="md">
            <Action
              onClick={() => {
                setSelectedRow(row);
                setEditAgencyIsOpen(true);
              }}
            >
              Edit
            </Action>
            <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
          </Flex>
        ),
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
    ],
  });
  const [editAgencyIsOpen, setEditAgencyIsOpen] = useState(false);
  const [editDataCreditsIsOpen, setEditDataCreditsIsOpen] = useState(false);
  const [editMarkupIsOpen, setEditMarkupIsOpen] = useState<boolean>(false);

  return (
    <PageTemplate>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Agencies
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        {canCreateAgency && (
          <Button
            width="auto"
            shadow
            onClick={() => {
              setSelectedRow(undefined);
              setEditAgencyIsOpen(true);
            }}
          >
            NEW AGENCY
          </Button>
        )}
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
      <EditMarkupsModal
        type="agency"
        agency={selectedRow}
        isOpen={editMarkupIsOpen}
        onClose={() => setEditMarkupIsOpen(false)}
        onSuccess={() => refetch()}
      />
      <EditAgencyModal
        isOpen={editAgencyIsOpen}
        agency={selectedRow}
        onClose={() => setEditAgencyIsOpen(false)}
        onSuccess={refetch}
      />
      <EditDataCreditModal
        isOpen={editDataCreditsIsOpen}
        agencyId={selectedRow?.id}
        onClose={() => setEditDataCreditsIsOpen(false)}
        onSuccess={refetch}
      />
    </PageTemplate>
  );
};
