import { Flex, Text } from 'src/components/Layout';

type EmptyProps = {
  message?: string;
  height?: string;
};

export const Empty = (props: EmptyProps) => {
  const { message, height } = props;

  return (
    <Flex justify="center" align="center" width="100%" height={height}>
      <Text size="xs" color="gray">
        {message ?? 'No data available yet.'}
      </Text>
    </Flex>
  );
};
