import { Empty } from 'src/components/Empty';
import { TABLE_MAX_COL_SPAN } from 'src/components/Table';

type DataTableEmptyProps = {
  message?: string;
};

export const DataTableEmpty = (props: DataTableEmptyProps) => {
  const { message } = props;

  return (
    <tr>
      <td colSpan={TABLE_MAX_COL_SPAN}>
        <Empty message={message} height="9rem" />
      </td>
    </tr>
  );
};
