import { pick } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { DataTable } from 'src/components/DataTable';
import { Action, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { TOKENS } from 'src/design';
import { useCommonPersistentFilters, useDataTable } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatDspIds, formatMarkups, formatOnlyDate } from 'src/utils';
import styled from 'styled-components';
import { EditDspMappingModal } from './EditDspMappingModal';
import { EditMarkupsModal } from 'src/components/EditMarkupModal';

const { useCampaignsQuery } = growthApi;

type CampaignsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Campaigns = () => {
  const { watch, control, setValue, reset } = useForm<CampaignsFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });

  const { data, isFetching, error, refetch } = useCampaignsQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
    with_mapping: true,
  });

  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false);
  const [isMarkupModalOpen, setIsMarkupModalOpen] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const { dataTableProps } = useDataTable({
    idKey: 'id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'campaign_name', 'advertiser_name', 'start_date', 'end_date', 'effective_status', 'group'],
    customSearch: (search, row) => {
      return row.dsp_ids_mappings && row.dsp_ids_mappings.some((item: any) => String(item.dsp_id) === search);
    },
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['budget', 'status'],
    columns: [
      {
        header: 'ID',
        accessor: 'id',
        sortable: true,
      },
      { header: 'Campaign Name', accessor: 'campaign_name', width: '16rem', sortable: true },
      {
        header: 'DSP IDs',
        accessor: 'dsp_ids_mappings',
        sortable: false,
        render: (value: any, row: any) => {
          return (
            <Flex gap="sm">
              <Text>
                {formatDspIds(
                  value?.map((item: any) => ({
                    platform: item.dsp_name,
                    platform_id: item.dsp_id,
                  })),
                )}
              </Text>
              <Action
                icon="edit"
                onClick={() => {
                  setCampaign(row);
                  setIsMappingModalOpen(true);
                }}
              />
            </Flex>
          );
        },
      },
      {
        header: 'Markup',
        accessor: 'markups',
        sortable: false,
        render: (value: any, row: any) => {
          return (
            <Flex gap="sm">
              <Text>{formatMarkups(value)}</Text>
              <Action
                icon="edit"
                onClick={() => {
                  setCampaign(row);
                  setIsMarkupModalOpen(true);
                }}
              />
            </Flex>
          );
        },
      },

      { header: 'Advertiser', accessor: 'advertiser_name', width: '10rem', sortable: true },
      { header: 'Start Date', accessor: 'schedule_start_date', sortable: true, render: formatOnlyDate },
      {
        header: 'End Date',
        accessor: 'schedule_end_date',
        sortable: true,
        render: formatOnlyDate,
      },
      {
        header: 'Status',
        accessor: 'status',
        sortable: true,
      },
    ],
  });

  return (
    <PageTemplate>
      <EditDspMappingModal
        isOpen={isMappingModalOpen}
        campaign={campaign}
        onClose={() => setIsMappingModalOpen(false)}
        onSuccess={() => refetch()}
      />
      <EditMarkupsModal
        type={'campaign'}
        isOpen={isMarkupModalOpen}
        onSuccess={() => refetch()}
        onClose={() => setIsMarkupModalOpen(false)}
        campaign={campaign}
      />
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Campaigns
          </Text>
        </Flex>
      </Flex>
      <Spacing size="lg" />
      <Flex gap="md">
        <AgencySelect
          prefix="Agency:"
          name="agency_id"
          control={control}
          withAll
          width="22rem"
          onValueChange={() => {
            setValue('advertiser_id', null);
          }}
        />
        <AdvertiserSelect
          agencyId={values.agency_id}
          prefix="Advertiser:"
          name="advertiser_id"
          control={control}
          withAll
          width="22rem"
        />
      </Flex>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
        </Flex>
        <Spacing />
        <DataTable {...dataTableProps} />
      </TableContainer>
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
