import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledTextArea, Form, FormAction, FormItem } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { growthApi } from 'src/services';
import styled from 'styled-components';

const { useLazyDetectPixelQuery } = growthApi;

type DetectPixelFormValues = {
  websites: string;
};

type DetectResult = {
  url: string;
  isDetectedPixel: boolean;
};

export const DetectPixel = () => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DetectPixelFormValues>();
  const values = watch();
  const [detectPixel] = useLazyDetectPixelQuery();
  const [result, setResult] = useState<DetectResult[]>([]);

  const onSubmit = async (values: DetectPixelFormValues) => {
    setResult([]);
    const urls = values.websites.split('\n');
    for (const url of urls) {
      const { data } = await detectPixel({ url }).unwrap();
      setResult((result) => [...result, { url, isDetectedPixel: data }]);
    }
  };

  return (
    <>
      <Text size="sm" color="gray">
        Detect if websites set our pixel code correctly
      </Text>
      <Spacing size="md" />
      <Grid columns={2} gap="xl">
        <Form>
          <FormItem label="Detect websites" required>
            <ControlledTextArea
              name="websites"
              control={control}
              rows={30}
              placeholder="Enter websites need to detect"
            />
          </FormItem>
          <FormAction
            disableBack
            submitText="DETECT"
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            disabled={!values.websites}
          />
        </Form>
        <div>
          <Text weight={500}>Detect pixel result</Text>
          <Spacing />
          <DetectPixelResultContainer>
            {result.length ? (
              <Flex direction="column">
                {result.map((item) => (
                  <Flex key={item.url} align="center" gap="xs">
                    <Text size="sm">{item.url}</Text>
                    {item.isDetectedPixel ? <Icon type="check" color="success" /> : <Icon type="close" color="error" />}
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Text size="sm" color="gray">
                Please enter websites, and click detect button
              </Text>
            )}
          </DetectPixelResultContainer>
        </div>
      </Grid>
    </>
  );
};

const DetectPixelResultContainer = styled.div`
  height: 40rem;
  overflow-y: auto;
`;
