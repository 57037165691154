import { useForm } from 'react-hook-form';
import { ControlledInput, Form, FormItem } from '../Form';
import { Modal } from '../Modal';
import { useToast } from 'src/hooks';
import { getApiErrorMessage } from 'src/utils';
import { growthApi } from 'src/services';

const { useUpdateDataCreditsMutation } = growthApi;

type EditDataCreditFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  data_credits?: number;
};

type EditDataCreditModalProps = {
  isOpen: boolean;
  agencyId?: number;
  advertiserId?: number;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditDataCreditModal = (props: EditDataCreditModalProps) => {
  const { isOpen, agencyId, advertiserId, onClose, onSuccess } = props;

  const { control, handleSubmit } = useForm<EditDataCreditFormValues>();
  const [updateDataCredits, { isLoading }] = useUpdateDataCreditsMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onSubmit = async (values: EditDataCreditFormValues) => {
    try {
      await updateDataCredits({ ...values, agency_id: agencyId, advertiser_id: advertiserId }).unwrap();
      showSuccessToast('Save data credits successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Data Credits"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <Form>
          <FormItem required label="Data Credit">
            <ControlledInput name="data_credits" control={control} placeholder="Enter data credit" />
          </FormItem>
        </Form>
      </Form>
    </Modal>
  );
};
