import { ReactNode } from 'react';
import styled from 'styled-components';
import { Text, TextProps } from '../Layout';

type LabelProps = TextProps & {
  children?: ReactNode;
};

export const Label = (props: LabelProps) => {
  const { children, ...rest } = props;

  return (
    <StyledText size="sm" {...rest}>
      {children}
    </StyledText>
  );
};

const StyledText = styled(Text)`
  margin: 0.6rem;
`;
