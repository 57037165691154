// import { Button } from 'src/components/Form';
import { Flex, Text } from 'src/components/Layout';
import { Icon } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import styled from 'styled-components';
import { imageUrl, isCustomDomain } from 'src/utils';
import { getUserName } from 'src/helpers';
import { useAppSelector } from 'src/store';
import { useWhiteLabelTheme } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import useNotificationsToggle from 'src/hooks/useNotificationsToggle';
import { growthApi } from 'src/services';
import { useEffect, useState } from 'react';

const { useLazyCountNotificationsQuery } = growthApi;

export const UserBanner = () => {
  const user = useAppSelector((state) => state.user.user);
  const theme = useWhiteLabelTheme();
  const navigate = useNavigate();
  const showNotifications = useNotificationsToggle();
  const [notificationsCount] = useLazyCountNotificationsQuery();

  const [badgeNumber, setBadgeNumber] = useState(null);

  useEffect(() => {
    const fetchCount = async () => {
      const result = await notificationsCount({}).unwrap();
      setBadgeNumber(result?.data);
    };
    if (user) {
      fetchCount();
    }
  }, [user]);

  const clickNotifications = () => {
    showNotifications();
    setBadgeNumber(null);
  };

  return (
    <Flex gap="md" align="center">
      {/* <Flex gap="xs">
        <Text size="xs">Current Plan:</Text>
        <Text size="xs" weight={600}>
          Basic
        </Text>
      </Flex>
      <Button width="auto" size="xs" various="highlight">
        UPGRADE
      </Button> */}
      <UserInfoContainer>
        <Flex gap="lg" align="center" justify="center">
          {!isCustomDomain() && (
            <a href="https://growthchannel.io/help" target="_blank" rel="noreferrer">
              <Icon color="gray" type="question" />
            </a>
          )}
          <a href={`mailto: ${theme.supportEmail}`}>
            <Icon color="gray" type="email" />
          </a>
          <a onClick={() => clickNotifications()}>
            <Icon badgeNumber={badgeNumber} color="gray" type="notification" />
          </a>
          {/* <Icon color="gray" type="notification" /> */}
          <UserInfo onClick={() => navigate('/settings/profile')}>
            <img src={imageUrl('avatar.png')} alt="avatar" />
            <Text size="sm" weight={700}>
              {getUserName(user)}
            </Text>
          </UserInfo>
        </Flex>
      </UserInfoContainer>
    </Flex>
  );
};

const UserInfoContainer = styled.div`
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};
  background: white;
  padding: 1.2rem 1.6rem;

  svg {
    cursor: pointer;
  }

  img {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
`;
