import { AgencySelect } from 'src/components/AgencySelect';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { useToast } from 'src/hooks';
import { getApiErrorMessage } from 'src/utils';
import { useEffect } from 'react';

const { useCreateOrUpdateAdvertiserMutation } = growthApi;

type EditAdvertiserFormValues = {
  id?: number;
  agency_id?: number;
  name?: string;
};

type EditAdvertiserModalProps = {
  isOpen: boolean;
  advertiser?: any;
  onClose: () => void;
  onSuccess: (newId?: number) => void;
};

const DEFAULT_FORM_VALUES: EditAdvertiserFormValues = {
  id: null,
  agency_id: null,
  name: '',
};

export const EditAdvertiserModal = (props: EditAdvertiserModalProps) => {
  const { isOpen, advertiser, onClose, onSuccess } = props;
  const isNew = !advertiser;

  const { control, handleSubmit, reset } = useForm<EditAdvertiserFormValues>({ defaultValues: DEFAULT_FORM_VALUES });
  const [createOrUpdateAdvertiser, { isLoading }] = useCreateOrUpdateAdvertiserMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      if (advertiser) {
        reset(advertiser);
      } else {
        reset(DEFAULT_FORM_VALUES);
      }
    }
  }, [isOpen, advertiser, reset]);

  const onSubmit = async (values: EditAdvertiserFormValues) => {
    try {
      const { data } = await createOrUpdateAdvertiser(values).unwrap();
      if (isNew) {
        showSuccessToast('Create advertiser successfully');
      } else {
        showSuccessToast('Save advertiser successfully');
      }
      onSuccess(isNew ? data.id : undefined);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? 'New Advertiser' : 'Edit Advertiser'}
      confirmText="SUBMIT"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        {isNew && (
          <FormItem label="Agency">
            <AgencySelect name="agency_id" control={control} />
          </FormItem>
        )}
        <FormItem label="Name">
          <ControlledInput name="name" control={control} placeholder="Enter name" />
        </FormItem>
      </Form>
    </Modal>
  );
};
