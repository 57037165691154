import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { DataTable, MAX_PAGE_SIZE } from 'src/components/DataTable';
import { ControlledInput } from 'src/components/Form';
import { Flex, FlexItem, Spacing } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useDataTable, useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { growthApi } from 'src/services';
import { AudienceRule } from 'src/types';
import { formatDate, getApiErrorMessage } from 'src/utils';
import styled from 'styled-components';

const { useAudiencesQuery, useApplyAudienceExclusionRulesMutation } = growthApi;

type ApplyToActiveAudiencesModalProps = {
  isOpen: boolean;
  exclusionRules?: AudienceRule[];
  onClose: () => void;
};

type ApplyToActiveFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const ApplyToActiveAudiencesModal = (props: ApplyToActiveAudiencesModalProps) => {
  const { isOpen, exclusionRules, onClose } = props;

  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();

  const { control, watch } = useForm<ApplyToActiveFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { data, isFetching, error } = useAudiencesQuery({
    agency_id: values.agency_id,
    advertiser_id: values.advertiser_id,
  });
  const [applyAudienceExclusionRules, { isLoading }] = useApplyAudienceExclusionRulesMutation();
  const { dataTableProps, selection } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'name', 'created_on'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id'],
    defaultPageSize: MAX_PAGE_SIZE,
    enableSelection: true,
    columns: [
      { header: 'ID', accessor: 'id' },
      { header: 'Name', accessor: 'audience_name' },
      { header: 'Created At', accessor: 'created_at', render: formatDate },
    ],
  });
  const { showSuccessToast, showErrorToast } = useToast();

  const onConfirm = async () => {
    try {
      await applyAudienceExclusionRules({
        ids: selection,
        rules: exclusionRules,
      }).unwrap();
      showSuccessToast('Apply exclusion rules to active audiences successfully');
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Apply Exclusion Rules To Active Audiences"
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      width="70vw"
    >
      <Flex gap="lg">
        {canAccessAgency && (
          <>
            <AgencySelect prefix="Agency:" name="agency_id" withAll width="22rem" control={control} />
            <AdvertiserSelect
              prefix="Advertiser:"
              name="advertiser_id"
              withAll
              agencyId={values.agency_id}
              width="22rem"
              control={control}
            />
          </>
        )}
        <FlexItem grow={1}>
          <ControlledInput prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </FlexItem>
      </Flex>
      <Spacing />
      <DataTableContainer>
        <DataTable {...dataTableProps} />
      </DataTableContainer>
    </Modal>
  );
};

const DataTableContainer = styled.div`
  height: 55rem;
  overflow-y: auto;
`;
