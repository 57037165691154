import { Fragment, useEffect, useMemo, useState } from 'react';
import { Icon } from '../Icon';
import { Flex, Grid, Spacing, Text } from '../Layout';
import { Modal } from '../Modal';
import { Checkbox } from '../Form';
import { useForm } from 'react-hook-form';
import { Column } from './DataTable';

type DataTableColumnCustomizeFormValues = {
  customizeColumns: string[];
};

type DataTableColumnCustomizeProps = {
  columns: Column[];
  customizeColumns: string[];
  onCustomizeColumnsChange: (customizeColumns: string[]) => void;
};

export const DataTableColumnCustomize = (props: DataTableColumnCustomizeProps) => {
  const { columns, customizeColumns, onCustomizeColumnsChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { watch, setValue } = useForm<DataTableColumnCustomizeFormValues>();
  const values = watch();

  useEffect(() => {
    if (isOpen) {
      setValue('customizeColumns', customizeColumns);
    }
  }, [customizeColumns, isOpen, setValue]);

  const groups = useMemo(() => {
    const result: string[] = [];
    columns.forEach((column) => {
      if (column.customizeGroup && !result.includes(column.customizeGroup)) {
        result.push(column.customizeGroup);
      }
    });
    return result;
  }, [columns]);

  const getCustomizeColumnProps = (customizeColumn: string) => {
    return {
      label: customizeColumn,
      value: values.customizeColumns?.includes(customizeColumn),
      onChange: (value: boolean) => {
        if (value) {
          setValue('customizeColumns', [...(values.customizeColumns || []), customizeColumn]);
        } else {
          setValue('customizeColumns', values.customizeColumns?.filter((item) => item !== customizeColumn) || []);
        }
      },
    };
  };

  const onConfirm = () => {
    onCustomizeColumnsChange(values.customizeColumns);
    setIsOpen(false);
  };

  return (
    <>
      <Flex clickable align="center" gap="xs" onClick={() => setIsOpen(true)}>
        <Text color="primary" size="sm" weight={600}>
          Table columns
        </Text>
        <Icon size="md" color="primary" type="forecast" />
      </Flex>
      <Modal isOpen={isOpen} title="Customize Table Columns" onClose={() => setIsOpen(false)} onConfirm={onConfirm}>
        <Flex direction="column" gap="xl">
          {groups.map((group) => (
            <div key={group}>
              <Text weight={500}>{group}</Text>
              <Spacing />
              <Grid columns={3} gap="sm">
                {columns
                  .filter((column) => column.customizeGroup === group)
                  .map((column) => (
                    <Checkbox key={column.accessor} {...getCustomizeColumnProps(column.header as string)} />
                  ))}
              </Grid>
            </div>
          ))}
        </Flex>
      </Modal>
    </>
  );
};
