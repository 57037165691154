import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { FormAction } from 'src/components/Form/FormAction';
import { Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { useMemo } from 'react';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { InsightFilter } from 'src/components/Insight';
import { useNavigate } from 'react-router-dom';

const { useCreateOrUpdateCustomAudienceInsightMutation } = growthApi;

export type PixelFormValues = {
  agency_id: number;
  advertiser_id: number;
  name: string;
  request_filter: object;
};

export const BuildFromScratch = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId } = useUserAgencyAdvertiser();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const [createOrUpdateCustomAudienceInsight, { isLoading }] = useCreateOrUpdateCustomAudienceInsightMutation();
  const defaultValues: PixelFormValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      name: '',
      request_filter: {},
    }),
    [agencyId, advertiserId],
  );
  const { watch, handleSubmit, control, setValue } = useForm<PixelFormValues>({
    defaultValues,
  });
  const values = watch();

  const onSubmit = async (values: PixelFormValues) => {
    try {
      await createOrUpdateCustomAudienceInsight(values).unwrap();
      showSuccessToast('Create insight successfully');
      navigate('/planning/insights');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate>
      <Text size="xxl" weight={700}>
        Build From Scratch
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        {canAccessAgency && (
          <>
            <FormItem label="Agency" required>
              <AgencySelect
                name="agency_id"
                control={control}
                onValueChange={() => {
                  setValue('advertiser_id', null);
                }}
              />
            </FormItem>
            <FormItem label="Advertiser" required>
              <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
            </FormItem>
          </>
        )}
        <FormItem label="Insight Name" required>
          <ControlledInput name="name" control={control} placeholder="Enter insight name" />
        </FormItem>
        <FormItem label="Insight Scope">
          <InsightFilter name="request_filter" control={control} />
        </FormItem>
        <FormAction onSubmit={handleSubmit(onSubmit)} isSubmitting={isLoading} />
      </Form>
    </PageTemplate>
  );
};
