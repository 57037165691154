import { HTMLProps } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { TextArea, TextAreaProps } from './TextArea';

type ControlledTextAreaProps<T> = UseControllerProps<T> & {
  placeholder?: string;
  rows?: number;
} & Pick<TextAreaProps, 'width'>;

export const ControlledTextArea = <T,>(props: ControlledTextAreaProps<T>) => {
  const { placeholder, rows, width, ...useControllerProps } = props;

  const {
    field: { name, onChange, onBlur, value, ref },
  } = useController(useControllerProps);

  return (
    <TextArea
      name={name}
      value={(value as HTMLProps<HTMLInputElement>['value']) || ''}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      width={width}
      rows={rows}
      ref={ref}
    />
  );
};
