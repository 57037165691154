import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { Form, FormItem } from 'src/components/Form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useManualDspBillingMutation } = growthApi;

type DailyBillManuallyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type DailyBillManuallyFormValues = {
  date?: Date;
  advertiser_id?: number;
};

export const DailyBillManuallyModal = (props: DailyBillManuallyModalProps) => {
  const { isOpen, onClose, onSuccess } = props;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DailyBillManuallyFormValues>({
    defaultValues: {
      advertiser_id: null,
    },
  });
  const [triggerDspBilling] = useManualDspBillingMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const validate = (values: DailyBillManuallyFormValues) => {
    if (!values.date) {
      showErrorToast('Please select bill date');
      return false;
    }
    return true;
  };

  const onSubmit = async (values: DailyBillManuallyFormValues) => {
    const isValidated = validate(values);
    if (!isValidated) {
      return;
    }
    try {
      await triggerDspBilling({
        date: format(values.date, 'yyyy/MM/dd 08:00:00'),
        advertiser_id: values.advertiser_id,
      }).unwrap();
      showSuccessToast('Daily billing manually successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Daily Bill Manually"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
    >
      <Form>
        <FormItem label="Date" required>
          <ControlledDatePicker name="date" control={control} placeholder="Please select date" />
        </FormItem>
        <FormItem label="Advertiser">
          <AdvertiserSelect name="advertiser_id" control={control} withAll placeholder="Please select advertiser" />
        </FormItem>
      </Form>
    </Modal>
  );
};
