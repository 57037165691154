import { AudienceRule } from 'src/types';
import { CustomRuleTypeDataProvider, DATA_PROVIDERS } from './CustomRuleTypeDataProvider';
import { CustomRuleTypeDayPart, CUSTOM_RULE_DAY_PART_ID } from './CustomRuleTypeDayPart';
import { CustomRuleTypeDeviceModel, CUSTOM_RULE_DEVICE_MODEL_ID } from './CustomRuleTypeDeviceModel';
import { CustomRuleTypeGeoFence, CUSTOM_RULE_GEO_FENCE } from './CustomRuleTypeGeoFence';
import { CustomRuleTypeOperatingSystem, CUSTOM_RULE_OPERATING_SYSTEM_ID } from './CustomRuleTypeOperatingSystem';
import { CustomRuleTypePixels, CUSTOM_RULE_PIXELS_ID } from './CustomRuleTypePixels';
import { CustomRuleTypePMP, CUSTOM_RULE_PMP_ID } from './CustomRuleTypePMP';
import { CUSTOM_RULE_THIRD_PARTY_AUDIENCES_ID, CustomThirdPartyAudiences } from './CustomRuleTypeThirdPartyAudiences';

type CustomRuleTypeProps = {
  ruleTypeId: number;
  rule?: AudienceRule;
  agencyId?: number;
  advertiserId?: number;
};

export const CustomRuleType = (props: CustomRuleTypeProps) => {
  const { ruleTypeId, rule, agencyId, advertiserId } = props;

  const dataProvider = DATA_PROVIDERS.find((item) => item.ruleTypeId === ruleTypeId);

  if (ruleTypeId === CUSTOM_RULE_THIRD_PARTY_AUDIENCES_ID) {
    return <CustomThirdPartyAudiences />;
  }

  if (ruleTypeId === CUSTOM_RULE_DAY_PART_ID) {
    return <CustomRuleTypeDayPart rule={rule} />;
  }

  if (ruleTypeId === CUSTOM_RULE_PIXELS_ID) {
    return <CustomRuleTypePixels agencyId={agencyId} advertiserId={advertiserId} rule={rule} />;
  }

  if (ruleTypeId === CUSTOM_RULE_GEO_FENCE) {
    return <CustomRuleTypeGeoFence agencyId={agencyId} advertiserId={advertiserId} rule={rule} />;
  }

  if (ruleTypeId === CUSTOM_RULE_DEVICE_MODEL_ID) {
    return <CustomRuleTypeDeviceModel rule={rule} />;
  }

  if (ruleTypeId === CUSTOM_RULE_OPERATING_SYSTEM_ID) {
    return <CustomRuleTypeOperatingSystem rule={rule} />;
  }

  if (ruleTypeId === CUSTOM_RULE_PMP_ID) {
    return <CustomRuleTypePMP />;
  }

  if (dataProvider) {
    return <CustomRuleTypeDataProvider {...dataProvider} />;
  }

  return null;
};
