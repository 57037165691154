export const calcWithMarkup = (value: any, markup?: number) => {
  if (value === undefined || value === '-') {
    return '-';
  }
  return value * (1 + (markup ?? 0) / 100);
};

export const calcWithMarkupNumber = (value: any, markup?: number) => {
  const result = calcWithMarkup(value, markup);
  return result === '-' ? 0 : result;
};

export const fixPrecision = (value: any) => {
  const numberValue = Number(value);
  return isNaN(numberValue) ? 0 : Number(numberValue.toFixed(2));
};
