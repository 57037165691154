import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { PlatformSelect } from 'src/components/PlatformSelect';

const { useCreateOrUpdateDspCampaignMappingMutation } = growthApi;

type EditDpsMappingModalProps = {
  isOpen: boolean;
  campaign: any;
  onClose: () => void;
  onSuccess: () => void;
};

type DspCampaignModelFormValues = {
  campaign_id: number;
  dsp_mappings: {
    id: string;
    dsp_name?: string;
    dsp_id?: number;
  }[];
};

export const EditDspMappingModal = (props: EditDpsMappingModalProps) => {
  const { isOpen, campaign, onClose, onSuccess } = props;

  const { watch, control, setValue, handleSubmit } = useForm<DspCampaignModelFormValues>();
  const values = watch();

  useEffect(() => {
    if (isOpen) {
      setValue('campaign_id', campaign?.id);
      setValue('dsp_mappings', campaign?.dsp_ids_mappings || []);
    }
  }, [isOpen, campaign, setValue]);

  const [createOrUpdateDspCampaignsMapping, { isLoading }] = useCreateOrUpdateDspCampaignMappingMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onAddMapping = () => {
    setValue('dsp_mappings', [...values.dsp_mappings, { id: nanoid() }]);
  };

  const onRemoveMapping = (id: string) => {
    setValue(
      'dsp_mappings',
      values.dsp_mappings.filter((mapping) => mapping.id !== id),
    );
  };

  const onSubmit = async (values: DspCampaignModelFormValues) => {
    try {
      await createOrUpdateDspCampaignsMapping(values).unwrap();
      showSuccessToast('Updated dsp campaign mappings successfully');
      onSuccess();
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const disabled = values.dsp_mappings?.some((item) => !item.dsp_name || !item.dsp_id);

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Campaign DSP IDs"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
      disabled={disabled}
    >
      <Form>
        <FormItem label="Campaign">
          <Text size="sm">
            {campaign?.campaign_name} - {campaign?.id}
          </Text>
        </FormItem>
        <FormItem
          label="DSP IDs"
          addition={
            <Text color="primary" size="sm" weight={600} onClick={onAddMapping} clickable>
              Add a DSP Id
            </Text>
          }
        >
          <Spacing />
          <Flex direction="column" gap="md">
            {values.dsp_mappings?.map((mapping, index) => (
              <Flex key={mapping.id} gap="md" align="center">
                <PlatformSelect
                  name={`dsp_mappings.${index}.dsp_name`}
                  control={control}
                  placeholder="Select platform"
                />
                <ControlledInput
                  name={`dsp_mappings.${index}.dsp_id`}
                  control={control}
                  type="number"
                  placeholder="Enter dsp campaign id"
                />
                <Icon type="trash" color="error" onClick={() => onRemoveMapping(mapping.id)} clickable />
              </Flex>
            ))}
          </Flex>
        </FormItem>
      </Form>
    </Modal>
  );
};
