import styled from 'styled-components';
import { Flex, Text } from 'src/components/Layout';
import { floor } from 'lodash';

type ProgressProps = {
  width?: string;
  value: number;
  description?: string;
};

export const Progress = (props: ProgressProps) => {
  const { width, value, description } = props;

  return (
    <Flex direction="column" gap="lg" align="center">
      <ProgressbarContainer width={width}>
        <Progressbar value={value}>
          <Text color="white" size="sm" weight={600}>
            {floor(value)}%
          </Text>
        </Progressbar>
      </ProgressbarContainer>
      {description && (
        <Text size="sm" color="gray">
          {description}
        </Text>
      )}
    </Flex>
  );
};

const ProgressbarContainer = styled.div<{ width?: string }>`
  position: relative;
  width: ${(props) => props.width ?? '100%'};
  height: 3rem;
  background: ${(props) => props.theme.color.grayLighter};
  border-radius: 3rem;
`;
const Progressbar = styled.div<{ value: number }>`
  position: absolute;
  left: 0;
  min-width: 6rem;
  width: ${(props) => props.value}%;
  background: ${(props) => props.theme.color.primary};
  height: 3rem;
  border-radius: 3rem;
  text-align: center;
  line-height: 3rem;
  transition: width 0.1s;
`;
