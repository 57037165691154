import { useAppSelector } from 'src/store';
import { Role } from 'src/types';

export const useRole = () => {
  const user = useAppSelector((state) => state.user.user);
  const whiteLabel = useAppSelector((state) => state.user.whiteLabel);

  return {
    user,
    whiteLabel,
    // role
    role: user?.role,
    isAdmin: user?.role === Role.Admin,
    isWhiteLabel: user?.role === Role.WhiteLabel,
    isAgency: user?.role === Role.Agency,
    isAdvertiser: user?.role === Role.Advertiser,
    isViewOnly: user?.role === Role.ViewOnly,
    // permissions
    canAccessAgency: [Role.Admin, Role.WhiteLabel, Role.Agency].includes(user?.role),
    canWithMarkup: [Role.Admin, Role.WhiteLabel].includes(user?.role),
    canCreateAgency: [Role.Admin, Role.WhiteLabel].includes(user?.role),
    // role information
    agencyId: user?.dsp_agency_id,
    advertiserId: user?.dsp_advertiser_id,
    whiteLabelId: whiteLabel?.id,
  };
};
