import { ControlledInput, Form, FormItem, Label } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { useToast } from 'src/hooks';
import { formatAmount, formatDate, getApiErrorMessage } from 'src/utils';
import { Flex } from 'src/components/Layout';
import { isEmpty, omitBy } from 'lodash';

const { useCreateOrUpdateForecastMutation } = growthApi;

type EditAdvertiserFormValues = {
  max_data_cpm?: number;
  cpm_impressions?: number;
  cpm_uniques?: number;
  cpm_sov?: number;
  total_impressions?: number;
  total_uniques?: number;
};

type ForecastReplyModalProps = {
  isOpen: boolean;
  forecast?: any;
  onClose: () => void;
  onSuccess: (newId?: number) => void;
};

export const ForecastReplyModal = (props: ForecastReplyModalProps) => {
  const { isOpen, forecast, onClose, onSuccess } = props;

  const { control, handleSubmit } = useForm<EditAdvertiserFormValues>();
  const [createOrUpdateForecast, { isLoading }] = useCreateOrUpdateForecastMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onSubmit = async (values: EditAdvertiserFormValues) => {
    try {
      await createOrUpdateForecast({ ...forecast, ...omitBy(values, isEmpty), is_replied: true }).unwrap();
      showSuccessToast('Forecast reply successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Forecast Reply"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Audience">
          <Flex gap="md">
            <Label>{forecast?.audience_name ? `{forecast?.audience_name} - {forecast?.audience_id}` : '-'}</Label>
          </Flex>
        </FormItem>
        <FormItem label="Campaign Budget">
          <Label>{formatAmount(forecast?.campaign_budget)}</Label>
        </FormItem>
        <FormItem label="Campaign Start/End Date">
          <Label>
            {formatDate(forecast?.campaign_start_date)} - {formatDate(forecast?.campaign_end_date)}
          </Label>
        </FormItem>
        <FormItem label="Channels">
          <Label>{forecast?.channels}</Label>
        </FormItem>
        <FormItem label="GEO">
          <Label>{forecast?.geo}</Label>
        </FormItem>
        <FormItem label="CPM Goal">
          <Label>{formatAmount(forecast?.cpm_goal)}</Label>
        </FormItem>
        <FormItem label="Max Data CPM">
          <ControlledInput
            prefix="$"
            type="number"
            name="max_data_cpm"
            control={control}
            placeholder="Enter max data cpm"
          />
        </FormItem>
        <FormItem label="Impressions at {$} CPM">
          <ControlledInput
            type="number"
            name="cpm_impressions"
            control={control}
            placeholder="Enter impressions at {$} cpm"
          />
        </FormItem>
        <FormItem label="Uniques at {$} CPM">
          <ControlledInput type="number" name="cpm_uniques" control={control} placeholder="Enter max data cpm" />
        </FormItem>
        <FormItem label="SOV at {$} CPM">
          <ControlledInput
            suffix="%"
            type="number"
            name="cpm_sov"
            control={control}
            placeholder="Enter sov at {$} cpm"
          />
        </FormItem>
        <FormItem label="Total Impressions">
          <ControlledInput
            type="number"
            name="total_impressions"
            control={control}
            placeholder="Enter total impressions"
          />
        </FormItem>
        <FormItem label="Total Uniques">
          <ControlledInput type="number" name="total_uniques" control={control} placeholder="Enter total uniques" />
        </FormItem>
      </Form>
    </Modal>
  );
};
