import { TOKENS } from 'src/design';
import { KeyboardEvent, ReactNode, useCallback, useMemo, useRef } from 'react';
import { ChangeEvent, useState, MouseEvent } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Button } from './Button';
import { Input, InputItem } from './Input';
import { formatText } from 'src/utils';

const DEFAULT_PLACEHOLDER = 'Select an option';

export type ControlledSelectOption = {
  label: string;
  labelNode?: ReactNode;
  value?: string | number;
  key?: string;
};

export type ControlledSelectProps<T> = UseControllerProps<T> & {
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  width?: string;
  options?: ControlledSelectOption[];
  prefix?: string;
  allowNew?: boolean;
  newLabel?: string;
  onNew?: () => void;
  onValueChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
};

export const ControlledSelect = <T,>(props: ControlledSelectProps<T>) => {
  const {
    placeholder = DEFAULT_PLACEHOLDER,
    isLoading,
    disabled,
    multiple,
    width,
    options,
    prefix,
    allowNew,
    newLabel,
    onNew,
    onValueChange,
    onInputChange,
    ...useControllerProps
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    return options?.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
  }, [inputValue, options]);

  const {
    field: { onChange, value },
  } = useController(useControllerProps);

  const typedValue = value as string | undefined;
  const typedMultipleValue: (string | number)[] = useMemo(() => {
    if (value) {
      if (Array.isArray(value)) {
        return value;
      } else if (typeof value === 'string') {
        // support string value, exp 1,2,3, and also check if item is number
        return value.split(',').map((item) => (/\d+/.test(item) ? Number(item) : item));
      } else if (typeof value === 'number') {
        // support number value, exp 1
        return [value];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [value]);

  const selectedInputValue = useMemo(() => {
    if (multiple) {
      return undefined;
    } else {
      return options?.find((option) => option.value === typedValue)?.label;
    }
  }, [multiple, options, typedValue]);

  const selectedInputItems = useMemo(() => {
    if (!multiple) {
      return undefined;
    } else {
      return options?.filter((option) => typedMultipleValue?.includes(option.value));
    }
  }, [multiple, options, typedMultipleValue]);

  const onInputChangeWrapper = (event: ChangeEvent<HTMLInputElement>) => {
    setIsOptionsOpen(true);
    setInputValue(event.target.value);
    onInputChange?.(event.target.value);
  };

  const onInputClick = useCallback(() => {
    if (disabled) {
      return;
    }
    inputRef.current?.focus();
    setIsOptionsOpen(true);
    if (!isOptionsOpen) {
      setInputValue('');
    }
  }, [disabled, isOptionsOpen]);

  const onInputFocus = () => {
    setIsFocus(true);
    setInputValue('');
  };

  const onInputBlur = () => {
    setIsFocus(false);
    setIsOptionsOpen(false);
  };

  const onChangeWrapper = useCallback(
    (value: any) => {
      onChange(value);
      onValueChange?.(value);
    },
    [onChange, onValueChange],
  );

  const onSelectOption = useCallback(
    (event: MouseEvent<HTMLDivElement>, option: ControlledSelectOption) => {
      event.preventDefault();
      if (multiple) {
        if (!typedMultipleValue?.includes(option.value!)) {
          onChangeWrapper([...(typedMultipleValue || []), option.value]);
        } else {
          onChangeWrapper(typedMultipleValue?.filter((value) => value !== option.value));
        }
      } else {
        setIsOptionsOpen(false);
        onChangeWrapper(option.value);
        setInputValue(option.label);
      }
    },
    [multiple, onChangeWrapper, typedMultipleValue],
  );

  const onInputItemRemove = (inputItem: InputItem) => {
    onChangeWrapper(typedMultipleValue?.filter((value) => value !== inputItem.value));
  };

  const onInputKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && filteredOptions.length > 0) {
        onSelectOption(event as any, filteredOptions[0]);
      }
    },
    [filteredOptions, onSelectOption],
  );

  return (
    <Container width={width} onClick={onInputClick}>
      <SelectInput
        placeholder={isLoading ? 'loading...' : selectedInputValue ?? placeholder}
        value={isFocus ? inputValue : selectedInputValue || ''}
        items={selectedInputItems}
        onItemRemove={onInputItemRemove}
        onChange={onInputChangeWrapper}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onKeyDown={onInputKeydown}
        width={width}
        icon="select"
        iconPosition="right"
        iconColor="primary"
        prefix={prefix}
        disabled={disabled}
        $isOptionsOpen={!isLoading && isOptionsOpen}
        ref={inputRef}
      />
      {!isLoading && isOptionsOpen && (
        <OptionsContainer>
          {allowNew && (
            <New>
              <Button size="xs" onMouseDown={onNew}>
                {newLabel}
              </Button>
            </New>
          )}
          {filteredOptions?.map((option) => (
            <Option
              key={option.key || option.value || ''}
              active={multiple ? typedMultipleValue?.includes(option.value!) : typedValue === option.value}
              onMouseDown={(e) => onSelectOption(e, option)}
            >
              {option.labelNode ?? formatText(option.label)}
            </Option>
          ))}
          {!filteredOptions?.length && <NoResult>No result</NoResult>}
        </OptionsContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ width?: string }>`
  ${(props) => (props.width ? `width: ${props.width};` : '')};
  position: relative;
`;

const SelectInput = styled(Input)<{ $isOptionsOpen: boolean; disabled?: boolean }>`
  border-radius: ${(props) => (props.$isOptionsOpen ? '1.2rem 1.2rem 0 0' : '1.2rem')} !important;
  ${(props) =>
    props.disabled
      ? css`
          background: #f1f1f1;
          input {
            color: #999;
          }
        `
      : ''}
`;

const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: white;
  font-size: 1.2rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  border: solid 0.1rem ${(props) => props.theme.color.grayLighter};
  border-radius: 0 0 1.2rem 1.2rem;
  max-height: 24rem;
  overflow-y: auto;
  text-align: left;
`;

const Option = styled.div<{ active?: boolean }>`
  padding: 1rem 1.8rem;
  word-break: break-all;
  cursor: pointer;

  &:hover {
    background: #f1f1f1;
  }

  ${(props) =>
    props.active
      ? css`
          color: ${(props) => props.theme.color.primary};
          font-weight: 600;
        `
      : ''}
`;

const New = styled.div`
  padding: 1rem 1.8rem;
`;

const NoResult = styled.div`
  font-size: 1.2rem;
  color: ${TOKENS.color.gray};
  text-align: center;
  padding: 1.6rem 0;
`;
