import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import { Action, Button, ControlledInput, ControlledSelect, Required, Steps, Switch } from 'src/components/Form';
import { Progress } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { VerticalSelect } from 'src/components/VerticalSelect';
import { TOKENS } from 'src/design';
import { useReloadUser, useToast, useWorkspace } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import styled, { css } from 'styled-components';

const { useMarketPlansQuery, useCreateOrUpdateMarketPlanMutation } = growthApi;

enum StartStep {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
}

enum StartCustomerType {
  Consumers = 'consumers',
  Businesses = 'businesses',
  Partners = 'partners',
  Sponsors = 'sponsors',
  Donors = 'donors',
  Employees = 'employees',
  Candidates = 'candidates',
}

enum StartObjective {
  Awareness = 'awareness',
  ClickAndTraffic = 'click_and_traffic',
  Video = 'video',
  Conversions = 'conversions',
  Audio = 'audio',
}

enum StartDataCreditType {
  Persona = 'persona',
  Tactics = 'tactics',
  CustomerJourney = 'customer_journey',
  PublishingSites = 'publishing_sites',
  KeywordGroups = 'keyword_groups',
  PPCAds = 'ppc_ads',
  AmazonAds = 'amazon_ads',
  FacebookAds = 'facebook_ads',
  LinkedInAds = 'kinkedin_ads',
}

const OVERVIEW_OBJECTIVE_OPTIONS = [
  { label: 'Awareness', value: StartObjective.Awareness },
  { label: 'Clicks & Traffic', value: StartObjective.ClickAndTraffic },
  { label: 'Video', value: StartObjective.Video },
  { label: 'Audio', value: StartObjective.Conversions },
  { label: 'Conversions', value: StartObjective.Audio },
];

const OVERVIEW_CUSTOMER_TYPE_OPTIONS = [
  { label: 'Consumers', value: StartCustomerType.Consumers },
  { label: 'Businesses', value: StartCustomerType.Businesses },
  { label: 'Partners', value: StartCustomerType.Partners },
  { label: 'Sponsors', value: StartCustomerType.Sponsors },
  { label: 'Donors', value: StartCustomerType.Donors },
  { label: 'Employees', value: StartCustomerType.Employees },
  { label: 'Candidates', value: StartCustomerType.Candidates },
];

const OVERVIEW_DATA_CREDIT_ITEMS = [
  {
    label: 'Persona',
    type: StartDataCreditType.Persona,
    dataCredit: 250,
    desc: 'Identify your ideal customer profile with demographics and interests',
  },
  {
    label: 'Tactics',
    type: StartDataCreditType.Tactics,
    dataCredit: 150,
    desc: 'Your top 10 growth marketing tactics and strategies to focus on',
    disabled: true,
  },
  {
    label: 'Customer journey',
    type: StartDataCreditType.CustomerJourney,
    dataCredit: 250,
    desc: 'Identify your channel strategy across the customer decision journey',
    disabled: true,
  },
  {
    label: 'Publishing sites',
    type: StartDataCreditType.PublishingSites,
    dataCredit: 50,
    desc: 'Your top 25 publishing sites for advertising and content opportunities',
    disabled: true,
  },
  {
    label: 'Keyword groups',
    type: StartDataCreditType.KeywordGroups,
    dataCredit: 50,
    desc: '20 keyword opportunities split into the 4 groups for your SEO and SEM efforts',
    disabled: true,
  },
  {
    label: 'PPC ads',
    type: StartDataCreditType.PPCAds,
    dataCredit: 50,
    desc: 'Generate 3 ad copies with headlines and description text for your PPC',
    disabled: true,
  },
  {
    label: 'Amazon ads',
    type: StartDataCreditType.AmazonAds,
    dataCredit: 50,
    desc: 'Generate 3 ad copies for your Amazon ad campaigns',
    disabled: true,
  },
  {
    label: 'Facebook ads',
    type: StartDataCreditType.FacebookAds,
    dataCredit: 50,
    desc: 'Generate 3 ad copies for your Facebook in-feed ad campaigns',
    disabled: true,
  },
  {
    label: 'LinkedIn ads',
    type: StartDataCreditType.LinkedInAds,
    dataCredit: 50,
    desc: 'Generate 3 ad copies for your LinkedIn text ad campaigns',
    disabled: true,
  },
];

type StartFormValues = {
  company_name: string;
  industry: number;
  website: string;
  product: string;
  target_geo: string;
  customer_type: string;
  target_audience: string;
  objective: string;
  competitors: string;
  main_benefits: string;
  data_credits: StartDataCreditType[];
};

export const Start = () => {
  const workspace = useWorkspace();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { data, isLoading } = useMarketPlansQuery(
    {
      workspace_id: workspace?.id,
      id,
    },
    { skip: !id },
  );
  const [step, setStep] = useState<StartStep>(StartStep.Step1);
  const { watch, control, setValue, reset } = useForm<StartFormValues>({
    defaultValues: {
      data_credits: [],
    },
  });
  const values = watch();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [result, setResult] = useState<any>();
  const nav = useNavigate();
  const [createOrUpdateMarketPlan] = useCreateOrUpdateMarketPlanMutation();
  const reloadUser = useReloadUser();
  const { showErrorToast } = useToast();

  useEffect(() => {
    if (data?.data) {
      reset(data?.data?.data);
    }
  }, [data, reset]);

  const setProgressTimer = () => {
    const allTime = 30000;
    const stepOfTime = 500;
    const progressTimer = setInterval(() => {
      setProgressValue((progressValue) => {
        if (progressValue >= 100) {
          clearInterval(progressTimer);
        }
        return Math.min(progressValue + (stepOfTime / allTime) * 100, 99);
      });
    }, stepOfTime);
  };

  const step1Disabled = useMemo(() => {
    if (!values.company_name) {
      return true;
    }
    return false;
  }, [values]);

  const generateMyPlaybook = async () => {
    setIsGenerating(true);
    setProgressTimer();
    try {
      const { data } = await createOrUpdateMarketPlan({
        workspace_id: workspace?.id,
        id: id ? Number(id) : undefined,
        data: values,
      }).unwrap();
      setResult(data);
      setProgressValue(100);
      // wait a moment for progress bar animation
      await new Promise((resolve) => setTimeout(resolve, 300));
      reloadUser();
      setIsGenerated(true);
    } catch (error) {
      setProgressValue(0);
      showErrorToast(getApiErrorMessage(error));
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Flex justify="end" align="center">
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Flex direction="column" align="center">
        <Spacing size="5rem" />
        <Text size="xxl" weight={700}>
          Let's get you started
        </Text>
        <Spacing size="6rem" />
        <Steps value={step} steps={['Your account', 'Business strategy', 'Marketing outline']} />
        <Spacing size="6rem" />
        {step === StartStep.Step1 && (
          <FormContainer>
            <Text weight={600}>Account information</Text>
            <Spacing size="lg" />
            <Text size="sm">
              Tell Growth Channel AI a little bit about your business to get an instant marketing plan with a list of
              growth opportunities
            </Text>
            <Spacing size="xxl" />
            <Grid columns="14rem 1fr" gap="md" align="center">
              <Flex gap="xs" align="center">
                <Text weight={600} size="sm" align="left">
                  Company name
                </Text>
                <Required />
              </Flex>
              <ControlledInput name="company_name" control={control} placeholder="ABC Company" />
              <Text weight={600} size="sm" align="left">
                Select Industry
              </Text>
              <VerticalSelect name="industry" control={control} />
              <Text weight={600} size="sm" align="left">
                Website
              </Text>
              <ControlledInput name="website" control={control} placeholder="YourWebsiteURL.com" />
            </Grid>
            <Spacing size="6rem" />
            <Flex justify="center" gap="xl">
              <Button width="16rem" various="secondary" onClick={() => nav(-1)}>
                BACK
              </Button>
              <Button width="16rem" shadow onClick={() => setStep(StartStep.Step2)} disabled={step1Disabled}>
                NEXT
              </Button>
            </Flex>
          </FormContainer>
        )}
        {step === StartStep.Step2 && (
          <>
            <FormContainer>
              <Text weight={600}>Marketing information</Text>
              <Spacing size="lg" />
              <Text size="sm">
                Tell Growth Channel AI a little bit about your business to get an instant marketing plan with a list of
                growth opportunities
              </Text>
              <Spacing size="xxl" />
              <Grid columns="14rem 1fr" gap="md" align="center">
                <Text weight={600} size="sm" align="left">
                  Product / service
                </Text>
                <ControlledInput name="product" control={control} placeholder="What are you selling?" />
                <Text weight={600} size="sm" align="left">
                  Target geography
                </Text>
                <ControlledInput
                  name="target_geo"
                  control={control}
                  placeholder="Global, Country, City, State, Region?"
                />
                <Text weight={600} size="sm" align="left">
                  Type of customers
                </Text>
                <ControlledSelect name="customer_type" control={control} options={OVERVIEW_CUSTOMER_TYPE_OPTIONS} />
                <Text weight={600} size="sm" align="left">
                  Target audience
                </Text>
                <ControlledInput
                  name="target_audience"
                  control={control}
                  placeholder="Describe your ideal customer profile"
                />
                <Text weight={600} size="sm" align="left">
                  Objective
                </Text>
                <ControlledSelect name="objective" control={control} options={OVERVIEW_OBJECTIVE_OPTIONS} />
                <Text weight={600} size="sm" align="left">
                  Competitors
                </Text>
                <ControlledInput name="competitors" control={control} placeholder="Competitors urls" />
                <Text weight={600} size="sm" align="left">
                  Main benefits
                </Text>
                <ControlledInput
                  name="main_benefits"
                  control={control}
                  placeholder="Describe your competitive advantages"
                />
              </Grid>
              <Spacing size="6rem" />
              <Flex justify="center" gap="xl">
                <Button width="16rem" various="secondary" onClick={() => setStep(StartStep.Step1)}>
                  BACK
                </Button>
                <Button width="16rem" shadow onClick={() => setStep(StartStep.Step3)}>
                  NEXT
                </Button>
              </Flex>
            </FormContainer>
          </>
        )}
        {step === StartStep.Step3 &&
          (isGenerated ? (
            <>
              <Text weight={600}>Your marketing plan is ready</Text>
              <Spacing size="5rem" />
              <StartGenerateResult>
                <Flex justify="center" gap="md">
                  <Card>
                    <Flex justify="space-between" align="center" gap="md">
                      <IconWrapper>
                        <Icon size="lg" type="users" />
                      </IconWrapper>
                      <FlexItem grow={1}>
                        <Flex direction="column" gap="sm">
                          <Text size="md" weight={500}>
                            1
                          </Text>
                          <Text size="xs" color="gray">
                            Target Persona
                          </Text>
                        </Flex>
                      </FlexItem>
                    </Flex>
                  </Card>
                </Flex>
              </StartGenerateResult>
              <Spacing size="6rem" />
              <Button
                width="26rem"
                shadow
                onClick={() => {
                  nav(`/planning/personas/edit?id=${result.persona.id}`);
                }}
              >
                SEE MY MARKETING PLAN
              </Button>
            </>
          ) : isGenerating ? (
            <>
              <Text weight={600}>Generating your playbook</Text>
              <Spacing size="8rem" />
              <Progress
                width="50rem"
                value={progressValue}
                description="Please wait a moment, it will take about 30 seconds"
              />
            </>
          ) : (
            <>
              <FormContainer>
                <Text weight={600}>Set the scope of your marketing plan</Text>
                <Spacing size="lg" />
                <Text size="sm">Choose the sections you want to include in your marketing plan</Text>
              </FormContainer>
              <Spacing size="xxl" />
              <Text size="sm" color="gray">
                <Flex gap="md">
                  <Action
                    onClick={() => {
                      setValue(
                        'data_credits',
                        OVERVIEW_DATA_CREDIT_ITEMS.filter((item) => !item.disabled).map((item) => item.type),
                      );
                    }}
                  >
                    Choose all
                  </Action>{' '}
                  |{' '}
                  <Action
                    onClick={() => {
                      setValue('data_credits', []);
                    }}
                  >
                    Clear all
                  </Action>
                </Flex>
              </Text>
              <Spacing size="xl" />
              <StartDataCredit>
                <Grid columns={3} gap="md">
                  {OVERVIEW_DATA_CREDIT_ITEMS.map((dataCredit) => (
                    <Card key={dataCredit.type} disabled={dataCredit.disabled}>
                      <Flex justify="space-between" align="center">
                        <Flex direction="column" gap="xs">
                          <Text size="sm" weight={600}>
                            {dataCredit.label}
                          </Text>
                          <Text size="xs" color="gray">
                            {dataCredit.dataCredit} Data credits
                          </Text>
                        </Flex>
                        <Switch
                          disabled={dataCredit.disabled}
                          value={values.data_credits.includes(dataCredit.type)}
                          onChange={() => {
                            setValue(
                              'data_credits',
                              values.data_credits.includes(dataCredit.type)
                                ? values.data_credits.filter((item) => item !== dataCredit.type)
                                : [...values.data_credits, dataCredit.type],
                            );
                          }}
                        />
                      </Flex>
                      <Spacing size="lg" />
                      <Text size="xs">{dataCredit.desc}</Text>
                    </Card>
                  ))}
                </Grid>
              </StartDataCredit>
              <Spacing size="6rem" />
              <Flex justify="center" gap="xl">
                <Button width="16rem" various="secondary" onClick={() => setStep(StartStep.Step2)}>
                  BACK
                </Button>
                <Button width="22rem" disabled={values.data_credits.length === 0} shadow onClick={generateMyPlaybook}>
                  GENERATE MY PLAYBOOK
                </Button>
              </Flex>
            </>
          ))}
        <Spacing size="6rem" />
      </Flex>
    </PageTemplate>
  );
};

const StartDataCredit = styled.div`
  width: 900px;
`;

const Card = styled.div<{ disabled?: boolean }>`
  padding: 20px;
  box-shadow: ${TOKENS.shadow.default};
  background: white;
  border-radius: 0.4rem;

  ${(props) =>
    props.disabled
      ? css`
          background: ${props.theme.color.grayLighter};
        `
      : css`
          background: white;
        `}
`;

const StartGenerateResult = styled.div`
  width: 50rem;
`;

const FormContainer = styled.div`
  width: 50rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  background: #eef9f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
