import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { growthApi } from 'src/services';
import { formatArray } from 'src/utils';
import { ControlledInput, ControlledSelect, ControlledSelectOption, FormItem } from '../Form';
import { Flex, FlexItem } from '../Layout';

export const CUSTOM_RULE_PMP_ID = 31;

const { useAudiencesDatasetQuery, useCurrencyQuery } = growthApi;

enum CustomRuleTypePMPType {
  Exchange = 1,
  DealId = 2,
  Price = 3,
}

enum CustomRuleTypePMPPriceType {
  Floor = 'Floor',
  Fixed = 'Fixed',
}

const TYPE_OPTIONS: ControlledSelectOption[] = [
  { value: CustomRuleTypePMPType.Exchange, label: 'Exchange' },
  { value: CustomRuleTypePMPType.DealId, label: 'Deal ID' },
  { value: CustomRuleTypePMPType.Price, label: 'Price' },
];

const PRICE_TYPE_OPTIONS: ControlledSelectOption[] = [
  { value: CustomRuleTypePMPPriceType.Floor, label: 'Floor' },
  { value: CustomRuleTypePMPPriceType.Fixed, label: 'Fixed' },
];

export const CustomRuleTypePMP = () => {
  const { watch, control, setValue } = useFormContext();
  const values = watch();

  const { data: exchangeData, isFetching: isExchangeLoading } = useAudiencesDatasetQuery({
    dataset: 'rule_values',
    type: CUSTOM_RULE_PMP_ID,
  });
  const { data: currencyData, isFetching: isCurrencyLoading } = useCurrencyQuery({});

  const exchangeOptions: ControlledSelectOption[] = useMemo(() => {
    return exchangeData?.data.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
  }, [exchangeData?.data]);

  const currencyOptions: ControlledSelectOption[] = useMemo(() => {
    return currencyData?.data.map((item: any) => ({
      value: item.currency_code,
      label: item.currency_name,
    }));
  }, [currencyData?.data]);

  // generate rule value name
  useEffect(() => {
    switch (Number(values.rule_sub_type_id)) {
      case CustomRuleTypePMPType.Exchange:
        setValue(
          'rule_value_name',
          exchangeOptions
            ?.filter((option) => formatArray(values.rule_value).includes(option.value))
            ?.map((option) => option.label)
            ?.join(','),
        );
        break;
      case CustomRuleTypePMPType.DealId:
        setValue('rule_value_name', values.rule_value);
        break;
      case CustomRuleTypePMPType.Price:
        setValue('rule_value_name', `${values.rule_value} - ${values.rule_value_additional}`);
        break;
    }
  }, [values.rule_value, setValue, exchangeOptions, values.rule_sub_type_id, values.rule_value_additional]);

  return (
    <FormItem label="Value">
      <Flex direction="column" gap="sm">
        <ControlledSelect
          name="rule_sub_type_id"
          control={control}
          options={TYPE_OPTIONS}
          onValueChange={() => {
            setValue('rule_value', '');
          }}
        />
        {Number(values.rule_sub_type_id) === CustomRuleTypePMPType.Exchange && (
          <ControlledSelect
            name="rule_value"
            control={control}
            options={exchangeOptions}
            isLoading={isExchangeLoading}
            multiple
          />
        )}
        {Number(values.rule_sub_type_id) === CustomRuleTypePMPType.DealId && (
          <ControlledInput name="rule_value" control={control} placeholder="Enter deal id" />
        )}
        {Number(values.rule_sub_type_id) === CustomRuleTypePMPType.Price && (
          <Flex gap="md">
            <FlexItem grow={1}>
              <ControlledSelect name="rule_value" control={control} options={PRICE_TYPE_OPTIONS} />
            </FlexItem>
            <FlexItem grow={1}>
              <ControlledSelect
                name="rule_sub_type_unit"
                control={control}
                options={currencyOptions}
                isLoading={isCurrencyLoading}
              />
            </FlexItem>
            <FlexItem grow={1}>
              <ControlledInput name="rule_value_additional" control={control} placeholder="Enter price" />
            </FlexItem>
          </Flex>
        )}
      </Flex>
    </FormItem>
  );
};
