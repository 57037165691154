export const OVERRIDE_RULES = {
  3: [
    {
      id: 8,
      name: 'Countries',
      rule_response_type: 3,
      grouping_options: 'or',
      placeholder_text: '',
    },
    {
      id: 9,
      name: 'City/State/Region',
      rule_response_type: 5,
      grouping_options: 'or',
      placeholder_text: '',
    },
    {
      id: 10,
      name: 'DMA/Media/Market',
      rule_response_type: 5,
      grouping_options: 'or',
      placeholder_text: '',
    },
    {
      id: 11,
      name: 'Post Codes',
      rule_response_type: 1,
      grouping_options: 'or',
      placeholder_text: '12345, DN11JE',
    },
    {
      id: 13,
      name: 'Geo Fence',
      rule_response_type: 5,
      grouping_options: 'or/and',
      placeholder_text: '',
    },
  ],
};
