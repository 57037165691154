import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { useToast } from 'src/hooks';
import { getApiErrorMessage } from 'src/utils';
import { useEffect } from 'react';
import { AgencySelect } from 'src/components/AgencySelect';

const { useCreateOrUpdateAgencyMutation } = growthApi;

type EditAgencyFormValues = {
  id?: number;
  name?: string;
  accessible_agencies?: number[];
};

type EditAgencyModalProps = {
  isOpen: boolean;
  agency?: any;
  onClose: () => void;
  onSuccess: (newId?: number) => void;
};

const DEFAULT_FORM_VALUES: EditAgencyFormValues = {
  id: null,
  name: '',
  accessible_agencies: [],
};

export const EditAgencyModal = (props: EditAgencyModalProps) => {
  const { isOpen, agency, onClose, onSuccess } = props;
  const isNew = !agency;

  const { control, handleSubmit, reset } = useForm<EditAgencyFormValues>({ defaultValues: DEFAULT_FORM_VALUES });
  const [createOrUpdateAgency, { isLoading }] = useCreateOrUpdateAgencyMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      if (agency) {
        reset(agency);
      } else {
        reset(DEFAULT_FORM_VALUES);
      }
    }
  }, [isOpen, agency, reset]);

  const onSubmit = async (values: EditAgencyFormValues) => {
    try {
      const { data } = await createOrUpdateAgency(values).unwrap();
      if (isNew) {
        showSuccessToast('Create agency successfully');
      } else {
        showSuccessToast('Save agency successfully');
      }
      onClose();
      onSuccess(isNew ? data.id : undefined);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isNew ? 'New Agency' : 'Edit Agency'}
      confirmText="SUBMIT"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Name" required>
          <ControlledInput name="name" control={control} placeholder="Enter name" />
        </FormItem>
        <FormItem label="Accessible Agencies">
          <AgencySelect name="accessible_agencies" control={control} multiple />
        </FormItem>
      </Form>
    </Modal>
  );
};
