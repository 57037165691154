import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, ControlledInput, ControlledSelect, FormItem } from '../Form';
import { Flex, FlexItem, Spacing, Text } from '../Layout';
import { Modal } from '../Modal';
import { useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { DataTable } from '../DataTable';
import { useDataTable, useDebouncedValue } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmount, formatArray, formatSize, formatText } from 'src/utils';
import { difference, isArray, union, xor } from 'lodash';
import { Tag } from '../Form/Tag';

const { useThirdPartyAudienceProvidersQuery, useThirdPartyAudiencesQuery } = growthApi;

export const CUSTOM_RULE_THIRD_PARTY_AUDIENCES_ID = 27;

type CustomThirdPartyAudiencesFormValues = {
  provider: string;
  search: string;
};

export const CustomThirdPartyAudiences = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { watch, control } = useForm<CustomThirdPartyAudiencesFormValues>({
    defaultValues: {
      provider: null,
    },
  });
  const values = watch();
  const debouncedSearch = useDebouncedValue({ value: values.search });
  const { data: providersData, isFetching: isProvidersFetching } = useThirdPartyAudienceProvidersQuery({});
  const { data, isFetching, error } = useThirdPartyAudiencesQuery({
    provider: values.provider,
    search: debouncedSearch,
  });
  const { dataTableProps } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    defaultPageSize: 10,
    columns: [
      {
        header: () => {
          const value = allIds.length > 0 && difference(allIds, parentValues.rule_value).length === 0;
          return <Checkbox value={value} onChange={() => onSelectAll(!value)} />;
        },
        accessor: '_selector',
        render: (_, row) => (
          <Checkbox
            value={formatRuleValue.includes(row.id)}
            onChange={(value) => {
              if (value) {
                setParentValue('rule_value', [...formatRuleValue, row.id]);
                setParentValue('rule_value_name', [...(parentValues.rule_value_name || []), row]);
              } else {
                setParentValue(
                  'rule_value',
                  formatRuleValue.filter((item: any) => item !== row.id),
                );
                setParentValue(
                  'rule_value_name',
                  parentValues.rule_value_name?.filter((item: any) => item.id !== row.id),
                );
              }
            }}
          />
        ),
      },
      {
        header: 'Segment name',
        accessor: 'name',
      },
      { header: 'Data Provider', accessor: 'providerSource' },
      { header: 'Geo', accessor: 'geo' },
      { header: 'Audience Size', accessor: 'reach', render: formatSize },
      {
        header: 'CPM',
        accessor: 'cost',
        render: (value) => {
          const base = 5;
          return formatAmount(Number(value) + base);
        },
      },
      { header: 'Description', accessor: 'description' },
    ],
  });
  const { watch: parentWatch, setValue: setParentValue } = useFormContext();
  const parentValues = parentWatch();
  const providerOptions = useMemo(() => {
    return [
      {
        value: null,
        label: 'All',
      },
      ...(providersData?.data.map((provider: any) => ({
        value: provider,
        label: provider,
      })) || []),
    ];
  }, [providersData?.data]);

  useEffect(() => {
    // auto open data provider modal if set open_data_provider_modal
    if (parentValues?.open_data_provider_modal) {
      setIsOpen(true);
    }
  }, [parentValues?.open_data_provider_modal, setParentValue]);

  const allIds = useMemo(() => {
    return dataTableProps.visibleData.map((row: any) => row.id);
  }, [dataTableProps.visibleData]);

  const onSelectAll = useCallback(
    (value: boolean) => {
      const ruleValue = value ? union(parentValues.rule_value, allIds) : xor(parentValues.rule_value, allIds);
      setParentValue('rule_value', ruleValue);
      setParentValue(
        'rule_value_name',
        dataTableProps.visibleData.filter((item: any) => ruleValue?.includes(item.id)) || [],
      );
    },
    [allIds, dataTableProps.visibleData, parentValues.rule_value, setParentValue],
  );

  const formatRuleValue = formatArray(parentValues.rule_value);

  return (
    <>
      <FormItem label="Data Provider">
        <Flex gap="lg" width="100%">
          <FlexItem grow={1}>
            <ControlledSelect
              name="provider"
              control={control}
              placeholder="Please select data provider"
              isLoading={isProvidersFetching}
              options={providerOptions}
            />
          </FlexItem>
          <Button various="secondary" width="15rem" rounded={false} onClick={() => setIsOpen(true)}>
            SELECT VALUES
          </Button>
        </Flex>
      </FormItem>
      <FormItem label="3rd Party Audiences">
        <Flex direction="column" gap="lg">
          <SelectedAudiences>
            {isArray(parentValues.rule_value_name) && parentValues.rule_value_name.length > 0 ? (
              <Flex gap="xs" wrap="wrap">
                {parentValues.rule_value_name.map((row: any) => (
                  <Tag
                    key={row.id}
                    onClose={() => {
                      setParentValue(
                        'rule_value',
                        // need format rule value
                        formatArray(parentValues.rule_value).filter((item: any) => item !== row.id),
                      );
                      setParentValue(
                        'rule_value_name',
                        parentValues.rule_value_name?.filter((item: any) => item.id !== row.id),
                      );
                    }}
                  >
                    {formatText(row.full_path || row.name)}
                  </Tag>
                ))}
              </Flex>
            ) : (
              <Flex justify="center">
                <Text size="sm" color="gray">
                  Please select values
                </Text>
              </Flex>
            )}
          </SelectedAudiences>
        </Flex>
      </FormItem>
      <Modal
        isOpen={isOpen}
        title="Select 3rd Party Audiences"
        onClose={() => setIsOpen(false)}
        onConfirm={() => setIsOpen(false)}
        width="75vw"
      >
        <ControlledInput
          prefix="Search:"
          name="search"
          control={control}
          width="40rem"
          placeholder="Enter search keywords"
        />
        <Spacing />
        <DataTable {...dataTableProps} />
      </Modal>
    </>
  );
};

const SelectedAudiences = styled.div`
  padding: 0.6rem;
`;
