import { TOKENS } from 'src/design';
import { HTMLAttributes } from 'react';
import styled, { css, CSSProperties } from 'styled-components';

export type GridGap = keyof typeof TOKENS['spacing'];

export type GridProps = {
  columns?: number | string;
  rows?: number;
  flow?: CSSProperties['gridAutoFlow'];
  justify?: CSSProperties['justifyItems'];
  align?: CSSProperties['alignItems'];
  gap?: GridGap | [GridGap, GridGap];
  width?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Grid = styled.div<GridProps>`
  display: grid;
  ${(props) => (props.flow ? ` grid-auto-flow: ${props.flow}; ` : '')}
  ${(props) =>
    props.columns
      ? ` grid-template-columns: ${
          typeof props.columns === 'number' ? `repeat(${props.columns}, minmax(0, 1fr));` : props.columns
        };`
      : ''}
  ${(props) => (props.rows ? `  grid-template-rows: repeat(${props.rows}, 1fr);` : '')}
  ${(props) => (props.justify ? `justify-items: ${props.justify};` : '')}
  ${(props) => (props.align ? `align-items: ${props.align};` : '')}
  ${(props) =>
    props.gap
      ? css`
          gap: ${Array.isArray(props.gap)
            ? props.gap.map((gap) => TOKENS.spacing[gap]).join(' ')
            : TOKENS.spacing[props.gap]};
        `
      : ''}
  width: ${(props) => props.width ?? '100%'};
`;
