export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
export const CITIES = [
  'ABERDEEN',
  'ABERNATHY',
  'ABILENE',
  'ABINGDON',
  'ACCOKEEK',
  'ACTON',
  'ACWORTH',
  'ADA',
  'ADAMS',
  'ADDISON',
  'ADELANTO',
  'ADOLPHUS',
  'ADRIAN',
  'AFTON',
  'AGOURA HILLS',
  'AGUANGA',
  'AIEA',
  'AIKEN',
  'AKRON',
  'ALACHUA',
  'ALAMEDA',
  'ALAMO',
  'ALAMOGORDO',
  'ALBANY',
  'ALBERTVILLE',
  'ALBUQUERQUE',
  'ALDIE',
  'ALEDO',
  'ALEXANDER',
  'ALEXANDRIA',
  'ALFRED',
  'ALGONQUIN',
  'ALHAMBRA',
  'ALIQUIPPA',
  'ALISO VIEJO',
  'ALLEGAN',
  'ALLEN',
  'ALLEN PARK',
  'ALLENDALE',
  'ALLENTOWN',
  'ALLIANCE',
  'ALLOWAY',
  'ALMA',
  'ALPHARETTA',
  'ALSIP',
  'ALTA LOMA',
  'ALTADENA',
  'ALTAMONTE SPG',
  'ALTAMONTE SPRINGS',
  'ALTON',
  'ALTOONA',
  'ALVARADO',
  'ALVIN',
  'AMANDA',
  'AMARILLO',
  'AMBLER',
  'AMERICAN FORK',
  'AMES',
  'AMESBURY',
  'AMHERST',
  'AMITE',
  'AMITYVILLE',
  'AMMON',
  'ANACONDA',
  'ANACORTES',
  'ANADARKO',
  'ANAHEIM',
  'ANAMOSA',
  'ANCHORAGE',
  'ANDALUSIA',
  'ANDERSON',
  'ANDOVER',
  'ANGLETON',
  'ANGOLA',
  'ANKENY',
  'ANN ARBOR',
  'ANNA',
  'ANNANDALE',
  'ANNAPOLIS',
  'ANNISTON',
  'ANNVILLE',
  'ANOKA',
  'ANTELOPE',
  'ANTHEM',
  'ANTHONY',
  'ANTIOCH',
  'ANZA',
  'APACHE JUNCTION',
  'APEX',
  'APISON',
  'APOPKA',
  'APPLE VALLEY',
  'APPLETON',
  'ARAB',
  'ARABI',
  'ARCADE',
  'ARCADIA',
  'ARCATA',
  'ARCHDALE',
  'ARDEN',
  'ARDSLEY',
  'ARGYLE',
  'ARIZONA CITY',
  'ARKADELPHIA',
  'ARKPORT',
  'ARLETA',
  'ARLINGTON',
  'ARLINGTON HEIGHTS',
  'ARMONK',
  'ARNOLD',
  'ARROYO GRANDE',
  'ARVADA',
  'ARVERNE',
  'ASBURY PARK',
  'ASHBURN',
  'ASHDOWN',
  'ASHEBORO',
  'ASHEVILLE',
  'ASHLAND',
  'ASHLAND CITY',
  'ASHVILLE',
  'ASPEN',
  'ASTORIA',
  'ATASCADERO',
  'ATHENS',
  'ATHERTON',
  'ATKINS',
  'ATKINSON',
  'ATLANTA',
  'ATLANTIC BEACH',
  'ATLANTIC CITY',
  'ATTLEBORO',
  'AUBREY',
  'AUBURN',
  'AUBURNDALE',
  'AUDUBON',
  'AUGUSTA',
  'AURORA',
  'AUSTELL',
  'AUSTIN',
  'AUSTINTOWN',
  'AVENTURA',
  'AVON',
  'AVON LAKE',
  'AVONDALE',
  'AYER',
  'AYNOR',
  'AZLE',
  'AZTEC',
  'AZUSA',
  'BABBITT',
  'BABYLON',
  'BACLIFF',
  'BAD AXE',
  'BAILEY',
  'BAILEYS CROSSROADS',
  'BAINBRIDGE',
  'BAINBRIDGE ISLAND',
  'BAKERSFIELD',
  'BALDWIN PARK',
  'BALDWYN',
  'BALLSTON SPA',
  'BALLWIN',
  'BALTIMORE',
  'BARABOO',
  'BARAGA',
  'BARBOURSVILLE',
  'BARDSTOWN',
  'BARKSDALE AFB',
  'BARLING',
  'BARNEGAT',
  'BARNESVILLE',
  'BARNHART',
  'BARRE',
  'BARRINGTON',
  'BARSTOW',
  'BARTLESVILLE',
  'BARTLETT',
  'BARTOW',
  'BASEHOR',
  'BASKING RIDGE',
  'BASTROP',
  'BATAVIA',
  'BATESVILLE',
  'BATON ROUGE',
  'BATTLE CREEK',
  'BATTLE GROUND',
  'BATTLE LAKE',
  'BATTLE MOUNTAIN',
  'BAUXITE',
  'BAY CITY',
  'BAY MINETTE',
  'BAY SHORE',
  'BAYFIELD',
  'BAYONNE',
  'BAYPORT',
  'BAYSIDE',
  'BAYTOWN',
  'BAYVILLE',
  'BEACON FALLS',
  'BEALETON',
  'BEAR',
  'BEARDSTOWN',
  'BEATRICE',
  'BEAUFORT',
  'BEAUMONT',
  'BEAVER',
  'BEAVER DAM',
  'BEAVERTON',
  'BEDFORD',
  'BEDFORD HEIGHTS',
  'BEEBE',
  'BEECHER',
  'BEL AIR',
  'BEL AIRE',
  'BELGRADE',
  'BELL',
  'BELL CANYON',
  'BELL GARDENS',
  'BELLAIRE',
  'BELLE PLAINE',
  'BELLEVIEW',
  'BELLEVILLE',
  'BELLEVUE',
  'BELLFLOWER',
  'BELLINGHAM',
  'BELLWOOD',
  'BELMOND',
  'BELMONT',
  'BELOIT',
  'BELTON',
  'BELTSVILLE',
  'BELVIDERE',
  'BEMENT',
  'BEMIDJI',
  'BENBROOK',
  'BEND',
  'BENNINGTON',
  'BENSALEM',
  'BENTON',
  'BENTONVILLE',
  'BEREA',
  'BERGENFIELD',
  'BERKELEY',
  'BERKELEY HEIGHTS',
  'BERKLEY',
  'BERLIN',
  'BERMUDA DUNES',
  'BERNARDSVILLE',
  'BERNE',
  'BERNICE',
  'BERNVILLE',
  'BERRIEN SPRINGS',
  'BERWYN',
  'BESSEMER',
  'BETHALTO',
  'BETHEL',
  'BETHEL PARK',
  'BETHESDA',
  'BETHLEHEM',
  'BETHPAGE',
  'BETTENDORF',
  'BEULAH',
  'BEVERLY',
  'BEVERLY HILLS',
  'BIG BEAR CITY',
  'BIG LAKE',
  'BIG SPRING',
  'BIG SPRINGS',
  'BIG STONE GAP',
  'BIGFORK',
  'BILLERICA',
  'BILLINGS',
  'BILOXI',
  'BINGHAMTON',
  'BIRMINGHAM',
  'BISHOP',
  'BISMARCK',
  'BIXBY',
  'BLACK HAWK',
  'BLACK JACK',
  'BLACKSBURG',
  'BLACKWOOD',
  'BLAINE',
  'BLAIR',
  'BLANCHESTER',
  'BLANDON',
  'BLISSFIELD',
  'BLOOMFIELD',
  'BLOOMFIELD HILLS',
  'BLOOMING PRAIRIE',
  'BLOOMINGDALE',
  'BLOOMINGTON',
  'BLUE ISLAND',
  'BLUE SPRINGS',
  'BLUFFTON',
  'BLYTHEVILLE',
  'BOALSBURG',
  'BOARDMAN',
  'BOAZ',
  'BOCA RATON',
  'BOERNE',
  'BOHEMIA',
  'BOILING SPRINGS',
  'BOISE',
  'BOLINGBROOK',
  'BOLTON',
  'BONAIRE',
  'BONIFAY',
  'BONITA SPRINGS',
  'BONNER SPRINGS',
  'BONNEY LAKE',
  'BONO',
  'BOONE',
  'BOONSBORO',
  'BOONTON',
  'BOONVILLE',
  'BORDENTOWN',
  'BORING',
  'BOSSIER CITY',
  'BOSTON',
  'BOTHELL',
  'BOULDER',
  'BOULDER CITY',
  'BOUND BROOK',
  'BOURBONNAIS',
  'BOVINA',
  'BOWIE',
  'BOWLING GREEN',
  'BOWMAN',
  'BOX ELDER',
  'BOYNTON BEACH',
  'BOZEMAN',
  'BRADBURY',
  'BRADENTON',
  'BRADFORD',
  'BRAINTREE',
  'BRAMBLETON',
  'BRANDON',
  'BRANFORD',
  'BRANSON',
  'BRANTLEY',
  'BRASELTON',
  'BREA',
  'BRECKENRIDGE',
  'BREMEN',
  'BREMERTON',
  'BRENTWOOD',
  'BRICK',
  'BRIDGEPORT',
  'BRIDGETON',
  'BRIDGEWATER',
  'BRIER',
  'BRIGHAM CITY',
  'BRIGHTON',
  'BRINKHAVEN',
  'BRISTOL',
  'BROAD BROOK',
  'BROAD RUN',
  'BROCKTON',
  'BROKEN ARROW',
  'BROKEN BOW',
  'BRONSTON',
  'BRONX',
  'BROOKFIELD',
  'BROOKHAVEN',
  'BROOKLAND',
  'BROOKLET',
  'BROOKLINE',
  'BROOKLYN',
  'BROOKLYN CENTER',
  'BROOKSHIRE',
  'BROOKSVILLE',
  'BROOKTONDALE',
  'BROOMFIELD',
  'BROWNFIELD',
  'BROWNS SUMMIT',
  'BROWNSBURG',
  'BROWNSVILLE',
  'BRUNSWICK',
  'BRYAN',
  'BRYANS ROAD',
  'BRYN MAWR',
  'BUCKEYE',
  'BUCKLEY',
  'BUCKLIN',
  'BUCKNER',
  'BUCYRUS',
  'BUDA',
  'BUELLTON',
  'BUENA PARK',
  'BUENA VISTA',
  'BUFFALO',
  'BUFFALO GROVE',
  'BUFORD',
  'BULLHEAD CITY',
  'BUNA',
  'BUNKER HILL',
  'BUNNELL',
  'BUNNLEVEL',
  'BURBANK',
  'BURIEN',
  'BURKBURNETT',
  'BURLESON',
  'BURLINGAME',
  'BURLINGTON',
  'BURNSVILLE',
  'BURR RIDGE',
  'BURTON',
  'BUSHKILL',
  'BUSHNELL',
  'BUTLER',
  'BUTTE',
  'BUXTON',
  'BYRAM',
  'BYRON CENTER',
  'CABOT',
  'CAL NEV ARI',
  'CALABASAS',
  'CALABASH',
  'CALAIS',
  'CALCIUM',
  'CALDWELL',
  'CALEDONIA',
  'CALEXICO',
  'CALHOUN',
  'CALIFORNIA',
  'CALUMET',
  'CAMANO ISLAND',
  'CAMARILLO',
  'CAMAS',
  'CAMBRIDGE',
  'CAMBY',
  'CAMDEN',
  'CAMILLA',
  'CAMILLUS',
  'CAMP HILL',
  'CAMPBELL',
  'CANADENSIS',
  'CANASTOTA',
  'CANBY',
  'CANDLER',
  'CANFIELD',
  'CANOGA PARK',
  'CANON CITY',
  'CANTERBURY',
  'CANTON',
  'CANUTILLO',
  'CANYON',
  'CANYON CNTRY',
  'CANYON COUNTRY',
  'CANYON LAKE',
  'CAPE CHARLES',
  'CAPE CORAL',
  'CAPE ELIZABETH',
  'CAPE GIRARDEAU',
  'CAPE MAY COURT HOUSE',
  'CAPITOL HEIGHTS',
  'CAPITOL HGTS',
  'CAPO BEACH',
  'CARBON CLIFF',
  'CARBONDALE',
  'CARDIFF',
  'CARENCRO',
  'CARLISLE',
  'CARLSBAD',
  'CARMEL',
  'CARMICHAEL',
  'CARNEGIE',
  'CAROL STREAM',
  'CARPENTERSVILLE',
  'CARRIER MILLS',
  'CARROLLTON',
  'CARSON',
  'CARSON CITY',
  'CARTERET',
  'CARTERSVILLE',
  'CARTHAGE',
  'CARTWRIGHT',
  'CARVER',
  'CARY',
  'CASA GRANDE',
  'CASEYVILLE',
  'CASHMERE',
  'CASSATT',
  'CASTAIC',
  'CASTALIAN SPRINGS',
  'CASTLE HAYNE',
  'CASTLE ROCK',
  'CASTRO VALLEY',
  'CATAWBA',
  'CATHEDRAL CITY',
  'CATHEDRAL CTY',
  'CATHLAMET',
  'CATONSVILLE',
  'CATOOSA',
  'CAVE CREEK',
  'CAVE SPRINGS',
  'CEDAR CITY',
  'CEDAR FALLS',
  'CEDAR GROVE',
  'CEDAR HILL',
  'CEDAR HILLS',
  'CEDAR LAKE',
  'CEDAR PARK',
  'CEDAR RAPIDS',
  'CEDARBURG',
  'CEDAREDGE',
  'CEDARTOWN',
  'CELEBRATION',
  'CENTENNIAL',
  'CENTER',
  'CENTER POINT',
  'CENTER VALLEY',
  'CENTERVILLE',
  'CENTRAL',
  'CENTRAL CITY',
  'CENTRAL POINT',
  'CENTRALIA',
  'CENTREVILLE',
  'CERES',
  'CERRITOS',
  'CERRO GORDO',
  'CHAGRIN FALLS',
  'CHALFONT',
  'CHALMETTE',
  'CHAMBERSBURG',
  'CHAMBLEE',
  'CHAMPAIGN',
  'CHAMPLIN',
  'CHANDLER',
  'CHANNAHON',
  'CHANNELVIEW',
  'CHANTILLY',
  'CHAPARRAL',
  'CHAPEL HILL',
  'CHAPIN',
  'CHAPPAQUA',
  'CHARLESTON',
  'CHARLESTOWN',
  'CHARLEVOIX',
  'CHARLOTTE',
  'CHARLOTTESVILLE',
  'CHARLOTTESVLE',
  'CHATHAM',
  'CHATSWORTH',
  'CHATTANOOGA',
  'CHATTAROY',
  'CHEEKTOWAGA',
  'CHEHALIS',
  'CHELMSFORD',
  'CHELSEA',
  'CHENEY',
  'CHEPACHET',
  'CHEROKEE VLG',
  'CHERRY HILL',
  'CHERRY VALLEY',
  'CHESANING',
  'CHESAPEAKE',
  'CHESAPEAKE BEACH',
  'CHESHIRE',
  'CHESTER',
  'CHESTERFIELD',
  'CHESTERLAND',
  'CHESTERTON',
  'CHESWICK',
  'CHEVY CHASE',
  'CHEYENNE',
  'CHICAGO',
  'CHICAGO HEIGHTS',
  'CHICKASHA',
  'CHICO',
  'CHICORA',
  'CHILTON',
  'CHINO',
  'CHINO HILLS',
  'CHINO VALLEY',
  'CHOCTAW',
  'CHRISTINE',
  'CHULA VISTA',
  'CHURCH HILL',
  'CHURCH POINT',
  'CHURUBUSCO',
  'CICERO',
  'CINCINNATI',
  'CINNAMINSON',
  'CITRUS SPRINGS',
  'CLAREMONT',
  'CLAREMORE',
  'CLARK',
  'CLARKESVILLE',
  'CLARKSBORO',
  'CLARKSDALE',
  'CLARKSTON',
  'CLARKSVILLE',
  'CLAY',
  'CLAY CITY',
  'CLAYTON',
  'CLEARFIELD',
  'CLEARWATER',
  'CLEARWATER BEACH',
  'CLEBURNE',
  'CLEMENTON',
  'CLEMENTS',
  'CLEMMONS',
  'CLEMSON',
  'CLERMONT',
  'CLEVELAND',
  'CLEVELAND HEIGHTS',
  'CLEVES',
  'CLIFTON',
  'CLIFTON SPRINGS',
  'CLINT',
  'CLINTON',
  'CLINTON TOWNSHIP',
  'CLINTON TWP',
  'CLINTONVILLE',
  'CLIO',
  'CLOSTER',
  'CLOVER',
  'CLOVIS',
  'CLUTE',
  'COACHELLA',
  'COAL CITY',
  'COAL TOWNSHIP',
  'COAL VALLEY',
  'COALPORT',
  'COATESVILLE',
  'COATS',
  'COBBTOWN',
  'COBLESKILL',
  'COCOA',
  'COCONUT CREEK',
  'COEUR D ALENE',
  'COFFEEN',
  'COGAN STATION',
  'COLBERT',
  'COLCHESTER',
  'COLD SPRING',
  'COLDSPRING',
  'COLLEGE PARK',
  'COLLEGE STATION',
  'COLLEGEVILLE',
  'COLLEYVILLE',
  'COLLIERS',
  'COLLIN',
  'COLLINSVILLE',
  'COLONIAL HEIGHTS',
  'COLORADO SPGS',
  'COLORADO SPRINGS',
  'COLTON',
  'COLUMBIA',
  'COLUMBIA CITY',
  'COLUMBUS',
  'COLUSA',
  'COMMACK',
  'COMMERCE',
  'COMMERCE CITY',
  'COMMERCIAL POINT',
  'COMPTON',
  'COMSTOCK PARK',
  'CONCORD',
  'CONLEY',
  'CONNEAUT',
  'CONNELLSVILLE',
  'CONROE',
  'CONSHOHOCKEN',
  'CONVERSE',
  'CONWAY',
  'CONYERS',
  'COOKEVILLE',
  'COOL',
  'COOLIDGE',
  'COON RAPIDS',
  'COOPER CITY',
  'COOS BAY',
  'COPIAGUE',
  'COPLEY',
  'COPPELL',
  'CORAL GABLES',
  'CORAL SPRINGS',
  'CORAM',
  'CORAOPOLIS',
  'CORBETT',
  'CORBIN',
  'CORDELE',
  'CORDOVA',
  'CORINNE',
  'CORINTH',
  'CORNELIA',
  'CORNELIUS',
  'CORNWALL',
  'CORNWALL ON HUDSON',
  'CORONA',
  'CORONADO',
  'CORP CHRISTI',
  'CORPUS CHRISTI',
  'CORRYTON',
  'CORSICANA',
  'CORTEZ',
  'CORVALLIS',
  'CORYDON',
  'COSTA MESA',
  'COTO DE CAZA',
  'COTTAGE GROVE',
  'COTTON VALLEY',
  'COTTONDALE',
  'COTTONWOOD',
  'COULTERVILLE',
  'COUNCIL BLUFFS',
  'COVENTRY',
  'COVINA',
  'COVINGTON',
  'COWETA',
  'CRANFORD',
  'CRAWFORD',
  'CRAWFORDSVILLE',
  'CRAWFORDVILLE',
  'CRESCENT CITY',
  'CRESCO',
  'CRETE',
  'CROOKSTON',
  'CROSBY',
  'CROSS LANES',
  'CROSS PLAINS',
  'CROSSVILLE',
  'CROTON ON HUDSON',
  'CROUSE',
  'CROWLEY',
  'CRYSTAL FALLS',
  'CRYSTAL LAKE',
  'CUDAHY',
  'CULVER CITY',
  'CUMBERLAND GAP',
  'CUMMING',
  'CUPERTINO',
  'CUSHING',
  'CUSICK',
  'CUSSETA',
  'CUTLER',
  'CUTLER BAY',
  'CUYAHOGA FALLS',
  'CYPRESS',
  'DACONO',
  'DACULA',
  'DADE CITY',
  'DALE',
  'DALLAS',
  'DALTON',
  'DALTON GARDENS',
  'DALY CITY',
  'DAMASCUS',
  'DAMIANSVILLE',
  'DANBURY',
  'DANVERS',
  'DANVILLE',
  'DAPHNE',
  'DARBY',
  'DARIEN',
  'DARLINGTON',
  'DAUPHIN',
  'DAVENPORT',
  'DAVIE',
  'DAVIS',
  'DAVISON',
  'DAWSON SPRINGS',
  'DAWSONVILLE',
  'DAYTON',
  'DAYTONA BEACH',
  'DE BERRY',
  'DE PERE',
  'DE SOTO',
  'DEARBORN',
  'DEARBORN HEIGHTS',
  'DEARBORN HTS',
  'DEBARY',
  'DECATUR',
  'DECORAH',
  'DEER LODGE',
  'DEER PARK',
  'DEER TRAIL',
  'DEERFIELD',
  'DEERFIELD BEACH',
  'DEFIANCE',
  'DEFOREST',
  'DEL MAR',
  'DEL RIO',
  'DELAND',
  'DELANO',
  'DELAVAN',
  'DELAWARE',
  'DELMAR',
  'DELRAY BEACH',
  'DELTA',
  'DELTONA',
  'DEMAREST',
  'DEMOTTE',
  'DENHAM SPRINGS',
  'DENISON',
  'DENNIS PORT',
  'DENTON',
  'DENVER',
  'DEPEW',
  'DEPOE BAY',
  'DERBY',
  'DERIDDER',
  'DERRY',
  'DERWOOD',
  'DES MOINES',
  'DES PLAINES',
  'DESOTO',
  'DESTIN',
  'DESTREHAN',
  'DETROIT',
  'DETROIT LAKES',
  'DEVON',
  'DEWEY',
  'DIABLO GRANDE',
  'DIAMOND BAR',
  'DIANA',
  'DIBERVILLE',
  'DIBOLL',
  'DICKINSON',
  'DICKSON',
  'DIKE',
  'DILLON',
  'DINUBA',
  'DISCOVERY BAY',
  'DISTRICT HEIGHTS',
  'DIX HILLS',
  'DIXON',
  'DODGE CITY',
  'DOLTON',
  'DONALDSONVILLE',
  'DORAL',
  'DORCHESTER',
  'DOTHAN',
  'DOUGLASVILLE',
  'DOVER',
  'DOWNEY',
  'DOWNINGTOWN',
  'DOWNS',
  'DOYLESTOWN',
  'DRACUT',
  'DRAPER',
  'DRESHER',
  'DRIPPING SPRINGS',
  'DSRT HOT SPGS',
  'DUBLIN',
  'DUBUQUE',
  'DUCHESNE',
  'DUDLEY',
  'DULUTH',
  'DUMFRIES',
  'DUNCAN',
  'DUNCANNON',
  'DUNCANSVILLE',
  'DUNCANVILLE',
  'DUNDALK',
  'DUNEDIN',
  'DUNN',
  'DURAND',
  'DURANGO',
  'DURANT',
  'DURHAM',
  'DUVALL',
  'DUXBURY',
  'E GREENWICH',
  'E WENATCHEE',
  'EADS',
  'EAGAN',
  'EAGAR',
  'EAGLE',
  'EAGLE MOUNTAIN',
  'EAGLE PASS',
  'EAGLE RIVER',
  'EARLY',
  'EASLEY',
  'EAST AMHERST',
  'EAST AURORA',
  'EAST CHICAGO',
  'EAST CLEVELAND',
  'EAST ELMHURST',
  'EAST FALMOUTH',
  'EAST FREEDOM',
  'EAST GRAND FORKS',
  'EAST GREENBUSH',
  'EAST GREENWICH',
  'EAST HAMPTON',
  'EAST HANOVER',
  'EAST HARTFORD',
  'EAST HELENA',
  'EAST LANSING',
  'EAST LIVERPOOL',
  'EAST LYME',
  'EAST MEADOW',
  'EAST NEWARK',
  'EAST NORTHPORT',
  'EAST ORANGE',
  'EAST PALO ALTO',
  'EAST PATCHOGUE',
  'EAST QUOGUE',
  'EAST ROCKAWAY',
  'EAST RUTHERFORD',
  'EAST SAINT LOUIS',
  'EAST STROUDSBURG',
  'EAST SYRACUSE',
  'EAST TAUNTON',
  'EAST WAREHAM',
  'EAST WINDSOR',
  'EASTHAM',
  'EASTON',
  'EASTOVER',
  'EASTPOINTE',
  'EASTSOUND',
  'EASTVALE',
  'EATON',
  'EATON RAPIDS',
  'EAU CLAIRE',
  'ECLECTIC',
  'ECORSE',
  'ECTOR',
  'EDEN PRAIRIE',
  'EDGEWATER',
  'EDGEWOOD',
  'EDINBURG',
  'EDMOND',
  'EDWARDS',
  'EDWARDSVILLE',
  'EGG HARBOR CITY',
  'EGG HBR TWP',
  'EL CAJON',
  'EL CENIZO',
  'EL CENTRO',
  'EL DORADO',
  'EL DORADO HILLS',
  'EL DORADO HLS',
  'EL MIRAGE',
  'EL MONTE',
  'EL PASO',
  'EL PRADO',
  'EL SEGUNDO',
  'EL TORO',
  'ELBERTA',
  'ELBERTON',
  'ELGIN',
  'ELIZABETH',
  'ELIZABETH CITY',
  'ELIZABETHTON',
  'ELIZABETHTOWN',
  'ELK GROVE',
  'ELK RIVER',
  'ELKHART',
  'ELKHORN',
  'ELKINS',
  'ELKINS PARK',
  'ELKMONT',
  'ELKTON',
  'ELKVIEW',
  'ELLENSBURG',
  'ELLENWOOD',
  'ELLICOTT CITY',
  'ELLIJAY',
  'ELLINWOOD',
  'ELLSWORTH',
  'ELLWOOD CITY',
  'ELM GROVE',
  'ELMHURST',
  'ELMONT',
  'ELMSFORD',
  'ELMWOOD PARK',
  'ELOY',
  'ELTON',
  'ELY',
  'ELYRIA',
  'EMERALD ISLE',
  'EMERYVILLE',
  'ENCINITAS',
  'ENCINO',
  'ENDICOTT',
  'ENFIELD',
  'ENGLEWOOD',
  'ENNIS',
  'ENTERPRISE',
  'EPHRATA',
  'EPPING',
  'ERIE',
  'ERWIN',
  'ESCONDIDO',
  'ESPANOLA',
  'ESSEX',
  'ESSEXVILLE',
  'ESSINGTON',
  'ESTERO',
  'ESTES PARK',
  'ETIWANDA',
  'ETOWAH',
  'EUCLID',
  'EUGENE',
  'EULESS',
  'EUNICE',
  'EUREKA',
  'EUREKA SPRINGS',
  'EUSTACE',
  'EUSTIS',
  'EVANS',
  'EVANSTON',
  'EVANSVILLE',
  'EVART',
  'EVERETT',
  'EVERSON',
  'EWA BEACH',
  'EWING',
  'EXCELSIOR',
  'EXCELSIOR SPRINGS',
  'EXETER',
  'EXTON',
  'FACTORYVILLE',
  'FAIR GROVE',
  'FAIR LAWN',
  'FAIR OAKS',
  'FAIRBANKS',
  'FAIRBORN',
  'FAIRBURN',
  'FAIRFAX',
  'FAIRFIELD',
  'FAIRHOPE',
  'FAIRLAND',
  'FAIRMONT',
  'FAIRVIEW',
  'FALFURRIAS',
  'FALL RIVER',
  'FALLBROOK',
  'FALLS CITY',
  'FALMOUTH',
  'FARGO',
  'FARMERS BRANCH',
  'FARMERVILLE',
  'FARMINGDALE',
  'FARMINGTON',
  'FARMINGTON HILLS',
  'FARMINGVILLE',
  'FARMLAND',
  'FAYETTEVILLE',
  'FEDERAL WAY',
  'FELTON',
  'FENTON',
  'FERGUS FALLS',
  'FERNDALE',
  'FERNLEY',
  'FESTUS',
  'FILLMORE',
  'FISHERS',
  'FITCHBURG',
  'FITZGERALD',
  'FLAGLER BEACH',
  'FLAGSTAFF',
  'FLAT ROCK',
  'FLEMING ISLAND',
  'FLEMINGTON',
  'FLETCHER',
  'FLINT',
  'FLIPPIN',
  'FLORAL PARK',
  'FLORENCE',
  'FLORESVILLE',
  'FLORISSANT',
  'FLOWER MOUND',
  'FLOWERY BRANCH',
  'FLOWOOD',
  'FLUSHING',
  'FOLEY',
  'FOLSOM',
  'FOND DU LAC',
  'FONTANA',
  'FOOTHILL RANCH',
  'FOOTHILL RNCH',
  'FOREST',
  'FOREST HILL',
  'FOREST HILLS',
  'FOREST LAKE',
  'FOREST PARK',
  'FORESTDALE',
  'FORESTHILL',
  'FORNEY',
  'FORREST CITY',
  'FORSYTH',
  'FORT ATKINSON',
  'FORT COLLINS',
  'FORT DODGE',
  'FORT EDWARD',
  'FORT GRATIOT',
  'FORT HOOD',
  'FORT HUACHUCA',
  'FORT LAUDERDALE',
  'FORT LEE',
  'FORT LEONARD WOOD',
  'FORT LUPTON',
  'FORT MILL',
  'FORT MOHAVE',
  'FORT MORGAN',
  'FORT MYERS',
  'FORT PIERCE',
  'FORT SMITH',
  'FORT STOCKTON',
  'FORT THOMAS',
  'FORT VALLEY',
  'FORT WALTON BEACH',
  'FORT WASHINGTON',
  'FORT WAYNE',
  'FORT WORTH',
  'FORTUNA',
  'FOSTORIA',
  'FOUNTAIN HILLS',
  'FOUNTAIN INN',
  'FOUNTAIN VLY',
  'FRAMINGHAM',
  'FRANKENMUTH',
  'FRANKFORT',
  'FRANKLIN',
  'FRANKLIN LAKES',
  'FRANKLIN LKS',
  'FRANKLIN PARK',
  'FRANKLIN SQUARE',
  'FRANKLINTON',
  'FRAZIER PARK',
  'FREDERICK',
  'FREDERICKSBURG',
  'FREDERICKTOWN',
  'FREDONIA',
  'FREEHOLD',
  'FREELAND',
  'FREEPORT',
  'FREMONT',
  'FRESH MEADOWS',
  'FRESNO',
  'FRIENDSHIP',
  'FRIENDSWOOD',
  'FRISCO',
  'FRONT ROYAL',
  'FRUITLAND',
  'FT LAUDERDALE',
  'FT MITCHELL',
  'FULLERTON',
  'FULSHEAR',
  'FULTON',
  'GAFFNEY',
  'GAINESVILLE',
  'GAITHERSBURG',
  'GALES FERRY',
  'GALESBURG',
  'GALION',
  'GALLATIN',
  'GALLOWAY',
  'GALLUP',
  'GALT',
  'GALVESTON',
  'GANS',
  'GAP',
  'GARDEN CITY',
  'GARDEN GROVE',
  'GARDENA',
  'GARDNER',
  'GARDNERS',
  'GARDNERVILLE',
  'GARFIELD',
  'GARLAND',
  'GARNER',
  'GARY',
  'GASTON',
  'GASTONIA',
  'GATES',
  'GATESVILLE',
  'GAUTIER',
  'GENESEO',
  'GENEVA',
  'GENTRY',
  'GEORGETOWN',
  'GERING',
  'GERMANTOWN',
  'GETZVILLE',
  'GIBSONIA',
  'GIG HARBOR',
  'GILBERT',
  'GILBERTSVILLE',
  'GILLETTE',
  'GILMER',
  'GILROY',
  'GLADEWATER',
  'GLASSBORO',
  'GLASTONBURY',
  'GLEN ALLEN',
  'GLEN BURNIE',
  'GLEN CARBON',
  'GLEN COVE',
  'GLEN ELLYN',
  'GLEN HEAD',
  'GLEN OAKS',
  'GLEN ROSE',
  'GLENCOE',
  'GLENDALE',
  'GLENDALE HEIGHTS',
  'GLENDORA',
  'GLENMONT',
  'GLENN HEIGHTS',
  'GLENSHAW',
  'GLENSIDE',
  'GLENVIEW',
  'GLENWOOD',
  'GLENWOOD CITY',
  'GLENWOOD SPRINGS',
  'GLOUCESTER',
  'GNADENHUTTEN',
  'GOFFSTOWN',
  'GOLD HILL',
  'GOLD RIVER',
  'GOLDEN',
  'GOLDEN VALLEY',
  'GOLDSBORO',
  'GONZALES',
  'GOODING',
  'GOODRICH',
  'GOODYEAR',
  'GORDONSVILLE',
  'GOSHEN',
  'GRAHAM',
  'GRANADA HILLS',
  'GRANBURY',
  'GRAND BLANC',
  'GRAND CANE',
  'GRAND FORKS',
  'GRAND ISLAND',
  'GRAND JUNCTION',
  'GRAND LEDGE',
  'GRAND PRAIRIE',
  'GRAND RAPIDS',
  'GRANDVIEW',
  'GRANDVILLE',
  'GRANGER',
  'GRANITE BAY',
  'GRANITE FALLS',
  'GRANTS PASS',
  'GRANVILLE',
  'GRAPEVINE',
  'GRASS LAKE',
  'GRAYSLAKE',
  'GREAT CACAPON',
  'GREAT FALLS',
  'GREAT LAKES',
  'GREAT MEADOWS',
  'GREAT MILLS',
  'GREAT NECK',
  'GREELEY',
  'GREEN BAY',
  'GREEN COVE SPRINGS',
  'GREEN LANE',
  'GREEN MOUNTAIN',
  'GREEN RIDGE',
  'GREEN VALLEY',
  'GREENBRIER',
  'GREENCASTLE',
  'GREENDALE',
  'GREENE',
  'GREENEVILLE',
  'GREENFIELD',
  'GREENSBORO',
  'GREENSBURG',
  'GREENVILLE',
  'GREENWICH',
  'GREENWOOD',
  'GREENWOOD VILLAGE',
  'GREER',
  'GRENADA',
  'GRESHAM',
  'GRETNA',
  'GRIFFIN',
  'GRIFFITH',
  'GRIFTON',
  'GROTON',
  'GROVE CITY',
  'GROVELAND',
  'GROVER BEACH',
  'GROVETOWN',
  'GULF BREEZE',
  'GULF SHORES',
  'GULFPORT',
  'GURNEE',
  'GUYTON',
  'GWYNN OAK',
  'HACIENDA HEIGHTS',
  'HACIENDA HTS',
  'HACKENSACK',
  'HACKETTSTOWN',
  'HADDON HEIGHTS',
  'HADLEY',
  'HAGERSTOWN',
  'HAIKU',
  'HAILEY',
  'HAINES CITY',
  'HALESITE',
  'HALETHORPE',
  'HALF MOON BAY',
  'HALIFAX',
  'HALLANDALE BEACH',
  'HALTOM CITY',
  'HAMBURG',
  'HAMDEN',
  'HAMILTON',
  'HAMMOND',
  'HAMPSHIRE',
  'HAMPSTEAD',
  'HAMPTON',
  'HAMPTON BAYS',
  'HAMTRAMCK',
  'HANAHAN',
  'HANFORD',
  'HANNIBAL',
  'HANOVER',
  'HARBOR CITY',
  'HARDY',
  'HARKER HEIGHTS',
  'HARLAN',
  'HARLEM',
  'HARLEYSVILLE',
  'HARLINGEN',
  'HARRIMAN',
  'HARRISBURG',
  'HARRISON',
  'HARRISON TOWNSHIP',
  'HARRISONBURG',
  'HARTFORD',
  'HARTLAND',
  'HARTSVILLE',
  'HARTWELL',
  'HARVARD',
  'HARVEST',
  'HARVEY',
  'HARWICH',
  'HASKELL',
  'HASLETT',
  'HASTINGS',
  'HATBORO',
  'HATTIESBURG',
  'HAUGHTON',
  'HAVERFORD',
  'HAVERHILL',
  'HAVERTOWN',
  'HAVRE',
  'HAWTHORN WDS',
  'HAWTHORN WOODS',
  'HAYDEN',
  'HAYESVILLE',
  'HAYSVILLE',
  'HAYWARD',
  'HAZEL CREST',
  'HAZEL GREEN',
  'HAZEL PARK',
  'HAZELWOOD',
  'HEALDSBURG',
  'HEALDTON',
  'HEARNE',
  'HEATH SPRINGS',
  'HEBER CITY',
  'HEBRON',
  'HEDGESVILLE',
  'HELEN',
  'HELENA',
  'HELENDALE',
  'HELOTES',
  'HEMET',
  'HEMPSTEAD',
  'HENDERSON',
  'HENDERSONVILLE',
  'HENDERSONVLLE',
  'HENRICO',
  'HEPHZIBAH',
  'HERALD',
  'HERCULES',
  'HEREFORD',
  'HERKIMER',
  'HERMINIE',
  'HERMISTON',
  'HERMITAGE',
  'HERMOSA BEACH',
  'HERNANDO',
  'HERNDON',
  'HERRIMAN',
  'HESPERIA',
  'HIALEAH',
  'HIBBING',
  'HICKORY',
  'HICKORY HILL',
  'HICKSVILLE',
  'HIGH POINT',
  'HIGHLAND',
  'HIGHLAND PARK',
  'HIGHLAND VILLAGE',
  'HIGHLANDS',
  'HIGHLANDS RANCH',
  'HILLIARD',
  'HILLSBORO',
  'HILLSBOROUGH',
  'HILO',
  'HILTON HEAD',
  'HILTON HEAD ISLAND',
  'HINESVILLE',
  'HINSDALE',
  'HIRAM',
  'HITCHCOCK',
  'HO HO KUS',
  'HOBART',
  'HOBBS',
  'HOBE SOUND',
  'HOCKESSIN',
  'HOCKLEY',
  'HOFFMAN ESTATES',
  'HOHENWALD',
  'HOLBROOK',
  'HOLDEN',
  'HOLDREGE',
  'HOLIDAY',
  'HOLLAND',
  'HOLLIDAYSBURG',
  'HOLLIS',
  'HOLLISTER',
  'HOLLY',
  'HOLLYWOOD',
  'HOLSTEIN',
  'HOLTVILLE',
  'HOLYOKE',
  'HOMER',
  'HOMESTEAD',
  'HOMEWOOD',
  'HONEY BROOK',
  'HONOLULU',
  'HOOKSETT',
  'HOOSICK FALLS',
  'HOOVER',
  'HOPEWELL',
  'HOPKINS',
  'HOPKINSVILLE',
  'HOPKINTON',
  'HORIZON CITY',
  'HORN LAKE',
  'HOSCHTON',
  'HOT SPRINGS',
  'HOT SPRINGS NATIONAL PARK',
  'HOUMA',
  'HOUSTON',
  'HOUSTON-THE WOODLANDS-SUGAR LAND TX',
  'HOWARD',
  'HOWARD BEACH',
  'HOWE',
  'HOWELL',
  'HUBERT',
  'HUBERTUS',
  'HUDSON',
  'HUDSON FALLS',
  'HUFFMAN',
  'HUGO',
  'HULBERT',
  'HULEN',
  'HUMBLE',
  'HUMMELSTOWN',
  'HUNTERSVILLE',
  'HUNTINGBURG',
  'HUNTINGDON VALLEY',
  'HUNTINGDON VY',
  'HUNTINGTN BCH',
  'HUNTINGTN STA',
  'HUNTINGTON',
  'HUNTINGTON BEACH',
  'HUNTINGTON PARK',
  'HUNTINGTON WOODS',
  'HUNTINGTOWN',
  'HUNTLEY',
  'HUNTSBURG',
  'HUNTSVILLE',
  'HURLEY',
  'HURLEYVILLE',
  'HURRICANE',
  'HURST',
  'HUTCHINSON',
  'HUTTO',
  'HYATTSVILLE',
  'HYDE PARK',
  'HYRUM',
  'IDAHO FALLS',
  'IMPERIAL',
  'IMPERIAL BEACH',
  'INDEPENDENCE',
  'INDIALANTIC',
  'INDIAN SPRINGS',
  'INDIANA',
  'INDIANAPOLIS',
  'INDIANOLA',
  'INDIO',
  'INGLESIDE',
  'INGLEWOOD',
  'INKOM',
  'INMAN',
  'INTERLAKEN',
  'INVER GROVE HEIGHTS',
  'INVERNESS',
  'INWOOD',
  'IONE',
  'IONIA',
  'IOWA CITY',
  'IRON MOUNTAIN',
  'IRVINE',
  'IRVING',
  'IRWIN',
  'ISANTI',
  'ISHPEMING',
  'ISLAND LAKE',
  'ISLIP',
  'ISSAQUAH',
  'ITASCA',
  'ITHACA',
  'IVINS',
  'JACKSON',
  'JACKSON HEIGHTS',
  'JACKSONVILLE',
  'JACKSONVILLE BEACH',
  'JAL',
  'JAMAICA',
  'JAMAICA PLAIN',
  'JAMESTOWN',
  'JAMESVILLE',
  'JANESVILLE',
  'JARRELL',
  'JASPER',
  'JEAN',
  'JEFFERSON',
  'JEFFERSONVILLE',
  'JEFFERSONVLLE',
  'JENISON',
  'JENSEN BEACH',
  'JEROME',
  'JERSEY CITY',
  'JESSUP',
  'JEWETT CITY',
  'JOHNSBURG',
  'JOHNSON CITY',
  'JOHNSON LAKE',
  'JOHNSTOWN',
  'JOLIET',
  'JONES',
  'JONES CREEK',
  'JONESBORO',
  'JONESTOWN',
  'JONESVILLE',
  'JOPLIN',
  'JOPPA',
  'JULIAN',
  'JUNCTION',
  'JUNEAU',
  'JUPITER',
  'JURUPA VALLEY',
  'JUSTICE',
  'JUSTIN',
  'KAHULUI',
  'KAILUA',
  'KAILUA KONA',
  'KALAHEO',
  'KALAMAZOO',
  'KALISPELL',
  'KANAB',
  'KANEOHE',
  'KANKAKEE',
  'KANNAPOLIS',
  'KANSAS CITY',
  'KAPAA',
  'KAPOLEI',
  'KATHLEEN',
  'KATY',
  'KAWKAWLIN',
  'KAYSVILLE',
  'KEARNEY',
  'KEARNS',
  'KEIZER',
  'KELLER',
  'KELLYVILLE',
  'KELSEYVILLE',
  'KELSO',
  'KEMP',
  'KEMPNER',
  'KENDALLVILLE',
  'KENNEBUNK',
  'KENNER',
  'KENNESAW',
  'KENNETT',
  'KENNEWICK',
  'KENOSHA',
  'KENT',
  'KENTON',
  'KENTWOOD',
  'KERMAN',
  'KERMIT',
  'KERNERSVILLE',
  'KERRVILLE',
  'KETTERING',
  'KEVIL',
  'KEY WEST',
  'KEYES',
  'KEYPORT',
  'KEYSTONE HEIGHTS',
  'KIHEI',
  'KILGORE',
  'KILL DEVIL HILLS',
  'KILLEEN',
  'KILLINGWORTH',
  'KINCAID',
  'KING CITY',
  'KING GEORGE',
  'KING OF PRUSSIA',
  'KINGFISHER',
  'KINGMAN',
  'KINGS PARK',
  'KINGSLAND',
  'KINGSLEY',
  'KINGSPORT',
  'KINGSTON',
  'KINGWOOD',
  'KINNELON',
  'KINNEY',
  'KINSTON',
  'KIRKLAND',
  'KIRKWOOD',
  'KISSIMMEE',
  'KLAMATH FALLS',
  'KNIGHTDALE',
  'KNOX',
  'KNOXVILLE',
  'KOKOMO',
  'KULA',
  'KUNA',
  'KYLE',
  'LA CANADA FLINTRIDGE',
  'LA CANADA FLT',
  'LA CRESCENTA',
  'LA CROSSE',
  'LA FAYETTE',
  'LA FOLLETTE',
  'LA FONTAINE',
  'LA GRANGE',
  'LA GRANGE HIGHLANDS',
  'LA HABRA',
  'LA HABRA HEIGHTS',
  'LA LUZ',
  'LA MARQUE',
  'LA MESA',
  'LA MIRADA',
  'LA PLACE',
  'LA PLATA',
  'LA PORTE',
  'LA PUENTE',
  'LA QUINTA',
  'LA VALLE',
  'LA VERGNE',
  'LA VERKIN',
  'LABELLE',
  'LACEY',
  'LACONA',
  'LADERA RANCH',
  'LADSON',
  'LAFAYETTE',
  'LAGO VISTA',
  'LAGRANGE',
  'LAGUNA BEACH',
  'LAGUNA HILLS',
  'LAGUNA NIGUEL',
  'LAHAINA',
  'LAKE BARRINGTON',
  'LAKE BUENA VISTA',
  'LAKE CHARLES',
  'LAKE CITY',
  'LAKE DALLAS',
  'LAKE ELSINORE',
  'LAKE FOREST',
  'LAKE FOREST PARK',
  'LAKE GEORGE',
  'LAKE HAVASU CITY',
  'LAKE HOPATCONG',
  'LAKE IN THE HILLS',
  'LAKE JACKSON',
  'LAKE KATRINE',
  'LAKE MARY',
  'LAKE MILLS',
  'LAKE OSWEGO',
  'LAKE QUIVIRA',
  'LAKE SAINT LOUIS',
  'LAKE STATION',
  'LAKE VILLA',
  'LAKE WORTH',
  'LAKE ZURICH',
  'LAKEHILLS',
  'LAKELAND',
  'LAKEMOOR',
  'LAKESIDE',
  'LAKEVILLE',
  'LAKEWAY',
  'LAKEWOOD',
  'LAKEWOOD RANCH',
  'LAMAR',
  'LAMBERTON',
  'LAMBERTVILLE',
  'LAMONT',
  'LAMPASAS',
  'LANCASTER',
  'LANETT',
  'LANGHORNE',
  'LANHAM',
  'LANSDALE',
  'LANSDOWNE',
  'LANSFORD',
  'LANSING',
  'LANTANA',
  'LARAMIE',
  'LARCHMONT',
  'LAREDO',
  'LARGO',
  'LARKSPUR',
  'LAS CRUCES',
  'LAS VEGAS',
  'LATHROP',
  'LAUDERDALE LAKES',
  'LAUDERHILL',
  'LAURA',
  'LAUREL',
  'LAURELTON',
  'LAURINBURG',
  'LAVA HOT SPRINGS',
  'LAVEEN',
  'LAWNSIDE',
  'LAWRENCE',
  'LAWRENCE TOWNSHIP',
  'LAWRENCEBURG',
  'LAWRENCEVILLE',
  'LAWTON',
  'LAYTON',
  'LEAD',
  'LEAF RIVER',
  'LEAGUE CITY',
  'LEANDER',
  'LEAVENWORTH',
  'LEAWOOD',
  'LEBANON',
  'LECANTO',
  'LEDYARD',
  'LEE',
  'LEECHBURG',
  'LEEDS',
  'LEES SUMMIT',
  'LEESBURG',
  'LEESVILLE',
  'LEETONIA',
  'LEHI',
  'LEHIGH ACRES',
  'LEMPSTER',
  'LENA',
  'LENEXA',
  'LENOIR',
  'LENORA',
  'LEOLA',
  'LEOMA',
  'LEOMINSTER',
  'LESLIE',
  'LEVELLAND',
  'LEVITTOWN',
  'LEWES',
  'LEWIS CENTER',
  'LEWISBURG',
  'LEWISTON',
  'LEWISVILLE',
  'LEXINGTON',
  'LIBERAL',
  'LIBERTY',
  'LIBERTY HILL',
  'LIBERTY LAKE',
  'LIBERTY TOWNSHIP',
  'LIBERTYVILLE',
  'LIHUE',
  'LILBURN',
  'LIMON',
  'LINCOLN',
  'LINCOLN PARK',
  'LINCOLNTON',
  'LINDALE',
  'LINDEN',
  'LINDENHURST',
  'LINDSTROM',
  'LINN',
  'LINO LAKES',
  'LINTON',
  'LISLE',
  'LITCHFIELD',
  'LITCHFIELD PARK',
  'LITHIA',
  'LITHONIA',
  'LITITZ',
  'LITTLE ELM',
  'LITTLE FERRY',
  'LITTLE HOCKING',
  'LITTLE RIVER',
  'LITTLE ROCK',
  'LITTLEFIELD',
  'LITTLEFORK',
  'LITTLETON',
  'LIVE OAK',
  'LIVERMORE',
  'LIVERPOOL',
  'LIVINGSTON',
  'LIVONIA',
  'LIZELLA',
  'LK HAVASU CTY',
  'LOCKHART',
  'LOCKPORT',
  'LOCUST GROVE',
  'LOGAN',
  'LOGANDALE',
  'LOGANSPORT',
  'LOGANVILLE',
  'LOMA',
  'LOMA LINDA',
  'LOMBARD',
  'LOMETA',
  'LOMITA',
  'LOMPOC',
  'LONDONDERRY',
  'LONE GROVE',
  'LONE TREE',
  'LONG BEACH',
  'LONG IS CITY',
  'LONG ISLAND CITY',
  'LONGMONT',
  'LONGVIEW',
  'LONGWOOD',
  'LORAIN',
  'LORETTO',
  'LORTON',
  'LOS ALAMITOS',
  'LOS ALAMOS',
  'LOS ANGELES',
  'LOS GATOS',
  'LOS LUNAS',
  'LOS RANCHOS',
  'LOUDON',
  'LOUISBURG',
  'LOUISVILLE',
  'LOVELAND',
  'LOVES PARK',
  'LOVETTSVILLE',
  'LOVINGSTON',
  'LOVINGTON',
  'LOWELL',
  'LOXLEY',
  'LUBBOCK',
  'LUDOWICI',
  'LUFKIN',
  'LUGOFF',
  'LULING',
  'LUMBERTON',
  'LUSBY',
  'LUTHERVILLE TIMONIUM',
  'LUTHVLE TIMON',
  'LUTZ',
  'LYMAN',
  'LYNBROOK',
  'LYNCHBURG',
  'LYNDEN',
  'LYNDHURST',
  'LYNN',
  'LYNNFIELD',
  'LYNNWOOD',
  'LYNWOOD',
  'LYONS',
  'MABELVALE',
  'MABLETON',
  'MACHESNEY PARK',
  'MACK',
  'MACKINAW',
  'MACKSVILLE',
  'MACOMB',
  'MACON',
  'MADERA',
  'MADILL',
  'MADISON',
  'MADISON HEIGHTS',
  'MADISONVILLE',
  'MAGNA',
  'MAGNOLIA',
  'MAHOPAC',
  'MAHWAH',
  'MAITLAND',
  'MALIBU',
  'MALVERN',
  'MALVERNE',
  'MAMARONECK',
  'MANAHAWKIN',
  'MANALAPAN',
  'MANASSAS',
  'MANASSAS PARK',
  'MANCHESTER',
  'MANCHESTER CENTER',
  'MANDAN',
  'MANDEVILLE',
  'MANHATTAN',
  'MANHATTAN BCH',
  'MANHATTAN BEACH',
  'MANHEIM',
  'MANISTIQUE',
  'MANITOWISH WATERS',
  'MANITOWOC',
  'MANKATO',
  'MANLIUS',
  'MANOR',
  'MANSFIELD',
  'MANTECA',
  'MANVEL',
  'MAPLE GROVE',
  'MAPLE LAKE',
  'MAPLE PLAIN',
  'MAPLE SHADE',
  'MAPLE VALLEY',
  'MAPLETON',
  'MAPLEWOOD',
  'MARANA',
  'MARATHON',
  'MARCO ISLAND',
  'MARCOLA',
  'MARCUS HOOK',
  'MARICOPA',
  'MARIETTA',
  'MARINA DEL REY',
  'MARINE ST CRX',
  'MARINETTE',
  'MARION',
  'MARKHAM',
  'MARLBORO',
  'MARLTON',
  'MARQUETTE HEIGHTS',
  'MARRERO',
  'MARS',
  'MARSHALL',
  'MARSHALLTOWN',
  'MARSHFIELD',
  'MARTINSVILLE',
  'MARY ESTHER',
  'MARYSVILLE',
  'MARYVILLE',
  'MASCOTTE',
  'MASCOUTAH',
  'MASON',
  'MASON CITY',
  'MASSAPEQUA',
  'MASSAPEQUA PARK',
  'MASSENA',
  'MASSILLON',
  'MASTIC',
  'MATAWAN',
  'MATTAPAN',
  'MATTHEWS',
  'MATTOON',
  'MAULDIN',
  'MAUMEE',
  'MAUMELLE',
  'MAXWELL',
  'MAYFLOWER',
  'MAYS LANDING',
  'MAYSVILLE',
  'MAYVILLE',
  'MAYWOOD',
  'MC CALLA',
  'MC CLELLANDTOWN',
  'MC CLELLANVILLE',
  'MC CLURE',
  'MC CORMICK',
  'MC FARLAND',
  'MC GREGOR',
  'MC KINNEY',
  'MC LEAN',
  'MC LEANSVILLE',
  'MC MINNVILLE',
  'MC NABB',
  'MC QUEENEY',
  'MCADOO',
  'MCALLEN',
  'MCCALL',
  'MCCOMB',
  'MCCORDSVILLE',
  'MCDONOUGH',
  'MCGREGOR',
  'MCHENRY',
  'MCKINNEY',
  'MCLOUD',
  'MCMINNVILLE',
  'MEANSVILLE',
  'MECHANICSBURG',
  'MECHANICSVILLE',
  'MECHANICSVLLE',
  'MECHANICVILLE',
  'MEDFIELD',
  'MEDFORD',
  'MEDIA',
  'MEDICAL LAKE',
  'MEDINA',
  'MEDWAY',
  'MEEKER',
  'MEHOOPANY',
  'MELBOURNE',
  'MELISSA',
  'MELROSE PARK',
  'MELVILLE',
  'MEMPHIS',
  'MENAHGA',
  'MENASHA',
  'MENDHAM',
  'MENDON',
  'MENDOTA',
  'MENIFEE',
  'MENLO',
  'MENLO PARK',
  'MENOMONEE FALLS',
  'MENOMONIE',
  'MENTONE',
  'MENTOR',
  'MEQUON',
  'MERCED',
  'MERCER ISLAND',
  'MERIDEN',
  'MERIDIAN',
  'MERRICK',
  'MERRILL',
  'MERRILLVILLE',
  'MERRIMACK',
  'MERRITT ISLAND',
  'MESA',
  'MESCALERO',
  'MESQUITE',
  'METAIRIE',
  'METHUEN',
  'MIAMI',
  'MIAMI BEACH',
  'MIAMI GARDENS',
  'MIAMI LAKES',
  'MICHIGAN CITY',
  'MICHIGANTOWN',
  'MIDDLE RIVER',
  'MIDDLE VILLAGE',
  'MIDDLEBORO',
  'MIDDLEBOURNE',
  'MIDDLEBURG',
  'MIDDLEFIELD',
  'MIDDLESEX',
  'MIDDLETON',
  'MIDDLETOWN',
  'MIDLAND',
  'MIDLAND PARK',
  'MIDLOTHIAN',
  'MIDVALE',
  'MIDWAY CITY',
  'MILFORD',
  'MILILANI',
  'MILL CREEK',
  'MILL VALLEY',
  'MILLBRAE',
  'MILLBROOK',
  'MILLBURN',
  'MILLEDGEVILLE',
  'MILLERSVILLE',
  'MILLINGTON',
  'MILLSTONE TWP',
  'MILLVILLE',
  'MILTON',
  'MILTONA',
  'MILWAUKEE',
  'MILWAUKEE-WAUKESHA WI',
  'MIMS',
  'MINCO',
  'MINERAL',
  'MINERAL BLUFF',
  'MINERAL POINT',
  'MINERAL RIDGE',
  'MINERAL WELLS',
  'MINERSVILLE',
  'MINERVA',
  'MINNEAPOLIS',
  'MINNEAPOLIS-ST. PAUL MN-WI MSA',
  'MINNETONKA',
  'MINOOKA',
  'MINOT',
  'MINT HILL',
  'MIRA LOMA',
  'MIRAMAR',
  'MIRAMAR BEACH',
  'MISHAWAKA',
  'MISSION',
  'MISSION HILLS',
  'MISSION VIEJO',
  'MISSOULA',
  'MISSOURI CITY',
  'MITCHELL',
  'MITCHELLVILLE',
  'MIZE',
  'MOAB',
  'MOAPA',
  'MOBILE',
  'MODESTO',
  'MOHAVE VALLEY',
  'MOHNTON',
  'MOKENA',
  'MOLALLA',
  'MOLINE',
  'MOLINO',
  'MONACA',
  'MONCKS CORNER',
  'MONCLOVA',
  'MONESSEN',
  'MONETT',
  'MONONGAHELA',
  'MONROE',
  'MONROE TOWNSHIP',
  'MONROE TWP',
  'MONROEVILLE',
  'MONROVIA',
  'MONTCLAIR',
  'MONTEBELLO',
  'MONTEREY PARK',
  'MONTGOMERY',
  'MONTICELLO',
  'MONTROSE',
  'MONTROSS',
  'MONUMENT',
  'MOON TOWNSHIP',
  'MOONACHIE',
  'MOORE',
  'MOORESVILLE',
  'MOORHEAD',
  'MOORPARK',
  'MORA',
  'MORAGA',
  'MOREHEAD',
  'MOREHEAD CITY',
  'MORENCI',
  'MORENO VALLEY',
  'MORGAN HILL',
  'MORGANTON',
  'MORGANTOWN',
  'MORIARTY',
  'MORONGO VALLEY',
  'MORRILL',
  'MORRIS',
  'MORRIS PLAINS',
  'MORRISTOWN',
  'MORRISVILLE',
  'MORRO BAY',
  'MORROW',
  'MORTON',
  'MOSCOW',
  'MOSELEY',
  'MOSELLE',
  'MOTT',
  'MOUNT AIRY',
  'MOUNT ANGEL',
  'MOUNT ENTERPRISE',
  'MOUNT HOLLY',
  'MOUNT HOPE',
  'MOUNT JACKSON',
  'MOUNT JULIET',
  'MOUNT KISCO',
  'MOUNT PLEASANT',
  'MOUNT PROSPECT',
  'MOUNT SHASTA',
  'MOUNT VERNON',
  'MOUNT WASHINGTON',
  'MOUNTAIN HOME',
  'MOUNTAIN TOP',
  'MOUNTAIN VIEW',
  'MOUNTAINBURG',
  'MOUNTAINSIDE',
  'MOUNTVILLE',
  'MOWEAQUA',
  'MT CARMEL',
  'MT PLEASANT',
  'MT WASHINGTON',
  'MUKWONAGO',
  'MULBERRY',
  'MULINO',
  'MULLICA HILL',
  'MUNCIE',
  'MUNDELEIN',
  'MUNFORD',
  'MUNROE FALLS',
  'MUNSTER',
  'MURFREESBORO',
  'MURPHY',
  'MURPHYSBORO',
  'MURRAY',
  'MURRELLS INLET',
  'MURRELLS INLT',
  'MURRIETA',
  'MURRYSVILLE',
  'MUSCATINE',
  'MUSCLE SHOALS',
  'MUSKEGO',
  'MUSKEGON',
  'MUSTANG',
  'MYRTLE BEACH',
  'N KINGSTOWN',
  'N LAS VEGAS',
  'N RICHLND HLS',
  'NACOGDOCHES',
  'NAHUNTA',
  'NAMPA',
  'NANTICOKE',
  'NANTUCKET',
  'NAPA',
  'NAPANOCH',
  'NAPERVILLE',
  'NAPLES',
  'NAPOLEON',
  'NAPPANEE',
  'NARBERTH',
  'NARRAGANSETT',
  'NASHPORT',
  'NASHUA',
  'NASHVILLE',
  'NATCHITOCHES',
  'NATICK',
  'NAUGATUCK',
  'NAVARRE',
  'NEEDHAM',
  'NEEDLES',
  'NEHALEM',
  'NELLIS AFB',
  'NELLYSFORD',
  'NEPTUNE',
  'NEPTUNE BEACH',
  'NEVADA',
  'NEVADA CITY',
  'NEW ALBANY',
  'NEW BADEN',
  'NEW BEDFORD',
  'NEW BERLIN',
  'NEW BERN',
  'NEW BLOOMFIELD',
  'NEW BRAUNFELS',
  'NEW BRITAIN',
  'NEW CANAAN',
  'NEW CANEY',
  'NEW CARLISLE',
  'NEW CASTLE',
  'NEW CITY',
  'NEW CONCORD',
  'NEW FAIRFIELD',
  'NEW FRANKEN',
  'NEW FREEDOM',
  'NEW GLARUS',
  'NEW HAMPTON',
  'NEW HARMONY',
  'NEW HARTFORD',
  'NEW HAVEN',
  'NEW HOLLAND',
  'NEW HOPE',
  'NEW HYDE PARK',
  'NEW IBERIA',
  'NEW KENSINGTON',
  'NEW LEBANON',
  'NEW LENOX',
  'NEW LONDON',
  'NEW MARKET',
  'NEW MARTINSVILLE',
  'NEW MILFORD',
  'NEW ORLEANS',
  'NEW PALESTINE',
  'NEW PALTZ',
  'NEW PORT RICHEY',
  'NEW PRT RCHY',
  'NEW RICHLAND',
  'NEW RINGGOLD',
  'NEW RIVER',
  'NEW ROCHELLE',
  'NEW SMYRNA BEACH',
  'NEW WINDSOR',
  'NEW YORK',
  'NEW YORK-NEWARK-JERSEY CITY NY-NJ-PA',
  'NEWARK',
  'NEWBERG',
  'NEWBURGH',
  'NEWBURY PARK',
  'NEWBURYPORT',
  'NEWCASTLE',
  'NEWHALL',
  'NEWMANSTOWN',
  'NEWNAN',
  'NEWPORT',
  'NEWPORT BEACH',
  'NEWPORT NEWS',
  'NEWTON',
  'NEWTOWN',
  'NEWVILLE',
  'NIAGARA FALLS',
  'NIANTIC',
  'NICEVILLE',
  'NICHOLASVILLE',
  'NICHOLLS',
  'NILES',
  'NOBLE',
  'NOBLESVILLE',
  'NOGALES',
  'NOKESVILLE',
  'NOLENSVILLE',
  'NORCROSS',
  'NORFOLK',
  'NORMAL',
  'NORMAN',
  'NORRISTOWN',
  'NORTH ADAMS',
  'NORTH ANDOVER',
  'NORTH AUGUSTA',
  'NORTH AURORA',
  'NORTH BANGOR',
  'NORTH BAY VILLAGE',
  'NORTH BEND',
  'NORTH BERGEN',
  'NORTH BETHESDA',
  'NORTH BRANCH',
  'NORTH BRANFORD',
  'NORTH BRUNSWICK',
  'NORTH CANTON',
  'NORTH CHARLESTON',
  'NORTH CHESTERFIELD',
  'NORTH EAST',
  'NORTH FORK',
  'NORTH FORT MYERS',
  'NORTH FRANKLIN',
  'NORTH HAVEN',
  'NORTH HIGHLANDS',
  'NORTH HILLS',
  'NORTH HOLLYWOOD',
  'NORTH KINGSTOWN',
  'NORTH LAS VEGAS',
  'NORTH LIBERTY',
  'NORTH LITTLE ROCK',
  'NORTH LOGAN',
  'NORTH MIAMI',
  'NORTH MIAMI BEACH',
  'NORTH MYRTLE BEACH',
  'NORTH OGDEN',
  'NORTH OLMSTED',
  'NORTH PLAINS',
  'NORTH PLATTE',
  'NORTH POTOMAC',
  'NORTH RICHLAND HILLS',
  'NORTH RIDGEVILLE',
  'NORTH ROYALTON',
  'NORTH SCITUATE',
  'NORTH VERNON',
  'NORTH WALES',
  'NORTH WILKESBORO',
  'NORTHBOROUGH',
  'NORTHBROOK',
  'NORTHFIELD',
  'NORTHFORD',
  'NORTHGLENN',
  'NORTHLAKE',
  'NORTHPORT',
  'NORTHRIDGE',
  'NORTHVILLE',
  'NORTON SHORES',
  'NORWALK',
  'NORWAY',
  'NORWICH',
  'NOVATO',
  'NOVI',
  'NUCLA',
  'O FALLON',
  'OAK CREEK',
  'OAK FOREST',
  'OAK HILL',
  'OAK HILLS',
  'OAK LAWN',
  'OAK PARK',
  'OAK RIDGE',
  'OAK VIEW',
  'OAKDALE',
  'OAKHURST',
  'OAKLAND',
  'OAKWOOD',
  'OBERLIN',
  'OCALA',
  'OCEAN',
  'OCEAN CITY',
  'OCEAN SPRINGS',
  'OCEANSIDE',
  'OCOEE',
  'OCONOMOWOC',
  'ODENTON',
  'ODESSA',
  'OGDEN',
  'OGLESBY',
  'OKLAHOMA CITY',
  'OKMULGEE',
  'OKOLONA',
  'OLATHE',
  'OLD BETHPAGE',
  'OLD BRIDGE',
  'OLD HICKORY',
  'OLD ORCHARD BEACH',
  'OLD WESTBURY',
  'OLDSMAR',
  'OLIVE BRANCH',
  'OLIVEHURST',
  'OLNEY',
  'OLYMPIA',
  'OMAHA',
  'OMAHA-COUNCIL BLUFFS NE-IA',
  'ONALASKA',
  'ONANCOCK',
  'ONEIDA',
  'ONEONTA',
  'ONSTED',
  'ONTARIO',
  'OPELIKA',
  'OPELOUSAS',
  'ORANGE',
  'ORANGE PARK',
  'ORANGEBURG',
  'ORCHARD LAKE',
  'ORCHARD PARK',
  'OREFIELD',
  'OREGON',
  'OREGON CITY',
  'ORLAND PARK',
  'ORLANDO',
  'ORMOND BEACH',
  'ORO VALLEY',
  'OROVILLE',
  'OSCEOLA',
  'OSHKOSH',
  'OSKALOOSA',
  'OSSEO',
  'OSSINING',
  'OSTRANDER',
  'OSWEGO',
  'OTTAWA',
  'OTTERTAIL',
  'OTTOSEN',
  'OTTUMWA',
  'OVERLAND PARK',
  'OVERTON',
  'OVIEDO',
  'OWASSO',
  'OWATONNA',
  'OWENS X RDS',
  'OWENSBORO',
  'OWINGS',
  'OWINGS MILLS',
  'OWOSSO',
  'OXFORD',
  'OXNARD',
  'OZARK',
  'OZONE PARK',
  'PACE',
  'PACIFIC GROVE',
  'PACIFIC PALISADES',
  'PACIFIC PLSDS',
  'PACIFICA',
  'PACOIMA',
  'PAGE',
  'PAGOSA SPRINGS',
  'PAHRUMP',
  'PAIA',
  'PAINESVILLE',
  'PALATINE',
  'PALATKA',
  'PALM BAY',
  'PALM BEACH',
  'PALM COAST',
  'PALM DESERT',
  'PALM HARBOR',
  'PALM SPRINGS',
  'PALMDALE',
  'PALMER',
  'PALMERTON',
  'PALMETTO',
  'PALMETTO BAY',
  'PALMVIEW',
  'PALMYRA',
  'PALO ALTO',
  'PALOS HEIGHTS',
  'PALOS HILLS',
  'PALOS PARK',
  'PALOS VERDES ESTATES',
  'PAMPA',
  'PANAMA CITY',
  'PANAMA CITY BEACH',
  'PANORAMA CITY',
  'PAOLI',
  'PAPILLION',
  'PARADISE VALLEY',
  'PARAGOULD',
  'PARDEEVILLE',
  'PARIS',
  'PARISH',
  'PARK CITY',
  'PARK HILLS',
  'PARK RIDGE',
  'PARKER',
  'PARKESBURG',
  'PARKLAND',
  'PARKTON',
  'PARLIN',
  'PARMA',
  'PARRISH',
  'PARSIPPANY',
  'PARSONS',
  'PASADENA',
  'PASCO',
  'PASSAIC',
  'PATCHOGUE',
  'PATERSON',
  'PATTERSON',
  'PAULDING',
  'PAW PAW',
  'PAWLEYS ISLAND',
  'PAWNEE',
  'PAWTUCKET',
  'PAXICO',
  'PAYSON',
  'PEABODY',
  'PEACHTREE CITY',
  'PEARL',
  'PEARL CITY',
  'PEARL RIVER',
  'PEARLAND',
  'PEARLINGTON',
  'PECOS',
  'PECULIAR',
  'PEKIN',
  'PELHAM',
  'PEMBROKE',
  'PEMBROKE PINES',
  'PENDERGRASS',
  'PENDLETON',
  'PENNDEL',
  'PENNINGTON',
  'PENNSAUKEN',
  'PENOBSCOT',
  'PENSACOLA',
  'PEORIA',
  'PEORIA HEIGHTS',
  'PEPPERELL',
  'PEQUOT LAKES',
  'PERALTA',
  'PERHAM',
  'PERKASIE',
  'PERRIS',
  'PERRY',
  'PERRY HALL',
  'PERRYSBURG',
  'PERRYVILLE',
  'PERU',
  'PETAL',
  'PETALUMA',
  'PETERBOROUGH',
  'PETERSBURG',
  'PETOSKEY',
  'PEWAUKEE',
  'PFLUGERVILLE',
  'PHARR',
  'PHELPS',
  'PHENIX CITY',
  'PHILA',
  'PHILADELPHIA',
  'PHILLIPSBURG',
  'PHILOMATH',
  'PHOENIX',
  'PHOENIXVILLE',
  'PICKERINGTON',
  'PICO RIVERA',
  'PIEDMONT',
  'PIERCE',
  'PIERMONT',
  'PIERSON',
  'PIKE ROAD',
  'PIKESVILLE',
  'PIKETON',
  'PIKEVILLE',
  'PINCKNEY',
  'PINE BEACH',
  'PINE BLUFF',
  'PINE CASTLE',
  'PINE CITY',
  'PINECREST',
  'PINEDALE',
  'PINEHURST',
  'PINELLAS PARK',
  'PINEVILLE',
  'PIPERSVILLE',
  'PISCATAWAY',
  'PISMO BEACH',
  'PITMAN',
  'PITTSBURG',
  'PITTSBURGH',
  'PITTSFIELD',
  'PITTSFORD',
  'PLACENTIA',
  'PLACIDA',
  'PLAINFIELD',
  'PLAINSBORO',
  'PLAINVIEW',
  'PLANO',
  'PLANTATION',
  'PLAQUEMINE',
  'PLATO',
  'PLATTEVILLE',
  'PLAYA DEL REY',
  'PLEASANT GROVE',
  'PLEASANT HILL',
  'PLEASANT PRAIRIE',
  'PLEASANT VALLEY',
  'PLEASANTON',
  'PLEASANTVILLE',
  'PLOVER',
  'PLUM BRANCH',
  'PLYMOUTH',
  'POCAHONTAS',
  'POCATELLO',
  'POCONO LAKE',
  'POMONA',
  'POMPANO BEACH',
  'PONCHATOULA',
  'PONDER',
  'PONTE VEDRA',
  'PONTIAC',
  'POOLER',
  'POPLAR BLUFF',
  'POPLARVILLE',
  'PORT ALLEN',
  'PORT ANGELES',
  'PORT CHARLOTTE',
  'PORT CHESTER',
  'PORT CLINTON',
  'PORT HADLOCK',
  'PORT HURON',
  'PORT ISABEL',
  'PORT JEFFERSON STATION',
  'PORT JERVIS',
  'PORT LAVACA',
  'PORT ORANGE',
  'PORT ORCHARD',
  'PORT REPUBLIC',
  'PORT RICHEY',
  'PORT SAINT JOE',
  'PORT SAINT LUCIE',
  'PORT ST LUCIE',
  'PORTAGE',
  'PORTALES',
  'PORTER',
  'PORTERVILLE',
  'PORTLAND',
  'PORTSMOUTH',
  'POST FALLS',
  'POTH',
  'POTTSTOWN',
  'POTWIN',
  'POUGHKEEPSIE',
  'POWAY',
  'POWDER SPRINGS',
  'POWHATAN',
  'PRAIRIEVILLE',
  'PRATT',
  'PRATTVILLE',
  'PREMONT',
  'PRESCOTT',
  'PRESCOTT VALLEY',
  'PRESTON',
  'PRINCE FREDERICK',
  'PRINCETON',
  'PROSPECT',
  'PROSPECT HTS',
  'PROSPER',
  'PROSPERITY',
  'PROSSER',
  'PROVIDENCE',
  'PROVO',
  'PRUDENVILLE',
  'PT CHARLOTTE',
  'PUEBLO',
  'PULASKI',
  'PULLMAN',
  'PUNTA GORDA',
  'PURCELLVILLE',
  'PURCHASE',
  'PUYALLUP',
  'QUAKERTOWN',
  'QUEEN CREEK',
  'QUEENS VILLAGE',
  'QUINCY',
  'RACINE',
  'RADCLIFF',
  'RAEFORD',
  'RAGLAND',
  'RAHWAY',
  'RALEIGH',
  'RAMEY',
  'RAMONA',
  'RANCHO CORDOVA',
  'RANCHO CUCAMONGA',
  'RANCHO PALOS VERDES',
  'RANCHO SANTA MARGARITA',
  'RANCHOS DE TAOS',
  'RANDOLPH',
  'RAVENEL',
  'RAVENSDALE',
  'RAVENSWOOD',
  'RAYMONDVILLE',
  'RAYTOWN',
  'RCH CUCAMONGA',
  'RCH PALOS VRD',
  'RCHO STA MARG',
  'READFIELD',
  'READING',
  'RED BANK',
  'RED BLUFF',
  'RED LION',
  'RED SPRINGS',
  'REDDICK',
  'REDDING',
  'REDLANDS',
  'REDMOND',
  'REDONDO BEACH',
  'REDWOOD CITY',
  'REDWOOD VALLEY',
  'REEDLEY',
  'REEDSBURG',
  'REEDSPORT',
  'REFUGIO',
  'REGO PARK',
  'REHOBOTH BCH',
  'REISTERSTOWN',
  'RENO',
  'RENSSELAER',
  'RENTON',
  'RESEDA',
  'RESTON',
  'REUNION',
  'REVERE',
  'REX',
  'REXBURG',
  'REYNOLDSBURG',
  'RHINEBECK',
  'RHOADESVILLE',
  'RHOME',
  'RIALTO',
  'RICH HILL',
  'RICHARDSON',
  'RICHBORO',
  'RICHLAND',
  'RICHLANDS',
  'RICHMOND',
  'RICHMOND HILL',
  'RICHTON PARK',
  'RIDGEFIELD',
  'RIDGEFIELD PARK',
  'RIDGELAND',
  'RIDGEWOOD',
  'RIESEL',
  'RIFLE',
  'RIGBY',
  'RINCON',
  'RINDGE',
  'RINGGOLD',
  'RIO BRAVO',
  'RIO GRANDE CITY',
  'RIO GRANDE CY',
  'RIO LINDA',
  'RIO RANCHO',
  'RISING SUN',
  'RITTER',
  'RIVER FOREST',
  'RIVER VALE',
  'RIVERBANK',
  'RIVERDALE',
  'RIVERHEAD',
  'RIVERSIDE',
  'RIVERTON',
  'RIVERVIEW',
  'RIVIERA BEACH',
  'ROANOKE',
  'ROARING BROOK TWP',
  'ROBBINS',
  'ROBBINSDALE',
  'ROBBINSVILLE',
  'ROBERTA',
  'ROBINSON',
  'ROBSTOWN',
  'ROCHESTER',
  'ROCHESTER HILLS',
  'ROCK HILL',
  'ROCK ISLAND',
  'ROCK RAPIDS',
  'ROCKAWAY',
  'ROCKAWAY PARK',
  'ROCKDALE',
  'ROCKFORD',
  'ROCKLEDGE',
  'ROCKLIN',
  'ROCKMART',
  'ROCKTON',
  'ROCKVILLE',
  'ROCKWALL',
  'ROCKWELL',
  'ROCKY FACE',
  'ROCKY MOUNT',
  'ROCKY RIVER',
  'RODEO',
  'ROGERS',
  'ROGUE RIVER',
  'ROHNERT PARK',
  'ROLLA',
  'ROLLING HILLS ESTATES',
  'ROLLING MEADOWS',
  'ROLLINSFORD',
  'ROME',
  'ROMEOVILLE',
  'ROMULUS',
  'RONKONKOMA',
  'ROSCOE',
  'ROSCOMMON',
  'ROSE HILL',
  'ROSEBURG',
  'ROSEDALE',
  'ROSELLE',
  'ROSEMEAD',
  'ROSEMOUNT',
  'ROSENBERG',
  'ROSEVILLE',
  'ROSHARON',
  'ROSLYN',
  'ROSLYN HEIGHTS',
  'ROSS',
  'ROSSFORD',
  'ROSSVILLE',
  'ROSWELL',
  'ROUND LAKE BEACH',
  'ROUND ROCK',
  'ROWLAND HEIGHTS',
  'ROWLETT',
  'ROXBURY',
  'ROXBURY CROSSING',
  'ROY',
  'ROYAL',
  'ROYAL OAK',
  'ROYAL PALM BEACH',
  'ROYERSFORD',
  'ROYSE CITY',
  'RUIDOSO',
  'RULEVILLE',
  'RURAL HALL',
  'RUSHVILLE',
  'RUSKIN',
  'RUSSELL',
  'RUSSELLVILLE',
  'RUSTON',
  'RUTHERFORD',
  'RYDAL',
  'RYE BROOK',
  'S LAKE TAHOE',
  'S PASADENA',
  'S PLAINFIELD',
  'S SALT LAKE',
  'SABATTUS',
  'SAC CITY',
  'SACHSE',
  'SACRAMENTO',
  'SADDLE RIVER',
  'SAGINAW',
  'SAGLE',
  'SAHUARITA',
  'SAINT ALBANS',
  'SAINT AUGUSTINE',
  'SAINT BONIFACIUS',
  'SAINT CHARLES',
  'SAINT CLAIR SHORES',
  'SAINT CLOUD',
  'SAINT FRANCIS',
  'SAINT GEORGE',
  'SAINT HEDWIG',
  'SAINT HELENS',
  'SAINT JAMES',
  'SAINT JOHN',
  'SAINT JOHNS',
  'SAINT JOSEPH',
  'SAINT LOUIS',
  'SAINT MARYS',
  'SAINT PAUL',
  'SAINT PAULS',
  'SAINT PETERS',
  'SAINT PETERSBURG',
  'SAINT REGIS',
  'SAINT ROBERT',
  'SALADO',
  'SALAMANCA',
  'SALEM',
  'SALIDA',
  'SALINA',
  'SALINAS',
  'SALISBURY',
  'SALOL',
  'SALT LAKE CITY',
  'SALT LAKE CTY',
  'SAMMAMISH',
  'SAN ANGELO',
  'SAN ANSELMO',
  'SAN ANTONIO',
  'SAN BENITO',
  'SAN BERNARDINO',
  'SAN CLEMENTE',
  'SAN DIEGO',
  'SAN DIMAS',
  'SAN ELIZARIO',
  'SAN FERNANDO',
  'SAN FRANCISCO',
  'SAN GABRIEL',
  'SAN JACINTO',
  'SAN JOSE',
  'SAN JUAN',
  'SAN LEANDRO',
  'SAN LORENZO',
  'SAN LUIS OBISPO',
  'SAN MARCOS',
  'SAN MARINO',
  'SAN MATEO',
  'SAN PABLO',
  'SAN PEDRO',
  'SAN RAFAEL',
  'SAN RAMON',
  'SAN SABA',
  'SAN TAN VALLEY',
  'SANDOWN',
  'SANDUSKY',
  'SANDWICH',
  'SANDY',
  'SANFORD',
  'SANGER',
  'SANTA ANA',
  'SANTA BARBARA',
  'SANTA CLARA',
  'SANTA CLARITA',
  'SANTA CRUZ',
  'SANTA FE',
  'SANTA MARIA',
  'SANTA MONICA',
  'SANTA PAULA',
  'SANTA ROSA',
  'SANTA ROSA BEACH',
  'SANTA YNEZ',
  'SANTEE',
  'SAPULPA',
  'SARAH',
  'SARAHSVILLE',
  'SARASOTA',
  'SARATOGA',
  'SARATOGA SPRINGS',
  'SARTELL',
  'SATELLITE BEACH',
  'SAUGERTIES',
  'SAUGUS',
  'SAUK CITY',
  'SAUK RAPIDS',
  'SAULT SAINTE MARIE',
  'SAUTEE NACOOCHEE',
  'SAVANNAH',
  'SAVOY',
  'SAXONBURG',
  'SCANDIA',
  'SCARSDALE',
  'SCHAUMBURG',
  'SCHENECTADY',
  'SCHERTZ',
  'SCHOFIELD',
  'SCHWENKSVILLE',
  'SCOBEY',
  'SCOTCH PLAINS',
  'SCOTT CITY',
  'SCOTTS VALLEY',
  'SCOTTSBLUFF',
  'SCOTTSDALE',
  'SCRANTON',
  'SCURRY',
  'SEA CLIFF',
  'SEABROOK',
  'SEAFORD',
  'SEAGOVILLE',
  'SEAGROVE',
  'SEAL BEACH',
  'SEALE',
  'SEARSMONT',
  'SEASIDE',
  'SEATAC',
  'SEATTLE',
  'SEBASTIAN',
  'SEBASTOPOL',
  'SEBRING',
  'SEDALIA',
  'SEDONA',
  'SEDRO WOOLLEY',
  'SEFFNER',
  'SEGUIN',
  'SELMA',
  'SEQUIM',
  'SEVEN VALLEYS',
  'SEVERN',
  'SEVERNA PARK',
  'SEWELL',
  'SEYMOUR',
  'SHAKER HEIGHTS',
  'SHAKOPEE',
  'SHALLOWATER',
  'SHANNON CITY',
  'SHARPSBURG',
  'SHAVANO PARK',
  'SHAWNEE',
  'SHAWNEE MISSION',
  'SHEFFIELD LAKE',
  'SHELBY',
  'SHELBY TOWNSHIP',
  'SHELBYVILLE',
  'SHELDON',
  'SHELLEY',
  'SHELLSBURG',
  'SHEPHERDSVILLE',
  'SHERBURNE',
  'SHERIDAN',
  'SHERMAN',
  'SHERMAN OAKS',
  'SHERWOOD',
  'SHERWOOD FOREST',
  'SHILOH',
  'SHINGLE SPRINGS',
  'SHIP BOTTOM',
  'SHIRLEY',
  'SHIRLEYSBURG',
  'SHOREHAM',
  'SHORELINE',
  'SHOREWOOD',
  'SHORT HILLS',
  'SHOW LOW',
  'SHREVEPORT',
  'SHREWSBURY',
  'SIBLEY',
  'SICKLERVILLE',
  'SIDNEY',
  'SIERRA VISTA',
  'SIGNAL HILL',
  'SILER CITY',
  'SILOAM SPRINGS',
  'SILSBEE',
  'SILVER SPRING',
  'SILVERDALE',
  'SILVERTHORNE',
  'SIMI VALLEY',
  'SIMPSONVILLE',
  'SIMSBURY',
  'SIOUX CITY',
  'SIOUX FALLS',
  'SITKA',
  'SKIATOOK',
  'SKOKIE',
  'SLEEPY HOLLOW',
  'SLIDELL',
  'SLINGER',
  'SMETHPORT',
  'SMITHFIELD',
  'SMITHS STATION',
  'SMITHSBURG',
  'SMITHTOWN',
  'SMITHVILLE',
  'SMYRNA',
  'SN LUIS OBISP',
  'SNELLVILLE',
  'SNOHOMISH',
  'SNOQUALMIE',
  'SOCIAL CIRCLE',
  'SOCORRO',
  'SODDY DAISY',
  'SOLANA BEACH',
  'SOLON',
  'SOMERDALE',
  'SOMERS',
  'SOMERSET',
  'SOMERSWORTH',
  'SOMERVILLE',
  'SOMIS',
  'SONOMA',
  'SOQUEL',
  'SORRENTO',
  'SOUDERTON',
  'SOUR LAKE',
  'SOUTH AMHERST',
  'SOUTH BEND',
  'SOUTH BERWICK',
  'SOUTH BOSTON',
  'SOUTH DENNIS',
  'SOUTH ELGIN',
  'SOUTH GATE',
  'SOUTH HILL',
  'SOUTH HOLLAND',
  'SOUTH HOUSTON',
  'SOUTH JACKSONVILLE',
  'SOUTH JORDAN',
  'SOUTH LAKE TAHOE',
  'SOUTH LYON',
  'SOUTH MILWAUKEE',
  'SOUTH ORANGE',
  'SOUTH OZONE PARK',
  'SOUTH PADRE ISLAND',
  'SOUTH PASADENA',
  'SOUTH PRINCE GEORGE',
  'SOUTH RIVER',
  'SOUTH SAINT PAUL',
  'SOUTH SAN FRANCISCO',
  'SOUTH WAYNE',
  'SOUTH WINDSOR',
  'SOUTHAMPTON',
  'SOUTHAVEN',
  'SOUTHERN PINES',
  'SOUTHFIELD',
  'SOUTHINGTON',
  'SOUTHLAKE',
  'SOUTHPORT',
  'SOUTHSIDE',
  'SOUTHWEST RANCHES',
  'SPANISH FORK',
  'SPANISH FORT',
  'SPARKS',
  'SPARTA',
  'SPARTANBURG',
  'SPENCER',
  'SPENCERPORT',
  'SPERRY',
  'SPICEWOOD',
  'SPIRIT LAKE',
  'SPOKANE',
  'SPOKANE VALLEY',
  'SPRING',
  'SPRING BRANCH',
  'SPRING CITY',
  'SPRING CREEK',
  'SPRING GROVE',
  'SPRING HILL',
  'SPRING LAKE',
  'SPRING VALLEY',
  'SPRINGBORO',
  'SPRINGDALE',
  'SPRINGFIELD',
  'SPRINGTOWN',
  'SPRINGVILLE',
  'ST CLAIRSVLE',
  'ST CLR SHORES',
  'ST GEORGE',
  'ST JOSEPH',
  'ST PETERSBURG',
  'STAFFORD',
  'STAMFORD',
  'STANLEY',
  'STANTON',
  'STAPLETON',
  'STARBUCK',
  'STARKE',
  'STARKVILLE',
  'STATE COLLEGE',
  'STATEN ISLAND',
  'STATESBORO',
  'STATESVILLE',
  'STATHAM',
  'STAUNTON',
  'STEELEVILLE',
  'STEPHENVILLE',
  'STERLING',
  'STERLING HEIGHTS',
  'STERLING HTS',
  'STEWARTSTOWN',
  'STILLWATER',
  'STOCKBRIDGE',
  'STOCKTON',
  'STONE MOUNTAIN',
  'STONE MTN',
  'STONE PARK',
  'STONEHAM',
  'STONEY BEACH',
  'STORRS MANSFIELD',
  'STOUGHTON',
  'STOW',
  'STRATFORD',
  'STRATHAM',
  'STREAMWOOD',
  'STREETSBORO',
  'STRONGSVILLE',
  'STRUTHERS',
  'STUART',
  'STUDIO CITY',
  'STURGEON BAY',
  'SUDBURY',
  'SUFFERN',
  'SUFFIELD',
  'SUFFOLK',
  'SUGAR GROVE',
  'SUGAR LAND',
  'SUITLAND',
  'SULLY',
  'SUMMERFIELD',
  'SUMMERVILLE',
  'SUMNER',
  'SUMRALL',
  'SUMTER',
  'SUMTERVILLE',
  'SUN CITY',
  'SUN CITY WEST',
  'SUN PRAIRIE',
  'SUN VALLEY',
  'SUNAPEE',
  'SUNLAND',
  'SUNLAND PARK',
  'SUNNY ISLES BEACH',
  'SUNNYSIDE',
  'SUNNYVALE',
  'SUNOL',
  'SUNRISE',
  'SUNSET',
  'SUNSET BEACH',
  'SUPERIOR',
  'SUPPLY',
  'SURF CITY',
  'SURFSIDE BEACH',
  'SURPRISE',
  'SUSANVILLE',
  'SUSSEX',
  'SUWANEE',
  'SWAINSBORO',
  'SWAMPSCOTT',
  'SWANNANOA',
  'SWANSEA',
  'SWANVILLE',
  'SWARTHMORE',
  'SWEDESBORO',
  'SWEET HOME',
  'SWISHER',
  'SYLACAUGA',
  'SYLMAR',
  'SYLVA',
  'SYLVANIA',
  'SYOSSET',
  'SYRACUSE',
  'TABERNACLE',
  'TACOMA',
  'TAFT',
  'TAHOKA',
  'TAKOMA PARK',
  'TALBOTT',
  'TALL TIMBERS',
  'TALLADEGA',
  'TALLAHASSEE',
  'TALLASSEE',
  'TAMAQUA',
  'TAMARAC',
  'TAMPA',
  'TANEYTOWN',
  'TAPPAN',
  'TARAWA TERRACE',
  'TARPON SPGS',
  'TARPON SPRINGS',
  'TARZANA',
  'TAUNTON',
  'TAVARES',
  'TAWAS CITY',
  'TAYLOR',
  'TAYLORS',
  'TAYLORSVILLE',
  'TEAGUE',
  'TEANECK',
  'TEHACHAPI',
  'TELFORD',
  'TELLICO PLAINS',
  'TELLURIDE',
  'TEMECULA',
  'TEMPE',
  'TEMPERANCE',
  'TEMPLE',
  'TEMPLE HILLS',
  'TERRE HAUTE',
  'TERRYVILLE',
  'TEWKSBURY',
  'TEXARKANA',
  'TEXAS CITY',
  'THE COLONY',
  'THE DALLES',
  'THE VILLAGES',
  'THE WOODLANDS',
  'THEODORE',
  'THOMASTON',
  'THOMASVILLE',
  'THOMPSONS STATION',
  'THONOTOSASSA',
  'THORNDALE',
  'THORNTON',
  'THOUSAND OAKS',
  'THOUSAND PALMS',
  'THREE FORKS',
  'TIFTON',
  'TIGARD',
  'TILLAMOOK',
  'TINLEY PARK',
  'TIOGA',
  'TIPTON',
  'TITUSVILLE',
  'TOANO',
  'TOBYHANNA',
  'TOLEDO',
  'TOLLAND',
  'TOLLESON',
  'TOMBALL',
  'TOMS RIVER',
  'TONEY',
  'TONGANOXIE',
  'TONOPAH',
  'TOOELE',
  'TOPANGA',
  'TOPEKA',
  'TOPOCK',
  'TORNILLO',
  'TORRANCE',
  'TOWANDA',
  'TRABUCO CANYON',
  'TRABUCO CYN',
  'TRACY',
  'TRAVELERS REST',
  'TRAVERSE CITY',
  'TREMONTON',
  'TRENT WOODS',
  'TRENTON',
  'TREVOR',
  'TRINIDAD',
  'TRIPP',
  'TROTWOOD',
  'TROUTDALE',
  'TROY',
  'TRUCKEE',
  'TRUMANSBURG',
  'TRUMBULL',
  'TRUSSVILLE',
  'TUALATIN',
  'TUCKER',
  'TUCKERTON',
  'TUCSON',
  'TUCUMCARI',
  'TUKWILA',
  'TULALIP',
  'TULARE',
  'TULAROSA',
  'TULIA',
  'TULSA',
  'TURLOCK',
  'TURNER',
  'TURNERSVILLE',
  'TURTLE CREEK',
  'TUSCALOOSA',
  'TUSCOLA',
  'TUSTIN',
  'TUTTLE',
  'TWENTYNINE PALMS',
  'TWIN FALLS',
  'TWIN LAKE',
  'TWIN LAKES',
  'TWIN PEAKS',
  'TWINSBURG',
  'TWO HARBORS',
  'TWO RIVERS',
  'TYBEE ISLAND',
  'TYLER',
  'TYRONE',
  'UNCASVILLE',
  'UNDERWOOD',
  'UNION CITY',
  'UNIONDALE',
  'UNIONTOWN',
  'UNIONVILLE',
  'UNIVERSAL CITY',
  'UNIVERSITY PARK',
  'UNIVERSITY PK',
  'UPLAND',
  'UPPER DARBY',
  'UPPER MARLBORO',
  'UPPER SADDLE RIVER',
  'UPPR MARLBORO',
  'URBANA',
  'URBANDALE',
  'URBANNA',
  'UTICA',
  'UVALDE',
  'VACAVILLE',
  'VAIL',
  'VALDOSTA',
  'VALENCIA',
  'VALLEJO',
  'VALLEY STREAM',
  'VALLEY VILLAGE',
  'VALPARAISO',
  'VALPO',
  'VALRICO',
  'VAN HORN',
  'VAN NUYS',
  'VAN WERT',
  'VANCOUVER',
  'VANDALIA',
  'VAUXHALL',
  'VEGA',
  'VENICE',
  'VENTNOR CITY',
  'VENTURA',
  'VENUS',
  'VERMILION',
  'VERNAL',
  'VERNON HILLS',
  'VERO BEACH',
  'VERONA',
  'VERSAILLES',
  'VESPER',
  'VESTAL',
  'VESTAVIA HILLS',
  'VHK2RI7YRMAZX5KN25K76ZQ4FJC4TVXFLEW6YNT74ZAOCCAVDR5QB22GJ25QCSVNJRQOCKYCLN4JA===',
  'VICKSBURG',
  'VICTORIA',
  'VICTORVILLE',
  'VIDOR',
  'VIENNA',
  'VILLA GROVE',
  'VILLA PARK',
  'VILLA RICA',
  'VILLAS',
  'VINCENNES',
  'VINE GROVE',
  'VINELAND',
  'VINEYARD',
  'VINITA',
  'VIRGINIA BCH',
  'VIRGINIA BEACH',
  'VISALIA',
  'VISTA',
  'VON ORMY',
  'VONORE',
  'VOORHEES',
  'VULCAN',
  'W LAFAYETTE',
  'W SACRAMENTO',
  'WACO',
  'WACONIA',
  'WADESVILLE',
  'WAELDER',
  'WAHIAWA',
  'WAIANAE',
  'WAILUKU',
  'WAIPAHU',
  'WAITE PARK',
  'WAKE FOREST',
  'WAKE VILLAGE',
  'WALDORF',
  'WALDWICK',
  'WALES',
  'WALESKA',
  'WALKER',
  'WALLA WALLA',
  'WALLINGFORD',
  'WALNUT',
  'WALNUT COVE',
  'WALNUT CREEK',
  'WALTHAM',
  'WALTON',
  'WAPAKONETA',
  'WARE',
  'WARMINSTER',
  'WARNER ROBINS',
  'WARR ACRES',
  'WARREN',
  'WARRENSVILLE HEIGHTS',
  'WARRENTON',
  'WARRENVILLE',
  'WARSAW',
  'WASHINGTON',
  'WASHINGTON COURT HOUSE',
  'WASHINGTON TWP',
  'WASHINGTONVILLE',
  'WASHOUGAL',
  'WASILLA',
  'WATAUGA',
  'WATERBURY',
  'WATERFORD',
  'WATERLOO',
  'WATERTOWN',
  'WATERVILLE',
  'WATERVLIET',
  'WATFORD CITY',
  'WATKINSVILLE',
  'WATSONVILLE',
  'WAUKEE',
  'WAUKEGAN',
  'WAUKESHA',
  'WAUPUN',
  'WAURIKA',
  'WAXAHACHIE',
  'WAXHAW',
  'WAYNE',
  'WAYNESBORO',
  'WAYNESVILLE',
  'WEARE',
  'WEATHERFORD',
  'WEAVERVILLE',
  'WEBSTER',
  'WEDOWEE',
  'WEEDSPORT',
  'WEEHAWKEN',
  'WEEMS',
  'WEIRTON',
  'WELDON',
  'WELDON SPRING',
  'WELLS',
  'WELLSBURG',
  'WELLSTON',
  'WENATCHEE',
  'WENONAH',
  'WENTZVILLE',
  'WESLACO',
  'WESLEY CHAPEL',
  'WEST ALLIS',
  'WEST BEND',
  'WEST BLOOMFIELD',
  'WEST BRANCH',
  'WEST CALDWELL',
  'WEST CHESTER',
  'WEST CHICAGO',
  'WEST COLUMBIA',
  'WEST COVINA',
  'WEST CREEK',
  'WEST DES MOINES',
  'WEST FARGO',
  'WEST GROVE',
  'WEST HARRISON',
  'WEST HARTFORD',
  'WEST HAVEN',
  'WEST HELENA',
  'WEST HENRIETTA',
  'WEST HILLS',
  'WEST HOLLYWOOD',
  'WEST JORDAN',
  'WEST LAFAYETTE',
  'WEST MEMPHIS',
  'WEST MILTON',
  'WEST MONROE',
  'WEST NEW YORK',
  'WEST NEWTON',
  'WEST NORRITON',
  'WEST ORANGE',
  'WEST PALM BCH',
  'WEST PALM BEACH',
  'WEST POINT',
  'WEST SALEM',
  'WEST SIMSBURY',
  'WEST SPRINGFIELD',
  'WEST UNION',
  'WEST VALLEY CITY',
  'WEST WARWICK',
  'WESTAMPTON',
  'WESTBROOK',
  'WESTBURY',
  'WESTCHESTER',
  'WESTERLY',
  'WESTERVILLE',
  'WESTFIELD',
  'WESTLAKE',
  'WESTLAKE VILLAGE',
  'WESTLAND',
  'WESTMINSTER',
  'WESTMONT',
  'WESTON',
  'WESTPOINT',
  'WESTPORT',
  'WESTVILLE',
  'WESTWEGO',
  'WETUMPKA',
  'WEYAUWEGA',
  'WHEAT RIDGE',
  'WHEATON',
  'WHEELING',
  'WHIPPANY',
  'WHISPERING PINES',
  'WHITE LAKE',
  'WHITE OAK',
  'WHITE PLAINS',
  'WHITE SWAN',
  'WHITEFISH',
  'WHITEHALL',
  'WHITEHOUSE',
  'WHITEWATER',
  'WHITNEY',
  'WHITTIER',
  'WICHITA',
  'WICHITA FALLS',
  'WICKENBURG',
  'WILBER',
  'WILDWOOD',
  'WILLARD',
  'WILLIAMSBURG',
  'WILLIAMSON',
  'WILLIAMSPORT',
  'WILLIAMSTON',
  'WILLIAMSTOWN',
  'WILLIAMSVILLE',
  'WILLIMANTIC',
  'WILLINGBORO',
  'WILLIS',
  'WILLISTON',
  'WILLISTON PARK',
  'WILLITS',
  'WILLMAR',
  'WILLOUGHBY',
  'WILLOUGHBY HILLS',
  'WILLOW GROVE',
  'WILLOW SPRINGS',
  'WILLS POINT',
  'WILMETTE',
  'WILMINGTON',
  'WILSON',
  'WILTON',
  'WINAMAC',
  'WINCHENDON',
  'WINCHESTER',
  'WINDBER',
  'WINDCREST',
  'WINDER',
  'WINDERMERE',
  'WINDHAM',
  'WINDSOR',
  'WINFIELD',
  'WINLOCK',
  'WINNEMUCCA',
  'WINNETKA',
  'WINNIE',
  'WINONA',
  'WINSLOW',
  'WINSTON',
  'WINSTON SALEM',
  'WINTER GARDEN',
  'WINTER HAVEN',
  'WINTER PARK',
  'WINTER SPRINGS',
  'WINTERPORT',
  'WINTHROP',
  'WISCONSIN RAPIDS',
  'WISE',
  'WISTER',
  'WIXOM',
  'WOBURN',
  'WOLCOTT',
  'WOMELSDORF',
  'WOODBRIDGE',
  'WOODBURN',
  'WOODBURY',
  'WOODINVILLE',
  'WOODLAKE',
  'WOODLAND',
  'WOODLAND HILLS',
  'WOODLAND HLS',
  'WOODMERE',
  'WOODRIDGE',
  'WOODRUFF',
  'WOODSIDE',
  'WOODSTOCK',
  'WOODWAY',
  'WOONSOCKET',
  'WORCESTER',
  'WORDEN',
  'WORLAND',
  'WORTH',
  'WRENTHAM',
  'WSHNGTN CT HS',
  'WYANDOTTE',
  'WYCKOFF',
  'WYLIE',
  'WYOMING',
  'XENIA',
  'YACOLT',
  'YAKIMA',
  'YONKERS',
  'YORBA LINDA',
  'YORK',
  'YORKTOWN',
  'YORKTOWN HEIGHTS',
  'YORKVILLE',
  'YOUNGSTOWN',
  'YOUNGSVILLE',
  'YPSILANTI',
  'YUBA CITY',
  'YUCAIPA',
  'YUKON',
  'YULEE',
  'YUMA',
  'ZACHARY',
  'ZANESVILLE',
  'ZAVALLA',
  'ZEBULON',
  'ZEELAND',
  'ZELLWOOD',
  'ZENDA',
  'ZEPHYRHILLS',
  'ZIMMERMAN',
  'ZION',
  'ZIONSVILLE',
  'ZUNI',
];
