import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';

const { useCampaignPerformanceQuery } = growthApi;

type CampaignSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  withAll?: boolean;
  useOldReporting?: boolean;
};

export const ReportingCampaignSelect = <T,>(props: CampaignSelectProps<T>) => {
  const { agencyId, advertiserId, withAll, useOldReporting, ...rest } = props;

  const { data, isFetching } = useCampaignPerformanceQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      time_range: 'all_time',
      breakout: 'campaign',
      without_markup: true,
      old_reporting: useOldReporting,
    },
    { skip: !agencyId || !advertiserId },
  );

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data.map((item: any) => ({
        label: `${item.campaign_name}`,
        value: JSON.stringify(item.campaign_ids),
      })) || [];
    return withAll ? [{ label: 'All', value: null }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select campaign" {...rest} />;
};
