import styled, { css } from 'styled-components';
import { Flex } from '../Layout';

export type Tab = {
  label: string;
  value: string;
};

type TabsProps = {
  value: string;
  onChange: (value: any) => void;
  tabs: Tab[];
};

export const Tabs = (props: TabsProps) => {
  const { value, onChange, tabs } = props;

  return (
    <Flex gap="lg" align="baseline">
      {tabs.map((tab) => (
        <StyledTab key={tab.value} onClick={() => onChange(tab.value)} $active={tab.value === value}>
          {tab.label}
        </StyledTab>
      ))}
    </Flex>
  );
};

const StyledTab = styled.div<{ $active: boolean }>`
  padding: 0.4rem;
  margin: 0.4rem 0 0.8rem 0;
  font-size: ${(props) => props.theme.fontSize.sm};
  cursor: pointer;

  ${(props) =>
    props.$active
      ? css`
          color: ${(props) => props.theme.color.primary};
          font-weight: 600;
          border-bottom: solid 0.2rem;
        `
      : css`
          color: ${(props) => props.theme.color.gray};
          font-weight: 500;
        `};
`;
