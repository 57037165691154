import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';

const { useCustomAudienceInsightsQuery } = growthApi;

type InsightSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
};

export const InsightSelect = <T,>(props: InsightSelectProps<T>) => {
  const { agencyId, advertiserId, ...rest } = props;

  const { data, isFetching } = useCustomAudienceInsightsQuery({
    agency_id: agencyId,
    advertiser_id: advertiserId,
  });

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return (
      data?.data?.map((insight: any) => ({
        label: `${insight.name} - ${insight.id}`,
        value: insight.id,
      })) || []
    );
  }, [isFetching, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select insight" {...rest} />;
};
