import styled from 'styled-components';
import { toastsSlice, useAppSelector, useAppDispatch } from 'src/store';
import { Toast } from './Toast';

export const Toasts = () => {
  const toasts = useAppSelector((state) => state.toasts);
  const dispatch = useAppDispatch();

  return (
    <ToastsContainer>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} onDismiss={() => dispatch(toastsSlice.actions.removeToast(toast.id))} />
      ))}
    </ToastsContainer>
  );
};

const ToastsContainer = styled.div`
  position: fixed;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 900;
`;
