import { CampaignOngoingInterval } from 'src/types';
import { CampaignEditFormValues } from './CampaignEdit';
import { differenceInDays, maxTime, minTime } from 'date-fns';

const ONGOING_INTERVAL_DAYS = {
  [CampaignOngoingInterval.Daily]: 1,
  [CampaignOngoingInterval.Weekly]: 7,
  [CampaignOngoingInterval.Monthly]: 30,
};

export const calcBudgetAndDate = (values: CampaignEditFormValues) => {
  let budget = 0;
  let dailyBudget = 0;
  let startDate: Date = null;
  let endDate: Date = null;

  if (values.ongoing_enabled === true) {
    if (values.ongoing_interval === CampaignOngoingInterval.Flights) {
      budget = values.ongoing_flights.reduce((acc, flight) => acc + Number(flight.budget), 0);
      startDate = values.ongoing_flights.reduce(
        (acc, flight) => new Date(Math.min(acc.valueOf(), flight.start_date.valueOf())),
        new Date(maxTime),
      );
      endDate = values.ongoing_flights.reduce(
        (acc, flight) => new Date(Math.max(acc.valueOf(), flight.end_date.valueOf())),
        new Date(minTime),
      );
      dailyBudget = budget / (differenceInDays(endDate, startDate) + 1);
    } else {
      budget = values.ongoing_interval_budget;
      dailyBudget = values.ongoing_interval_budget / ONGOING_INTERVAL_DAYS[values.ongoing_interval];
      startDate = values.schedule_start_date || new Date();
    }
  } else {
    budget = values.budget_amount;
    startDate = values.schedule_start_date;
    endDate = values.schedule_end_date;
    dailyBudget = values.budget_amount / (differenceInDays(values.schedule_end_date, values.schedule_start_date) + 1);
  }

  return { budget, dailyBudget, startDate, endDate };
};
