import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useCreateOrUpdatePlatformMutation } = growthApi;

type EditPlatformFormValues = {
  id?: number;
  platform_name?: string;
  platform_key?: string;
};

type EditPlatformModalProps = {
  platform?: any;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditPlatformModal = (props: EditPlatformModalProps) => {
  const { platform, isOpen, onClose, onSuccess } = props;

  const isNew = !platform;
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EditPlatformFormValues>();
  const [createOrUpdatePlatform] = useCreateOrUpdatePlatformMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      setValue('id', platform?.id);
      setValue('platform_name', platform?.platform_name || '');
      setValue('platform_key', platform?.platform_key || '');
    }
  }, [isOpen, platform, setValue]);

  const validate = (values: EditPlatformFormValues) => {
    const errors: string[] = [];
    if (!values.platform_name) {
      errors.push('Name is required');
    }
    if (!values.platform_key) {
      errors.push('Key is required');
    }
    return errors;
  };

  const onSubmit = async (values: EditPlatformFormValues) => {
    const errors = validate(values);
    if (!isEmpty(errors)) {
      showErrorToast(errors);
      return;
    }
    try {
      await createOrUpdatePlatform(values).unwrap();
      showSuccessToast(isNew ? 'Create DSP successfully' : 'Update DSP successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title={isNew ? 'New DSP' : 'Edit DSP'}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
    >
      <Form>
        <FormItem label="Name" required>
          <ControlledInput name="platform_name" control={control} placeholder="Enter platform name" />
        </FormItem>
        <FormItem label="Key" required>
          <ControlledInput name="platform_key" control={control} placeholder="Enter platform key" />
        </FormItem>
      </Form>
    </Modal>
  );
};
