import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { PlatformSelect } from 'src/components/PlatformSelect';

const { useUpdateAdvertiserExtMutation } = growthApi;

type EditPlatformFormValues = {
  advertiser_id: number;
  platforms: {
    id: string;
    platform?: string;
    platform_id?: string;
  }[];
};

const DEFAULT_FORM_VALUES: EditPlatformFormValues = {
  advertiser_id: null,
  platforms: [],
};

type EditPlatformModalProps = {
  isOpen: boolean;
  advertiser: any;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditPlatformModal = (props: EditPlatformModalProps) => {
  const { isOpen, advertiser, onClose, onSuccess } = props;

  const { watch, control, setValue, handleSubmit } = useForm<EditPlatformFormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const values = watch();

  const [updateAdvertiserExt, { isLoading }] = useUpdateAdvertiserExtMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      setValue('advertiser_id', advertiser?.id);
      setValue('platforms', advertiser?.platforms || []);
    }
  }, [isOpen, advertiser, setValue]);

  const onAddPlatform = () => {
    setValue('platforms', [...values.platforms, { id: nanoid() }]);
  };

  const onRemovePlatform = (id: string) => {
    setValue(
      'platforms',
      values.platforms.filter((platform) => platform.id !== id),
    );
  };

  const onSubmit = async (values: EditPlatformFormValues) => {
    try {
      await updateAdvertiserExt(values).unwrap();
      showSuccessToast('Update advertiser platform successfully');
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Advertiser DSPs"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem
          label="DSPs"
          addition={
            <Text color="primary" size="sm" weight={600} onClick={onAddPlatform} clickable>
              Add DSP
            </Text>
          }
        >
          <Spacing />
          <Flex direction="column" gap="md">
            {values.platforms.map((platform, index) => (
              <Flex key={platform.id} gap="md" align="center">
                <PlatformSelect name={`platforms.${index}.platform`} control={control} />
                <ControlledInput
                  name={`platforms.${index}.platform_id`}
                  control={control}
                  type="number"
                  placeholder="Enter DSP id"
                />
                <Icon type="trash" color="error" onClick={() => onRemovePlatform(platform.id)} clickable />
              </Flex>
            ))}
          </Flex>
        </FormItem>
      </Form>
    </Modal>
  );
};
