import { Button, ControlledInput } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { PasswordFormItems, passwordIsSafe } from 'src/components/PasswordFormItems';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useSimpleChangePasswordMutation } = growthApi;

type SimpleCahangePasswordFormValues = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export const SimpleChangePass = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const { handleSubmit, watch, control } = useForm<SimpleCahangePasswordFormValues>();
  const [simpleChangePass, { isLoading }] = useSimpleChangePasswordMutation();

  const values = watch();
  const isDisabled = (
    !values.oldPassword ||
    values.password !== values.confirmPassword ||
    !passwordIsSafe(values.password)
  );

  const onSubmit = async (values: SimpleCahangePasswordFormValues) => {
    try {
      const apiResult = await simpleChangePass({
        old_password: values.oldPassword,
        new_password: values.password,
      }).unwrap();
      showSuccessToast(apiResult.message);
      navigate('/settings/profile');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Reset password">
      <Spacing size="1.2rem" />
      <ControlledInput name="oldPassword" placeholder="Enter old password" control={control} type="password" icon="password" />
      <Spacing size="1.2rem" />
      <PasswordFormItems
        control={control}
        passwordPlaceHolder='Enter new password'
        confirmPlaceHolder='Confirm new password'
      />
      <Spacing size="1.2rem" />
      <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isDisabled}>
        Change Password
      </Button>
    </AuthTemplate>
  );
};
