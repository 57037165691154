import { HTMLProps, useCallback } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Input, InputProps } from './Input';

type ControlledInputProps<T> = UseControllerProps<T> &
  Pick<InputProps, 'placeholder' | 'various' | 'width' | 'type' | 'prefix' | 'suffix' | 'icon' | 'disabled'> & {
    onValueChange?: (value: any) => void;
  };

export const ControlledInput = <T,>(props: ControlledInputProps<T>) => {
  const { placeholder, various, width, type, prefix, suffix, icon, disabled, onValueChange, ...useControllerProps } =
    props;

  const {
    field: { onChange, value },
  } = useController(useControllerProps);

  const onChangeWrapper = useCallback(
    (event: any) => {
      onChange(event.target.value);
      onValueChange?.(event.target.value);
    },
    [onChange, onValueChange],
  );

  return (
    <Input
      value={(value as HTMLProps<HTMLInputElement>['value']) ?? ''}
      onChange={onChangeWrapper}
      various={various}
      placeholder={placeholder}
      width={width}
      type={type}
      prefix={prefix}
      suffix={suffix}
      icon={icon}
      disabled={disabled}
      // disable scroll
      onWheel={(event) => event.currentTarget.blur()}
    />
  );
};
