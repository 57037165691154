import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

export const useDeepEffect = (effect: React.EffectCallback, dependencies: any[]) => {
  const dependenciesRef = useRef<any[]>([]);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, dependenciesRef.current);
};
