import { Control } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { FormItem } from 'src/components/Form';
import { useRole } from 'src/hooks';

type AccessFormItemProps = {
  control: Control<any>;
  values: any;
  setValue: (path: string, value: any) => void;
};

export type AccessFormValues = {
  agency_id: number;
  advertiser_id: number;
};

export const ACCESS_DEFAULT_FORM_VALUES: AccessFormValues = {
  agency_id: null,
  advertiser_id: null,
};

export const AccessFormItem = (props: AccessFormItemProps) => {
  const { canAccessAgency } = useRole();
  const { control, values, setValue } = props;

  if (!canAccessAgency) {
    return null;
  }

  return (
    <>
      <FormItem label="Agency" required>
        <AgencySelect
          name="agency_id"
          control={control}
          onValueChange={() => {
            setValue('advertiser_id', null);
          }}
        />
      </FormItem>
      <FormItem label="Advertiser">
        <AdvertiserSelect
          agencyId={values.agency_id}
          name="advertiser_id"
          control={control}
          withAll
          disableAutoSelect
        />
      </FormItem>
    </>
  );
};
