import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { formatAmount, imageUrl } from 'src/utils';

type PersonaPdfProps = {
  persona?: any;
};

export const PersonaPdf = (props: PersonaPdfProps) => {
  const { persona } = props;

  return (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={{ padding: '10', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            alignItems: 'center',
            width: '18%',
          }}
        >
          <View style={{ height: 20 }} />
          <Text style={{ fontSize: 16, fontWeight: 600 }}>{persona?.['Name']}</Text>
          <Text style={{ fontSize: 11, color: '#9CA3AF' }}>{persona?.['Job title']}</Text>
          <Image style={{ width: 80, height: 80, borderRadius: '50%' }} source={persona?.['Avatar']} />
          <Text style={{ fontSize: 10 }}>Annual Salary: {formatAmount(persona?.['Annual salary in USD'])}</Text>
          <Text style={{ fontSize: 10 }}>{persona?.['Location']}</Text>
          <View style={{ height: 20 }} />
          <Text style={{ fontSize: 16, fontWeight: 600 }}>"{persona?.['Quote']}"</Text>
        </View>
        <View style={{ width: '80%' }}>
          <View style={{ height: 15 }} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              color: '#00A57C',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            <Text style={{ width: '48%' }}>{persona?.['Top 2 category identifiers']?.[0]}</Text>
            <Text style={{ width: '48%' }}>{persona?.['Top 2 category identifiers']?.[1]}</Text>
          </View>
          <View style={{ height: 20 }} />
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 6, width: '48%' }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Bio</Text>
              <Text style={{ color: '#363636' }}>{persona?.['Bio']}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 6, width: '48%' }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Tags</Text>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 6, flexWrap: 'wrap' }}>
                {persona?.['Top 5 tags']?.map((tag: string) => (
                  <Text
                    style={{ color: '#fff', backgroundColor: '#F1B532', padding: '2 5', borderRadius: 2 }}
                    key={tag}
                  >
                    {tag}
                  </Text>
                ))}
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ display: 'flex', flexDirection: 'column', gap: 6, width: '48%' }}>
                  <Text style={{ fontSize: 12, fontWeight: 600 }}>Technology</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', gap: 6, flexWrap: 'wrap' }}>
                    {persona?.['Device types']?.map((deviceType: string) => (
                      <Image
                        style={{ color: '#fff', width: 16, height: 16 }}
                        key={deviceType}
                        source={imageUrl(`persona/${deviceType}.png`)}
                      />
                    ))}
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', gap: 6, width: '48%' }}>
                  <Text style={{ fontSize: 12, fontWeight: 600 }}>Social Networks</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', gap: 6, flexWrap: 'wrap' }}>
                    {persona?.['Social media channels']?.map((socialMediaChannel: string) => (
                      <Image
                        style={{ color: '#fff', width: 16, height: 16 }}
                        key={socialMediaChannel}
                        source={imageUrl(`persona/${socialMediaChannel}.png`)}
                      />
                    ))}
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ height: 20 }} />
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10 }}>
            <View style={{ width: '31%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Goals</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 goals']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
            <View style={{ width: '31%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Pain points</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 pain points']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
            <View style={{ width: '31%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Influence</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 decision criteria']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
          </View>
          <View style={{ height: 20 }} />
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10 }}>
            <View style={{ width: '23%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Decision criteria</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 decision criteria']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
            <View style={{ width: '23%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>KPIs</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 KPIs']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
            <View style={{ width: '23%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Likes</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 likes']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
            <View style={{ width: '23%', display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Text style={{ fontSize: 12, fontWeight: 600 }}>Dislikes</Text>
              <View style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {persona?.['Top 5 dislikes']?.map((goal: string) => (
                  <Text key={goal} style={{ color: '#363636' }}>
                    {goal}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
