import { useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Icon } from '../Icon';
import { Flex, Text } from '../Layout';
import { Input } from './Input';
import styled from 'styled-components';
import { TOKENS } from 'src/design';

type ControlledEditableTagProps<T> = UseControllerProps<T> & {
  title: string;
};

export const ControlledEditableTag = <T,>(props: ControlledEditableTagProps<T>) => {
  const { title, ...useControllerProps } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>('');

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  const typedValue = value as string[];

  return (
    <Flex direction="column" gap="md">
      <Flex gap="xs" justify="space-between" align="center">
        <Text weight={600}>{title}</Text>
        {isEdit ? (
          <Flex>
            <Icon
              size="md"
              color="primary"
              type="check"
              clickable
              onClick={() => {
                onChange(editValue.split(','));
                setIsEdit(false);
              }}
            />
            <Icon size="md" color="error" type="close" clickable onClick={() => setIsEdit(false)} />
          </Flex>
        ) : (
          <Icon
            size="sm"
            color="gray"
            type="edit"
            clickable
            onClick={() => {
              setEditValue(typedValue?.join(','));
              setIsEdit(true);
            }}
          />
        )}
      </Flex>
      {isEdit ? (
        <Input rows={5} value={editValue} onChange={(event) => setEditValue(event.currentTarget.value)} />
      ) : (
        <Flex wrap="wrap">
          {typedValue?.map((value) => (
            <Tag key={value}>{value}</Tag>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const Tag = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  display: inline-block;
  padding: 0.8rem 1.2rem;
  color: white;
  background: ${TOKENS.color.highlight};
  border-radius: 0.4rem;
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
`;
