import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { DataTable, MAX_PAGE_SIZE } from 'src/components/DataTable';
import { Checkbox, ControlledInput } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { growthApi } from 'src/services';
import { formatAmount, formatArray, formatSize } from 'src/utils';
import styled from 'styled-components';
import { difference, union, xor } from 'lodash';
import { Icon } from '../Icon';
import { useDataTable, useDebouncedValue } from 'src/hooks';

const { useAudiencesDatasetQuery, useAudiencesDatasetQueryMutation } = growthApi;

const CAN_SELECT_PARENT_DATASET = ['city-state-region', 'dma'];

type DataProviderModalProps = {
  dataset: string;
  parent?: number;
  isOpen: boolean;
  onClose: () => void;
};

type DataProviderFormValues = {
  search?: string;
};

export const DataProviderModal = (props: DataProviderModalProps) => {
  const { dataset, parent, isOpen, onClose } = props;

  const { control, watch } = useForm<DataProviderFormValues>();
  const values = watch();
  const debouncedSearch = useDebouncedValue({ value: values.search });
  const { data, isFetching, error } = useAudiencesDatasetQuery({ dataset, parent, search: debouncedSearch });
  const [audiencesDatasetQuery] = useAudiencesDatasetQueryMutation();

  const { watch: parentWatch, setValue: setParentValue } = useFormContext();
  const parentValues = parentWatch();
  const canSelectParent = CAN_SELECT_PARENT_DATASET.includes(dataset);

  const { dataTableProps, isExpend, isExpendLoading, triggerExpend, getExpendData } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    onExpend: async (id: any) => {
      const expendData = await audiencesDatasetQuery({ dataset, parent: id }).unwrap();
      return expendData?.data;
    },
    columns: [
      {
        header: () => {
          const value = allIds.length > 0 && difference(allIds, parentValues.rule_value).length === 0;
          return <Checkbox value={value} onChange={() => onSelectAll(!value)} />;
        },
        accessor: '_selector',
        render: (_, row) =>
          (canSelectParent || !row.child_count) && (
            <Checkbox
              value={formatRuleValue.includes(row.id)}
              onChange={(value) => {
                if (value) {
                  setParentValue('rule_value', [...formatRuleValue, row.id]);
                  setParentValue('rule_value_name', [...(parentValues.rule_value_name || []), row]);
                } else {
                  setParentValue(
                    'rule_value',
                    formatRuleValue.filter((item: any) => item !== row.id),
                  );
                  setParentValue(
                    'rule_value_name',
                    parentValues.rule_value_name?.filter((item: any) => item.id !== row.id),
                  );
                }
              }}
            />
          ),
      },
      { header: 'ID', accessor: 'id' },
      {
        header: 'Name',
        accessor: 'name',
        render: (value, row, level) => (
          <Flex align="center" gap="xs">
            <Spacing horizontal size={`${(level - 1) * 1.6}rem`} />
            {row.child_count > 0 && (
              <FlexItem shrink={0}>
                <Icon
                  size="sm"
                  color="primary"
                  type={isExpendLoading(row.id) ? 'loading' : isExpend(row.id) ? 'caretDown' : 'caretRight'}
                  clickable
                  onClick={() => triggerExpend(row.id)}
                />
              </FlexItem>
            )}
            <Text>{value}</Text>
          </Flex>
        ),
      },
      { header: 'Full Path', accessor: 'full_path' },
      ...(dataset === '3rd-party-audiences'
        ? [
            { header: 'Size', accessor: 'uniques', render: formatSize },
            { header: 'CPM', accessor: 'cpm', render: (value) => (value ? formatAmount(value) : '-') },
          ]
        : []),
    ],
  });

  const allIds = useMemo(() => {
    return [...(data?.data || []), ...getExpendData()]
      ?.filter((row: any) => canSelectParent || !row.child_count)
      ?.map((row: any) => row.id);
  }, [canSelectParent, data?.data, getExpendData]);

  const onSelectAll = useCallback(
    (value: boolean) => {
      const ruleValue = value ? union(parentValues.rule_value, allIds) : xor(parentValues.rule_value, allIds);
      setParentValue('rule_value', ruleValue);
      setParentValue(
        'rule_value_name',
        [...(data?.data || []), ...getExpendData()].filter((item: any) => ruleValue?.includes(item.id)),
      );
    },
    [allIds, data?.data, getExpendData, parentValues.rule_value, setParentValue],
  );

  const formatRuleValue = formatArray(parentValues.rule_value);

  return (
    <Modal title="Data Provider - Select Values" isOpen={isOpen} onClose={onClose} onConfirm={onClose} width="75vw">
      <ControlledInput
        prefix="Search:"
        name="search"
        control={control}
        width="40rem"
        placeholder="Enter search keywords"
      />
      <Spacing />
      <DataTableContainer>
        <DataTable {...dataTableProps} pageSize={MAX_PAGE_SIZE} />
      </DataTableContainer>
    </Modal>
  );
};

const DataTableContainer = styled.div`
  height: 55rem;
  overflow-y: auto;
`;
