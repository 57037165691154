import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { ControlledInput, ControlledRadioGroup, Form, FormItem } from 'src/components/Form';
import { InsightSelect } from 'src/components/Insight';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useShareCustomAudienceMutation } = growthApi;

type CustomAudienceShareFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  type?: string;
  name?: string;
  insight_id?: number;
};

type CustomAudienceShareProps = {
  customAudienceKey: string;
  filter: any;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomAudienceShare = (props: CustomAudienceShareProps) => {
  const { customAudienceKey, filter, isOpen, onClose } = props;
  const { control, watch, handleSubmit } = useForm<CustomAudienceShareFormValues>({
    defaultValues: {
      type: 'new',
    },
  });
  const values = watch();
  const [shareCustomAudience, { isLoading }] = useShareCustomAudienceMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onSubmit = async (values: CustomAudienceShareFormValues) => {
    try {
      await shareCustomAudience({ ...values, key: customAudienceKey, filter }).unwrap();
      showSuccessToast('Share custom audience successfully');
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Share Custom Audience"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Agency" required>
          <AgencySelect name="agency_id" control={control} />
        </FormItem>
        <FormItem label="Advertiser" required>
          <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
        </FormItem>
        <FormItem label="Share Type" required>
          <ControlledRadioGroup
            name="type"
            control={control}
            options={[
              { label: 'Create new insight', value: 'new' },
              { label: 'Share to exist insight', value: 'update' },
            ]}
          />
        </FormItem>
        {values.type === 'new' && (
          <FormItem label="Insight Name" required>
            <ControlledInput name="name" control={control} placeholder="Enter insight name" />
          </FormItem>
        )}
        {values.type === 'update' && (
          <FormItem label="Exist Insight" required>
            <InsightSelect
              agencyId={values.agency_id}
              advertiserId={values.advertiser_id}
              name="insight_id"
              control={control}
            />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
};
