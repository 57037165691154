import { Control } from 'react-hook-form';
import { ControlledTextArea, FormItem } from 'src/components/Form';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { useRole } from 'src/hooks';
import { PixelFormValues } from './PixelEdit';
import { growthApi } from 'src/services';

const { usePlatformsQuery } = growthApi;

type PixelCodesProps = {
  control: Control<PixelFormValues>;
};

export const PixelCodes = (props: PixelCodesProps) => {
  const { control } = props;

  const { isAdmin } = useRole();
  const { data } = usePlatformsQuery({});

  if (!isAdmin) return null;

  return (
    <FormItem label="Pixel Code" required>
      <Text size="sm" color="error">
        The pixel code format we supported: &#60;script&#62;xxx&#60;/script&#62;, &#60;script
        src="xxx"&#62;&#60;/script&#62;, &#60;img src="xxx"&#62;, others will be ignored.
      </Text>
      <Spacing />
      <Grid columns={4} gap="md">
        {data?.data?.map((platform: any) => (
          <Flex key={platform.platform_key} direction="column" gap="sm">
            <Text size="sm" weight={500}>
              {platform.platform_name} Pixel Code
            </Text>
            <ControlledTextArea
              name={`codes.${platform.platform_key}`}
              control={control}
              placeholder={`Enter ${platform.platform_name} pixel code here.`}
              rows={8}
            />
          </Flex>
        ))}
      </Grid>
    </FormItem>
  );
};
