import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { ACCESS_DEFAULT_FORM_VALUES, AccessFormItem, AccessFormValues } from './AccessFormItem';
import { isEmpty } from 'lodash';

const { useCreateUserMutation } = growthApi;

type NewUserModalProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

type NewUserFormValues = {
  email?: string;
  password?: string;
} & AccessFormValues;

export const NewUserModal = (props: NewUserModalProps) => {
  const { isOpen, onSuccess, onClose } = props;

  const { handleSubmit, control, watch, setValue } = useForm<NewUserFormValues>({
    defaultValues: {
      ...ACCESS_DEFAULT_FORM_VALUES,
    },
  });
  const values = watch();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const validate = (values: NewUserFormValues) => {
    const errors: string[] = [];
    if (!values.email) {
      errors.push('Email is required');
    }
    if (!values.password) {
      errors.push('Password is required');
    }
    if (!values.agency_id) {
      errors.push('Agency is required');
    }
    return errors;
  };

  const onSubmit = async (values: NewUserFormValues) => {
    try {
      const errors = validate(values);
      if (!isEmpty(errors)) {
        showErrorToast(errors);
        return;
      }
      await createUser(values).unwrap();
      showSuccessToast('Create user successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal isOpen={isOpen} title="New User" isLoading={isLoading} onConfirm={handleSubmit(onSubmit)} onClose={onClose}>
      <Form>
        <FormItem label="Email" required>
          <ControlledInput name="email" control={control} placeholder="Enter email" />
        </FormItem>
        <FormItem label="Password" required>
          <ControlledInput name="password" control={control} placeholder="Enter password" />
        </FormItem>
        <AccessFormItem control={control} values={values} setValue={setValue} />
      </Form>
    </Modal>
  );
};
