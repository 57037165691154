import { useState } from 'react';
import { Tab, Tabs } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { BulkUploadBeeswax } from './BulkUploadBeeswax';
import { BulkUploadDataInHouse } from './BulkUploadDataInHouse';

enum BulkUploadTab {
  DataInHouse = 'data_in_house',
  Beeswax = 'beeswax',
}

const BULK_UPLOAD_TABS: Tab[] = [
  { label: 'Data in-house', value: BulkUploadTab.DataInHouse },
  { label: 'Beeswax', value: BulkUploadTab.Beeswax },
];

export const BulkUpload = () => {
  const [tab, setTab] = useState(BulkUploadTab.DataInHouse);

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Bulk Upload
          </Text>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Tabs tabs={BULK_UPLOAD_TABS} value={tab} onChange={setTab} />
      <Spacing size="xl" />
      {tab === BulkUploadTab.DataInHouse && <BulkUploadDataInHouse />}
      {tab === BulkUploadTab.Beeswax && <BulkUploadBeeswax />}
    </PageTemplate>
  );
};
