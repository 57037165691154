import { useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Icon } from '../Icon';
import { Flex, FlexItem, Text } from '../Layout';
import { Input } from './Input';

type ControlledEditableTextProps<T> = UseControllerProps<T> & {
  format?: (content: string) => string;
};

export const ControlledEditableText = <T,>(props: ControlledEditableTextProps<T>) => {
  const { format, ...useControllerProps } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>('');

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  const typedValue = value as string;

  return (
    <Flex gap="xs" align="center">
      {isEdit ? (
        <>
          <Input value={editValue} onChange={(event) => setEditValue(event.currentTarget.value)} />
          <Flex>
            <Icon
              size="md"
              color="primary"
              type="check"
              clickable
              onClick={() => {
                onChange(editValue);
                setIsEdit(false);
              }}
            />
            <Icon size="md" color="error" type="close" clickable onClick={() => setIsEdit(false)} />
          </Flex>
        </>
      ) : (
        <>
          <Text>{format ? format(typedValue) : typedValue}</Text>
          <FlexItem shrink={0}>
            <Icon
              size="sm"
              color="gray"
              type="edit"
              clickable
              onClick={() => {
                setEditValue(typedValue);
                setIsEdit(true);
              }}
            />
          </FlexItem>
        </>
      )}
    </Flex>
  );
};
