import { ControlledSelectOption } from 'src/components/Form';
import {
  addDays,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export enum DateRange {
  Yesterday = 'yesterday',
  Today = 'today',
  Last7Days = '7',
  Last30Days = '30',
  Last90Days = '90',
  LastMonth = 'last_month',
  ThisMonth = 'this_month',
  LastWeek = 'last_week',
  ThisWeek = 'this_week',
  AllTime = 'all_time',
  Custom = 'custom',
}

export enum PreviousPeriod {
  PreviousPeriod,
  PreviousYear,
  CustomPeriodStart,
}

export const DATE_RANGE_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Yesterday',
    value: DateRange.Yesterday,
  },
  {
    label: 'Today',
    value: DateRange.Today,
  },
  {
    label: '7 Days',
    value: DateRange.Last7Days,
  },
  {
    label: '30 Days',
    value: DateRange.Last30Days,
  },
  {
    label: '90 Days',
    value: DateRange.Last90Days,
  },
  {
    label: 'Last Month',
    value: DateRange.LastMonth,
  },
  {
    label: 'This Month',
    value: DateRange.ThisMonth,
  },
  {
    label: 'Last Week',
    value: DateRange.LastWeek,
  },
  {
    label: 'This Week',
    value: DateRange.ThisWeek,
  },
  {
    label: 'All Time',
    value: DateRange.AllTime,
  },
];

export const DATE_RANGE_CUSTOM_OPTIONS: ControlledSelectOption[] = [
  ...DATE_RANGE_OPTIONS,
  {
    label: 'Custom',
    value: DateRange.Custom,
  },
];

export const REPORTING_PREVIOUS_PERIOD_OPTIONS: ControlledSelectOption[] = [
  { label: 'None', value: null },
  { label: 'Previous period', value: PreviousPeriod.PreviousPeriod },
  { label: 'Previous year', value: PreviousPeriod.PreviousYear },
  { label: 'Custom period start', value: PreviousPeriod.CustomPeriodStart },
];

export const parseDateRange = (dateRange: DateRange) => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  switch (dateRange) {
    case DateRange.Yesterday: {
      return {
        dateTo: yesterday,
        dateFrom: yesterday,
      };
    }
    case DateRange.Today: {
      return {
        dateTo: today,
        dateFrom: today,
      };
    }
    case DateRange.Last7Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 7),
      };
    }
    case DateRange.Last30Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 30),
      };
    }
    case DateRange.Last90Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 90),
      };
    }
    case DateRange.LastMonth: {
      return {
        dateFrom: startOfMonth(subMonths(today, 1)),
        dateTo: endOfMonth(subMonths(today, 1)),
      };
    }
    case DateRange.ThisMonth: {
      return {
        dateFrom: startOfMonth(today),
        dateTo: today,
      };
    }
    case DateRange.LastWeek: {
      return {
        dateFrom: startOfWeek(subWeeks(today, 1)),
        dateTo: endOfWeek(subWeeks(today, 1)),
      };
    }
    case DateRange.ThisWeek: {
      return {
        dateFrom: startOfWeek(today),
        dateTo: today,
      };
    }
    case DateRange.AllTime: {
      return {
        dateTo: undefined,
        dateFrom: undefined,
      };
    }
  }
};

export const parsePreviousDateRange = (options: {
  previousPeriod: PreviousPeriod;
  customPeriodStart?: Date;
  dateFrom?: Date;
  dateTo?: Date;
}) => {
  const result: {
    perviousDateFrom?: Date;
    perviousDateTo?: Date;
  } = {
    perviousDateFrom: null,
    perviousDateTo: null,
  };

  if (!options.dateFrom || !options.dateTo) {
    return result;
  }

  switch (options.previousPeriod) {
    case PreviousPeriod.CustomPeriodStart:
      if (!options.customPeriodStart) {
        return result;
      }
      result.perviousDateFrom = options.customPeriodStart;
      result.perviousDateTo = addDays(result.perviousDateFrom, differenceInDays(options.dateTo, options.dateFrom));
      break;
    case PreviousPeriod.PreviousPeriod:
      result.perviousDateTo = subDays(options.dateFrom, 1);
      result.perviousDateFrom = subDays(result.perviousDateTo, differenceInDays(options.dateTo, options.dateFrom));
      break;
    case PreviousPeriod.PreviousYear:
      result.perviousDateFrom = subYears(options.dateFrom, 1);
      result.perviousDateTo = subYears(options.dateTo, 1);
      break;
  }

  return result;
};

export const TIMEZONE_OPTIONS: ControlledSelectOption[] = [
  {
    label: '(GMT -11:00) Midway Island, Samoa',
    value: 2,
  },
  {
    label: '(GMT -10:00) Hawaii',
    value: 3,
  },
  {
    label: '(GMT -9:00) Alaska',
    value: 4,
  },
  {
    label: '(GMT -8:00) Pacific Time (US & Canada)',
    value: 5,
  },
  {
    label: '(GMT -7:00) Mountain Time (US & Canada)',
    value: 6,
  },
  {
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
    value: 7,
  },
  {
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    value: 8,
  },
  {
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: 9,
  },
  {
    label: '(GMT -3:30) Newfoundland',
    value: 10,
  },
  {
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    value: 11,
  },
  {
    label: '(GMT -2:00) Mid-Atlantic',
    value: 12,
  },
  {
    label: '(GMT -1:00) Azores, Cape Verde Islands',
    value: 13,
  },
  {
    label: '(GMT) Greenwich Mean Time (no daylight savings)',
    value: 14,
  },
  {
    label: '(GMT) London, Lisbon, Casablanca',
    value: 15,
  },
  {
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    value: 16,
  },
  {
    label: '(GMT +2:00) Kaliningrad, South Africa',
    value: 17,
  },
  {
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: 18,
  },
  {
    label: '(GMT +3:30) Tehran',
    value: 19,
  },
  {
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: 20,
  },
  {
    label: '(GMT +4:30) Kabul',
    value: 21,
  },
  {
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: 22,
  },
  {
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    value: 23,
  },
  {
    label: '(GMT +5:45) Kathmandu',
    value: 24,
  },
  {
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
    value: 25,
  },
  {
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    value: 26,
  },
  {
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    value: 27,
  },
  {
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: 28,
  },
  {
    label: '(GMT +9:30) Adelaide, Darwin',
    value: 29,
  },
  {
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: 30,
  },
  {
    label: '(GMT +11:00) Pohnpei, Solomon Islands, New Caledonia',
    value: 31,
  },
  {
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: 32,
  },
];
