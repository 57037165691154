import { DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { EditAdvertiserModal } from 'src/components/EditAdvertiserModal';
import { useState } from 'react';
import { growthApi } from 'src/services';
import { PageTemplate } from 'src/components/Template';
import { ArchiveNew } from 'src/components/Archive';
import { useForm } from 'react-hook-form';
import { useDataTable, useReloadUser, useRole } from 'src/hooks';
import { formatCount, formatDspIds, formatMarkups } from 'src/utils';
import { Role, User } from 'src/types';
import { EditMarkupsModal } from 'src/components/EditMarkupModal';
import { AgencySelect } from 'src/components/AgencySelect';
import { isUndefined } from 'lodash';
import { EditPlatformModal } from './EditPlatformModal';
import { EditDataCreditModal } from 'src/components/EditDataCreditModal';

const { useAdvertisersQuery, useArchiveAdvertiserMutation } = growthApi;

type AdvertisersFormValues = {
  agency_id?: string;
  search?: string;
};

export const Advertisers = () => {
  const { isWhiteLabel } = useRole();
  const { watch, control } = useForm<AdvertisersFormValues>({
    defaultValues: {
      agency_id: null,
    },
  });
  const values = watch();
  const { data, error, isFetching, refetch } = useAdvertisersQuery({
    agency_id: values.agency_id,
    with_data_credits: true,
  });
  const [archiveAdvertiser, { isLoading: isArchiving }] = useArchiveAdvertiserMutation();
  const onArchive = async (row: any) => {
    await archiveAdvertiser({
      id: row.id,
    });
    refetch();
  };
  const { dataTableProps, selectedRow, setSelectedRow } = useDataTable({
    data: data?.data,
    search: values.search,
    isLoading: isFetching,
    error,
    searchKeys: ['id', 'name', 'agency_id', 'agency_name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'markup', 'data_credits'],
    columns: [
      { header: 'ID', accessor: 'id', sortable: true },
      {
        header: 'Agency',
        accessor: '_agency',
        sortable: true,
        render: (_, row) => `${row.agency_name} - ${row.agency_id}`,
      },
      { header: 'Advertiser', accessor: 'name', sortable: true },
      {
        header: 'DSPs',
        accessor: 'platforms',
        render: (value: any, row: any) => {
          return (
            <Flex gap="sm">
              <Text>{formatDspIds(value)}</Text>
              <Action
                icon="edit"
                onClick={() => {
                  setSelectedRow(row);
                  setEditPlatformIsOpen(true);
                }}
              />
            </Flex>
          );
        },
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'Our Markup',
        accessor: 'markups',
        render: (value: any, row: any) => (
          <Flex gap="sm">
            <Text>{formatMarkups(value)}</Text>
            <Action
              icon="edit"
              onClick={() => {
                setSelectedRow(row);
                setEditMarkupsIsOpen(true);
              }}
            />
          </Flex>
        ),
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        // display Markup for WL users
        header: isWhiteLabel ? 'Markup' : 'WL Markup',
        accessor: 'markups_wl',
        render: (value: any, row: any) => (
          <Flex gap="sm">
            <Text>{formatMarkups(value)}</Text>
            {row.is_white_label && (
              <Action
                icon="edit"
                onClick={() => {
                  setSelectedRow(row);
                  setEditMarkupWLIsOpen(true);
                }}
              />
            )}
          </Flex>
        ),
        sortable: true,
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
      {
        header: 'Data Credits',
        accessor: 'data_credits',
        render: (value: any, row: any) => {
          return (
            <Flex gap="sm">
              <Text>{!isUndefined(value) ? formatCount(value) : '-'}</Text>
              <Action
                icon="edit"
                onClick={() => {
                  setSelectedRow(row);
                  setEditDataCreditsIsOpen(true);
                }}
              />
            </Flex>
          );
        },
        sortable: true,
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        render: (_, row) => (
          <Flex gap="md">
            <Action
              onClick={() => {
                setSelectedRow(row);
                setEditAdvertiserIsOpen(true);
              }}
            >
              Edit
            </Action>
            <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
          </Flex>
        ),
      },
    ],
  });
  const [editAdvertiserIsOpen, setEditAdvertiserIsOpen] = useState<boolean>(false);
  const [editMarkupsIsOpen, setEditMarkupsIsOpen] = useState<boolean>(false);
  const [editMarkupWLIsOpen, setEditMarkupWLIsOpen] = useState<boolean>(false);
  const [editPlatformIsOpen, setEditPlatformIsOpen] = useState<boolean>(false);
  const [editDataCreditsIsOpen, setEditDataCreditsIsOpen] = useState<boolean>(false);
  const reloadUser = useReloadUser();

  return (
    <PageTemplate>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Advertisers
          </Text>
          <AgencySelect name="agency_id" prefix="Agency: " control={control} withAll />
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Button
          width="auto"
          shadow
          onClick={() => {
            setSelectedRow(undefined);
            setEditAdvertiserIsOpen(true);
          }}
        >
          NEW ADVERTISER
        </Button>
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
      <EditAdvertiserModal
        isOpen={editAdvertiserIsOpen}
        advertiser={selectedRow}
        onClose={() => setEditAdvertiserIsOpen(false)}
        onSuccess={() => {
          setEditAdvertiserIsOpen(false);
          refetch();
        }}
      />
      <EditMarkupsModal
        isOpen={editMarkupsIsOpen}
        onClose={() => setEditMarkupsIsOpen(false)}
        onSuccess={() => refetch()}
        type="advertiser"
        advertiser={selectedRow}
      />
      <EditMarkupsModal
        advertiser={selectedRow}
        type="advertiser_wl"
        isOpen={editMarkupWLIsOpen}
        onClose={() => setEditMarkupWLIsOpen(false)}
        onSuccess={() => refetch()}
      />
      <EditPlatformModal
        isOpen={editPlatformIsOpen}
        advertiser={selectedRow}
        onClose={() => setEditPlatformIsOpen(false)}
        onSuccess={() => {
          setEditPlatformIsOpen(false);
          refetch();
          reloadUser();
        }}
      />
      <EditDataCreditModal
        isOpen={editDataCreditsIsOpen}
        agencyId={selectedRow?.agency_id}
        advertiserId={selectedRow?.id}
        onClose={() => setEditDataCreditsIsOpen(false)}
        onSuccess={refetch}
      />
    </PageTemplate>
  );
};
