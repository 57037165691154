import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { jsonToCSV } from 'react-papaparse';
import { Action, Button, ControlledFile, Form, FormItem } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { exportCSV, fileToBase64, getApiErrorMessage } from 'src/utils';

const { useBulkUploadCampaignsMutation, useBulkUploadPixelsMutation } = growthApi;

type BulkUploadDataInHouseFormValues = {
  campaign_file?: File;
  pixel_file?: File;
};

export const BulkUploadDataInHouse = () => {
  const { control, watch } = useForm<BulkUploadDataInHouseFormValues>();
  const values = watch();
  const [campaignIsOpen, setCampaignIsOpen] = useState(false);
  const [bulkUploadCampaigns, { isLoading: campaignIsLoading }] = useBulkUploadCampaignsMutation();
  const [pixelIsOpen, setPixelIsOpen] = useState(false);
  const [bullUploadPixels, { isLoading: pixelIsLoading }] = useBulkUploadPixelsMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const downloadCampaignTemplate = () => {
    const templateFields = [
      'Agency ID',
      'Advertiser ID',
      'Campaign Group',
      'Campaign Name',
      'Campaign Start Date',
      'Campaign End Date',
      'Custom Flight day',
      'Monthly Budget',
      'Daily Cap',
      'Frequency',
      'Max CPM',
      'Pacing',
      'Conversion Pixel',
      'Objective',
      'Audience Name',
      'Audience Targeting Pixel',
      'Channels',
      'Geo Targeting (country/state/city)',
      'Creative Start Dates',
      '',
      '',
      '',
      '',
      'Creative name',
      'Creative Sync Link',
      'Creative Click-Through URL',
      'New Creative Refresh Day',
    ];
    exportCSV(
      'bulk_upload_campaigns_template.csv',
      jsonToCSV({
        fields: templateFields,
        data: templateFields.map(() => ''),
      }),
    );
  };

  const downloadPixelTemplate = () => {
    const templateFields = ['Agency ID', 'Advertiser ID', 'Pixel Name', 'Pixel Platform', 'Pixel Code'];
    exportCSV(
      'bulk_upload_pixels_template.csv',
      jsonToCSV({
        fields: templateFields,
        data: templateFields.map(() => ''),
      }),
    );
  };

  const onCampaignSubmit = async () => {
    try {
      const result = await bulkUploadCampaigns({
        file: await fileToBase64(values.campaign_file),
      }).unwrap();
      const { data } = result;
      showSuccessToast(`${data.count} Campaigns upload successfully!`);
      setCampaignIsOpen(false);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onPixelSubmit = async () => {
    try {
      const result = await bullUploadPixels({
        file: await fileToBase64(values.pixel_file),
      }).unwrap();
      const { data } = result;
      showSuccessToast(`${data.count} Pixels upload successfully!`);
      setCampaignIsOpen(false);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Flex direction="column" gap="lg">
      {/* <Button various="primary" width="24rem" onClick={() => setCampaignIsOpen(true)}>
        BULK UPLOAD CAMPAIGNS
      </Button> */}
      <Button various="secondary" width="24rem" onClick={() => setPixelIsOpen(true)}>
        BULK UPLOAD PIXELS
      </Button>
      <Modal
        title="Bulk Upload Campaigns"
        isOpen={campaignIsOpen}
        onClose={() => setCampaignIsOpen(false)}
        onConfirm={onCampaignSubmit}
        isLoading={campaignIsLoading}
      >
        <Form>
          <FormItem label="Bulk upload file" required>
            <ControlledFile name="campaign_file" control={control} />
            <Spacing />
            <Flex justify="center" gap="lg">
              <Action onClick={() => downloadCampaignTemplate()}>
                <Text size="sm">Download Template</Text>
              </Action>
            </Flex>
          </FormItem>
        </Form>
      </Modal>
      <Modal
        title="Bulk Upload Pixels"
        isOpen={pixelIsOpen}
        onClose={() => setPixelIsOpen(false)}
        onConfirm={onPixelSubmit}
        isLoading={pixelIsLoading}
      >
        <Form>
          <FormItem label="Bulk upload file" required>
            <ControlledFile name="pixel_file" control={control} />
            <Spacing />
            <Flex justify="center" gap="lg">
              <Action onClick={() => downloadPixelTemplate()}>
                <Text size="sm">Download Template</Text>
              </Action>
            </Flex>
          </FormItem>
        </Form>
      </Modal>
    </Flex>
  );
};
