import { Flex, FlexItem, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';
import { ControlledInput } from './ControlledInput';

export const ControlledCard = <T,>(props: UseControllerProps<T>) => {
  const { name, control } = props;

  return (
    <Container>
      <Flex width="100%" align="center">
        <FlexItem grow={1}>
          <ControlledInput
            various="pure"
            name={`${name}.number` as any}
            placeholder="Enter card number"
            control={control}
          />
        </FlexItem>
        <FlexItem width="12rem">
          <Flex gap="xs" align="center">
            <ControlledInput various="pure" name={`${name}.exp_month` as any} placeholder="Month" control={control} />
            <Text size="xs" color="grayLighter">
              /
            </Text>
            <ControlledInput various="pure" name={`${name}.exp_year` as any} placeholder="Year" control={control} />
          </Flex>
        </FlexItem>
        <FlexItem width="8rem">
          <ControlledInput various="pure" name={`${name}.cvc` as any} placeholder="CVC" control={control} />
        </FlexItem>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  border: solid 0.1rem ${TOKENS.color.grayLighter};
  border-radius: 1.2rem;
  box-shadow: ${TOKENS.shadow.default};

  input {
    width: 100%;
    padding: 0 0.6rem;
    border: none;
    outline: none;

    ::placeholder {
      color: ${TOKENS.color.gray};
    }
  }
`;
