import styled from 'styled-components';

type ThProps = {
  width?: string;
  sortable?: boolean;
};

export const Th = styled.th<ThProps>`
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) => (props.sortable ? `cursor: pointer;` : '')}
`;
