import { PageTemplate } from 'src/components/Template';
import { DataTable, DataTableExport } from 'src/components/DataTable';
import { growthApi } from 'src/services';
import { formatAmount, formatDate } from 'src/utils';
import { Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { format } from 'date-fns-tz';
import styled from 'styled-components';
import { TOKENS } from 'src/design';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { Role } from 'src/types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize, get, sumBy } from 'lodash';
import { DailyBillManuallyModal } from './DailyBillManuallyModal';

const { useTransactionsQuery } = growthApi;

const getTotalAmount = (data: any[], key: string) => {
  let totalSpend = 0;
  let totalCharge = 0;
  data.forEach((row) => {
    const amount = Number(get(row, key));
    if (row.type === 'spend') {
      totalSpend += amount;
    } else {
      totalCharge += amount;
    }
  });
  return (
    <Flex direction="column">
      <Text>-{formatAmount(totalSpend)}</Text>
      <Text>+{formatAmount(totalCharge)}</Text>
    </Flex>
  );
};

type TransactionsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  date_from?: Date;
  date_to?: Date;
  search?: string;
};

export const Transactions = () => {
  const { isAdmin, isWhiteLabel, canAccessAgency } = useRole();
  const { control, watch, setValue, reset } = useForm<TransactionsFormValues>();
  const values = watch();
  useCommonPersistentFilters({
    values,
    setValues: reset,
  });

  const { data, isFetching, error, refetch } = useTransactionsQuery({
    agency_id: values.agency_id,
    advertiser_id: values.advertiser_id,
    date_from: values.date_from ? format(values.date_from, 'yyyy-MM-dd') : undefined,
    date_to: values.date_to ? format(values.date_to, 'yyyy-MM-dd') : undefined,
  });
  const [isDailyBillManuallyOpen, setIsDailyBillManuallyOpen] = useState(false);

  const transactionData = useMemo(() => {
    if (data?.data) {
      return data.data.map((item: any) => ({
        ...item,
        admin_amount: Number(item.amount),
        admin_our_markup: Number((item.amount_wl || item.amount) - item.amount),
        admin_wl_markup: Number(item.markup_wl || 0),
        wl_amount: Number(item.amount_wl || item.amount),
        wl_markup: Number(item.markup_wl || 0),
        total_amount: Number(item.amount) + Number(item.markup || 0),
      }));
    }
    return [];
  }, [data?.data]);

  const { dataTableProps, dataTableExportProps } = useDataTable({
    name: 'transaction',
    data: transactionData,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'advertiser_name', 'type', 'time', 'created_at'],
    defaultSort: {
      key: 'transaction_time',
      direction: 'desc',
    },
    sortNumberKeys: [
      'id',
      'admin_amount',
      'admin_our_markup',
      'admin_wl_markup',
      'wl_amount',
      'wl_markup',
      'total_amount',
    ],
    enableTotal: true,
    columns: [
      { header: 'ID', accessor: 'id', sortable: true, totalRender: () => 'Total' },
      {
        header: 'Advertiser',
        accessor: 'advertiser_name',
        render: (value) => value ?? '-',
        sortable: true,
      },
      { header: 'Type', accessor: 'type', sortable: true, render: capitalize },
      { header: 'Time', accessor: 'transaction_time', render: formatDate, sortable: true },
      // for admin
      {
        header: 'Amount',
        accessor: 'admin_amount',
        render: (value: any, row: any) => `${row.type === 'spend' ? '-' : '+'} ${formatAmount(value)}`,
        totalRender: (data) => {
          return getTotalAmount(data, 'admin_amount');
        },
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      {
        header: 'Our Markup',
        accessor: 'admin_our_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'admin_our_markup')),
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      {
        header: 'WL Markup',
        accessor: 'admin_wl_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'admin_wl_markup')),
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      // for wl
      {
        header: 'Amount',
        accessor: 'wl_amount',
        render: (value: any, row: any) => `${row.type === 'spend' ? '-' : '+'} ${formatAmount(value)}`,
        totalRender: (data) => {
          return getTotalAmount(data, 'wl_amount');
        },
        sortable: true,
        when: (user) => user.role === Role.WhiteLabel,
      },
      {
        header: 'Markup',
        accessor: 'wl_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'wl_markup')),
        sortable: true,
        when: (user) => user.role === Role.WhiteLabel,
      },
      // for others
      {
        header: 'Amount',
        accessor: 'total_amount',
        render: (value: any, row: any) => `${row.type === 'spend' ? '-' : '+'} ${formatAmount(value)}`,
        totalRender: (data) => {
          return getTotalAmount(data, 'total_amount');
        },
        sortable: true,
        when: (user) => ![Role.Admin, Role.WhiteLabel].includes(user.role),
      },
      {
        header: 'Balance',
        accessor: 'current_balance',
        render: (value: any) => formatAmount(value),
      },
      { header: 'Description', accessor: 'description' },
      { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
      // {
      //   header: '',
      //   accessor: '_action',
      //   render: (_, row) => {
      //     return <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />;
      //   },
      //   when: (user) => [Role.Admin].includes(user.role),
      // },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="xxl">
          <Text size="xxl" weight={700}>
            Transactions
          </Text>
        </Flex>
        <Flex gap="md" align="center">
          {isAdmin && (
            <Button width="22rem" shadow onClick={() => setIsDailyBillManuallyOpen(true)}>
              DAILY BILLING MANUALLY
            </Button>
          )}
        </Flex>
      </Flex>
      <Spacing size="lg" />
      <Flex gap="md">
        {canAccessAgency && (
          <>
            <AgencySelect
              prefix="Agency:"
              name="agency_id"
              control={control}
              withAll
              width="22rem"
              onValueChange={() => {
                setValue('advertiser_id', null);
              }}
            />
            <AdvertiserSelect
              agencyId={values.agency_id}
              prefix="Advertiser:"
              name="advertiser_id"
              control={control}
              withAll
              width="22rem"
            />
          </>
        )}
        <ControlledDatePicker
          prefix="From:"
          name="date_from"
          control={control}
          placeholder="Start Date"
          width="22rem"
        />
        <ControlledDatePicker prefix="To:" name="date_to" control={control} placeholder="End Date" width="22rem" />
      </Flex>
      <Spacing size="lg" />
      <TableContainer>
        <Flex align="center" justify="space-between">
          <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
          <Flex gap="lg" align="center">
            {(isAdmin || isWhiteLabel) && (
              <Link to="/admin/finance-report">
                <Text color="primary" weight={600} size="sm">
                  Finance Report
                </Text>
              </Link>
            )}
            <DataTableExport {...dataTableExportProps} />
          </Flex>
        </Flex>
        <Spacing />
        <DataTable {...dataTableProps} />
      </TableContainer>
      <DailyBillManuallyModal
        isOpen={isDailyBillManuallyOpen}
        onClose={() => setIsDailyBillManuallyOpen(false)}
        onSuccess={refetch}
      />
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
