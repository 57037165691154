export const GROUP_INCLUSION_OPTIONS = [
  {
    label: 'Include',
    value: 'include',
  },
  {
    label: 'Exclude',
    value: 'exclude',
  },
];

export const RULE_MULTI_LOGIC_OPTIONS = [
  {
    label: 'Or',
    value: 'or',
  },
  {
    label: 'And',
    value: 'and',
  },
];

export const RULE_YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'on',
  },
  {
    label: 'No',
    value: 'off',
  },
];
