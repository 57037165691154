import { Button } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { PasswordFormItems, passwordIsSafe } from 'src/components/PasswordFormItems';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useResetPasswordMutation } = growthApi;

type ResetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const { handleSubmit, watch, control } = useForm<ResetPasswordFormValues>();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const values = watch();
  const isDisabled = values.password !== values.confirmPassword || !passwordIsSafe(values.password);

  const onSubmit = async (values: ResetPasswordFormValues) => {
    try {
      const apiResult = await resetPassword({
        email: searchParams.get('email') || '',
        password: values.password,
        confirmCode: searchParams.get('confirmCode') || '',
      }).unwrap();
      showSuccessToast(apiResult.message);
      navigate('/login');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Reset password">
      <Spacing size="1.2rem" />
      <PasswordFormItems control={control} />
      <Spacing size="1.2rem" />
      <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isDisabled}>
        Reset Password
      </Button>
    </AuthTemplate>
  );
};
