import { useState } from 'react';
import { Flex, Text } from 'src/components/Layout';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { Modal } from '../Modal';

const { useDspArchiveMutation, useUpdateUserProfileMutation } = growthApi;

type ArchiveProps = {
  type: string;
  typeId: number;
  onSuccess: () => void;
};

export const Archive = (props: ArchiveProps) => {
  const { type, typeId, onSuccess } = props;

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [dspArchive, { isLoading: isDspArchiveLoading }] = useDspArchiveMutation();
  const [updateUserProfile, { isLoading: isUpdateUserProfileLoading }] = useUpdateUserProfileMutation();

  const { showSuccessToast, showErrorToast } = useToast();

  const getTypeName = (type: string) => type.replace('_stackadapt', '');

  const onConfirm = async () => {
    try {
      if (type === 'user') {
        await updateUserProfile({ user_id: typeId, is_deleted: true }).unwrap();
      } else {
        await dspArchive({ type, type_id: typeId }).unwrap();
      }
      showSuccessToast(`Archive ${getTypeName(type)} successfully`);
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <>
      <Text size="xs" weight={600} color="error" clickable onClick={() => setIsConfirmOpen(true)}>
        Archive
      </Text>
      <Modal
        title="Confirmation"
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={onConfirm}
        isLoading={isDspArchiveLoading || isUpdateUserProfileLoading}
      >
        <Flex justify="center">
          <Text size="md">Are you sure to archive this {getTypeName(type)}?</Text>
        </Flex>
      </Modal>
    </>
  );
};
