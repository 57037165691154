import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';
import { useWorkspace } from 'src/hooks';

const { useMarketPlansQuery } = growthApi;

type MarketPlanSelectProps<T> = ControlledSelectProps<T> & {
  withAll?: boolean;
};

export const MarketPlanSelect = <T,>(props: MarketPlanSelectProps<T>) => {
  const { withAll, ...rest } = props;

  const workspace = useWorkspace();
  const { data, isFetching } = useMarketPlansQuery({ workspace_id: workspace?.id }, { skip: !workspace?.id });

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data?.map((marketPlan: any) => ({
        label: `${marketPlan.data?.company_name}`,
        value: Number(marketPlan.id),
      })) || [];
    return withAll ? [{ label: 'All', value: undefined }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select market plan" {...rest} />;
};
