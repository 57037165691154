import { merge, pick } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useRole } from './useRole';
import { useDeepEffect } from './useDeepEffect';
import { useEffectOnce } from './useEffectOnce';

export const PERSISTENT_FILTERS_STORAGE_KEY = 'filters';

const DEFAULT_FN = (filters: any) => filters;

export type UsePersistentFiltersOptions = {
  filters: string[];
  persistentFn?: (filters: any) => any;
  recoverFn?: (filters: any) => any;
  values?: any;
  setValues?: (values: any) => void;
};

export const usePersistentFilters = (options: UsePersistentFiltersOptions) => {
  const { filters, persistentFn = DEFAULT_FN, recoverFn = DEFAULT_FN, values, setValues } = options;

  const { agencyId, advertiserId } = useRole();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffectOnce(() => {
    const persistentFilters = pick(
      merge(
        { agencyId, advertiserId },
        JSON.parse(localStorage.getItem(PERSISTENT_FILTERS_STORAGE_KEY) || '{}'),
        JSON.parse(searchParams.get(PERSISTENT_FILTERS_STORAGE_KEY) || '{}'),
      ),
      filters,
    );
    setValues?.(merge(values, recoverFn(persistentFilters)));
    setSearchParams((searchParams) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(PERSISTENT_FILTERS_STORAGE_KEY, JSON.stringify(persistentFilters));
      return newSearchParams;
    });
  });

  useDeepEffect(() => {
    if (!values) {
      return;
    }
    const persistentFilters = pick(persistentFn(values), filters);
    const oldStorage = JSON.parse(localStorage.getItem(PERSISTENT_FILTERS_STORAGE_KEY) || '{}');
    localStorage.setItem(PERSISTENT_FILTERS_STORAGE_KEY, JSON.stringify(merge(oldStorage, persistentFilters)));
    setSearchParams((searchParams) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(PERSISTENT_FILTERS_STORAGE_KEY, JSON.stringify(persistentFilters));
      return newSearchParams;
    });
  }, [values]);
};
