import {
  ControlledInput,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  ControlledSelect,
  ControlledSwitch,
  Form,
  FormAction,
  FormItem,
  Required,
} from 'src/components/Form';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { Icon, IconProps } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import styled, { css } from 'styled-components';
import { nanoid } from 'nanoid';
import { useFormContext } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { cloneDeep, isEmpty } from 'lodash';
import { PixelSelect } from 'src/components/PixelSelect';
import {
  CampaignObjective,
  CampaignBillingType,
  CampaignDeliveryFrequencyInterval,
  CampaignEngagement,
  CampaignOngoingInterval,
  CampaignPacing,
} from 'src/types';
import { CampaignEditFormValues } from './CampaignEdit';
import { useToast } from 'src/hooks';

const CAMPAIGN_OBJECTIVE: { title: string; value: CampaignObjective; icon: IconProps['type'] }[] = [
  {
    title: 'Awareness',
    value: CampaignObjective.Awareness,
    icon: 'users',
  },
  {
    title: 'Clicks & Traffic',
    value: CampaignObjective.ClickAndTraffic,
    icon: 'pixels',
  },
  {
    title: 'Video',
    value: CampaignObjective.Video,
    icon: 'video',
  },
  {
    title: 'Audio',
    value: CampaignObjective.Audio,
    icon: 'voice',
  },
  {
    title: 'Conversions',
    value: CampaignObjective.Conversions,
    icon: 'bag',
  },
];

const CAMPAIGN_BILLING_TYPE_OPTIONS = [
  {
    label: 'CTR',
    value: CampaignBillingType.CTR,
  },
  {
    label: 'Viewability Rate',
    value: CampaignBillingType.ViewabilityRate,
  },
  {
    label: 'CPC',
    value: CampaignBillingType.CPC,
  },
  {
    label: 'CPV',
    value: CampaignBillingType.CPV,
  },
  {
    label: 'CPCV',
    value: CampaignBillingType.CPCV,
  },
  {
    label: 'VCR',
    value: CampaignBillingType.VCR,
  },
  {
    label: 'CPA',
    value: CampaignBillingType.CPA,
  },
  {
    label: 'ROI',
    value: CampaignBillingType.ROI,
  },
  {
    label: 'CPL',
    value: CampaignBillingType.CPL,
  },
  {
    label: 'CPCL',
    value: CampaignBillingType.CPCL,
  },
];

const CAMPAIGN_ONGOING_INTERVAL_OPTIONS = [
  {
    label: 'Daily',
    value: CampaignOngoingInterval.Daily,
  },
  {
    label: 'Weekly',
    value: CampaignOngoingInterval.Weekly,
  },
  {
    label: 'Monthly',
    value: CampaignOngoingInterval.Monthly,
  },
  {
    label: 'Flights',
    value: CampaignOngoingInterval.Flights,
  },
];

const CAMPAIGN_PACING_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Spend Evenly Through End Date',
    value: CampaignPacing.SpendEvenly,
  },
  {
    label: 'Spend ASAP',
    value: CampaignPacing.SpendASAP,
  },
];

const CAMPAIGN_ENGAGEMENT_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'No engagement tracking',
    value: CampaignEngagement.NoEngagement,
  },
  {
    label: 'Use Pixel',
    value: CampaignEngagement.Pixel,
  },
];

const CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Hour',
    value: CampaignDeliveryFrequencyInterval.Hour,
  },
  {
    label: 'Day',
    value: CampaignDeliveryFrequencyInterval.Day,
  },
  {
    label: 'Week',
    value: CampaignDeliveryFrequencyInterval.Week,
  },
  {
    label: 'Month',
    value: CampaignDeliveryFrequencyInterval.Month,
  },
];

const CAMPAIGN_OBJECTIVE_BILLING_TYPES: Record<CampaignObjective, CampaignBillingType[]> = {
  [CampaignObjective.Awareness]: [CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate],
  [CampaignObjective.ClickAndTraffic]: [CampaignBillingType.CPC, CampaignBillingType.CTR],
  [CampaignObjective.Video]: [
    CampaignBillingType.CPV,
    CampaignBillingType.CPCV,
    CampaignBillingType.VCR,
    CampaignBillingType.CPC,
    CampaignBillingType.CTR,
    CampaignBillingType.ViewabilityRate,
  ],
  [CampaignObjective.Conversions]: [CampaignBillingType.CPA, CampaignBillingType.ROI],
  [CampaignObjective.Audio]: [CampaignBillingType.CPL, CampaignBillingType.CPCL],
};

type SetCampaignObjectiveProps = {
  enableDraft: boolean;
  onDraftSubmit: () => void;
  onBack: () => void;
  onNext: () => void;
};

export const SetCampaignObjective = (props: SetCampaignObjectiveProps) => {
  const { enableDraft, onDraftSubmit, onBack, onNext } = props;

  const { watch, control, setValue, formState } = useFormContext<CampaignEditFormValues>();
  const values = watch();
  const { showErrorToast } = useToast();

  const onAddOngoingFlight = (index: number) => {
    const flights = cloneDeep(values.ongoing_flights);
    flights.splice(index + 1, 0, { id: nanoid() });
    setValue('ongoing_flights', flights);
  };

  const onRemoveOngoingFlight = (index: number) => {
    setValue(
      'ongoing_flights',
      values.ongoing_flights.filter((_, flightIndex) => flightIndex !== index),
    );
  };

  const validate = () => {
    const errors = [];
    if (values.ongoing_enabled) {
      if (!values.ongoing_interval) {
        errors.push('Ongoing interval is required');
      }
      if (
        [CampaignOngoingInterval.Daily, CampaignOngoingInterval.Weekly, CampaignOngoingInterval.Monthly].includes(
          values.ongoing_interval,
        ) &&
        !values.ongoing_interval_budget
      ) {
        errors.push('Ongoing interval budget is required');
      }
      if (values.ongoing_interval === CampaignOngoingInterval.Flights) {
        if (!values.ongoing_flights) {
          errors.push('Ongoing flights are required');
        }
        for (const flight of values.ongoing_flights) {
          if (!flight.budget || !flight.start_date || !flight.end_date) {
            errors.push('Budget, start date and end date are required for all flights');
            break;
          }
        }
      }
    } else {
      if (!values.budget_amount) {
        errors.push('Budget is required');
      }
      if (!values.schedule_start_date) {
        errors.push('Start date is required');
      }
      if (!values.schedule_end_date) {
        errors.push('End date is required');
      }
    }
    if (errors.length > 0) {
      showErrorToast(errors);
      return false;
    }
    return true;
  };

  return (
    <>
      <Text size="xxl" weight={700}>
        Campaign Objective
      </Text>
      <Spacing size="xl" />
      <Text size="xl" weight={600}>
        {values.campaign_name}
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        <Flex direction="column" gap="xl">
          <FormItem>
            <Grid columns={3} gap="lg">
              {CAMPAIGN_OBJECTIVE.map((objective) => (
                <Type
                  key={objective.value}
                  active={objective.value === values.objective}
                  onClick={() => {
                    setValue('objective', objective.value);
                    setValue(
                      'budget_billing_type',
                      CAMPAIGN_OBJECTIVE_BILLING_TYPES[objective.value || CampaignObjective.Awareness][0],
                    );
                  }}
                >
                  <Icon size="lg" type={objective.icon} />
                  <Text size="md" weight={500}>
                    {objective.title}
                  </Text>
                </Type>
              ))}
            </Grid>
          </FormItem>
          <FormItem>
            <Text size="xl" weight={600}>
              Select KPI
            </Text>
            <Spacing />
            <Flex gap="md">
              <ControlledSelect
                name="budget_billing_type"
                control={control}
                options={CAMPAIGN_OBJECTIVE_BILLING_TYPES[values.objective || CampaignObjective.Awareness].map(
                  (billingType) => CAMPAIGN_BILLING_TYPE_OPTIONS.find((option) => option.value === billingType),
                )}
                width="25rem"
              />
              {[
                CampaignBillingType.CPV,
                CampaignBillingType.CPCV,
                CampaignBillingType.CPC,
                CampaignBillingType.CPA,
                CampaignBillingType.CPL,
                CampaignBillingType.CPCL,
              ].includes(values.budget_billing_type) && (
                <ControlledInput
                  type="number"
                  prefix="$"
                  name="budget_billing_target"
                  control={control}
                  placeholder="KPI"
                  width="20rem"
                />
              )}
              {[CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate, CampaignBillingType.VCR].includes(
                values.budget_billing_type,
              ) && (
                <ControlledInput
                  type="number"
                  suffix="%"
                  name="budget_billing_target"
                  control={control}
                  placeholder="KPI"
                  width="20rem"
                />
              )}
              {values.budget_billing_type === CampaignBillingType.ROI && (
                <Flex width="100%" gap="md">
                  <ControlledInput
                    suffix=": 1"
                    name="budget_billing_roi_avg_order_value"
                    control={control}
                    placeholder="ROI Amount"
                    width="20rem"
                  />
                  <ControlledInput
                    prefix="$"
                    name="budget_billing_target"
                    control={control}
                    placeholder="Average Order Value"
                    width="20rem"
                  />
                </Flex>
              )}
            </Flex>
          </FormItem>
          <FormItem>
            <Flex>
              <Text size="xl" weight={600}>
                Schedule
              </Text>
              <Required />
            </Flex>
            <Spacing size="lg" />
            <Text size="sm">
              <Flex direction="column" gap="md">
                <Flex gap="md" align="center">
                  <div>Is Ongoing?</div>
                  <ControlledSwitch name="ongoing_enabled" control={control} />
                </Flex>
                {values.ongoing_enabled ? (
                  <>
                    <Flex align="center" gap="md">
                      <div>Ongoing Interval</div>
                      <ControlledSelect
                        name="ongoing_interval"
                        control={control}
                        width="12rem"
                        options={CAMPAIGN_ONGOING_INTERVAL_OPTIONS}
                        placeholder=""
                      />
                      {[
                        CampaignOngoingInterval.Daily,
                        CampaignOngoingInterval.Weekly,
                        CampaignOngoingInterval.Monthly,
                      ].includes(values.ongoing_interval) && (
                        <>
                          <div>Budget</div>
                          <ControlledInput
                            prefix="$"
                            name="ongoing_interval_budget"
                            type="number"
                            control={control}
                            width="16rem"
                          />
                        </>
                      )}
                      {[
                        CampaignOngoingInterval.Weekly,
                        CampaignOngoingInterval.Monthly,
                        CampaignOngoingInterval.Flights,
                      ].includes(values.ongoing_interval) && (
                        <>
                          <div>Daily cap</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget_amount_daily"
                            control={control}
                            width="10rem"
                          />
                        </>
                      )}
                      <div>Max Bid</div>
                      <ControlledInput
                        type="number"
                        prefix="$"
                        name="budget_max_bid_cpm"
                        control={control}
                        width="10rem"
                      />
                      <div>CPM</div>
                    </Flex>
                    {values.ongoing_interval === CampaignOngoingInterval.Flights && (
                      <>
                        <Text size="md" weight={600}>
                          Ongoing Flights
                        </Text>
                        {values.ongoing_flights?.map((flight, index) => (
                          <Flex key={flight.id} align="center" gap="md">
                            <ControlledInput
                              prefix="$"
                              name={`ongoing_flights.${index}.budget`}
                              type="number"
                              control={control}
                              width="16rem"
                              placeholder="Budget"
                            />
                            <ControlledDatePicker
                              name={`ongoing_flights.${index}.start_date`}
                              control={control}
                              placeholder="Start date"
                              showTimeSelect
                              width="24rem"
                            />
                            <ControlledDatePicker
                              name={`ongoing_flights.${index}.end_date`}
                              control={control}
                              placeholder="End date"
                              showTimeSelect
                              width="24rem"
                            />
                            <Icon type="plus" color="primary" onClick={() => onAddOngoingFlight(index)} clickable />
                            {index !== 0 && (
                              <Icon type="trash" color="error" onClick={() => onRemoveOngoingFlight(index)} clickable />
                            )}
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Flex gap="md">
                      <FlexItem grow={1}>
                        <Flex direction="column" gap="md">
                          <Text size="sm">When should this campaign start?</Text>
                          <ControlledDatePicker
                            name="schedule_start_date"
                            control={control}
                            placeholder="Select start date"
                            showTimeSelect
                          />
                        </Flex>
                      </FlexItem>
                      <FlexItem grow={1}>
                        <Flex direction="column" gap="md">
                          <Text size="sm">When should this campaign finish?</Text>
                          <ControlledDatePicker
                            name="schedule_end_date"
                            control={control}
                            placeholder="Select end date"
                            showTimeSelect
                          />
                        </Flex>
                      </FlexItem>
                    </Flex>
                    <Flex align="center" gap="md">
                      <div>Budget</div>
                      <ControlledInput type="number" prefix="$" name="budget_amount" control={control} width="16rem" />
                      <div>Daily cap</div>
                      <ControlledInput
                        type="number"
                        prefix="$"
                        name="budget_amount_daily"
                        control={control}
                        width="10rem"
                      />
                      <div>Max Bid</div>
                      <ControlledInput
                        type="number"
                        prefix="$"
                        name="budget_max_bid_cpm"
                        control={control}
                        width="10rem"
                      />
                      <div>CPM</div>
                    </Flex>
                  </>
                )}
                <Text color="gray">Please note: The timezone is default to New York</Text>
                <ControlledRadioGroup name="budget_pacing" control={control} options={CAMPAIGN_PACING_OPTIONS} />
              </Flex>
            </Text>
          </FormItem>
          <FormItem>
            <Text size="xl" weight={600}>
              Frequency capping
            </Text>
            <Spacing />
            <Text size="sm">
              <Flex align="center" gap="sm">
                <div>No more than</div>
                <ControlledInput type="number" name="delivery_frequency_amount" control={control} width="10rem" />
                <div>impression(s) per user every</div>
                <ControlledSelect
                  name="delivery_frequency_interval"
                  options={CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS}
                  control={control}
                  width="10rem"
                  placeholder=""
                />
              </Flex>
            </Text>
          </FormItem>
          <FormItem>
            <Text size="xl" weight={600}>
              Conversion Tracking
            </Text>
            <Spacing />
            <Text size="sm">What action do you want to track after a click?</Text>
            <Spacing />
            <Flex>
              <ControlledRadioGroup
                name="engagement"
                control={control}
                options={CAMPAIGN_ENGAGEMENT_OPTIONS}
                width="35rem"
              />
              <PixelSelect
                agencyId={values.agency_id}
                advertiserId={values.advertiser_id}
                name="pixel_uuid"
                control={control}
                width="40rem"
                onValueChange={(value) => {
                  setValue('engagement', isEmpty(value) ? CampaignEngagement.NoEngagement : CampaignEngagement.Pixel);
                }}
                multiple
              />
            </Flex>
          </FormItem>
          <FormAction
            onBack={onBack}
            onSubmit={() => validate() && onNext()}
            submitText="NEXT"
            enableDraft={enableDraft}
            isSubmitting={formState.isSubmitting}
            onDraftSubmit={onDraftSubmit}
          />
        </Flex>
      </Form>
    </>
  );
};

const Type = styled.div<{ active?: boolean }>`
  padding: 2.4rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;

  img {
    width: 7.5rem;
    height: 7.5rem;
  }

  svg {
    width: 4rem;
    height: 4rem;
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 0.4rem ${TOKENS.color.primary};
        `
      : ''}
`;
