import { keyBy } from 'lodash';
import { ControlledSelectOption } from 'src/components/Form';
import { CampaignChannel, CreativeType } from 'src/types';

export const CAMPAIGN_CHANNEL_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Digital Out of Home',
    value: CampaignChannel.DigitalOutOfHome,
  },
  {
    label: 'CTV / OTT',
    value: CampaignChannel.CTVOrOTT,
  },
  {
    label: 'Audio',
    value: CampaignChannel.Audio,
  },
  {
    label: 'Video',
    value: CampaignChannel.Video,
  },
  {
    label: 'Native',
    value: CampaignChannel.Native,
  },
  {
    label: 'Display',
    value: CampaignChannel.Display,
  },
];

export const CAMPAIGN_CHANNEL_OPTIONS_BY_VALUE = keyBy(CAMPAIGN_CHANNEL_OPTIONS, 'value');

export const CAMPAIGN_CHANNEL_CREATIVE_TYPES_MAPPING: Record<CampaignChannel, CreativeType[]> = {
  [CampaignChannel.DigitalOutOfHome]: [CreativeType.BannerAd],
  [CampaignChannel.CTVOrOTT]: [CreativeType.VideoAd],
  [CampaignChannel.Audio]: [CreativeType.AudioAd],
  [CampaignChannel.Video]: [CreativeType.VideoAd],
  [CampaignChannel.Native]: [CreativeType.NativeAd],
  [CampaignChannel.Display]: [CreativeType.BannerAd, CreativeType.HTML5Ad, CreativeType.AdTag],
};
