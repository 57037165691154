import { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { ControlledSelect, ControlledSelectOption, FormItem } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { AudienceRule } from 'src/types';
import { TIMEZONE_OPTIONS } from 'src/utils';

export const CUSTOM_RULE_DAY_PART_ID = 14;

const DAY_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Weekdays',
    value: 'Weekdays',
  },
  {
    label: 'Weekends',
    value: 'Weekends',
  },
];
const HOUR_START_OPTIONS: ControlledSelectOption[] = [
  {
    label: '12:00',
    value: 12,
  },
  {
    label: '01:00',
    value: 1,
  },
  {
    label: '02:00',
    value: 2,
  },
  {
    label: '03:00',
    value: 3,
  },
  {
    label: '04:00',
    value: 4,
  },
  {
    label: '05:00',
    value: 5,
  },
  {
    label: '06:00',
    value: 6,
  },
  {
    label: '07:00',
    value: 7,
  },
  {
    label: '08:00',
    value: 8,
  },
  {
    label: '09:00',
    value: 9,
  },
  {
    label: '10:00',
    value: 10,
  },
  {
    label: '11:00',
    value: 11,
  },
];
const HOUR_END_OPTIONS: ControlledSelectOption[] = [
  {
    label: '12:59',
    value: 12,
  },
  {
    label: '01:59',
    value: 1,
  },
  {
    label: '02:59',
    value: 2,
  },
  {
    label: '03:59',
    value: 3,
  },
  {
    label: '04:59',
    value: 4,
  },
  {
    label: '05:59',
    value: 5,
  },
  {
    label: '06:59',
    value: 6,
  },
  {
    label: '07:59',
    value: 7,
  },
  {
    label: '08:59',
    value: 8,
  },
  {
    label: '09:59',
    value: 9,
  },
  {
    label: '10:59',
    value: 10,
  },
  {
    label: '11:59',
    value: 11,
  },
];
const HOUR_MERIDIEM: ControlledSelectOption[] = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

type DayPartFormValues = {
  day?: string[];
  hourStart?: number;
  hourStartMeridiem?: string;
  hourEnd?: number;
  hourEndMeridiem?: string;
  timezone?: number;
};

type CustomRuleTypeDayPartProps = {
  rule?: AudienceRule;
};

export const CustomRuleTypeDayPart = (props: CustomRuleTypeDayPartProps) => {
  const { rule } = props;

  const { control, watch, reset } = useForm<DayPartFormValues>({
    defaultValues: {
      day: ['All'],
      hourStart: 12,
      hourStartMeridiem: 'AM',
      hourEnd: 12,
      hourEndMeridiem: 'AM',
      timezone: 14,
    },
  });
  const { setValue: setParentValue } = useFormContext();
  const values = watch();

  useEffect(() => {
    if (rule) {
      if (rule.rule_value) {
        const [day, hourStart, hourStartMeridiem, hourEnd, hourEndMeridiem, timezone] = rule.rule_value.split('|');
        reset({
          day: day.split(','),
          hourStart: Number(hourStart),
          hourStartMeridiem,
          hourEnd: Number(hourEnd),
          hourEndMeridiem,
          timezone: Number(timezone),
        });
      }
    }
  }, [reset, rule]);

  useEffect(() => {
    if (
      values.day &&
      values.hourStart &&
      values.hourStartMeridiem &&
      values.hourEnd &&
      values.hourEndMeridiem &&
      values.timezone
    )
      setParentValue(
        'rule_value',
        `${values.day?.join(',')}|${values.hourStart}|${values.hourStartMeridiem}|${values.hourEnd}|${
          values.hourEndMeridiem
        }|${values.timezone}`,
      );
    setParentValue(
      'rule_value_name',
      `${values.day?.join(',')} - ${HOUR_START_OPTIONS.find((item) => item.value === values.hourStart)?.label} ${
        values.hourStartMeridiem
      } to ${HOUR_END_OPTIONS.find((item) => item.value === values.hourEnd)?.label} ${values.hourEndMeridiem} ${
        TIMEZONE_OPTIONS.find((item) => item.value === values.timezone)?.label
      }`,
    );
  }, [
    setParentValue,
    values.day,
    values.hourEnd,
    values.hourEndMeridiem,
    values.hourStart,
    values.hourStartMeridiem,
    values.timezone,
  ]);

  return (
    <>
      <FormItem label="Day">
        <ControlledSelect name="day" control={control} options={DAY_OPTIONS} multiple placeholder="Select day" />
      </FormItem>
      <FormItem label="Time">
        <Flex align="center" gap="md">
          <FlexItem>
            <ControlledSelect
              name="hourStart"
              control={control}
              options={HOUR_START_OPTIONS}
              placeholder="Select hour start"
            />
          </FlexItem>
          <FlexItem width="10rem">
            <ControlledSelect name="hourStartMeridiem" control={control} options={HOUR_MERIDIEM} />
          </FlexItem>
          <Text> - </Text>
          <FlexItem>
            <ControlledSelect
              name="hourEnd"
              control={control}
              options={HOUR_END_OPTIONS}
              placeholder="Select hour end"
            />
          </FlexItem>
          <FlexItem width="10rem">
            <ControlledSelect name="hourEndMeridiem" control={control} options={HOUR_MERIDIEM} />
          </FlexItem>
        </Flex>
        <Spacing />
        <ControlledSelect name="timezone" control={control} options={TIMEZONE_OPTIONS} placeholder="Select timezone" />
      </FormItem>
    </>
  );
};
