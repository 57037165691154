import { useCallback } from 'react';
import { useAppSelector, useAppDispatch, userSlice } from 'src/store';

export const useUserMarketPlan = () => {
  const { marketPlanId } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const changeMarketPlan = useCallback(
    (marketPlanId?: number) => {
      dispatch(userSlice.actions.setMarketPlan({ marketPlanId }));
    },
    [dispatch],
  );

  return {
    marketPlanId,
    changeMarketPlan,
  };
};
