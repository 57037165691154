import { User } from 'src/types';

export const getUserName = (user?: User) => {
  if (!user) {
    return '';
  }
  if (user.user_name) {
    return user.user_name;
  }
  const emailName = user.email.split('@')[0];
  return emailName.charAt(0).toUpperCase() + emailName?.slice(1);
};
