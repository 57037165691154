import { UsePersistentFiltersOptions, usePersistentFilters } from './usePersistentFilters';

type UseReportingPersistentFiltersProps = Pick<UsePersistentFiltersOptions, 'values' | 'setValues'>;

export const useCommonPersistentFilters = (props: UseReportingPersistentFiltersProps) => {
  const { values, setValues } = props;

  usePersistentFilters({
    filters: ['agencyId', 'advertiserId'],
    values,
    setValues,
    persistentFn: (filters) => {
      const persistentFilters = {
        ...filters,
        agencyId: filters.agency_id,
        advertiserId: filters.advertiser_id,
      };
      delete persistentFilters.agency_id;
      delete persistentFilters.advertiser_id;
      return persistentFilters;
    },
    recoverFn: (filters) => {
      const recoveredFilters = {
        ...filters,
        agency_id: filters.agencyId,
        advertiser_id: filters.advertiserId,
      };
      delete recoveredFilters.agencyId;
      delete recoveredFilters.advertiserId;
      return recoveredFilters;
    },
  });
};
