import { PERSISTENT_FILTERS_STORAGE_KEY } from './usePersistentFilters';
import { useRole } from './useRole';

export const usePersistentAgencyAdvertiser = () => {
  const { agencyId, advertiserId } = useRole();

  const persistentFilter = JSON.parse(localStorage.getItem(PERSISTENT_FILTERS_STORAGE_KEY) || '{}');

  return {
    agencyId: persistentFilter.agencyId || agencyId,
    advertiserId: persistentFilter.advertiserId || advertiserId,
  };
};
