import { ControlledSelectOption } from 'src/components/Form';

export const METRIC_OPTIONS: ControlledSelectOption[] = [
  { label: 'Impressions', value: 'impressions' },
  { label: 'Clicks', value: 'clicks' },
  { label: 'Video/Audio views', value: 'video_audio_views' },
  { label: 'View completed', value: 'video_audio_complete' },
  // { label: 'Listens', value: 'audio_listens' },
  // { label: 'Completed Listens', value: 'audio_complete_listens' },
  { label: 'CTR', value: 'ctr' },
  { label: 'CPM', value: 'cpm' },
  { label: 'CPC', value: 'cpc' },
  { label: 'CPV', value: 'cpv' },
  { label: 'CPCV', value: 'cpcv' },
  { label: 'CPL', value: 'cpl' },
  { label: 'CPCL', value: 'cpcl' },
  { label: 'Conversions', value: 'total_conversions' },
  { label: 'CPA', value: 'cpa' },
  { label: 'Total Spend', value: 'total_spend' },
  { label: 'None', value: undefined },
];

export const AUCTION_METRIC_OPTIONS: ControlledSelectOption[] = [
  { label: 'Matched Bid Opportunities', value: 'matched_bid_opportunities' },
  { label: 'Bids', value: 'bids' },
  { label: 'Bid Rate', value: 'bid_rate' },
  { label: 'Wins', value: 'wins' },
  { label: 'Win Rate', value: 'win_rate' },
];

export const MARKUP_FIELDS = ['cpm', 'cpc', 'cpv', 'cpa', 'total_spend'];

export const REPORTING_SORT_NUMBER_KEYS = [
  'impressions',
  'clicks',
  'ctr',
  'cpm',
  'cpm_markup',
  'cpm_our_markup',
  'cpc',
  'cpc_markup',
  'cpc_our_markup',
  'cpv',
  'cpv_markup',
  'cpv_our_markup',
  'total_conversions',
  'cpa',
  'cpa_markup',
  'cpa_our_markup',
  'total_spend',
  'total_spend_markup',
  'total_spend_our_markup',
];

export const REPORTING_DEFAULT_CUSTOMIZE_COLUMNS = [
  'Impressions',
  'Clicks',
  'CTR',
  'CPM',
  'CPC',
  'CPV',
  'Conversions',
  'CPA',
  'Spend',
];
