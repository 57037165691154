import { Button, ControlledFile, Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { FormAction } from 'src/components/Form/FormAction';
import { PageTemplate } from 'src/components/Template';
import { Spacing, Text } from 'src/components/Layout';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AudienceGroups } from './AudienceGroups';
import { NewRoleGroupModal } from './NewRoleGroupModal';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { fileToBase64, getApiErrorMessage } from 'src/utils';
import { usePersistentAgencyAdvertiser, useRole, useToast } from 'src/hooks';
import { AgencySelect } from 'src/components/AgencySelect';
import { useAppSelector } from 'src/store';
import { EditTargetingRuleModal } from 'src/components/EditTargetingRuleModal';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { TOKENS } from 'src/design';
import { Icon, IconProps } from 'src/components/Icon';
import { cloneDeep, omit } from 'lodash';
import { AudienceFile, AudienceGroup, AudienceRule } from 'src/types';

const { useAudiencesQuery, useCreateOrUpdateAudienceMutation } = growthApi;

enum AudienceStrategy {
  Custom = 'custom',
  PreBuilt = 'pre_built',
  GeoFencing = 'geo_fencing',
  Lookalikes = 'lookalikes',
  ReMarketing = 're_marketing',
  ContextAndKeywords = 'context_keywords',
  TargetSites = 'target_sites',
  StartFromScratch = 'start_from_scratch',
}

enum AudienceStep {
  Strategy = 'strategy',
  Form = 'form',
  Custom = 'custom',
}

export type AudienceEditFormValues = {
  id?: number;
  agency_id?: number;
  advertiser_id?: number;
  audience_name?: string;
  global_grouping_include?: string;
  global_grouping_exclude?: string;
  rule_grouping_include?: string;
  rule_grouping_exclude?: string;
  groups?: AudienceGroup[];
  rules?: AudienceRule[];
  file?: AudienceFile;
  // extends
  custom_audience_file?: File;
  step?: AudienceStep;
  strategy?: AudienceStrategy;
  preset_rule?: AudienceRule;
};

const AUDIENCE_STRATEGIES: { label: string; icon?: IconProps['type']; value: AudienceStrategy }[] = [
  {
    label: 'Upload a custom audience',
    icon: 'file',
    value: AudienceStrategy.Custom,
  },
  {
    label: 'Select a pre-built audience',
    icon: 'preBuilt',
    value: AudienceStrategy.PreBuilt,
  },
  {
    label: 'GeoFencing',
    icon: 'geo',
    value: AudienceStrategy.GeoFencing,
  },
  {
    label: 'Lookalikes',
    icon: 'copy',
    value: AudienceStrategy.Lookalikes,
  },
  {
    label: 'ReMarketing',
    icon: 'reMarketing',
    value: AudienceStrategy.ReMarketing,
  },
  {
    label: 'Context & keywords',
    icon: 'keywords',
    value: AudienceStrategy.ContextAndKeywords,
  },
  {
    label: 'Target sites',
    icon: 'sites',
    value: AudienceStrategy.TargetSites,
  },
  {
    label: 'Start from scratch',
    icon: 'edit',
    value: AudienceStrategy.StartFromScratch,
  },
];

export const AudienceEdit = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId } = usePersistentAgencyAdvertiser();
  const user = useAppSelector((state) => state.user.user);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const copy = searchParams.get('copy');
  const location = useLocation();
  const isNew = !id;
  const { data, isLoading } = useAudiencesQuery({ id: id || copy }, { skip: !id && !copy });
  const [createOrUpdateAudience] = useCreateOrUpdateAudienceMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const exclusionRules = useMemo(() => user?.dsp_exclusion_rules || [], [user?.dsp_exclusion_rules]);
  const useFormReturns = useForm<AudienceEditFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      global_grouping_include: 'or',
      global_grouping_exclude: 'or',
      rule_grouping_include: 'or',
      rule_grouping_exclude: 'or',
      groups: [],
      rules: exclusionRules,
      step: AudienceStep.Strategy,
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useFormReturns;
  const [newRoleGroupIsOpen, setNewRoleGroupIsOpen] = useState<boolean>(false);
  const [editTargetingRuleIsOpen, setEditTargetingRuleIsOpen] = useState<boolean>(false);
  const [editRuleIndex, setEditRuleIndex] = useState<number>();

  useEffect(() => {
    if (data) {
      const audience = cloneDeep(data.data);
      // change name & delete id if copy
      if (copy) {
        audience.audience_name = `${audience.audience_name} copy`;
        delete audience.id;
      }
      reset({
        ...audience,
        step: AudienceStep.Form,
      });
    }
  }, [data, copy, reset]);

  const values = watch();
  const hasIncludeRule = Boolean(values.rules?.find((rule) => rule.rule_inclusion_type === 'include'));

  const onSelectStrategy = (strategy: AudienceStrategy) => {
    switch (strategy) {
      case AudienceStrategy.PreBuilt:
        setValue('preset_rule', {
          audience_group_id: 0,
          multi_value_grouping_type: 'or',
          rule_category_id: 10,
          rule_inclusion_type: 'include',
          rule_response_type: 5,
          rule_sub_type_id: -1,
          rule_type_id: 27,
          rule_type_id_name: '3rd Party Audiences',
          rule_type_name: 'Data Provider',
          open_data_provider_modal: true,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.GeoFencing:
        setValue('preset_rule', {
          audience_group_id: 0,
          multi_value_grouping_type: 'or',
          rule_category_id: 3,
          rule_inclusion_type: 'include',
          rule_type_id: 13,
          rule_type_id_name: 'Geo',
          rule_sub_type_id: 2,
          rule_type_name: 'Geo Fence',
          rule_response_type: 5,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.Lookalikes:
      case AudienceStrategy.ReMarketing:
        setValue('preset_rule', {
          audience_group_id: 0,
          rule_type_id: 25,
          rule_category_id: 8,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_inclusion_type: 'include',
          rule_type_id_name: 'My Pixels',
          rule_type_name: 'Pixels',
          rule_response_type: 5,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.ContextAndKeywords:
        setValue('preset_rule', {
          rule_inclusion_type: 'include',
          audience_group_id: 0,
          rule_type_id: 0,
          rule_category_id: 1,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_type_id_name: 'Context & Keywords',
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.TargetSites:
        setValue('preset_rule', {
          rule_inclusion_type: 'include',
          audience_group_id: 0,
          rule_type_id: 0,
          rule_category_id: 2,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_type_id_name: 'Sites & Apps',
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.Custom:
      case AudienceStrategy.StartFromScratch:
        setValue('preset_rule', undefined);
        break;
    }
    setValue('strategy', strategy);
    setValue('step', AudienceStep.Form);
    setValue('rules', exclusionRules);
  };

  const onAddNewRoleGroup = useCallback(
    (group: AudienceGroup) => {
      setValue('groups', [...(values.groups || []), group]);
    },
    [setValue, values.groups],
  );

  const onEditTargetingRule = useCallback(
    (rule: AudienceRule) => {
      if (editRuleIndex !== undefined) {
        const copiedRules = [...(values.rules || [])];
        copiedRules[editRuleIndex] = rule;
        setValue('rules', copiedRules);
      } else {
        setValue('rules', [...(values.rules || []), rule]);
      }
      setEditTargetingRuleIsOpen(false);
    },
    [editRuleIndex, setValue, values.rules],
  );

  const onRuleRemove = (ruleIndex: number) => {
    setValue(
      'rules',
      values.rules?.filter((_, index) => index !== ruleIndex),
    );
  };

  const onRuleEdit = (ruleIndex: number) => {
    setEditRuleIndex(ruleIndex);
    setEditTargetingRuleIsOpen(true);
  };

  const onValidate = (values: AudienceEditFormValues) => {
    if (!values.agency_id) {
      showErrorToast('Please select agency');
      return false;
    }
    if (!values.advertiser_id) {
      showErrorToast('Please select advertiser');
      return false;
    }
    if (!values.audience_name) {
      showErrorToast('Please enter audience name');
      return false;
    }
    if (values.strategy === AudienceStrategy.Custom) {
      if (!values.custom_audience_file) {
        showErrorToast('Please upload custom audience file');
        return false;
      }
    } else {
      if (!values.rules?.find((rule) => rule.rule_inclusion_type === 'include')) {
        showErrorToast('Please add include rule');
        return false;
      }
      if (values.strategy === AudienceStrategy.Lookalikes) {
        // check if added pixels rules
        if (!values.rules?.find((rule) => rule.rule_inclusion_type === 'include' && rule.rule_type_id === 25)) {
          showErrorToast('Please add pixel include rule for create lookalikes audience');
        }
      }
    }
    return true;
  };

  const onSubmit = async (values: AudienceEditFormValues) => {
    try {
      if (!onValidate(values)) {
        return false;
      }
      const result = await createOrUpdateAudience({
        file:
          isNew && values.custom_audience_file
            ? { file_name: values.custom_audience_file.name, file: await fileToBase64(values.custom_audience_file) }
            : values.file,
        ...omit(values, ['file', 'step', 'strategy', 'preset_rule']),
      }).unwrap();
      if (isNew) {
        showSuccessToast('Create audience successfully');
        if (location.state?.from === 'campaign') {
          navigate('/activate/campaigns/new', {
            state: {
              campaign: {
                ...location.state?.campaign,
                audience_id: result.data?.id,
              },
            },
          });
        } else {
          navigate('/activate/audiences');
        }
      } else {
        showSuccessToast('Save audience successfully');
        navigate('/activate/audiences');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Title>{isNew ? 'New Audience' : 'Edit Audience'}</Title>
      <FormProvider {...useFormReturns}>
        {values.step === AudienceStep.Strategy && (
          <FormItem>
            <TypeContainer>
              {AUDIENCE_STRATEGIES.map((type) => (
                <Type key={type.value} onClick={() => onSelectStrategy(type.value)}>
                  <Icon type={type.icon} />
                  <Text size="md" weight={600}>
                    {type.label}
                  </Text>
                </Type>
              ))}
            </TypeContainer>
          </FormItem>
        )}
        {values.step === AudienceStep.Form && (
          <Form width="70%">
            {canAccessAgency && isNew && (
              <>
                <FormItem label="Agency" required>
                  <AgencySelect
                    name="agency_id"
                    control={control}
                    onValueChange={() => setValue('advertiser_id', null)}
                  />
                </FormItem>
                <FormItem label="Advertiser" required>
                  <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
                </FormItem>
              </>
            )}
            <FormItem label="Audience Name" required>
              <ControlledInput name="audience_name" control={control} placeholder="Enter audience name" />
            </FormItem>
            {values.strategy === AudienceStrategy.Custom ? (
              <FormItem label="Custom Audience File" required>
                <ControlledFile name="custom_audience_file" control={control} />
              </FormItem>
            ) : (
              <FormItem label="Targeting Rules" required>
                <ButtonContainer>
                  <Button width="22rem" various="secondary" rounded={false} onClick={() => setNewRoleGroupIsOpen(true)}>
                    ADD NEW ROLE GROUP
                  </Button>
                  <Button
                    width="22rem"
                    rounded={false}
                    onClick={() => {
                      setEditRuleIndex(undefined);
                      setEditTargetingRuleIsOpen(true);
                    }}
                  >
                    ADD NEW TARGETING RULE
                  </Button>
                </ButtonContainer>
                <Spacing size="md" />
                <Text size="xs">Targeting rules for this Audience can be seen below:</Text>
                <Spacing size="md" />
                <AudienceGroups onRuleEdit={onRuleEdit} onRuleRemove={onRuleRemove} />
              </FormItem>
            )}
            <FormAction
              onSubmit={handleSubmit(onSubmit)}
              onBack={isNew && !copy ? () => setValue('step', AudienceStep.Strategy) : undefined}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </FormProvider>
      <NewRoleGroupModal
        isOpen={newRoleGroupIsOpen}
        groups={values.groups}
        onSuccess={onAddNewRoleGroup}
        onClose={() => setNewRoleGroupIsOpen(false)}
      />
      <EditTargetingRuleModal
        isOpen={editTargetingRuleIsOpen}
        groups={values.groups}
        // use template default rule, when don't exists include rule
        rule={
          editRuleIndex !== undefined ? values.rules?.[editRuleIndex] : hasIncludeRule ? undefined : values.preset_rule
        }
        agencyId={values.agency_id}
        advertiserId={values.advertiser_id}
        onSuccess={onEditTargetingRule}
        onClose={() => setEditTargetingRuleIsOpen(false)}
      />
    </PageTemplate>
  );
};

const Title = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
  padding-bottom: 2.4rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1.2rem;
`;

const TypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 1.2rem;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;

const Type = styled.div`
  padding: 3.6rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
`;
