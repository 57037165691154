import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import { Icon } from 'src/components/Icon';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { MarketPlanSelect } from 'src/components/MarketPlanSelect';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { useReloadUser, useToast, useUserMarketPlan, useWorkspace } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatOnlyDate, getApiErrorMessage } from 'src/utils';
import styled, { css } from 'styled-components';

const { usePersonasQuery, useCreateOrUpdatePersonaMutation } = growthApi;

type PersonasFormValues = {
  market_plan_id?: number;
};

export const Personas = () => {
  const workspace = useWorkspace();
  const { marketPlanId, changeMarketPlan } = useUserMarketPlan();
  const { watch, control } = useForm<PersonasFormValues>({
    defaultValues: {
      market_plan_id: marketPlanId,
    },
  });
  const values = watch();
  const { data, isLoading, refetch } = usePersonasQuery(
    {
      workspace_id: workspace?.id,
      market_plan_id: values.market_plan_id,
    },
    { skip: !workspace?.id },
  );
  const [createOrUpdatePersona, { isLoading: isGenerating }] = useCreateOrUpdatePersonaMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const reloadUser = useReloadUser();

  useEffect(() => {
    changeMarketPlan(values.market_plan_id);
  }, [changeMarketPlan, values.market_plan_id]);

  const generatePersona = async () => {
    if (!marketPlanId) {
      showErrorToast('Please select a market plan');
      return;
    }
    try {
      await createOrUpdatePersona({ workspace_id: workspace?.id, market_plan_id: marketPlanId }).unwrap();
      showSuccessToast('Generate persona successfully');
      refetch();
      reloadUser();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Personas
          </Text>
          <MarketPlanSelect name="market_plan_id" control={control} withAll />
        </Flex>
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Grid columns={5} gap="md">
        <NewPersonal onClick={generatePersona} isLoading={isGenerating}>
          <Flex align="center" direction="column" justify="center" gap="xl" height="100%">
            <Icon color="white" size="lg" type={isGenerating ? 'loading' : 'plus'} />
            <Flex align="center" direction="column" gap="sm">
              <Text color="white" weight={600}>
                {isGenerating ? 'Generating' : 'Generate Persona'}
              </Text>
              <Text size="sm" color="white">
                (250 data credits)
              </Text>
            </Flex>
          </Flex>
        </NewPersonal>
        {data?.data?.map((item: any) => (
          <PersonalItem key={item.id}>
            <Flex justify="space-between">
              <Text weight={600}>{item.data['Name']}</Text>
              <Link to={`/planning/personas/edit?id=${item.id}`}>
                <Icon color="gray" type="edit" clickable />
              </Link>
            </Flex>
            <Spacing />
            <img src={item.data['Avatar']} alt="avatar" />
            <Spacing />
            <Text size="sm">{item.data['Job title']}</Text>
            <Spacing size="lg" />
            <Text size="xs" color="gray">
              {formatOnlyDate(item.created_at)}
            </Text>
          </PersonalItem>
        ))}
      </Grid>
    </PageTemplate>
  );
};

const NewPersonal = styled.div<{ isLoading?: boolean }>`
  border-radius: 1rem;
  padding: 2.4rem;

  ${(props) =>
    props.isLoading
      ? css`
          cursor: not-allowed;
          background: ${(props) => props.theme.color.gray};
        `
      : css`
          cursor: pointer;
          background: ${(props) => props.theme.color.primary};
        `}
`;

const PersonalItem = styled.div`
  background: white;
  border-radius: 1rem;
  border: solid 0.2rem ${(props) => props.theme.color.bgGray};
  padding: 2.4rem;

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
`;
