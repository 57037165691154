import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useState } from 'react';
import { UseFormReset, useForm } from 'react-hook-form';
import {
  Button,
  ControlledFile,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  Form,
  FormItem,
} from 'src/components/Form';
import { Flex } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useBulkUploadMutation, useUpdateCreativesMutation, useSegmentsUpdateMutation } = growthApi;

const ImportCampaignTypesOption: ControlledRadioGroupOption[] = [
  {
    label: 'Update',
    value: 'update',
  },
  {
    label: 'Create',
    value: 'create',
  },
];

type ImportFormValues = {
  file: File;
};

type CampaignImportFormValues = ImportFormValues & {
  type: 'create' | 'update';
};

export const BulkUploadBeeswax = () => {
  const { showSuccessToast, showErrorToast } = useToast();

  // campaigns
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [bulkUpload, { isLoading: campaignIsLoading }] = useBulkUploadMutation();
  const { control: importControl, watch: importWatch, reset: importReset } = useForm<CampaignImportFormValues>();
  const importValues = importWatch();
  const IsImportConfirmDisabled = !importValues.file;

  // segments
  const [isSegmentsImportOpen, setIsSegmentsImportOpen] = useState(false);
  const [segmentsUpdate, { isLoading: segmentsIsLoading }] = useSegmentsUpdateMutation();
  const {
    control: importSegmentsControl,
    watch: importSegmentsWatch,
    reset: importSegmentsReset,
  } = useForm<ImportFormValues>();
  const importSegmentsValues = importSegmentsWatch();
  const IsImportSegmentsConfirmDisabled = !importSegmentsValues.file;

  // creatives
  const [isCreativeImportOpen, setIsCreativesImportOpen] = useState(false);
  const [updateCreatives, { isLoading: creativesIsLoading }] = useUpdateCreativesMutation();
  const {
    control: importCreativesControl,
    watch: importCreativesWatch,
    reset: importCreativesReset,
  } = useForm<ImportFormValues>();
  const importCreativesValues = importCreativesWatch();
  const IsImportCreativesConfirmDisabled = !importCreativesValues.file;

  const FileUpload = async (
    file: File,
    action: MutationTrigger<any>,
    reset: UseFormReset<ImportFormValues>,
    successMessage: string,
    setOpen: React.Dispatch<any>,
    extra?: { isCreate?: boolean },
  ) => {
    if (!file.name.includes('.csv')) {
      showErrorToast('File format should be .csv');
      reset({ file: null });
      return;
    }
    let formData = new FormData();
    formData.append('file', file);
    let payload = {};
    if (extra?.isCreate) {
      payload = {
        formData: formData,
        query: { isCreate: extra?.isCreate },
      };
    } else {
      payload = formData;
    }
    try {
      await action(payload).unwrap();
      showSuccessToast(successMessage);
      setOpen(false);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error) as string);
    }
    reset({ file: null });
  };

  const onFileUpload = async () => {
    const { file } = importValues;
    await FileUpload(file, bulkUpload, importReset, 'Campaigns send successfully!', setIsImportOpen, {
      isCreate: importValues.type === 'create',
    });
  };

  const onSegmentsFileUpload = async () => {
    const { file } = importSegmentsValues;
    await FileUpload(file, segmentsUpdate, importSegmentsReset, 'Segments send successfully!', setIsSegmentsImportOpen);
  };

  const onCreativesFileUpload = async () => {
    const { file } = importCreativesValues;
    await FileUpload(
      file,
      updateCreatives,
      importCreativesReset,
      'Creatives update send successfully!',
      setIsCreativesImportOpen,
    );
  };

  return (
    <>
      <Flex direction="column" gap="lg">
        <Button various="primary" width="24rem" onClick={() => setIsImportOpen(true)}>
          BULK UPLOAD
        </Button>
        <Button various="secondary" width="24rem" onClick={() => setIsCreativesImportOpen(true)}>
          UPDATE CREATIVES
        </Button>
        <Button various="secondary" width="24rem" onClick={() => setIsSegmentsImportOpen(true)}>
          UPDATE SEGMENTS
        </Button>
      </Flex>
      <Modal
        isOpen={isImportOpen}
        title="Import Campaigns"
        onConfirm={onFileUpload}
        disabled={IsImportConfirmDisabled}
        onClose={() => setIsImportOpen(false)}
      >
        <Form>
          <FormItem required label="Choose a campaigns file">
            {campaignIsLoading ? (
              <Loading />
            ) : (
              <>
                <ControlledRadioGroup name="type" control={importControl} options={ImportCampaignTypesOption} />
                <ControlledFile name="file" control={importControl} />
              </>
            )}
          </FormItem>
        </Form>
      </Modal>
      <Modal
        isOpen={isCreativeImportOpen}
        title="Import Creatives"
        onConfirm={onCreativesFileUpload}
        disabled={IsImportCreativesConfirmDisabled}
        onClose={() => setIsCreativesImportOpen(false)}
      >
        <Form>
          <FormItem required label="Choose a segments update file">
            {creativesIsLoading ? <Loading /> : <ControlledFile name="file" control={importCreativesControl} />}
          </FormItem>
        </Form>
      </Modal>
      <Modal
        isOpen={isSegmentsImportOpen}
        title="Import Segments"
        onConfirm={onSegmentsFileUpload}
        disabled={IsImportSegmentsConfirmDisabled}
        onClose={() => setIsSegmentsImportOpen(false)}
      >
        <Form>
          <FormItem required label="Choose a segments update file">
            {segmentsIsLoading ? <Loading /> : <ControlledFile name="file" control={importSegmentsControl} />}
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
