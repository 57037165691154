import { DataTable } from 'src/components/DataTable';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { growthApi } from 'src/services';
import { formatDate } from 'src/utils';
import { PageTemplate } from 'src/components/Template';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { useDataTable, useRole } from 'src/hooks';
import { Role, User } from 'src/types';
import { useState } from 'react';
import { ArchiveNew } from 'src/components/Archive';
import { EditPlatformModal } from './EditPlatformModal';

const { usePlatformsQuery, useArchivePlatformMutation } = growthApi;

type PlatformsFormValues = {
  search?: string;
};

export const Platforms = () => {
  const { canCreateAgency } = useRole();
  const { data, error, isFetching, refetch } = usePlatformsQuery({});
  const { watch, control } = useForm<PlatformsFormValues>();
  const values = watch();
  const [archivePlatform, { isLoading: isArchiving }] = useArchivePlatformMutation();
  const onArchive = async (row: any) => {
    await archivePlatform({
      id: row.id,
    });
    refetch();
  };
  const { dataTableProps, selectedRow, setSelectedRow } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'platform_name', 'platform_key'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    columns: [
      { header: 'ID', accessor: 'id', sortable: true },
      { header: 'Name', accessor: 'platform_name', sortable: true },
      {
        header: 'Key',
        accessor: 'platform_key',
        sortable: true,
      },
      { header: 'Created At', accessor: 'created_at', sortable: true, render: formatDate },
      {
        header: '',
        accessor: '_action',
        width: '3rem',
        render: (_, row) => (
          <Flex gap="md">
            <Action
              onClick={() => {
                setSelectedRow(row);
                setIsEditOpen(true);
              }}
            >
              Edit
            </Action>
            <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
          </Flex>
        ),
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
    ],
  });
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <PageTemplate>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            DSPs
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        {canCreateAgency && (
          <Button
            width="auto"
            shadow
            onClick={() => {
              setSelectedRow(null);
              setIsEditOpen(true);
            }}
          >
            NEW DSP
          </Button>
        )}
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
      <EditPlatformModal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        platform={selectedRow}
        onSuccess={refetch}
      />
    </PageTemplate>
  );
};
