import { useState } from 'react';
import { Flex, Text } from 'src/components/Layout';
import { useToast } from 'src/hooks';
import { getApiErrorMessage } from 'src/utils';
import { Modal } from '../Modal';

type ArchiveNewProps = {
  onArchive: () => Promise<void>;
  isArchiving: boolean;
};

export const ArchiveNew = (props: ArchiveNewProps) => {
  const { onArchive, isArchiving } = props;

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const onConfirm = async () => {
    try {
      await onArchive();
      showSuccessToast('Archive successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <>
      <Text size="xs" weight={600} color="error" clickable onClick={() => setIsConfirmOpen(true)}>
        Archive
      </Text>
      <Modal
        title="Confirmation"
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={onConfirm}
        isLoading={isArchiving}
      >
        <Flex justify="center">
          <Text size="md">Are you sure to archive?</Text>
        </Flex>
      </Modal>
    </>
  );
};
