import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useUpdateAdvertiserExtMutation } = growthApi;

type EditMarkupModalProps = {
  advertiser?: any;
  title?: string;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

type EditMarkupFormValues = {
  markup?: '' | number;
};

export const EditMarkupModal = (props: EditMarkupModalProps) => {
  const { advertiser, isOpen, title = 'Edit Markup', onSuccess, onClose } = props;

  const { handleSubmit, control, reset } = useForm<EditMarkupFormValues>();
  const [updateAdvertiserExt, { isLoading: isUpdateAdvertiserExtLoading }] = useUpdateAdvertiserExtMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      reset({
        markup: advertiser?.markup,
      });
    }
  }, [isOpen, reset, advertiser]);

  const onSubmit = async (values: EditMarkupFormValues) => {
    try {
      await updateAdvertiserExt({ markup: values.markup, advertiser_id: advertiser?.id }).unwrap();
      showSuccessToast('Save markup successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      isLoading={isUpdateAdvertiserExtLoading}
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Form>
        <FormItem label="Markup">
          <ControlledInput name="markup" control={control} placeholder="Enter markup" suffix="%" />
        </FormItem>
      </Form>
    </Modal>
  );
};
