import { useForm } from 'react-hook-form';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useSetCampaignGroupMutation } = growthApi;

type CampaignGroupModalProps = {
  agencyId?: number;
  advertiserId?: number;
  campaignIds?: any[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type CampaignGroupFormValues = {
  group: string;
};

export const CampaignGroupModal = (props: CampaignGroupModalProps) => {
  const { campaignIds, isOpen, onClose, onSuccess } = props;

  const { control, handleSubmit } = useForm<CampaignGroupFormValues>();
  const [setCampaignGroup, { isLoading }] = useSetCampaignGroupMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onSubmit = async (values: CampaignGroupFormValues) => {
    try {
      await setCampaignGroup({
        ...values,
        id: campaignIds,
      }).unwrap();
      showSuccessToast('Set campaign group successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Set Campaign Group"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Campaign Group" required>
          <ControlledInput name="group" control={control} placeholder="Enter campaign group name" />
        </FormItem>
      </Form>
    </Modal>
  );
};
