import { ReactNode } from 'react';
import styled from 'styled-components';
import { Flex } from '../Layout';
import { Required } from './Required';

type FormItemProps = {
  label?: string;
  addition?: ReactNode;
  required?: boolean;
  children?: ReactNode;
};

export const FormItem = (props: FormItemProps) => {
  const { label, addition, required, children } = props;

  return (
    <FormItemContainer>
      {label && (
        <Flex justify="space-between" align="center">
          <Flex gap="xs">
            <Label>{label}</Label>
            {required && <Required />}
          </Flex>
          {addition}
        </Flex>
      )}
      <div>{children}</div>
    </FormItemContainer>
  );
};

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  padding-left: 0.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  padding-bottom: 0.9rem;
`;
