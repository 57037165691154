import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DataTable, DataTableColumnCustomize, DataTableExport } from 'src/components/DataTable';
import { ControlledInput, ControlledSwitch, Tabs } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { UseDataTableOptions, useDataTable, useRole } from 'src/hooks';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { REPORTING_DEFAULT_CUSTOMIZE_COLUMNS, REPORTING_SORT_NUMBER_KEYS } from './constants';
import { getReportingMetricsColumns } from './utils';
import { keyBy } from 'lodash';

const { useGeoQuery } = growthApi;

enum ReportingGeoTab {
  Country = 'country',
  Region = 'region',
  PostCode = 'post_code',
}

type ReportingGeoFormValues = {
  search?: string;
  isWithMarkup?: boolean;
};

type ReportingGeoProps = ReportingFormValues;

export const ReportingGeo = (props: ReportingGeoProps) => {
  const {
    timeRange,
    dateFrom,
    dateTo,
    previousDateFrom,
    previousDateTo,
    agencyId,
    advertiserId,
    campaignGroup,
    campaignId,
  } = props;

  const { isAdmin, canWithMarkup } = useRole();
  const { watch, control } = useForm<ReportingGeoFormValues>({
    defaultValues: {
      isWithMarkup: true,
    },
  });
  const values = watch();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;
  const isShowPrevious = Boolean(previousDateFrom && previousDateTo);
  const [tab, setTab] = useState<ReportingGeoTab>(ReportingGeoTab.Country);

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  };

  const {
    data: countryData,
    isFetching: countryIsFetching,
    error: countryError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'country',
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.Country },
  );

  const {
    data: previousCountryData,
    isFetching: previousCountryIsFetching,
    error: previousCountryError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'country',
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.Country || !isShowPrevious },
  );
  const perviousByCountry = useMemo(() => {
    return keyBy(previousCountryData?.data, 'country');
  }, [previousCountryData?.data]);

  const {
    data: regionData,
    isFetching: regionIsFetching,
    error: regionError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'region',
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.Region },
  );

  const {
    data: previousRegionData,
    isFetching: previousRegionIsFetching,
    error: previousRegionError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'region',
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.Region || !isShowPrevious },
  );
  const perviousByRegion = useMemo(() => {
    return keyBy(previousRegionData?.data, (item) => `${item.country}@${item.region}`);
  }, [previousRegionData?.data]);

  const {
    data: postCodeData,
    isFetching: postCodeIsFetching,
    error: postCodeError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'zip_code',
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.PostCode },
  );

  const {
    data: previousPostCodeData,
    isFetching: previousPostCodeIsFetching,
    error: previousPostCodeError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'zip_code',
      time_range: 'custom',
      date_from: previousDateFrom ? format(previousDateFrom, 'yyyy-MM-dd') : undefined,
      date_to: previousDateTo ? format(previousDateTo, 'yyyy-MM-dd') : undefined,
    },
    { skip: isNotSelectAgencyAdvertiser || tab !== ReportingGeoTab.PostCode || !isShowPrevious },
  );
  const perviousByPostCode = useMemo(() => {
    return keyBy(previousPostCodeData?.data, (item) => `${item.country}@${item.zip_code}`);
  }, [previousPostCodeData?.data]);

  const getUseDataTableOptions = () => {
    const options: UseDataTableOptions = {
      name: 'reporting-geo',
      search: values.search,
      defaultSort: {
        key: 'impressions',
        direction: 'desc',
      },
      sortNumberKeys: REPORTING_SORT_NUMBER_KEYS,
      defaultCustomizeColumns: REPORTING_DEFAULT_CUSTOMIZE_COLUMNS,
      enableTotal: true,
    };
    switch (tab) {
      case ReportingGeoTab.Country:
        options.data = countryData?.data;
        options.isLoading = countryIsFetching || (isShowPrevious && previousCountryIsFetching);
        options.error = countryError || (isShowPrevious && previousCountryError);
        options.searchKeys = ['country'];
        options.columns = [
          {
            header: 'Country',
            accessor: 'country',
            sortable: true,
            totalRender: () => {
              return 'Total';
            },
          },
          ...getReportingMetricsColumns({
            isWithMarkup: values.isWithMarkup,
            isAdmin,
            isShowPrevious,
            getPreviousData: (row) => perviousByCountry[row.country],
          }),
        ];
        break;
      case ReportingGeoTab.Region:
        options.data = regionData?.data;
        options.isLoading = regionIsFetching || (isShowPrevious && previousRegionIsFetching);
        options.error = regionError || (isShowPrevious && previousRegionError);
        options.searchKeys = ['country', 'region'];
        options.columns = [
          {
            header: 'Country',
            accessor: 'country',
            sortable: true,
            totalRender: () => {
              return 'Total';
            },
          },
          {
            header: 'Region',
            accessor: 'region',
            sortable: true,
          },
          ...getReportingMetricsColumns({
            isWithMarkup: values.isWithMarkup,
            isAdmin,
            isShowPrevious,
            getPreviousData: (row) => perviousByRegion[`${row.country}@${row.region}`],
          }),
        ];
        break;
      case ReportingGeoTab.PostCode:
        options.data = postCodeData?.data;
        options.isLoading = postCodeIsFetching || (isShowPrevious && previousPostCodeIsFetching);
        options.error = postCodeError || (isShowPrevious && previousPostCodeError);
        options.searchKeys = ['country', 'zip_code'];
        options.columns = [
          {
            header: 'Country',
            accessor: 'country',
            sortable: true,
            totalRender: () => {
              return 'Total';
            },
          },
          {
            header: 'Post code',
            accessor: 'zip_code',
            sortable: true,
          },
          ...getReportingMetricsColumns({
            isWithMarkup: values.isWithMarkup,
            isAdmin,
            isShowPrevious,
            getPreviousData: (row) => perviousByPostCode[`${row.country}@${row.zip_code}`],
          }),
        ];
        break;
    }
    return options;
  };

  const { dataTableProps, dataTableExportProps, dataTableCustomizeColumnsProps } = useDataTable(
    getUseDataTableOptions(),
  );

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <Tabs
        value={tab}
        tabs={[
          { label: 'Country', value: ReportingGeoTab.Country },
          { label: 'Region', value: ReportingGeoTab.Region },
          { label: 'Post code', value: ReportingGeoTab.PostCode },
        ]}
        onChange={setTab}
      />
      <Spacing />
      <TableContainer>
        <Flex gap="lg" align="center" justify="space-between" width="100%">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" width="30rem" />
          <Flex gap="lg" align="center">
            {canWithMarkup && (
              <FlexItem shrink={0}>
                <Flex gap="md" align="center">
                  <Text size="xs">With markup</Text>
                  <ControlledSwitch name="isWithMarkup" control={control} />
                </Flex>
              </FlexItem>
            )}
            <DataTableColumnCustomize {...dataTableCustomizeColumnsProps} />
            <DataTableExport {...dataTableExportProps} />
          </Flex>
        </Flex>
        <Spacing size="lg" />
        <DataTable {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
