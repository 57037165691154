import { TOKENS } from 'src/design';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${TOKENS.fontFamily.montserrat};
    font-size: ${TOKENS.responsive.desktop};
  }

  body {
    margin: 0;
    font-size: ${TOKENS.fontSize.md};
    min-width: ${TOKENS.responsive.width};
    color: ${TOKENS.color.text};
  }

  a {
    text-decoration: none;
    color: unset;
  }

  // fix date picker cover issue
  .react-datepicker-popper {
    z-index:  200;
  }

  // fix date picker size issue
  .react-datepicker {
    zoom: 150%;

    .react-datepicker__time-list-item  {
      height: 12px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .react-datepicker__navigation {
      zoom: 75%;
    }
  }

  // avoid apex charts toolbar covered data picker
  .apexcharts-toolbar {
    z-index: unset;
  }

  // remove -/+ in number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // set the sweetalert style
  .swal-modal {
    border-radius: 1rem;
  }

  .swal-button {
    padding: 0 2.4rem;
    text-align: center;
    font-weight: 700;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 10rem;
    height: 4rem;
  }

  .swal-footer {
    display: flex;
    justify-content: center;
  }

  .swal-button--cancel {
    color: ${TOKENS.color.primary};
    border: solid 0.1rem ${TOKENS.color.primary};
    background: transparent;
  }

  .swal-button--confirm {
    color: white;
    background-color: ${TOKENS.color.primary};
  }

  .swal-button--confirm:not([disabled]):hover {
    color: white;
    background-color: ${TOKENS.color.primary};
  }

  .swal-button:focus {
    box-shadow: none;
  }

  .swal-button--cancel:not([disabled]):hover {
    color: ${TOKENS.color.primary};
    border: solid 0.1rem ${TOKENS.color.primary};
    background: transparent;
  }
`;
