import { format } from 'date-fns';
import { UsePersistentFiltersOptions, usePersistentFilters } from './usePersistentFilters';
import { DateRange, parseDateRange } from 'src/utils';

const DEFAULT_DATE_RANGE = DateRange.Last7Days;

type UseReportingPersistentFiltersProps = Pick<UsePersistentFiltersOptions, 'values' | 'setValues'>;

export const useReportingPersistentFilters = (props: UseReportingPersistentFiltersProps) => {
  const { values, setValues } = props;

  usePersistentFilters({
    filters: ['agencyId', 'advertiserId', 'campaignGroup', 'campaignId', 'timeRange', 'customDateFrom', 'customDateTo'],
    values,
    setValues,
    persistentFn: (filters) => {
      const persistentFilters = {
        ...filters,
        customDateFrom: filters.customDateFrom ? format(values.customDateFrom, 'yyyy/MM/dd') : null,
        customDateTo: filters.customDateTo ? format(values.customDateTo, 'yyyy/MM/dd') : null,
      };
      return persistentFilters;
    },
    recoverFn: (filters) => {
      const timeRange = filters.timeRange || DEFAULT_DATE_RANGE;
      const customDateFrom = filters.customDateFrom ? new Date(filters.customDateFrom) : null;
      const customDateTo = filters.customDateTo ? new Date(filters.customDateTo) : null;
      const recoveredFilters = {
        ...filters,
        uiTimeRange: timeRange,
        ...parseDateRange(timeRange),
        customDateFrom,
        customDateTo,
        ...(timeRange === DateRange.Custom
          ? {
              dateFrom: customDateFrom,
              dateTo: customDateTo,
            }
          : {}),
      };
      return recoveredFilters;
    },
  });
};
