import { CHART_COLORS, TOKENS } from 'src/design';
import styled from 'styled-components';
import { Text, Spacing, Flex } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import ReactApexChart from 'react-apexcharts';
import { OverviewFormValues } from './Overview';
import { growthApi } from 'src/services';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { orderBy, take } from 'lodash';
import { ApexOptions } from 'apexcharts';
import { Link } from 'react-router-dom';
import { useWhiteLabelTheme } from 'src/hooks';

const { useSiteTransparencyQuery } = growthApi;

type ChartSummaryProps = OverviewFormValues;

const METRIC = 'impressions';
const MAX_COUNT = 10;

export const ChartSites = (props: ChartSummaryProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignGroup, campaignId } = props;

  const theme = useWhiteLabelTheme();
  const { data, isFetching } = useSiteTransparencyQuery({
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
  });

  const chartData = useMemo(() => {
    let xaxisCategories: any[] = [];
    let seriesData: any[] = [];

    if (data?.data?.length) {
      const orderedData = orderBy(data.data, (row) => Number(row[METRIC] ?? 0), 'desc');
      const resultData = take(orderedData, MAX_COUNT);
      xaxisCategories = resultData.map((row) => row.site_domain);
      seriesData = resultData.map((row) => row[METRIC] ?? 0);
    }

    return { xaxisCategories, seriesData };
  }, [data]);

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: chartData.xaxisCategories,
    },
    stroke: {
      curve: 'straight',
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: METRIC.toUpperCase(),
      data: chartData.seriesData,
    },
  ];

  return (
    <Container>
      <Flex justify="space-between">
        <Text size="md" weight={700}>
          Top Sites
        </Text>
        <Link to="/activate/reporting?type=sites">
          <Text size="sm" weight={600} color="primary">
            {'Sites report >>'}
          </Text>
        </Link>
      </Flex>
      <Spacing size="lg" />
      {isFetching ? (
        <Loading height="25rem" />
      ) : (
        <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={250} />
      )}
    </Container>
  );
};

const Container = styled.div`
  background: white;
  padding: 1.6rem 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
  height: 32rem;
`;
