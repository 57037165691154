import { Icon } from 'src/components/Icon';
import { Flex, Text } from 'src/components/Layout';

type LoadingProps = {
  height?: string;
};

export const Loading = (props: LoadingProps) => {
  const { height } = props;

  return (
    <Flex direction="column" justify="center" align="center" gap="sm" height={height}>
      <Icon size="4rem" type="loading" color="primary" />
      <Text size="xs">Loading...</Text>
    </Flex>
  );
};
