import { Button } from '../Form';
import { Flex, Spacing, Text } from '../Layout';
import { useForm } from 'react-hook-form';
import { InsightFilter } from './InsightFilter';
import { useEffect, useState } from 'react';
import { growthApi } from 'src/services';
import { InsightData } from './InsightData';

const { useCustomAudiencesQuery } = growthApi;

type InsightVisualizeFormValues = {
  filter: any;
};

type InsightVisualizeProps = {
  insightKey?: string;
  defaultFilter?: any;
  onFilterChange?: (filter: any) => void;
};

export const InsightVisualize = (props: InsightVisualizeProps) => {
  const { insightKey, defaultFilter, onFilterChange } = props;

  const [filter, setFilter] = useState();
  const { watch, control, setValue } = useForm<InsightVisualizeFormValues>();
  const values = watch();
  const { data, isFetching, error } = useCustomAudiencesQuery(
    {
      key: insightKey,
      filter: filter ? JSON.stringify(filter) : undefined,
    },
    {
      skip: !insightKey,
    },
  );

  useEffect(() => {
    for (const key in defaultFilter) {
      setValue(`filter.${key}`, defaultFilter[key]);
    }
    setFilter(defaultFilter);
  }, [defaultFilter, setValue]);

  return (
    <>
      <Flex justify="space-between" align="center">
        <Text size="xl" weight={600}>
          Filters
        </Text>
        <Button
          width="12rem"
          onClick={() => {
            setFilter(values.filter);
            onFilterChange?.(values.filter);
          }}
        >
          APPLY
        </Button>
      </Flex>
      <Spacing size="md" />
      <InsightFilter name="filter" control={control} />
      <Spacing size="xl" />
      <Text size="xl" weight={600}>
        Visualize
      </Text>
      <Spacing size="xl" />
      <InsightData data={data?.data} isLoading={isFetching} error={error} />
    </>
  );
};
