import { LoadingTemplate } from 'src/components/Template';
import { useEffectOnce, useToast } from 'src/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useAppDispatch, userSlice } from 'src/store';
import { getApiErrorMessage } from 'src/utils';

const { useV1LoginMutation } = growthApi;

export const Login = () => {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const next = searchParams.get('next');
  const [v1Login] = useV1LoginMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  useEffectOnce(() => {
    (async () => {
      try {
        const apiResult = await v1Login({
          accessToken,
        }).unwrap();
        dispatch(userSlice.actions.login(apiResult.data!));
        navigate(next ?? '/activate/overview');
      } catch (error) {
        showErrorToast(getApiErrorMessage(error));
      }
    })();
  });

  return <LoadingTemplate message="Login" />;
};
