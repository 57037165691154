import { useController, UseControllerProps } from 'react-hook-form';
import { Switch } from './Switch';

export const ControlledSwitch = <T,>(props: UseControllerProps<T>) => {
  const {
    field: { onChange, value },
  } = useController(props);

  return <Switch value={Boolean(value)} onChange={onChange} />;
};
