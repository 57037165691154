import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ControlledInput, Form, FormItem } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PasswordFormItems, passwordIsSafe } from 'src/components/PasswordFormItems';
import { PageTemplate } from 'src/components/Template';
import { TOKENS } from 'src/design';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import styled, { useTheme } from 'styled-components';

const { useGetTeamInviteQuery, useAcceptTeamInviteMutation } = growthApi;

type TeamInviteFormValues = {
  email: string;
  code: string;
  password?: string;
  confirmPassword?: string;
};

export const TeamInvite = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const theme = useTheme();
  const { data, isLoading } = useGetTeamInviteQuery({
    code,
  });
  const teamInvite = data?.data;
  const { watch, control, setValue, handleSubmit } = useForm<TeamInviteFormValues>({
    defaultValues: {
      code,
    },
  });
  const values = watch();
  const isDisable =
    teamInvite?.password_required &&
    (!values.password || !passwordIsSafe(values.password) || values.password !== values.confirmPassword);
  const [acceptTeamInvite, { isLoading: isSubmitting }] = useAcceptTeamInviteMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const nav = useNavigate();

  useEffect(() => {
    if (teamInvite) {
      setValue('email', teamInvite?.email);
    }
  }, [setValue, teamInvite]);

  const onSubmit = async (values: TeamInviteFormValues) => {
    try {
      await acceptTeamInvite(values).unwrap();
      showSuccessToast('Team invite accept successfully');
      nav('/login');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading} withoutSidebar>
      <Container>
        <LogoContainer>
          <img src={theme.logo} alt="logo" />
        </LogoContainer>
        <Spacing />
        <BindingCreditCardForm>
          <Text size="lg" weight={600}>
            Team Invite
          </Text>
          <Spacing size="sm" />
          <Text size="sm" color="gray">
            <Text color="primary" as="span">
              {teamInvite?.from}
            </Text>{' '}
            invites you to join their team.
          </Text>
          <Spacing size="xxl" />
          <Form>
            <FormItem label="Your Email" required>
              <ControlledInput name="email" control={control} placeholder="Enter email" disabled />
            </FormItem>
            {teamInvite?.password_required && (
              <FormItem label="Setup Your Password" required>
                <PasswordFormItems control={control} />
              </FormItem>
            )}
            <Spacing />
            <Flex justify="center">
              <Button
                width="40rem"
                shadow
                disabled={isDisable}
                isLoading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                Accept Invite
              </Button>
            </Flex>
          </Form>
        </BindingCreditCardForm>
      </Container>
    </PageTemplate>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  background: ${TOKENS.color.bgGray};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem 0;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 9rem;
    height: 9rem;
  }
`;

const BindingCreditCardForm = styled.div`
  width: 50%;
  box-sizing: border-box;
  background: white;
  border-radius: 1rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  padding: 2.4rem 3.2rem 3.2rem;
  border-top: solid 1rem ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
