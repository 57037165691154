import { TOKENS } from 'src/design';
import styled from 'styled-components';

type InfoProps = {
  title: string;
  message: string;
  fullHeight?: boolean;
};

export const Info = (props: InfoProps) => {
  const { title, message, fullHeight } = props;

  return (
    <InfoContainer fullHeight={fullHeight}>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </InfoContainer>
  );
};

export const InfoContainer = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  background: ${TOKENS.color.bgGray};
  height: ${(props) => (props.fullHeight ? '100vh' : '100%')};
`;

export const Title = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
`;

export const Message = styled.div`
  font-size: 1.4rem;
`;
