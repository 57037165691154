import { Button, Input } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { getApiErrorMessage } from 'src/utils';

const { useForgetPasswordMutation } = growthApi;

type ForgetPasswordFormValues = {
  email: string;
};

export const ForgetPassword = () => {
  const [forgetPassword] = useForgetPasswordMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<ForgetPasswordFormValues>();

  const values = watch();
  const isDisabled = !values.email;

  const onSubmit = async (values: ForgetPasswordFormValues) => {
    try {
      const apiResult = await forgetPassword(values).unwrap();
      setValue('email', '');
      showSuccessToast(apiResult.message);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Forgot Password">
      <Description>Enter your email and we'll send the link on how to reset your password</Description>
      <Spacing size="3rem" />
      <Input {...register('email')} placeholder="Enter mail" icon="email" />
      <Spacing size="3rem" />
      <Button disabled={isDisabled} isLoading={isSubmitting} onClick={handleSubmit(onSubmit)}>
        Send to email
      </Button>
      <Spacing size="2.4rem" />
      <Login to="/login">Login</Login>
    </AuthTemplate>
  );
};

const Description = styled.div`
  color: ${(props) => props.theme.color.grayLight};
  font-size: 1.2rem;
`;

const Login = styled(Link)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.primary};
  border-bottom: solid 0.2rem ${(props) => props.theme.color.primary};
  text-decoration: none;
`;
