import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { useRole, useToast } from 'src/hooks';
import { getApiErrorMessage } from 'src/utils';
import { ACCESS_DEFAULT_FORM_VALUES, AccessFormItem, AccessFormValues } from './AccessFormItem';
import { isEmpty } from 'lodash';

const { useSendTeamInviteMutation } = growthApi;

export type TeamInviteType = 'same' | 'agency' | 'advertiser';

type TeamInviteFormValues = {
  email: string;
} & AccessFormValues;

type TeamInviteModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TeamInviteModal = (props: TeamInviteModalProps) => {
  const { isOpen, onClose } = props;

  const { isAdvertiser } = useRole();
  const { watch, control, handleSubmit, setValue } = useForm<TeamInviteFormValues>({
    defaultValues: {
      ...ACCESS_DEFAULT_FORM_VALUES,
    },
  });
  const values = watch();
  const [sendTeamInvite, { isLoading }] = useSendTeamInviteMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const validate = (values: TeamInviteFormValues) => {
    const errors: string[] = [];
    if (!values.email) {
      errors.push('Email is required');
    }
    if (!isAdvertiser && !values.agency_id) {
      errors.push('Agency is required');
    }
    return errors;
  };

  const onConfirm = async (values: TeamInviteFormValues) => {
    try {
      const errors = validate(values);
      if (!isEmpty(errors)) {
        showErrorToast(errors);
        return;
      }
      await sendTeamInvite(values).unwrap();
      showSuccessToast('Team invite sent');
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Team Invite"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onConfirm)}
      confirmText="INVITE"
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Email" required>
          <ControlledInput name="email" control={control} placeholder="Enter email" />
        </FormItem>
        <AccessFormItem control={control} values={values} setValue={setValue} />
      </Form>
    </Modal>
  );
};
