import { DefaultTheme } from 'styled-components';

export const CHART_COLORS = (theme: DefaultTheme) => [
  theme.color.primary,
  theme.color.highlight,
  '#008ffb',
  '#ff4561',
  '#775dd0',
  '#FF4500',
];
