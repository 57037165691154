import swal from "sweetalert"
import { SwalOptions } from "sweetalert/typings/modules/options";

export const useConfirm = () => {

  return (params: Partial<SwalOptions>) => {
    return new Promise((resolve) => {
      swal({
        title: 'Are you sure?',
        text: params.title,
        buttons: {
          cancel: true,
          confirm: true,
        }
      }).then(
        (isConfrim) => { resolve(Boolean(isConfrim)) },
        () => resolve(false)
      );
    });
  }
}