import { Icon } from 'src/components/Icon';
import { Flex, Text } from 'src/components/Layout';

type ErrorProps = {
  error: string | string[];
  height?: string;
};

export const Error = (props: ErrorProps) => {
  const { error, height } = props;

  return (
    <Flex direction="column" justify="center" align="center" gap="sm" height={height}>
      <Icon size="4rem" type="error" color="error" />
      <Text size="xs">{Array.isArray(error) ? error.join(',') : error}</Text>
    </Flex>
  );
};
