import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreativeType } from 'src/types';

const { useCreativesQuery } = growthApi;

type CreativeSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  types?: CreativeType[];
  withAll?: boolean;
  newState?: any;
};

export const CreativeSelect = <T,>(props: CreativeSelectProps<T>) => {
  const { agencyId, advertiserId, types, withAll, newState, ...rest } = props;

  const { data, isFetching } = useCreativesQuery({
    agency_id: agencyId,
    advertiser_id: advertiserId,
    types,
  });
  const navigate = useNavigate();

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data?.map((creative: any) => ({
        label: `${creative.creative_name} - ${creative.id}`,
        value: Number(creative.id),
      })) || [];
    return withAll ? [{ label: 'All', value: undefined }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  return (
    <ControlledSelect
      options={options}
      isLoading={isFetching}
      placeholder="Select creative"
      newLabel="New Creative"
      onNew={() => navigate('/activate/creatives/new', { state: newState })}
      {...rest}
    />
  );
};
