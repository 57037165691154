import { TOKENS } from 'src/design';
import styled from 'styled-components';
import { Flex, Text } from '../Layout';

type StepsProps = {
  value: number;
  steps: string[];
};

export const Steps = (props: StepsProps) => {
  const { value, steps } = props;

  return (
    <Flex gap="4rem">
      {steps.map((step, index) => {
        const active = value >= index + 1;
        return (
          <StepItem key={step}>
            <Flex direction="column" align="center" gap="md">
              <StepNumber $active={active}>{index + 1}</StepNumber>
              <Text size="sm" color={active ? 'primary' : '#E4E4E4'} weight={600}>
                {step}
              </Text>
            </Flex>
            {index > 0 && <StepLine index={index} $active={active} />}
          </StepItem>
        );
      })}
    </Flex>
  );
};

const StepItem = styled.div`
  position: relative;
  width: 9rem;
  text-align: center;
`;

const StepNumber = styled.div<{ $active: boolean }>`
  width: 3.6rem;
  height: 3.6rem;
  text-align: center;
  line-height: 3.6rem;
  font-size: ${TOKENS.fontSize.lg};
  font-weight: bold;
  color: ${(props) => (props.$active ? props.theme.color.primary : '#E4E4E4')};
  border: solid 0.1rem ${(props) => (props.$active ? props.theme.color.primary : '#E4E4E4')};
  border-radius: 50%;
  z-index: 200;
  background: white;
`;

const StepLine = styled.div<{ index: number; $active: boolean }>`
  position: absolute;
  height: 0.2rem;
  top: 1.8rem;
  left: -8rem;
  width: 12rem;
  z-index: 100;
  background: ${(props) => (props.$active ? props.theme.color.primary : '#E4E4E4')};
`;
