import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/types';
import * as Sentry from '@sentry/react';

export type UserState = {
  user?: User;
  accessToken?: string;
  whiteLabel?: any;
  agencyId?: number;
  advertiserId?: number;
  marketPlanId?: number;
};

const LOCAL_STORAGE_KEY = 'user';
const LOCAL_STORAGE_VALUE = localStorage.getItem(LOCAL_STORAGE_KEY);

const INITIAL_STATE: UserState = (LOCAL_STORAGE_VALUE ? JSON.parse(LOCAL_STORAGE_VALUE) : {}) as UserState;

const persistUser = (user: any) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      Sentry.setUser({ email: action.payload.user?.email });
      persistUser(state);
    },
    logout: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
      state.agencyId = null;
      state.advertiserId = null;
      Sentry.setUser(null);
      localStorage.clear();
      persistUser(state);
    },
    updateUser: (state, action: PayloadAction<UserState['user']>) => {
      state.user = action.payload;
      persistUser(state);
    },
    setWhiteLabel: (state, action: PayloadAction<UserState['whiteLabel']>) => {
      state.whiteLabel = action.payload;
      persistUser(state);
    },
    setAgencyAdvertiser: (state, action: PayloadAction<Pick<UserState, 'agencyId' | 'advertiserId'>>) => {
      state.agencyId = action.payload.agencyId;
      state.advertiserId = action.payload.advertiserId;
      persistUser(state);
    },
    setMarketPlan: (state, action: PayloadAction<Pick<UserState, 'marketPlanId'>>) => {
      state.marketPlanId = action.payload.marketPlanId;
      persistUser(state);
    },
  },
});
