import { differenceInDays, endOfDay, format, max, minTime, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty, keyBy, round } from 'lodash';
import { DSP_OPTIONS } from 'src/pages/Admin/Advertisers/constants';
import { CampaignOngoingInterval } from 'src/types';

export const formatBoolean = (value: any) => {
  return value ? 'Yes' : 'No';
};

export const formatDate = (value: any) => {
  if (!value || value === '-' || value === '0000-00-00 00:00:00') {
    return '-';
  }
  return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
};

export const formatDateWithTimezone = (value: any) => {
  if (!value || value === '-' || value === '0000-00-00 00:00:00') {
    return '-';
  }
  return formatInTimeZone(new Date(value), 'America/Los_Angeles', 'yyyy-MM-dd HH:mm:ss');
};

export const formatOnlyDate = (value: any) => {
  if (!value || value === '-' || value === '0000-00-00 00:00:00') {
    return '-';
  }
  return format(new Date(value), 'yyyy-MM-dd');
};

export const formatCount = (value?: any) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US');
  }
};

export const formatAmount = (value?: any) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }
};

export const formatAmountWithPrecision = (value?: any, precision?: number) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision ?? 2,
    });
  }
};

export const formatPercentage = (value?: any) => {
  if (value === undefined || value === null || value === '-' || value === '') {
    return '-';
  } else {
    return Number(Number(value).toFixed(2)) + '%';
  }
};

export const formatPercentageWithPrecision = (value?: any, precision?: number) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(Number(value).toFixed(precision ?? 2)) + '%';
  }
};

export const formatText = (value?: any) => {
  if (!value) {
    return '';
  }
  return String(value)
    .replace(/<\/?[^>]+(>|$)/g, '')
    .trim();
};

export const formatArray = (value?: any) => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  } else if (typeof value === 'string') {
    // support string value, exp 1,2,3, and also check if item is number
    return value.split(',').map((item) => (/\d+/.test(item) ? Number(item) : item));
  } else if (typeof value === 'number') {
    // support number value, exp 1
    return [value];
  } else {
    return [];
  }
};

export const formatSize = (value?: any) => {
  if (!value) {
    return '-';
  }
  if (value > 1000 * 1000) {
    return `${round(value / 1000 / 1000)}M`;
  } else if (value > 1000) {
    return `${round(value / 1000)}K`;
  } else {
    return value;
  }
};

export const formatReportingId = (value?: any) => {
  const ids = [];
  for (const platform in value) {
    if (Array.isArray(value[platform])) {
      value[platform].forEach((id: any) => {
        ids.push(id);
      });
    } else if (['string', 'number'].includes(typeof value[platform])) {
      ids.push(value[platform]);
    }
  }
  return ids.join(', ');
};

export const formatDspIds = (dspIds?: { platform: string; platform_id: string }[]) => {
  if (isEmpty(dspIds)) {
    return '-';
  }
  const result: Record<string, string[]> = {};
  for (const dspId of dspIds) {
    if (!result[dspId.platform]) {
      result[dspId.platform] = [];
    }
    result[dspId.platform].push(dspId.platform_id);
  }
  const dspOptionByPlatform = keyBy(DSP_OPTIONS, 'value');
  return Object.entries(result)
    .map(([platform, platformIds]) => {
      const dspOption = dspOptionByPlatform[platform];
      return `${dspOption.label} (${platformIds.join(', ')})`;
    })
    .join(' ');
};

export const formatMarkups = (markups?: any) => {
  const now = new Date();
  let currentMarkup = '-';
  for (let markup of markups || []) {
    if (markup.start_date && now.valueOf() < new Date(markup.start_date).valueOf()) {
      continue;
    }
    if (markup.end_date && now.valueOf() > endOfDay(new Date(markup.end_date)).valueOf()) {
      continue;
    }
    currentMarkup = formatPercentage(markup.markup);
    break;
  }
  return currentMarkup;
};

export const calcEstBudget = (startDate?: Date, endDate?: Date, campaign?: any) => {
  if (!campaign) {
    return 0;
  }
  startDate = startOfDay(max([startDate ?? minTime, new Date(campaign.schedule_start_date)]));
  endDate = endOfDay(endDate ?? new Date());
  if (campaign.ongoing_enabled) {
    if (campaign.ongoing_interval === CampaignOngoingInterval.Flights) {
      let totalBudget = 0;
      for (const flight of campaign.ongoing_flights) {
        if (
          new Date(flight.start_date).valueOf() >= endDate.valueOf() ||
          new Date(flight.end_date).valueOf() <= startDate.valueOf()
        ) {
          continue;
        }
        const flightDailyBudget =
          Number(flight.budget) / (differenceInDays(new Date(flight.end_date), new Date(flight.start_date)) + 1);
        if (
          startDate.valueOf() >= new Date(flight.start_date).valueOf() &&
          endDate.valueOf() >= new Date(flight.end_date).valueOf()
        ) {
          totalBudget += flightDailyBudget * (differenceInDays(new Date(flight.end_date), startDate) + 1);
        } else if (
          startDate.valueOf() <= new Date(flight.start_date).valueOf() &&
          endDate.valueOf() >= new Date(flight.end_date).valueOf()
        ) {
          totalBudget += Number(flight.budget);
        } else if (
          startDate.valueOf() <= new Date(flight.start_date).valueOf() &&
          endDate.valueOf() <= new Date(flight.end_date).valueOf()
        ) {
          totalBudget += flightDailyBudget * (differenceInDays(endDate, new Date(flight.start_date)) + 1);
        } else if (
          startDate.valueOf() >= new Date(flight.start_date).valueOf() &&
          endDate.valueOf() <= new Date(flight.end_date).valueOf()
        ) {
          totalBudget += flightDailyBudget * (differenceInDays(endDate, startDate) + 1);
        }
      }
      return totalBudget;
    } else {
      const days = differenceInDays(endDate, startDate) + 1;
      if (campaign.ongoing_interval === CampaignOngoingInterval.Daily) {
        return campaign.ongoing_interval_budget * days;
      } else if (campaign.ongoing_interval === CampaignOngoingInterval.Weekly) {
        return (campaign.ongoing_interval_budget / 7) * days;
      } else if (campaign.ongoing_interval === CampaignOngoingInterval.Monthly) {
        return (campaign.ongoing_interval_budget / 30) * days;
      }
    }
  } else {
    return Number(campaign.budget_amount);
  }
};
