import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import {
  Action,
  FormAction,
  ControlledEditableText,
  ControlledEditableTextarea,
  ControlledEditableIcon,
  ControlledEditableList,
  ControlledEditableTag,
} from 'src/components/Form';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { TOKENS } from 'src/design';
import { useToast, useWorkspace } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmount, getApiErrorMessage, imageUrl } from 'src/utils';
import styled from 'styled-components';
import { PersonaPdf } from './PersonaPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

const { usePersonasQuery, useCreateOrUpdatePersonaMutation } = growthApi;

export const PersonaEdit = () => {
  const workspace = useWorkspace();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { data, isLoading } = usePersonasQuery({ workspace_id: workspace?.id, id });
  const [createOrUpdatePersona, { isLoading: isSubmitting }] = useCreateOrUpdatePersonaMutation();
  const { watch, control, reset, handleSubmit } = useForm();
  const values = watch();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    reset(data?.data?.data);
  }, [data, reset]);

  const onSubmit = async (values: any) => {
    try {
      await createOrUpdatePersona({
        id: id ? Number(id) : undefined,
        data: values,
      }).unwrap();
      showSuccessToast('Save persona successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Flex justify="space-between" align="center">
        <Text size="xxl" weight={700}>
          Persona Edit
        </Text>
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Flex gap="md" id="pdf">
        <UserInfo>
          <Spacing size="4rem" />
          <Flex direction="column" align="center">
            <Text size="lg" weight={700}>
              <ControlledEditableText name="Name" control={control} />
            </Text>
            <Spacing size="sm" />
            <Text size="sm" color="gray">
              <ControlledEditableText name="Job title" control={control} />
            </Text>
            <Spacing />
            <Avatar src={values?.['Avatar']} />
            <Spacing />
            <Text size="xs" weight={600}>
              <Flex gap="xs" align="center">
                <Text>Annual Salary:</Text>
                <ControlledEditableText name="Annual salary in USD" control={control} format={formatAmount} />
              </Flex>
            </Text>
            <Spacing size="sm" />
            <Text size="xs" weight={600}>
              <ControlledEditableText name="Location" control={control} />
            </Text>
            <Spacing size="4rem" />
            <Text size="lg" weight={600}>
              <ControlledEditableText name="Quote" control={control} format={(content) => `"${content}"`} />
            </Text>
          </Flex>
        </UserInfo>
        <FlexItem grow={1}>
          <Grid columns={2} gap="md">
            <Card>
              <Text size="lg" weight={700} color="primary">
                <ControlledEditableText name="Top 2 category identifiers.0" control={control} />
              </Text>
            </Card>
            <Card>
              <Text size="lg" weight={700} color="primary">
                <ControlledEditableText name="Top 2 category identifiers.1" control={control} />
              </Text>
            </Card>
          </Grid>
          <Spacing />
          <Grid columns={2} gap="md">
            <Card>
              <ControlledEditableTextarea title="Bio" name="Bio" control={control} />
            </Card>
            <div>
              <ControlledEditableTag title="Tags" name="Top 5 tags" control={control} />
              <Spacing />
              <Grid columns={2} gap="md">
                <div>
                  <ControlledEditableIcon
                    title="Technology"
                    icons={[
                      { value: 'mac', url: imageUrl('persona/mac.png') },
                      { value: 'windows', url: imageUrl('persona/windows.png') },
                      { value: 'iphone', url: imageUrl('persona/iphone.png') },
                      { value: 'android', url: imageUrl('persona/android.png') },
                    ]}
                    name="Device types"
                    control={control}
                  />
                </div>
                <div>
                  <ControlledEditableIcon
                    title="Social Networks"
                    icons={[
                      { value: 'facebook', url: imageUrl('persona/facebook.png') },
                      { value: 'youtube', url: imageUrl('persona/youtube.png') },
                      { value: 'whatsapp', url: imageUrl('persona/whatsapp.png') },
                      { value: 'instagram', url: imageUrl('persona/instagram.png') },
                      { value: 'tiktok', url: imageUrl('persona/tiktok.png') },
                      { value: 'snapchat', url: imageUrl('persona/snapchat.png') },
                      { value: 'pinterest', url: imageUrl('persona/pinterest.png') },
                      { value: 'reddit', url: imageUrl('persona/reddit.png') },
                      { value: 'linkedin', url: imageUrl('persona/linkedin.png') },
                      { value: 'twitter', url: imageUrl('persona/twitter.png') },
                    ]}
                    name="Social media channels"
                    control={control}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
          <Spacing />
          <Grid columns={3} gap="md">
            <Card>
              <ControlledEditableList title="Goals" name="Top 5 goals" control={control} />
            </Card>
            <Card>
              <ControlledEditableList title="Pain points" name="Top 5 pain points" control={control} />
            </Card>
            <Card>
              <ControlledEditableList title="Influence" name="Top 5 influence" control={control} />
            </Card>
          </Grid>
          <Spacing />
          <Grid columns={4} gap="md">
            <Card>
              <ControlledEditableList title="Decision criteria" name="Top 5 decision criteria" control={control} />
            </Card>
            <Card>
              <ControlledEditableList title="KPIs" name="Top 5 KPIs" control={control} />
            </Card>
            <Card>
              <ControlledEditableList title="Likes" name="Top 5 likes" control={control} />
            </Card>
            <Card>
              <ControlledEditableList title="Dislikes" name="Top 5 dislikes" control={control} />
            </Card>
          </Grid>
        </FlexItem>
      </Flex>
      <Flex gap="xxl" align="center">
        <FormAction onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} />
        <PDFDownloadLink document={<PersonaPdf persona={values} />} fileName={`Persona - ${values?.['Name']}.pdf`}>
          {() => <Action>Download as PDF</Action>}
        </PDFDownloadLink>
      </Flex>
    </PageTemplate>
  );
};

const Card = styled.div`
  padding: 2.4rem 3.2rem;
  box-shadow: ${TOKENS.shadow.default};
  background: white;
  border-radius: 0.4rem;
`;

const UserInfo = styled(Card)`
  flex-shrink: 0;
  width: 22rem;

  img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }
`;

const Avatar = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
`;
