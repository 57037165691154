export const DSP_OPTIONS = [
  { label: 'Adlib', value: 'adlib' },
  { label: 'Stackadapt', value: 'stackadapt' },
  { label: 'Beeswax', value: 'beeswax' },
  { label: 'IQM', value: 'iqm' },
  { label: 'OR-Adlib', value: 'oradlib' },
  { label: 'Criteo', value: 'criteo' },
  { label: 'Adlib2', value: 'adlib2' },
  { label: 'Basis', value: 'basis' },
  { label: 'Meta', value: 'meta' },
  { label: 'DV360', value: 'dv360' },
];
