import { TOKENS } from 'src/design';
import { forwardRef, HTMLProps } from 'react';
import styled from 'styled-components';

export type TextAreaProps = Omit<HTMLProps<HTMLTextAreaElement>, 'as' | 'ref' | 'width'> & {
  width?: string;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { width, className, ...textAreaProps } = props;

  return (
    <Container width={width} className={className}>
      <textarea className="data-hj-allow" ref={ref} {...textAreaProps} />
    </Container>
  );
});

const Container = styled.div<{ width?: string; border?: boolean }>`
  width: ${(props) => props.width ?? '100%'};
  padding: 1.2rem;
  box-sizing: border-box;
  background: white;
  display: flex;
  border-radius: 1rem;
  border: solid 0.1rem ${TOKENS.color.grayLighter};

  textarea {
    font-family: ${TOKENS.fontFamily.montserrat};
    flex-grow: 1;
    padding: 0 0.6rem;
    border: none;
    outline: none;
    max-width: 100%;

    ::placeholder {
      color: ${TOKENS.color.gray};
    }
  }
`;
