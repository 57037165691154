import { Icon } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import { useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

export type ControlledCheckboxGroupOption = {
  label: ReactNode;
  value: string | number;
};

const CONTROLLED_RADIO_GROUP_SIZE_CSS = {
  sm: css`
    font-size: 1.2rem;
  `,
  md: css`
    font-size: 1.4rem;
  `,
  lg: css`
    font-size: 1.6rem;
  `,
};

type ControlledCheckboxGroupProps<T> = UseControllerProps<T> & {
  options?: ControlledCheckboxGroupOption[];
  width?: string;
  size?: keyof typeof CONTROLLED_RADIO_GROUP_SIZE_CSS;
  vertical?: boolean;
};

export const ControlledCheckBoxGroup = <T,>(props: ControlledCheckboxGroupProps<T>) => {
  const { options, width, size, vertical, ...useControllerProps } = props;
  const {
    field: { onChange, value },
  } = useController(useControllerProps);

  const typedValue: any[] | undefined = value as any;

  const selectOption = (option: ControlledCheckboxGroupOption) => {
    if (typedValue?.includes(option.value)) {
      onChange(typedValue.filter((item) => item !== option.value));
    } else {
      onChange([...(typedValue || []), option.value]);
    }
  };

  return (
    <Container width={width} size={size} vertical={vertical}>
      {options?.map((option) => (
        <Option key={option.value} active={typedValue?.includes(option.value)} onClick={() => selectOption(option)}>
          <Icon type="checkbox" size={size || 'md'} />
          <div>{option.label}</div>
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div<Pick<ControlledCheckboxGroupProps<any>, 'width' | 'size' | 'vertical'>>`
  display: flex;
  gap: 1.6rem;
  padding: 0.6rem 0;
  width: ${(props) => props.width ?? '100%'};
  ${(props) => CONTROLLED_RADIO_GROUP_SIZE_CSS[props.size ?? 'md']}
  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
        `
      : ''}
`;

const Option = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;

  svg {
    color: ${(props) => (props.active ? TOKENS.color.primary : TOKENS.color.grayLighter)};
  }
`;
