import { upperFirst } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DataTableEmpty } from 'src/components/DataTable/DataTableEmpty';
import { EditTargetingRuleModal } from 'src/components/EditTargetingRuleModal';
import { Button } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Table, TABLE_MAX_COL_SPAN } from 'src/components/Table';
import { PageTemplate } from 'src/components/Template';
import { renderRuleValue } from 'src/helpers';
import { useRole, useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { useAppDispatch, userSlice } from 'src/store';
import { AudienceRule } from 'src/types';
import { getApiErrorMessage } from 'src/utils';
import { ApplyToActiveAudiencesModal } from './ApplyToActiveAudiencesModal';

const { useProfileQuery, useUpdateProfileMutation } = growthApi;

type ExclusionRulesFormValues = {
  dsp_exclusion_rules: AudienceRule[];
};

export const ExclusionRules = () => {
  const { isViewOnly } = useRole();
  const { data: profile, isLoading } = useProfileQuery();
  const [editTargetingRuleIsOpen, setEditTargetingRuleIsOpen] = useState<boolean>(false);
  const { watch, setValue, handleSubmit } = useForm<ExclusionRulesFormValues>({
    defaultValues: {
      dsp_exclusion_rules: [],
    },
  });
  const values = watch();
  const [editRuleIndex, setEditRuleIndex] = useState<number>();
  const [updateProfile, { isLoading: isUploadProfileLoading }] = useUpdateProfileMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const dispatch = useAppDispatch();
  const [applyToActiveAudiencesIsOpen, setApplyToActiveAudiencesIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (profile?.data) {
      setValue('dsp_exclusion_rules', profile.data.dsp_exclusion_rules || []);
    }
  }, [profile, setValue]);

  const onSubmit = async (values: ExclusionRulesFormValues) => {
    try {
      const { data } = await updateProfile({
        dsp_exclusion_rules: values.dsp_exclusion_rules.map((rule) => ({
          ...rule,
          rule_inclusion_type: 'exclude',
        })),
      }).unwrap();
      showSuccessToast('Save exclusion rules successfully');
      dispatch(userSlice.actions.updateUser(data));
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onEditTargetingRule = useCallback(
    (rule: AudienceRule) => {
      if (editRuleIndex !== undefined) {
        const copiedRules = [...(values.dsp_exclusion_rules || [])];
        copiedRules[editRuleIndex] = rule;
        setValue('dsp_exclusion_rules', copiedRules);
      } else {
        setValue('dsp_exclusion_rules', [...(values.dsp_exclusion_rules || []), rule]);
      }
      setEditTargetingRuleIsOpen(false);
    },
    [editRuleIndex, setValue, values.dsp_exclusion_rules],
  );

  const onRuleRemove = (ruleIndex: number) => {
    setValue(
      'dsp_exclusion_rules',
      values.dsp_exclusion_rules?.filter((_, index) => index !== ruleIndex),
    );
  };

  const onRuleEdit = (ruleIndex: number) => {
    setEditRuleIndex(ruleIndex);
    setEditTargetingRuleIsOpen(true);
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Flex justify="space-between">
        <Text size="xxl" weight={700}>
          Exclusion Rules
        </Text>
        {!isViewOnly && (
          <Button
            onClick={() => {
              setEditRuleIndex(undefined);
              setEditTargetingRuleIsOpen(true);
            }}
            width="16rem"
          >
            ADD RULE
          </Button>
        )}
      </Flex>
      <Spacing size="xxl" />
      <Table various="border" border={1} cellSpacing={0} cellPadding={0}>
        <tbody>
          <tr>
            <th colSpan={TABLE_MAX_COL_SPAN}>Audience Global Exclusion Rules</th>
          </tr>
          {values.dsp_exclusion_rules.length ? (
            values.dsp_exclusion_rules.map((rule, index) => (
              <tr key={index}>
                <td>{`${rule.rule_type_id_name} > ${rule.rule_type_name}`}</td>
                <td>{renderRuleValue(rule)}</td>
                <td>{upperFirst(rule.multi_value_grouping_type)}</td>
                <td>
                  <Flex gap="md">
                    <Text color="primary" weight={600} clickable onClick={() => onRuleEdit(index)}>
                      Edit
                    </Text>
                    <Text color="error" weight={600} clickable onClick={() => onRuleRemove(index)}>
                      Remove
                    </Text>
                  </Flex>
                </td>
              </tr>
            ))
          ) : (
            <DataTableEmpty message="No rules in place" />
          )}
        </tbody>
      </Table>
      <Spacing size="xl" />
      {!isViewOnly && (
        <Flex gap="lg">
          <Button width="18rem" shadow isLoading={isUploadProfileLoading} onClick={handleSubmit(onSubmit)}>
            SAVE CHANGES
          </Button>
          <Button various="secondary" width="26rem" onClick={() => setApplyToActiveAudiencesIsOpen(true)}>
            APPLY TO ACTIVE AUDIENCES
          </Button>
        </Flex>
      )}
      <EditTargetingRuleModal
        isOpen={editTargetingRuleIsOpen}
        rule={editRuleIndex !== undefined ? values.dsp_exclusion_rules?.[editRuleIndex] : undefined}
        onClose={() => {
          setEditTargetingRuleIsOpen(false);
        }}
        onSuccess={onEditTargetingRule}
        typeDisabled
        groupDisabled
      />
      <ApplyToActiveAudiencesModal
        isOpen={applyToActiveAudiencesIsOpen}
        exclusionRules={values.dsp_exclusion_rules}
        onClose={() => setApplyToActiveAudiencesIsOpen(false)}
      />
    </PageTemplate>
  );
};
