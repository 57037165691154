import { Button, ControlledCheckBoxGroup, ControlledInput, ControlledSelect } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PasswordFormItems, passwordIsSafe } from 'src/components/PasswordFormItems';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { getApiErrorMessage } from 'src/utils';
import { useState } from 'react';

const { useSignupMutation } = growthApi;

enum SignupStep {
  Account,
  AccountType,
}

type SignupFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  promoCode: string;
  accountType: string;
  companyName: string;
  website: string;
  monthlyAdSpend: string;
  terms: string[];
};

const MONTHLY_AD_SPEND_OPTIONS = [
  { label: 'Less than $10,000', value: 'Less than $10,000' },
  { label: '$10,000 - $50,000', value: '$10,000 - $50,000' },
  { label: '$50,000 - $100,000', value: '$50,000 - $100,000' },
  { label: '$100,000 - $500,000', value: '$100,000 - $500,000' },
  { label: 'More than $500,000', value: 'More than $500,000' },
];

const TERMS_CHECKBOX_OPTIONS = [
  {
    label: (
      <Text size="xs" color="gray">
        I accept the Growth Channel Activate DSP{' '}
        <a href="https://growthchannel.io/activate-dsp-terms" target="_blank" rel="noreferrer">
          <Text color="primary" weight={500} as="span">
            Terms
          </Text>
        </a>{' '}
        and{' '}
        <a href="https://growthchannel.io/activate-dsp-privacy" target="_blank" rel="noreferrer">
          <Text color="primary" weight={500} as="span">
            {' '}
            Privacy Policy
          </Text>
        </a>
      </Text>
    ),
    value: 'term1',
  },
];

export const Signup = () => {
  const [searchParams] = useSearchParams();
  const promoCode = searchParams.get('promo_code');
  const { handleSubmit, watch, control } = useForm<SignupFormValues>({
    defaultValues: {
      promoCode,
    },
  });
  const [signup, { isLoading }] = useSignupMutation();
  const [step, setStep] = useState<SignupStep>(SignupStep.Account);
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  const values = watch();
  const isStep1Disabled =
    !values.email || values.password !== values.confirmPassword || !passwordIsSafe(values.password);
  const isStep2Disabled =
    !values.accountType || !values.companyName || !values.website || !values.monthlyAdSpend || !values.terms?.length;

  const onSubmit = async (values: SignupFormValues) => {
    try {
      const apiResult = await signup(values).unwrap();
      showSuccessToast(apiResult.message);
      navigate('/login');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Create Account">
      {step === SignupStep.Account && (
        <>
          <ControlledInput name="email" control={control} placeholder="Enter email" icon="email" />
          <Spacing size="1.6rem" />
          <PasswordFormItems control={control} />
          <Spacing size="1.6rem" />
          <ControlledInput name="promoCode" control={control} placeholder="Promo code (optional)" />
          <Spacing size="1.2rem" />
          <Button onClick={() => setStep(SignupStep.AccountType)} disabled={isStep1Disabled}>
            Next Step
          </Button>
        </>
      )}
      {step === SignupStep.AccountType && (
        <>
          <Flex direction="column" gap="md" width="100%">
            <ControlledSelect
              name="accountType"
              control={control}
              options={[
                { label: 'Business', value: 'business' },
                { label: 'Agency', value: 'agency' },
              ]}
              placeholder="Account type"
            />
            <ControlledInput name="companyName" control={control} placeholder="Company name" />
            <ControlledInput name="website" control={control} placeholder="Website" />
            <ControlledSelect
              name="monthlyAdSpend"
              control={control}
              placeholder="Monthly ad spend"
              options={MONTHLY_AD_SPEND_OPTIONS}
            />
            <ControlledCheckBoxGroup name="terms" control={control} options={TERMS_CHECKBOX_OPTIONS} />
          </Flex>
          <Spacing size="1.2rem" />
          <Flex width="100%" gap="md">
            <Button various="secondary" onClick={() => setStep(SignupStep.Account)} disabled={isLoading}>
              Back
            </Button>
            <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isStep2Disabled}>
              Create Account
            </Button>
          </Flex>
        </>
      )}
      <LoginContainer>
        <div>Already have an account?</div>
        <Login to="/login">Login</Login>
      </LoginContainer>
    </AuthTemplate>
  );
};

const LoginContainer = styled.div`
  display: flex;
  gap: 0.6rem;
  margin-top: 1.2rem;
  font-size: 1.2rem;
`;

const Login = styled(Link)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.primary};
  border-bottom: solid 0.2rem ${(props) => props.theme.color.primary};
  text-decoration: none;
`;
