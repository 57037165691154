import { LoadingTemplate } from 'src/components/Template';
import { useToast, useEffectOnce } from 'src/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useConfirmMutation } = growthApi;

export const Confirm = () => {
  const [searchParams] = useSearchParams();
  const [confirm] = useConfirmMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  useEffectOnce(() => {
    (async () => {
      try {
        const apiResult = await confirm({
          email: searchParams.get('email') || '',
          confirmCode: searchParams.get('confirmCode') || '',
        }).unwrap();
        showSuccessToast(apiResult.message);
      } catch (error) {
        showErrorToast(getApiErrorMessage(error));
      } finally {
        navigate('/login');
      }
    })();
  });

  return <LoadingTemplate message="Confirming" />;
};
