import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';

const { useAudiencesDatasetQuery } = growthApi;

type GeoSelectProps<T> = ControlledSelectProps<T>;

export const GeoSelect = <T,>(props: GeoSelectProps<T>) => {
  const { data, isFetching } = useAudiencesDatasetQuery({
    dataset: 'rule_values',
    type: 8,
  });

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return (
      data?.data?.map((item: any) => ({
        label: item.country_name,
        value: item.country_name,
      })) || []
    );
  }, [isFetching, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select GEO" {...props} />;
};
