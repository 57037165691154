import { TOKENS } from 'src/design';
import styled, { css } from 'styled-components';

type SpacingProps = { horizontal?: boolean; size?: keyof typeof TOKENS.spacing | (string & {}) };

export const Spacing = styled.div<SpacingProps>`
  ${(props) =>
    props.horizontal
      ? css`
          height: 100%;
          width: ${TOKENS.spacing[(props.size ?? 'md') as keyof typeof TOKENS.spacing] ?? props.size};
        `
      : css`
          width: 100%;
          height: ${TOKENS.spacing[(props.size ?? 'md') as keyof typeof TOKENS.spacing] ?? props.size};
        `}
`;
