import styled from 'styled-components';
import { Modal } from '../Modal';
import { Flex, Text } from '../Layout';
import { CREATIVE_AUDIO_PREVIEW_REG, CREATIVE_IMAGE_PREVIEW_REG, CREATIVE_VIDEO_PREVIEW_REG } from './constants';

type CreativePreviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileUrl?: string;
};

export const CreativePreviewModal = (props: CreativePreviewModalProps) => {
  const { isOpen, onClose, fileUrl } = props;

  const onConfirm = () => {
    const tempEl = document.createElement('a');
    tempEl.href = fileUrl;
    document.body.appendChild(tempEl);
    tempEl.click();
    document.body.removeChild(tempEl);
  };

  return (
    <Modal
      title="Creative Preview"
      isOpen={isOpen}
      width="auto"
      onClose={onClose}
      cancelText="CLOSE"
      confirmText="DOWNLOAD"
      onConfirm={onConfirm}
    >
      <Flex justify="center">
        {CREATIVE_IMAGE_PREVIEW_REG.test(fileUrl) ? (
          <CreativeImagePreview src={fileUrl} />
        ) : CREATIVE_AUDIO_PREVIEW_REG.test(fileUrl) ? (
          <audio controls>
            <source src={fileUrl} />
          </audio>
        ) : CREATIVE_VIDEO_PREVIEW_REG.test(fileUrl) ? (
          <video width={700} height={450} controls>
            <source src={fileUrl} />
          </video>
        ) : (
          <Text color="gray">Creative file unable to preview</Text>
        )}
      </Flex>
    </Modal>
  );
};

const CreativeImagePreview = styled.img`
  max-width: 80vw;
  max-height: 80vh;
`;
