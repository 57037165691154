import { useAppDispatch } from "src/store";
import { notificationsSidebarSlice } from "src/store/notificationsSidebarSlice";


const useNotificationsToggle = () => {
  const dispatch = useAppDispatch();

  const toggleNotificationsShow = () => {
    dispatch(notificationsSidebarSlice.actions.toggleNotificationsShow());
  }
  return toggleNotificationsShow;
}

export default useNotificationsToggle;