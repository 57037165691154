import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormAction } from 'src/components/Form';
import { Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { CustomAudienceShare } from './CustomAudienceShare';
import { InsightVisualize } from 'src/components/Insight';

export const CustomAudienceView = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [filter, setFilter] = useState();

  return (
    <PageTemplate>
      <Text size="xxl" weight={700}>
        View Custom Audience
      </Text>
      <Spacing size="xl" />
      <InsightVisualize insightKey={key} onFilterChange={setFilter} />
      <FormAction submitText="SHARE" onSubmit={() => setIsShareOpen(true)} />
      <CustomAudienceShare
        customAudienceKey={key}
        filter={filter}
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
      />
    </PageTemplate>
  );
};
