import { useFormContext } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { ControlledInput, ControlledSelect, FormAction, Required } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { CampaignEditFormValues } from './CampaignEdit';
import { useRole, useToast } from 'src/hooks';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AudienceSelect } from 'src/components/AudienceSelect.tsx';
import { CreativeSelect } from 'src/components/CreativeSelect';
import styled from 'styled-components';
import { CAMPAIGN_CHANNEL_CREATIVE_TYPES_MAPPING, CAMPAIGN_CHANNEL_OPTIONS } from './constants';

type OrganizeCampaignProps = {
  enableDraft: boolean;
  onDraftSubmit: () => void;
  onNext: () => void;
};

export const OrganizeCampaign = (props: OrganizeCampaignProps) => {
  const { enableDraft, onDraftSubmit, onNext } = props;

  const { canAccessAgency } = useRole();
  const { watch, control, setValue, formState } = useFormContext<CampaignEditFormValues>();
  const values = watch();
  const isNew = !values.id;
  const { showErrorToast } = useToast();

  const validate = () => {
    const errors = [];
    if (!values.agency_id) {
      errors.push('Agency is required');
    }
    if (!values.advertiser_id) {
      errors.push('Advertiser is required');
    }
    if (!values.channel) {
      errors.push('Channel is required');
    }
    if (!values.campaign_name) {
      errors.push('Campaign name is required');
    }
    if (!values.audience_id) {
      errors.push('Audience is required');
    }
    if (!values.creative_ids || values.creative_ids.length === 0) {
      errors.push('Creatives is required');
    }
    if (errors.length > 0) {
      showErrorToast(errors);
      return false;
    }
    return true;
  };

  return (
    <Flex width="100%" minHeight="100%" justify="center" align="center" direction="column">
      <Text size="xxl" weight={700}>
        Organize your campaign
      </Text>
      <Spacing size="6rem" />
      <OrganizeFormContainer>
        {canAccessAgency && isNew && (
          <>
            <Flex>
              <Text size="sm" weight={600}>
                Agency
              </Text>
              <Required />
            </Flex>
            <AgencySelect name="agency_id" control={control} />
            <Flex>
              <Text size="sm" weight={600}>
                Advertiser
              </Text>
              <Required />
            </Flex>
            <AdvertiserSelect
              agencyId={values.agency_id}
              name="advertiser_id"
              control={control}
              allowNew
              onValueChange={() => {
                setValue('creative_ids', undefined);
              }}
            />
          </>
        )}
        <Flex>
          <Text size="sm" weight={600}>
            Channel
          </Text>
          <Required />
        </Flex>
        <ControlledSelect
          name="channel"
          control={control}
          placeholder="Select channel"
          options={CAMPAIGN_CHANNEL_OPTIONS}
          onValueChange={() => {
            setValue('creative_ids', []);
          }}
        />
        <Flex>
          <Text size="sm" weight={600}>
            Campaign name
          </Text>
          <Required />
        </Flex>
        <ControlledInput name="campaign_name" control={control} placeholder="Enter campaign name" />
        <Flex>
          <Text size="sm" weight={600}>
            Audience
          </Text>
          <Required />
        </Flex>
        <AudienceSelect
          name="audience_id"
          agencyId={values.agency_id}
          advertiserId={values.advertiser_id}
          control={control}
          allowNew
          newState={{ from: 'campaign', campaign: values }}
        />
        <Flex>
          <Text size="sm" weight={600}>
            Creatives
          </Text>
          <Required />
        </Flex>
        <CreativeSelect
          multiple
          agencyId={values.agency_id}
          advertiserId={values.advertiser_id}
          types={CAMPAIGN_CHANNEL_CREATIVE_TYPES_MAPPING[values.channel]}
          name="creative_ids"
          control={control}
          allowNew
          newState={{ from: 'campaign', campaign: values }}
        />
      </OrganizeFormContainer>
      <Spacing size="xxl" />
      <FormAction
        onSubmit={() => validate() && onNext()}
        submitText="PROCEED"
        enableDraft={enableDraft}
        isSubmitting={formState.isSubmitting}
        onDraftSubmit={onDraftSubmit}
      />
    </Flex>
  );
};

const OrganizeFormContainer = styled.div`
  width: 50rem;
  display: grid;
  grid-template-columns: 16rem 1fr;
  gap: 1.2rem 0;
  align-items: center;
`;
