import { useCallback } from 'react';
import { useAppSelector, useAppDispatch, userSlice } from 'src/store';
import { useRole } from './useRole';

export const useUserAgencyAdvertiser = () => {
  const { canAccessAgency, agencyId: roleAgencyId, advertiserId: roleAdvertiserId } = useRole();
  const { agencyId: userAgencyId, advertiserId: userAdvertiserId } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const changeAgencyAdvertiser = useCallback(
    (agencyId?: number, advertiserId?: number) => {
      if (canAccessAgency) {
        dispatch(userSlice.actions.setAgencyAdvertiser({ agencyId, advertiserId }));
      }
    },
    [canAccessAgency, dispatch],
  );

  return {
    agencyId: (canAccessAgency ? userAgencyId : roleAgencyId) || null,
    advertiserId: (canAccessAgency ? userAdvertiserId : roleAdvertiserId) || null,
    changeAgencyAdvertiser,
  };
};
