import { ControlledFile, ControlledInput, ControlledTextArea, Form, FormItem } from 'src/components/Form';
import { FormAction } from 'src/components/Form/FormAction';
import { Icon, IconProps } from 'src/components/Icon';
import { PageTemplate } from 'src/components/Template';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { usePersistentAgencyAdvertiser, useRole, useToast } from 'src/hooks';
import { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { dateToString, fileToBase64, getApiErrorMessage, isCustomDomain, stringToDate } from 'src/utils';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { AgencySelect } from 'src/components/AgencySelect';
import { cloneDeep, isArray, omit, sum } from 'lodash';
import { CreativeFile, CreativeType } from 'src/types';
import { CREATIVE_PREVIEW_REG } from 'src/constants';

const { useCreativesQuery, useCreateOrUpdateCreativeMutation } = growthApi;

enum CreativeStep {
  Type = 'type',
  Form = 'form',
}

const CREATIVE_STEP_TITLES: Record<CreativeStep, string> = {
  [CreativeStep.Type]: 'Create Creative',
  [CreativeStep.Form]: 'Ad Builder',
};

const CREATIVE_TYPES: { label: string; value: CreativeType; icon: IconProps['type'] }[] = [
  { label: 'Banner Ad', value: CreativeType.BannerAd, icon: 'image' },
  { label: 'Ad Tag', value: CreativeType.AdTag, icon: 'tag' },
  { label: 'HTML5 Ad', value: CreativeType.HTML5Ad, icon: 'html' },
  { label: 'Audio Ad', value: CreativeType.AudioAd, icon: 'voice' },
  { label: 'Video Ad', value: CreativeType.VideoAd, icon: 'video' },
  { label: 'Native Ad', value: CreativeType.NativeAd, icon: 'desktop' },
];

const CREATIVE_TYPE_TITLES: Record<CreativeType, string> = {
  [CreativeType.BannerAd]: 'Banner Ad',
  [CreativeType.AdTag]: 'Ad Tag',
  [CreativeType.HTML5Ad]: 'Html5 Ad',
  [CreativeType.AudioAd]: 'Audio Ad',
  [CreativeType.VideoAd]: 'Video Ad',
  [CreativeType.NativeAd]: 'Native Ad',
};

const CREATIVE_UPLOAD_NOTES: Record<CreativeType, ReactNode> = {
  [CreativeType.BannerAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.jpg, .png, or .gif only (500KB max)</Text>
      <Text size="xs">Multiple files can be uploaded in a .zip (4MB max)</Text>
      <Text size="xs">All animations must stop after 15 seconds</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Display Ad Dimensions
        </Text>
        : 300x250, 728x90, 160x600, 300x600, 970x250, 336x280, 468x60, 120x600, 180x150, 250x250, 234x60, 970x90,
        200x200, 300x1050
      </Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Mobile Ad Dimensions
        </Text>
        : 320x480, 320x50, 728x90, 300x250, 360x640, 300x50, 216x36, 120x20, 168x28, 480x320, 768x1024, 120x240
      </Text>
      <Text size="xs">
        <Text weight={600} as="span">
          DOOH Ad Dimensions
        </Text>
        : 1400x400, 840x400, 1200x400, 1920x1080, 1080x1920, 2160x3840, 1024x768, 1280x720, 2048x768, 1080x1440,
        1920x960, 2560x720
      </Text>
    </Flex>
  ),
  [CreativeType.AdTag]: null,
  [CreativeType.HTML5Ad]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.zip files only (500KB max)</Text>
      {!isCustomDomain() && (
        <a
          target="_blank"
          href="https://growthchannel.io/help/article/what-are-the-compatible-html5-assets"
          rel="noreferrer"
        >
          <Text as="span" size="xs" color="primary">
            View Complete HTML5 Ad Specs
          </Text>
        </a>
      )}
    </Flex>
  ),
  [CreativeType.AudioAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">Upload Audio files only or audio + companion banners as a .zip (1GB) max</Text>
      <Text size="xs">Audio files must be .wav, .mp3, or .ogg</Text>
      <Text size="xs">Companion banners must be .gif, .jpg, or .png</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Audio Ad Companion Banner Dimensions
        </Text>
        : 300x250, 728x90, 300x50, 320x50, 500x500, 540x640, 640x640
      </Text>
    </Flex>
  ),
  [CreativeType.VideoAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.flv, .mp4, .avi, wmv, .mpeg1/2, .webm or .mov files only (1GB max)</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Video Ad Dimensions
        </Text>
        : 1920x1080:700, 1920x800:700, 1280x720:400, 854x480:400,1024x768:400, 640x380:100, 640x480:100,
        640x360:100,480x360:100, 320x240:100, 300x250:100
      </Text>
      {!isCustomDomain() && (
        <Text size="xs">
          For all VPAID options, visit our{' '}
          <a
            target="_blank"
            href="https://growthchannel.io/help/article/video-and-vpaid-creatives-what-formats-are-supported"
            rel="noreferrer"
          >
            <Text as="span" color="primary">
              help center
            </Text>
          </a>
        </Text>
      )}
    </Flex>
  ),
  [CreativeType.NativeAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">Native image sizes must be 1600x1200, 1200x1200, or 1600x1600 below 500 kb.</Text>
      <Text size="xs">
        For multiple ads, upload a zip file containing 1) Native Image Assets and 2) a csv file that includes columns
        for Creative Name, Image Name, Headline, Subtitle, Sponsored by name
      </Text>
      <a target="_blank" href="https://dsp.growthchannel.io/resources/native-ad-template.csv" rel="noreferrer">
        <Text as="span" size="xs" color="primary">
          Download Native CSV Template
        </Text>
      </a>
    </Flex>
  ),
};

type CreativeFormValues = {
  id?: number;
  agency_id?: number;
  advertiser_id?: number;
  creative_name?: string;
  creative_type?: CreativeType;
  clickthrough_url?: string;
  third_party_impression_tracker?: string;
  file?: CreativeFile;
  tag?: string;
  start_date?: Date;
  end_date?: Date;
  is_active?: boolean;
  // custom fields
  files?: File[];
  step?: CreativeStep;
};

export const CreativeEdit = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId } = usePersistentAgencyAdvertiser();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const copy = searchParams.get('copy');
  const location = useLocation();
  const isNew = !id;
  const { data, isLoading: isDetailLoading } = useCreativesQuery({ id: id || copy }, { skip: isNew && !copy });
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const { handleSubmit, control, watch, setValue, reset } = useForm<CreativeFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      is_active: true,
      step: CreativeStep.Type,
    },
  });
  const [createOrUpdateCreative, { isLoading }] = useCreateOrUpdateCreativeMutation();

  const values = watch();

  useEffect(() => {
    if (data) {
      const creative = cloneDeep(data?.data);
      if (copy) {
        creative.creative_name = `${creative.creative_name} copy`;
        delete creative.id;
        delete creative.file;
      }
      reset({
        ...creative,
        start_date: stringToDate(creative.start_date),
        end_date: stringToDate(creative.end_date),
        step: CreativeStep.Form,
      });
    }
  }, [copy, data, reset]);

  const validate = () => {
    const errors = [];
    if (!values.agency_id) {
      errors.push('Agency is required');
    }
    if (!values.advertiser_id) {
      errors.push('Advertiser is required');
    }
    if (!values.creative_name) {
      errors.push('Campaign name is required');
    }
    if (values.creative_type === CreativeType.AdTag) {
      if (!values.tag) {
        errors.push('Ad Tag is required');
      }
    }
    if (isNew && values.creative_type !== CreativeType.AdTag) {
      if (!values.files?.length) {
        errors.push('Creative files is required');
      } else {
        if (sum(values.files.map((file) => file.size)) > 150 * 1024 * 1024) {
          errors.push('Creative files is too large, exceeding the file size limit of 150MB');
        }
      }
    }
    if (!values.clickthrough_url) {
      errors.push('Landing page is required');
    }
    if (errors.length > 0) {
      showErrorToast(errors);
      return false;
    }
    return true;
  };

  const onSubmit = async (values: CreativeFormValues) => {
    try {
      if (!validate()) {
        return;
      }
      const result = await createOrUpdateCreative({
        ...omit(values, ['files', 'step']),
        files:
          isNew && values.files
            ? await Promise.all(
                values.files.map(async (file: any) => ({
                  file: await fileToBase64(file),
                  file_name: file.name,
                })),
              )
            : null,
        start_date: dateToString(values.start_date),
        end_date: dateToString(values.end_date),
      }).unwrap();
      if (isNew) {
        showSuccessToast('Create creative successfully');
        if (location.state?.from === 'campaign') {
          navigate('/activate/campaigns/new', {
            state: {
              campaign: {
                ...location.state?.campaign,
                creative_ids: isArray(result.data)
                  ? result.data?.map((creative: any) => creative.id)
                  : [result.data.id],
              },
            },
          });
        } else {
          navigate('/activate/creatives');
        }
      } else {
        showSuccessToast('Save creative successfully');
        navigate('/activate/creatives');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isDetailLoading}>
      <Text size="xxl" weight={700}>
        {CREATIVE_STEP_TITLES[values.step!]}
      </Text>
      <Spacing size="xl" />
      {values.step === CreativeStep.Type && (
        <FormItem>
          <TypeContainer>
            {CREATIVE_TYPES.map((type) => (
              <Type
                key={type.value}
                onClick={() => {
                  setValue('creative_type', type.value);
                  setValue('step', CreativeStep.Form);
                }}
              >
                <Icon type={type.icon} />
                <Text size="md" weight={600}>
                  {type.label}
                </Text>
              </Type>
            ))}
          </TypeContainer>
        </FormItem>
      )}
      {values.step === CreativeStep.Form && (
        <Flex gap="xxl">
          <FlexItem grow={1}>
            <Text size="lg" weight={600}>
              {CREATIVE_TYPE_TITLES[values.creative_type!]}
            </Text>
            <Spacing size="lg" />
            <Form>
              {canAccessAgency && isNew && (
                <>
                  <FormItem label="Agency" required>
                    <AgencySelect
                      name="agency_id"
                      control={control}
                      onValueChange={() => setValue('advertiser_id', null)}
                    />
                  </FormItem>
                  <FormItem label="Advertiser" required>
                    <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
                  </FormItem>
                </>
              )}
              <FormItem label="Creative name" required>
                <ControlledInput name="creative_name" control={control} placeholder="Enter creative name" />
              </FormItem>
              {values.creative_type === CreativeType.AdTag ? (
                <FormItem label="Ad Tag" required>
                  <Flex direction="column" gap="sm">
                    <ControlledTextArea name="tag" control={control} placeholder="Place code here" rows={10} />
                    {!isCustomDomain() && (
                      <a
                        target="_blank"
                        href="https://growthchannel.io/help/article/what-macros-can-i-use-for-3rd-party-adserver-tags-and-click-trackers"
                        rel="noreferrer"
                      >
                        <Text size="xs" color="primary">
                          See accepted macros
                        </Text>
                      </a>
                    )}
                  </Flex>
                </FormItem>
              ) : isNew ? (
                <FormItem label="Creative Files" required>
                  <ControlledFile
                    name="files"
                    control={control}
                    notes={CREATIVE_UPLOAD_NOTES[values.creative_type!]}
                    multiple={true}
                  />
                </FormItem>
              ) : null}
              <FormItem label="Landing Page" required>
                <ControlledInput name="clickthrough_url" control={control} placeholder="Enter landing page url" />
              </FormItem>
              <FormItem label="Impression tracker">
                <ControlledInput
                  name="third_party_impression_tracker"
                  control={control}
                  placeholder="Enter impression tracking url"
                />
              </FormItem>
              <FormItem label="Schedule">
                <Flex gap="md">
                  <ControlledDatePicker
                    name="start_date"
                    control={control}
                    placeholder="Select start date"
                    showTimeSelect
                  />
                  <ControlledDatePicker
                    name="end_date"
                    control={control}
                    placeholder="Select end date"
                    showTimeSelect
                  />
                </Flex>
              </FormItem>
              <FormAction
                onBack={isNew ? () => setValue('step', CreativeStep.Type) : undefined}
                onSubmit={handleSubmit(onSubmit)}
                isSubmitting={isLoading}
                disabled={isDetailLoading}
              />
            </Form>
          </FlexItem>
          <FlexItem width="40rem">
            <Text size="lg" weight={600}>
              Ad Preview
            </Text>
            <Spacing size="lg" />
            <Flex direction="column" gap="md">
              {isNew ? (
                <>
                  {values.files && values.files.length > 0 ? (
                    <PreviewImages>
                      <Flex direction="column" gap="md">
                        {values.files.map((file, index) => {
                          if (CREATIVE_PREVIEW_REG.test(file.name)) {
                            return <PreviewImage key={index} alt="img" src={URL.createObjectURL(file)} />;
                          }
                          return null;
                        })}
                      </Flex>
                    </PreviewImages>
                  ) : (
                    <NoImage>
                      <Flex direction="column" gap="md" justify="center" align="center">
                        <Text size="sm">Choose image file to preview</Text>
                      </Flex>
                    </NoImage>
                  )}
                </>
              ) : CREATIVE_PREVIEW_REG.test(values.file?.file_url) ? (
                <PreviewImages>
                  <PreviewImage alt="img" src={values.file.file_url} />
                </PreviewImages>
              ) : (
                <NoImage>
                  <Flex direction="column" gap="md" justify="center" align="center">
                    <Text size="sm">No image file to preview</Text>
                  </Flex>
                </NoImage>
              )}
            </Flex>
          </FlexItem>
        </Flex>
      )}
    </PageTemplate>
  );
};

const TypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 1.2rem;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;

const Type = styled.div`
  padding: 3.6rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
`;

const PreviewImages = styled.div`
  max-height: 60rem;
  overflow: auto;

  img {
    aspect-ratio: unset;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
  background: white;
`;

const NoImage = styled.div`
  width: 40rem;
  height: 40rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
