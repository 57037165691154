import { Modal } from 'src/components/Modal';
import { useRole, useToast } from 'src/hooks';
import styled from 'styled-components';
import { Flex, Text } from 'src/components/Layout';

type GetPixelCodeModalProps = {
  isOpen: boolean;
  pixel?: any;
  onClose: () => void;
};

const DEFAULT_PIXEL_DOMAIN = 'pxl.growth-channel.net';

export const GetPixelCodeModal = (props: GetPixelCodeModalProps) => {
  const { whiteLabel } = useRole();
  const { isOpen, pixel, onClose } = props;

  const { showSuccessToast } = useToast();

  const pixelDomain = whiteLabel?.pixel_custom_domain || DEFAULT_PIXEL_DOMAIN;
  const pixelCode = `<script async src="https://${pixelDomain}/s/${pixel?.uuid}"></script>`;

  const onCopy = () => {
    navigator.clipboard.writeText(pixelCode);
    showSuccessToast('Pixel code has been copied to your clipboard');
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Get the pixel code"
      onClose={onClose}
      onConfirm={onCopy}
      confirmText="COPY"
      cancelText="CLOSE"
    >
      <PixelCode>
        <Flex direction="column" gap="lg">
          {/* <Text size="md" weight={600} align="center">
            Site Visit
          </Text> */}
          <Text size="sm" color="gray">
            {pixelCode}
          </Text>
        </Flex>
      </PixelCode>
    </Modal>
  );
};

const PixelCode = styled.div`
  margin: 0.8rem 0;
  padding: 1.6rem 2.4rem;
  border-radius: 0.4rem;
  border: solid 0.1rem #f2f2f2;
`;
