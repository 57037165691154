import { differenceInDays, format, min, subDays } from 'date-fns';
import { flatten, keyBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { DataTable, DataTableCellLoading, DataTableExport } from 'src/components/DataTable';
import { ControlledInput } from 'src/components/Form';
import { Flex, Spacing } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { useDataTable, useDeepEffect } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmount, formatOnlyDate, formatPercentageWithPrecision } from 'src/utils';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { useCallback, useState } from 'react';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { CampaignOngoingInterval } from 'src/types';

const { useCampaignPerformanceQuery, useLazyCampaignPerformanceBatchQuery } = growthApi;

type ReportingPacingProps = ReportingFormValues;

type ReportingPacingFormValues = {
  search?: string;
};

export const ReportingPacing = (props: ReportingPacingProps) => {
  const { agencyId, advertiserId, campaignGroup } = props;

  const { watch, control } = useForm<ReportingPacingFormValues>();
  const values = watch();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const { data, isFetching, error } = useCampaignPerformanceQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      campaign_group: campaignGroup,
      time_range: 'all_time',
      breakout: 'campaign',
      with_campaign: true,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );
  const { data: yesterdayData, isFetching: isYesterdayFetching } = useCampaignPerformanceQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      time_range: 'yesterday',
      date_from: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      date_to: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      breakout: 'campaign',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );
  const [lazyCampaignPerformanceBatch] = useLazyCampaignPerformanceBatchQuery();
  const [flightData, setFlightData] = useState<any[]>([]);
  const [flightIsFetching, setFlightIsFetching] = useState(false);

  const getCampaignActiveFlight = (campaign: any) => {
    if (!campaign) {
      return {};
    }
    const now = new Date();
    let activeFlight: any;
    if (campaign.ongoing_enabled && campaign.ongoing_interval === CampaignOngoingInterval.Flights) {
      campaign.ongoing_flights.forEach((flight: any) => {
        if (
          now.valueOf() >= new Date(flight.start_date).valueOf() &&
          now.valueOf() < new Date(flight.end_date).valueOf()
        ) {
          activeFlight = flight;
        }
      });
    }
    if (!activeFlight) {
      activeFlight = {
        start_date: campaign.schedule_start_date,
        end_date: campaign.schedule_end_date,
        budget: campaign.budget_amount,
      };
    }
    return activeFlight;
  };

  const getYesterdaySpend = useCallback(
    (row: any) => {
      const yesterdayByName = keyBy(yesterdayData?.data, 'campaign_name');
      return yesterdayByName[row.campaign_name]?.total_spend_markup;
    },
    [yesterdayData?.data],
  );

  const getFlightSpend = useCallback(
    (row: any) => {
      const flightById = keyBy(flightData, 'campaign_name');
      return flightById[row.campaign_name]?.total_spend_markup;
    },
    [flightData],
  );

  const isMissingCampaign = useCallback((row: any) => {
    return !row.campaign;
  }, []);

  const isCampaignEnd = useCallback((row: any) => {
    const flight = getCampaignActiveFlight(row.campaign);
    return new Date().valueOf() > new Date(flight.end_date).valueOf();
  }, []);

  const getDailyDifference = useCallback(
    (row: any) => {
      const flight = getCampaignActiveFlight(row.campaign);
      return (
        getYesterdaySpend(row) -
        flight.budget / (differenceInDays(new Date(flight.end_date), new Date(flight.start_date)) + 1)
      );
    },
    [getYesterdaySpend],
  );

  const { dataTableProps, dataTableExportProps } = useDataTable({
    name: 'reporting-pacing',
    idKey: 'adlib_id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['campaign_name'],
    defaultSort: {
      key: 'campaign.active_flight_start_date',
      direction: 'desc',
    },
    columns: [
      {
        header: 'Campaign ID',
        accessor: 'campaign.id',
        render: (value) => value || '-',
      },
      {
        header: 'Campaign Name',
        accessor: 'campaign_name',
        sortable: true,
      },
      {
        header: 'Start Date',
        accessor: '_start_date',
        render: (_, row) => {
          const flight = getCampaignActiveFlight(row.campaign);
          return formatOnlyDate(flight.start_date);
        },
      },
      {
        header: 'End Date',
        accessor: '_end_date',
        render: (_, row) => {
          const flight = getCampaignActiveFlight(row.campaign);
          return formatOnlyDate(flight.end_date);
        },
      },
      {
        header: 'Flight duration',
        accessor: 'flight_duration',
        render: (_, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          const flight = getCampaignActiveFlight(row.campaign);
          return differenceInDays(new Date(flight.end_date), new Date(flight.start_date)) + 1;
        },
      },
      {
        header: 'Days completed',
        accessor: 'days_completed',
        render: (_, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          const flight = getCampaignActiveFlight(row.campaign);
          return differenceInDays(min([new Date(), new Date(flight.end_date)]), new Date(flight.start_date)) + 1;
        },
      },
      {
        header: 'Budget',
        accessor: 'campaign.active_flight_budget',
        render: (_, row) => {
          const flight = getCampaignActiveFlight(row.campaign);
          return formatAmount(flight.budget);
        },
      },
      {
        header: 'Daily Spend Target',
        accessor: 'daily_spend_target',
        render: (_, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          const flight = getCampaignActiveFlight(row.campaign);
          return formatAmount(
            flight.budget / (differenceInDays(new Date(flight.end_date), new Date(flight.start_date)) + 1),
          );
        },
      },
      {
        header: 'Spend Yesterday',
        accessor: 'spend_yesterday',
        render: (_, row: any) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          if (isCampaignEnd(row)) {
            return '-';
          }
          if (isYesterdayFetching) {
            return <DataTableCellLoading />;
          }
          return formatAmount(getYesterdaySpend(row));
        },
      },
      {
        header: 'Daily Difference',
        accessor: 'daily_difference',
        render: (_, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          if (isCampaignEnd(row)) {
            return '-';
          }
          if (isYesterdayFetching) {
            return <DataTableCellLoading />;
          }
          return formatAmount(getDailyDifference(row));
        },
      },
      {
        header: 'Total Spent',
        title: 'Actual spends till yesterday',
        accessor: 'total_spent',
        render: (_, row: any) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          if (flightIsFetching) {
            return <DataTableCellLoading />;
          }
          return formatAmount(getFlightSpend(row));
        },
      },
      {
        header: 'Total Difference',
        title: 'Est. Difference till end of campaign',
        accessor: 'total_difference',
        render: (_, row: any) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          if (flightIsFetching || isYesterdayFetching) {
            return <DataTableCellLoading />;
          }
          const flight = getCampaignActiveFlight(row.campaign);
          if (isCampaignEnd(row)) {
            return formatAmount(getFlightSpend(row) - flight.budget);
          } else {
            return formatAmount(
              getDailyDifference(row) * (differenceInDays(new Date(flight.end_date), new Date()) + 1),
            );
          }
        },
      },
      {
        header: 'Pacing',
        title: 'Est. budget consumption till end of campaign',
        accessor: 'pacing',
        render: (_, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          if (isYesterdayFetching || flightIsFetching) {
            return <DataTableCellLoading />;
          }
          const flight = getCampaignActiveFlight(row.campaign);
          if (isCampaignEnd(row)) {
            return formatPercentageWithPrecision((getFlightSpend(row) / flight.budget) * 100, 0);
          } else {
            return formatPercentageWithPrecision(
              ((getDailyDifference(row) * (differenceInDays(new Date(flight.end_date), new Date()) + 1) +
                Number(flight.budget)) /
                Number(flight.budget)) *
                100,
              0,
            );
          }
        },
      },
      {
        header: 'Setting',
        accessor: 'campaign.budget_pacing',
        render: (value, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          return value;
        },
      },
      {
        header: 'Daily cap',
        accessor: 'campaign.budget_amount_daily',
        render: (value, row) => {
          if (isMissingCampaign(row)) {
            return '-';
          }
          return formatAmount(value);
        },
      },
    ],
  });

  useDeepEffect(() => {
    (async () => {
      if (dataTableProps.visibleData.length === 0) {
        return;
      }
      const inputs = [];
      dataTableProps.visibleData.forEach((row) => {
        if (!row.campaign) {
          return;
        }
        const flight = getCampaignActiveFlight(row.campaign);
        const startDate = formatOnlyDate(flight.start_date);
        const endDate = formatOnlyDate(flight.end_date);
        inputs.push({
          agency_id: agencyId,
          advertiser_id: advertiserId,
          // TODO: remove later when we swich to redshift
          campaign_id: row.campaign_ids.hasOwnProperty('growth')
            ? row.campaign_ids.growth[0]
            : JSON.stringify(row.campaign_ids),
          time_range: 'custom',
          date_from: startDate,
          date_to: endDate,
          breakout: 'campaign',
        });
      });
      setFlightIsFetching(true);
      const flightData = await lazyCampaignPerformanceBatch({ inputs }).unwrap();
      setFlightData(flatten(flightData.data));
      setFlightIsFetching(false);
    })();
  }, [dataTableProps.visibleData]);

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <TableContainer>
      <Flex gap="lg" align="center">
        <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />
        <DataTableExport {...dataTableExportProps} />
      </Flex>
      <Spacing size="lg" />
      <DataTable {...dataTableProps} scroll />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
