import { Icon } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import { ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

const BUTTON_VARIOUS_CSS = (theme: DefaultTheme) => ({
  primary: css`
    color: white;
    background: ${theme.color.primary};
  `,
  secondary: css`
    color: ${theme.color.primary};
    border: solid 0.1rem ${theme.color.primary};
    background: transparent;
  `,
  highlight: css`
    color: white;
    background: ${theme.color.highlight};
  `,
  dangerous: css`
    color: ${theme.color.error};
    border: solid 0.1rem ${theme.color.error};
    background: transparent;
  `,
});

const BUTTON_SIZE_CSS = (_: DefaultTheme) => ({
  xs: css`
    height: 2.8rem;
    font-size: 1rem;
  `,
  sm: css`
    height: 3.2rem;
    font-size: 1.1rem;
  `,
  md: css`
    height: 4rem;
    font-size: 1.2rem;
  `,
  lg: css`
    height: 4.8rem;
    font-size: 1.3rem;
  `,
});

type ButtonProps = {
  various?: keyof ReturnType<typeof BUTTON_VARIOUS_CSS>;
  size?: keyof ReturnType<typeof BUTTON_SIZE_CSS>;
  isLoading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  width?: string;
  onClick?: () => void;
  onMouseDown?: () => void;
  children?: ReactNode;
};

export const Button = (props: ButtonProps) => {
  const { various, size, isLoading, disabled, rounded = true, width, children, ...rest } = props;

  return (
    <StyledButton
      size={size}
      rounded={rounded}
      various={various}
      width={width}
      {...rest}
      disabled={isLoading || disabled}
    >
      {isLoading ? <Icon type="loading" /> : children}
    </StyledButton>
  );
};

const StyledButton = styled.button<ButtonProps>`
  padding: 0 2.4rem;
  text-align: center;
  font-weight: 700;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: ${(props) => props.width ?? '100%'};
  border-radius: ${(props) => (props.rounded ? '10rem' : '0.2rem')};
  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : ''};
  ${(props) =>
    props.shadow
      ? css`
          box-shadow: 0 1rem 0 0 #e9eaed;
        `
      : ''};
  ${(props) => BUTTON_VARIOUS_CSS(props.theme)[props.various ?? 'primary']}
  ${(props) => BUTTON_SIZE_CSS(props.theme)[props.size ?? 'md']}

  &:disabled {
    cursor: not-allowed;
    color: white;
    border: solid 0.1rem ${TOKENS.color.gray};
    background: ${TOKENS.color.gray};
  }
`;
