import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE: boolean = false;

export const notificationsSidebarSlice = createSlice({
  name: 'notificationsSidabarSlice',
  initialState: INITIAL_STATE,
  reducers: {
    toggleNotificationsShow: (state) => {
      return !state;
    }
  }
});