import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import { Button } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { MarketPlanSelect } from 'src/components/MarketPlanSelect';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { TOKENS } from 'src/design';
import { useUserMarketPlan, useWorkspace } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatCount, formatOnlyDate, imageUrl } from 'src/utils';
import styled, { css } from 'styled-components';

const { useMarketPlansQuery, usePersonasQuery } = growthApi;

type OverviewFormValues = {
  market_plan_id?: number;
};

const GROWTH_STATION_ITEMS = [
  {
    label: 'Personas',
    url: '/planning/personas',
  },
  {
    label: 'Tactics',
    url: '/planning/personas',
    disabled: true,
  },
  {
    label: 'Keywords',
    url: '/planning/personas',
    disabled: true,
  },
  {
    label: 'Benchmarks',
    url: '/planning/personas',
    disabled: true,
  },
  {
    label: 'Publishing sites',
    url: '/planning/personas',
    disabled: true,
  },
  {
    label: 'Ad copy',
    url: '/planning/personas',
    disabled: true,
  },
];

export const Overview = () => {
  const workspace = useWorkspace();
  const { marketPlanId, changeMarketPlan } = useUserMarketPlan();
  const { watch, control } = useForm<OverviewFormValues>({
    defaultValues: {
      market_plan_id: marketPlanId,
    },
  });
  const values = watch();
  const { data: marketPlansData, isLoading: marketPlansIsLoading } = useMarketPlansQuery(
    {
      workspace_id: workspace?.id,
    },
    { skip: !workspace?.id },
  );
  const { data: personasData, isLoading: personasIsLoading } = usePersonasQuery(
    {
      workspace_id: workspace?.id,
      market_plan_id: values.market_plan_id,
    },
    { skip: !workspace?.id },
  );
  const nav = useNavigate();

  useEffect(() => {
    changeMarketPlan(values.market_plan_id);
  }, [changeMarketPlan, values.market_plan_id]);

  const isLoading = marketPlansIsLoading || personasIsLoading;

  return (
    <PageTemplate isLoading={isLoading}>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Overview
          </Text>
          <MarketPlanSelect name="market_plan_id" control={control} withAll />
        </Flex>
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Grid columns={3} gap="lg">
        <div>
          <Card>
            <Text size="lg" weight={600}>
              Marketing plans
            </Text>
            <Spacing size="lg" />
            <MarketingPlansImage src={imageUrl('planning/marketing-plan.jpg')} />
            <Spacing size="lg" />
            <Flex direction="column" align="center">
              {marketPlansData?.data?.length ? (
                <Flex direction="column" gap="sm" width="100%">
                  {marketPlansData?.data?.map((item: any) => (
                    <Link key={item.id} to={`/planning/start?id=${item.id}`}>
                      <Flex key={item.id} justify="space-between" align="center">
                        <Flex gap="sm" align="center">
                          <Icon type="reporting" color="primary" />
                          <Text size="sm" weight={500}>
                            {item.data?.company_name}
                          </Text>
                        </Flex>
                        <Text size="sm" color="gray">
                          {formatOnlyDate(item.created_at)}
                        </Text>
                      </Flex>
                    </Link>
                  ))}
                </Flex>
              ) : (
                <Text size="sm">You have not created any marketing plans yet</Text>
              )}
              <Spacing size="xxl" />
              <Button
                width="70%"
                shadow
                onClick={() => {
                  nav('/planning/start');
                }}
              >
                GENERATE A MARKETING PLAN
              </Button>
              <Spacing size="lg" />
            </Flex>
          </Card>
        </div>
        <div>
          <Card>
            <Text size="lg" weight={600}>
              Growth station
            </Text>
            <Spacing size="xl" />
            <Flex direction="column" gap="md">
              {GROWTH_STATION_ITEMS.map((item) => (
                <GrowthStationItem key={item.label} disabled={item.disabled}>
                  <Flex
                    justify="space-between"
                    align="center"
                    clickable={!item.disabled}
                    onClick={() => {
                      if (item.disabled) {
                        return;
                      }
                      nav(item.url);
                    }}
                  >
                    <Text size="sm" weight={500}>
                      {item.label}
                    </Text>
                    <Flex align="center" gap="xs">
                      <Text size="xs" color="gray">
                        {item.label === 'Personas' && personasData?.data?.length
                          ? formatCount(personasData.data.length)
                          : 'Generate'}
                      </Text>
                      <Icon type="arrowRight" color="primary" />
                    </Flex>
                  </Flex>
                </GrowthStationItem>
              ))}
            </Flex>
          </Card>
        </div>
        <div>
          <Flex direction="column" gap="lg">
            <Card>
              <Flex justify="space-between" gap="md" align="center">
                <Flex direction="column" gap="lg">
                  <Text size="lg" weight={600}>
                    Explore the Knowledge Base
                  </Text>
                  <Flex direction="column" gap="md">
                    <a href="https://growthchannel.io/help" target="_blank" rel="noreferrer">
                      <Text size="sm" color="primary" weight={500}>
                        Help Center
                      </Text>
                    </a>
                    <a href="https://growthchannel.io/trainings-webinars" target="_blank" rel="noreferrer">
                      <Text size="sm" color="primary" weight={500}>
                        Training and webinars
                      </Text>
                    </a>
                    <a href="https://growthchannel.io/growth-library" target="_blank" rel="noreferrer">
                      <Text size="sm" color="primary" weight={500}>
                        Growth Library
                      </Text>
                    </a>
                  </Flex>
                </Flex>
                <SectionImage src={imageUrl('planning/explore-the-knowledge-base.jpg')} />
              </Flex>
            </Card>
            <Card>
              <Flex justify="space-between" gap="md" align="center">
                <Flex direction="column" gap="lg">
                  <Text size="lg" weight={600}>
                    Hire a Growth Expert
                  </Text>
                  <Text size="sm">Get matched with a pre-vetted Growth Marketer who can help you reach your goals</Text>
                  <a href="https://growthchannel.io/experts" target="_blank" rel="noreferrer">
                    <Text size="sm" color="primary" weight={500}>
                      Submit a request
                    </Text>
                  </a>
                </Flex>
                <SectionImage src={imageUrl('planning/hire-a-growth-expert.jpg')} />
              </Flex>
            </Card>
            <Card>
              <Flex justify="space-between" gap="md" align="center">
                <Flex direction="column" gap="lg">
                  <Text size="lg" weight={600}>
                    Need help?
                  </Text>
                  <Text size="sm">
                    Have a question about Growth Channel or want to book a review session for your latest marketing
                    plan?
                  </Text>
                  <a href="https://www.growthchannel.com/request-demo" target="_blank" rel="noreferrer">
                    <Text size="sm" color="primary" weight={500}>
                      Book a demo
                    </Text>
                  </a>
                </Flex>
                <SectionImage src={imageUrl('planning/need-help.jpg')} />
              </Flex>
            </Card>
          </Flex>
        </div>
      </Grid>
    </PageTemplate>
  );
};

const Card = styled.div`
  padding: 2.4rem 3.2rem;
  box-shadow: ${TOKENS.shadow.default};
  background: white;
  border-radius: 0.4rem;
`;

const MarketingPlansImage = styled.img`
  width: 100%;
`;

const SectionImage = styled.img`
  width: 8.5rem;
  height: 8.5rem;
  flex-shrink: 0;
`;

const GrowthStationItem = styled.div<{ disabled?: boolean }>`
  border-radius: 1.2rem;
  padding: 0.6rem 1.2rem;

  ${(props) =>
    props.disabled
      ? css`
          background: ${props.theme.color.grayLighter};
          cursor: not-allowed;
        `
      : css`
          border: solid 1px #e5e5e5;
        `}
`;
