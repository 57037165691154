import { Icon } from 'src/components/Icon';
import styled from 'styled-components';

type LoadingTemplateProps = {
  message?: string;
};

export const LoadingTemplate = (props: LoadingTemplateProps) => {
  const { message } = props;

  return (
    <LoadingContainer>
      <Icon size="5rem" type="loading" />
      {message && <div>{message}</div>}
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  background: ${(props) => props.theme.color.bgGray};
  font-size: 1.4rem;
  font-weight: 500;

  svg {
    fill: ${(props) => props.theme.color.primary};
  }
`;
