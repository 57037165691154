import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';

const { useVerticalsQuery } = growthApi;

type VerticalSelectProps<T> = ControlledSelectProps<T>;

export const VerticalSelect = <T,>(props: VerticalSelectProps<T>) => {
  const { data, isFetching } = useVerticalsQuery({});

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return (
      data?.data
        ?.filter((vertical) => vertical.status)
        ?.map((vertical) => ({
          label: vertical.name,
          value: vertical.id,
        })) || []
    );
  }, [isFetching, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select vertical" {...props} />;
};
