import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRole, useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { ControlledInput, Form, FormItem } from '../Form';
import { Modal } from '../Modal';

const { useAdWalletPrepaymentMutation } = growthApi;

type PrepaymentModalProps = {
  agencyId?: number;
  advertiserId?: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type PrepaymentFormValues = {
  amount: number;
  description?: string;
};

export const PrepaymentModal = (props: PrepaymentModalProps) => {
  const { agencyId, advertiserId, isOpen, onClose, onSuccess } = props;

  const { agencyId: userAgencyId, isWhiteLabel, whiteLabelId } = useRole();
  const { control, handleSubmit } = useForm<PrepaymentFormValues>();
  const [adWalletPrepayment, { isLoading }] = useAdWalletPrepaymentMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const finalWhiteLabelId = useMemo(() => {
    // if is white label and they set their agency payment, will binding credit card to us
    return isWhiteLabel && agencyId === userAgencyId && !advertiserId ? undefined : whiteLabelId;
  }, [advertiserId, agencyId, isWhiteLabel, userAgencyId, whiteLabelId]);

  const onSubmit = async (values: PrepaymentFormValues) => {
    try {
      await adWalletPrepayment({
        ...values,
        agency_id: agencyId,
        advertiser_id: advertiserId,
        white_label_id: finalWhiteLabelId,
      }).unwrap();
      showSuccessToast('Prepayment successfully');
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Prepayment"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Amount" required>
          <ControlledInput type="number" name="amount" control={control} placeholder="Enter amount" />
        </FormItem>
        <FormItem label="Description">
          <ControlledInput name="description" control={control} placeholder="Enter description" />
        </FormItem>
      </Form>
    </Modal>
  );
};
