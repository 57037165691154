import { AudienceRule, AudienceRuleResponseType } from 'src/types';
import { formatText } from 'src/utils';

export const renderRuleValue = (rule: AudienceRule) => {
  if (rule.rule_response_type === AudienceRuleResponseType.Text) {
    return rule.rule_value;
  } else if (rule.rule_response_type === AudienceRuleResponseType.YesNo) {
    return rule.rule_value === 'on' ? 'Yes' : 'No';
  } else {
    if (typeof rule.rule_value_name === 'string') {
      return rule.rule_value_name;
    }
    if (Array.isArray(rule.rule_value_name)) {
      return rule.rule_value_name.map((row) => formatText(row.full_path || row.name)).join(', ');
    }
    if (!rule.rule_value_name) {
      return `${rule.rule_value}${rule.rule_value_additional ? ` - ${rule.rule_value_additional}` : ''}`;
    }
  }
};
