import { addDays } from 'date-fns';
import { keyBy } from 'lodash';
import { formatOnlyDate } from './format';

export const autoFillData = (data?: any[], dateFrom?: Date, dateTo?: Date) => {
  if (!data) {
    return [];
  }

  if (!dateFrom || !dateTo) {
    return data;
  }

  const dataByDate = keyBy(data, 'start_date');
  const autoFilledData: any[] = [];

  let currentDate = new Date(dateFrom);
  while (currentDate <= dateTo) {
    const date = formatOnlyDate(currentDate);
    autoFilledData.push(dataByDate[date] ?? { start_date: date });
    currentDate = addDays(currentDate, 1);
  }

  return autoFilledData;
};
