import { useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Icon } from '../Icon';
import { Flex, Grid, Spacing, Text } from '../Layout';
import styled from 'styled-components';
import { Modal } from '../Modal';

type ControlledEditableIconProps<T> = UseControllerProps<T> & {
  title: string;
  icons: {
    value: string;
    url: string;
  }[];
};

export const ControlledEditableIcon = <T,>(props: ControlledEditableIconProps<T>) => {
  const { title, icons, ...useControllerProps } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string[]>([]);

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  const typedValue = value as string[];

  return (
    <>
      <Flex direction="column" gap="md">
        <Flex gap="xs" align="center">
          <Text weight={600}>{title}</Text>
          <Icon
            size="sm"
            color="gray"
            type="edit"
            clickable
            onClick={() => {
              setEditValue(typedValue);
              setIsEdit(true);
            }}
          />
        </Flex>
        <Flex gap="md" wrap="wrap">
          {typedValue?.map((value) => {
            const icon = icons.find((icon) => icon.value === value);
            return <IconImg key={value} alt={value} src={icon?.url} />;
          })}
        </Flex>
      </Flex>
      <Modal
        isOpen={isEdit}
        title={`Edit ${title}`}
        onClose={() => setIsEdit(false)}
        onConfirm={() => {
          onChange(editValue);
          setIsEdit(false);
        }}
      >
        <Spacing size="lg" />
        <Grid columns={4} gap="md">
          {icons.map((icon) => {
            const isSelected = editValue.includes(icon.value);
            return (
              <SelectableIcon
                key={icon.value}
                selected={isSelected}
                onClick={() => {
                  isSelected
                    ? setEditValue(editValue.filter((value) => value !== icon.value))
                    : setEditValue([...editValue, icon.value]);
                }}
              >
                <img alt={icon.value} src={icon.url} />
                {isSelected && (
                  <IsSelectedIcon>
                    <Icon size="md" type="check" color="primary" />
                  </IsSelectedIcon>
                )}
              </SelectableIcon>
            );
          })}
        </Grid>
      </Modal>
    </>
  );
};

const IconImg = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`;

const SelectableIcon = styled.div<{ selected: boolean }>`
  position: relative;
  text-align: center;
  padding: 2.4rem;
  border: ${(props) =>
    props.selected ? `solid 0.3rem ${props.theme.color.primary}` : `solid 0.3rem ${props.theme.color.bgGray}`};
  border-radius: 0.2rem;
  cursor: pointer;

  img {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const IsSelectedIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2.4rem;
  text-align: center;
`;
