import { UseControllerProps } from 'react-hook-form';
import { Flex, Grid } from '../Layout';
import { INSIGHT_FILTERS } from './constants';
import { ControlledSelect, Label } from '../Form';

type InsightFilterProps<T> = UseControllerProps<T>;

export const InsightFilter = <T,>(props: InsightFilterProps<T>) => {
  const { name, control } = props;

  return (
    <Grid columns={4} gap="md">
      {INSIGHT_FILTERS.map((filter) => (
        <Flex key={filter.name} direction="column">
          <Label size="sm" weight={500}>
            {filter.label}
          </Label>
          <ControlledSelect
            name={`${name}.${filter.name}` as any}
            control={control}
            options={filter.options}
            multiple
          />
        </Flex>
      ))}
    </Grid>
  );
};
