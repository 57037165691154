import { isNil } from 'lodash';
import { Text } from 'src/components/Layout';
import { formatPercentage } from 'src/utils';

const DEFAULT_PRECISION = 2;

const getDiff = (current: number, previous: number, precision: number, isOpposite: boolean) => {
  const result: {
    color?: string;
    percentage?: string;
  } = {};

  if (!isNil(current) && !isNil(previous)) {
    if (current.toFixed(precision) !== previous.toFixed(precision)) {
      result.color = current > previous ? (isOpposite ? 'error' : 'success') : isOpposite ? 'success' : 'error';

      if (previous !== 0) {
        result.percentage = `(${current > previous ? '+' : '-'}${formatPercentage(
          (Math.abs(current - previous) / previous) * 100,
        )})`;
      }
    }
  }

  return result;
};

type DiffNumberProps = {
  current?: any;
  previous?: any;
  precision?: number;
  format?: (value: any) => string;
  isShowDiff?: boolean;
  isOpposite?: boolean;
};

export const DiffNumber = (props: DiffNumberProps) => {
  const { current, previous, precision = DEFAULT_PRECISION, format, isShowDiff, isOpposite } = props;

  const { color, percentage } = getDiff(current, previous, precision, isOpposite);

  return (
    <>
      <Text color={isShowDiff ? color : undefined}>
        {format ? format(current) : current}
        {isShowDiff && percentage && <Text size="xxs">{percentage}</Text>}
      </Text>
      {isShowDiff && <Text color="gray">{format ? format(previous) : previous}</Text>}
    </>
  );
};
