import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { growthApi } from 'src/services';
import { AudienceRule } from 'src/types';
import { ControlledSelect, ControlledSelectOption, FormItem } from '../Form';
import { Flex, FlexItem } from '../Layout';

export const CUSTOM_RULE_OPERATING_SYSTEM_ID = 20;

const { useAudiencesDatasetQuery } = growthApi;

type CustomRuleTypeOperatingSystemProps = {
  rule?: AudienceRule;
};

type OperatingSystemFormValues = {
  rule_sub_type_id: number;
  rule_value: number;
};

export const CustomRuleTypeOperatingSystem = (props: CustomRuleTypeOperatingSystemProps) => {
  const { rule } = props;
  const { setValue: setParentValue } = useFormContext();
  const { watch, control, setValue } = useForm<OperatingSystemFormValues>();
  const values = watch();
  const { data: parentData, isFetching: isParentLoading } = useAudiencesDatasetQuery({
    dataset: 'rule_values',
    type: CUSTOM_RULE_OPERATING_SYSTEM_ID,
  });

  useEffect(() => {
    if (rule) {
      setValue('rule_sub_type_id', rule.rule_sub_type_id);
      setValue('rule_value', rule.rule_value);
    }
  }, [rule, setValue]);

  const { data, isFetching: isLoading } = useAudiencesDatasetQuery(
    {
      dataset: 'rule_values',
      type: CUSTOM_RULE_OPERATING_SYSTEM_ID,
      parent: values.rule_sub_type_id,
    },
    { skip: !values.rule_sub_type_id },
  );

  const parentOptions = useMemo(() => {
    return parentData?.data.map(
      (item: any): ControlledSelectOption => ({
        value: item.id,
        label: item.name,
      }),
    );
  }, [parentData?.data]);

  const options = useMemo(() => {
    return data?.data.map(
      (item: any): ControlledSelectOption => ({
        value: item.id,
        label: String(item.name),
      }),
    );
  }, [data?.data]);

  return (
    <FormItem label="Value">
      <Flex width="100%" gap="md">
        <FlexItem grow={1}>
          <ControlledSelect
            name="rule_sub_type_id"
            control={control}
            options={parentOptions}
            isLoading={isParentLoading}
            onValueChange={(value: any) => {
              setParentValue('rule_sub_type_id', value);
              // clear rule value when parent change
              setValue('rule_value', undefined);
              setParentValue('rule_value', undefined);
            }}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <ControlledSelect
            name="rule_value"
            control={control}
            options={options}
            isLoading={isLoading}
            onValueChange={(value: any) => {
              setParentValue('rule_value', value);
              setParentValue('rule_value_name', options.find((option: any) => option.value === value)?.label);
            }}
          />
        </FlexItem>
      </Flex>
    </FormItem>
  );
};
