import { Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { User } from 'src/types';
import { getApiErrorMessage } from 'src/utils';
import { Text } from 'src/components/Layout';
import { AccessFormItem, AccessFormValues } from './AccessFormItem';

const { useUserDSPBindingMutation } = growthApi;

type EditUserAccessModalProps = {
  isOpen: boolean;
  user?: User;
  onSuccess: () => void;
  onClose: () => void;
};

type EditUserAccessFormValues = {
  user_id: number;
} & AccessFormValues;

export const EditUserAccessModal = (props: EditUserAccessModalProps) => {
  const { isOpen, user, onSuccess, onClose } = props;

  const { watch, handleSubmit, control, setValue, reset } = useForm<EditUserAccessFormValues>();
  const [userDSPBinding, { isLoading }] = useUserDSPBindingMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const values = watch();

  useEffect(() => {
    if (isOpen && user) {
      reset({
        user_id: user?.id,
        agency_id: user?.dsp_agency_id,
        advertiser_id: user?.dsp_advertiser_id,
      });
    }
  }, [isOpen, user, reset]);

  const onSubmit = async (values: EditUserAccessFormValues) => {
    try {
      await userDSPBinding(values).unwrap();
      showSuccessToast('Update user access successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit User Access"
      isLoading={isLoading}
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Form>
        <FormItem label="Email">
          <Text size="sm">{user?.email}</Text>
        </FormItem>
        <AccessFormItem control={control} values={values} setValue={setValue} />
      </Form>
    </Modal>
  );
};
