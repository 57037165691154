import { useForm } from 'react-hook-form';
import { DataTableEmpty } from 'src/components/DataTable/DataTableEmpty';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Table } from 'src/components/Table';
import { PageTemplate } from 'src/components/Template';
import { useRole, useToast } from 'src/hooks';
import { nanoid } from 'nanoid';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { useEffect } from 'react';

const { useMarkupRulesQuery, useUpdateMarkupRulesMutation } = growthApi;

type MarkupRule = {
  id?: string;
  from?: number;
  to?: number;
  rate?: number;
};

type MarkupRulesFormValues = {
  rules?: MarkupRule[];
};

export const MarkupRules = () => {
  const { whiteLabelId } = useRole();
  const { data, isLoading: isPageLoading } = useMarkupRulesQuery({ white_label_id: whiteLabelId });
  const [updateMarkupRules, { isLoading }] = useUpdateMarkupRulesMutation();
  const { watch, control, setValue, handleSubmit } = useForm<MarkupRulesFormValues>();
  const values = watch();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (data?.data) {
      setValue('rules', data.data?.rules);
    }
  }, [data?.data, setValue]);

  const onSubmit = async (values: MarkupRulesFormValues) => {
    try {
      await updateMarkupRules({
        white_label_id: whiteLabelId,
        ...values,
      }).unwrap();
      showSuccessToast('Save markup rules successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isPageLoading}>
      <Flex justify="space-between">
        <Text size="xxl" weight={700}>
          Markup Rules
        </Text>
        <Button
          onClick={() => {
            setValue('rules', [...(values.rules || []), { id: nanoid() }]);
          }}
          width="16rem"
        >
          ADD RULE
        </Button>
      </Flex>
      <Spacing size="xxl" />
      <Table various="border" border={1} cellSpacing={0} cellPadding={0}>
        <tbody>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Rate</th>
            <th></th>
          </tr>
          {values.rules && values.rules.length > 0 ? (
            values.rules.map((rule, index) => (
              <tr key={rule.id}>
                <td>
                  <ControlledInput
                    type="number"
                    prefix="$"
                    name={`rules[${index}].from` as any}
                    control={control}
                    placeholder="Enter from"
                  />
                </td>
                <td>
                  <ControlledInput
                    type="number"
                    prefix="$"
                    name={`rules[${index}].to` as any}
                    control={control}
                    placeholder="Enter to"
                  />
                </td>
                <td>
                  <ControlledInput
                    type="number"
                    suffix="%"
                    name={`rules[${index}].rate` as any}
                    control={control}
                    placeholder="Enter rate"
                  />
                </td>
                <td>
                  <Action
                    dangerous
                    onClick={() => {
                      setValue(
                        'rules',
                        values.rules.filter((item) => item.id !== rule.id),
                      );
                    }}
                  >
                    Delete
                  </Action>
                </td>
              </tr>
            ))
          ) : (
            <DataTableEmpty message="No rules in place" />
          )}
        </tbody>
      </Table>
      <Spacing size="xl" />
      <Flex gap="lg">
        <Button width="18rem" shadow isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
          SAVE CHANGES
        </Button>
      </Flex>
    </PageTemplate>
  );
};
