import { growthApi } from 'src/services';
import { useAppDispatch, userSlice } from 'src/store';

const { useLazyProfileQuery } = growthApi;

export const useReloadUser = () => {
  const dispatch = useAppDispatch();
  const [getProfile] = useLazyProfileQuery();

  const reloadUser = async () => {
    const data = await getProfile().unwrap();
    dispatch(userSlice.actions.updateUser(data?.data));
  };

  return reloadUser;
};
