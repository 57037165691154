import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormAction } from 'src/components/Form';
import { InsightData, InsightVisualize } from 'src/components/Insight';
import { Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useCustomAudienceInsightsQuery, useCreateOrUpdateCustomAudienceInsightMutation } = growthApi;

export const InsightView = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { data, isLoading } = useCustomAudienceInsightsQuery({
    id: Number(id),
  });
  const [createOrUpdateCustomAudienceInsight, { isLoading: isSubmitting }] =
    useCreateOrUpdateCustomAudienceInsightMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const [filter, setFilter] = useState();

  const isNewInsight = Boolean(data?.data?.key);
  const defaultFilter = data?.data?.save_filter;

  useMemo(() => {
    setFilter(defaultFilter);
  }, [defaultFilter]);

  const onSubmit = async () => {
    try {
      await createOrUpdateCustomAudienceInsight({
        ...data?.data,
        save_filter: filter,
      }).unwrap();
      showSuccessToast('Save insight successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Text size="xxl" weight={700}>
        View Insight
      </Text>
      <Spacing size="xl" />
      {isNewInsight ? (
        <InsightVisualize insightKey={data.data.key} defaultFilter={defaultFilter} onFilterChange={setFilter} />
      ) : (
        <InsightData data={data?.data?.data}></InsightData>
      )}
      <FormAction disableSubmit={!isNewInsight} submitText="SAVE" onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </PageTemplate>
  );
};
