import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Form';
import { Flex, Text } from '../Layout';
import { useRole, useWorkspace } from 'src/hooks';
import { capitalize } from 'lodash';
import { formatCount } from 'src/utils';

export const DataCreditsBanner = () => {
  const { user } = useRole();
  const workspace = useWorkspace();
  const nav = useNavigate();

  const plan = useMemo(() => {
    if (workspace?.plan) {
      return capitalize(workspace.plan);
    } else {
      return user?.promo_code ? 'PitchGround' : 'Free';
    }
  }, [user?.promo_code, workspace?.plan]);

  return (
    <>
      <Flex gap="sm">
        <Text size="xs">Current Plan: </Text>
        <Text size="xs" weight={600}>
          {plan}
        </Text>
      </Flex>
      <Flex gap="sm">
        <Text size="xs">Data Credits: </Text>
        <Text size="xs" weight={600}>
          {formatCount(workspace?.data_credits || 0)}
        </Text>
      </Flex>
      <Button size="sm" various="highlight" width="auto" onClick={() => nav('/planning/pricing')}>
        SEE PRICING
      </Button>
    </>
  );
};
