import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '../Layout';

const { usePixelsQuery } = growthApi;

type PixelSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  withAll?: boolean;
  idKey?: string;
};

export const PixelSelect = <T,>(props: PixelSelectProps<T>) => {
  const { agencyId, advertiserId, withAll, ...rest } = props;

  const { data, isLoading } = usePixelsQuery({ agency_id: agencyId, advertiser_id: advertiserId });
  const navigate = useNavigate();

  const options = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return (
      data?.data?.map((pixel: any) => ({
        label: `${pixel.pixel_name} - ${pixel.uuid}`,
        labelNode: (
          <Flex align="center" gap="sm">
            <Text>
              {pixel.pixel_name} - {pixel.uuid}
            </Text>
          </Flex>
        ),
        value: pixel.uuid,
      })) || []
    );
  }, [isLoading, data?.data]);

  return (
    <ControlledSelect
      options={options}
      isLoading={isLoading}
      placeholder="Select pixel"
      newLabel="New Pixel"
      onNew={() => navigate('/activate/pixels/new')}
      {...rest}
    />
  );
};
