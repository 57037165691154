import { debounce } from 'lodash';
import { useState, useEffect, useRef } from 'react';

const DEFAULT_WAIT = 200;

type UseDebouncedValueOptions = {
  value: any;
  wait?: number;
};

export const useDebouncedValue = (options: UseDebouncedValueOptions) => {
  const { value, wait = DEFAULT_WAIT } = options;

  const [debouncedValue, setDebouncedValue] = useState(value);
  const debounceFnRef = useRef(debounce(setDebouncedValue, wait));

  useEffect(() => {
    debounceFnRef.current?.(value);
  }, [value]);

  return debouncedValue;
};
