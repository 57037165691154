import { Icon, IconProps } from 'src/components/Icon';
import { ReactNode } from 'react';
import styled from 'styled-components';

type ActionProps = {
  icon?: IconProps['type'];
  dangerous?: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

export const Action = (props: ActionProps) => {
  const { icon, dangerous, onClick, children } = props;

  return (
    <ActionContainer onClick={onClick} dangerous={dangerous}>
      {icon && <Icon type={icon} size="sm" />}
      <div>{children}</div>
    </ActionContainer>
  );
};

const ActionContainer = styled.div<Pick<ActionProps, 'dangerous'>>`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
  color: ${(props) => (props.dangerous ? props.theme.color.error : props.theme.color.primary)};
  font-weight: 600;
`;
