import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import { Button, ControlledInput } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { SetBillingInformationModal } from 'src/components/SetBillingInformationModal';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { TOKENS } from 'src/design';
import { useReloadUser, useToast, useWorkspace } from 'src/hooks';
import { useConfirm } from 'src/hooks/useConfirm';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import styled, { css } from 'styled-components';

const { useUpdateWorkspaceMutation } = growthApi;

const PRICING_PLANS = [
  {
    title: 'Business',
    plan: 'Business',
    priceMonthly: 200,
    priceAnnually: 125,
    privileges: [
      '3 users',
      '1 advertiser',
      '2500 Monthly AI Data Credits',
      'Built-in 275+ integrations',
      'Premium advertising',
      'Persona builder',
      'Cookieless Tracking',
      'Geofencing',
      'Custom Audiences as an Add-on',
      'Audience Insights as an Add-on',
      'Managed Service as an Add-on',
      'Foot traffic attribution as an Add-on',
    ],
  },
  {
    title: 'Agency',
    plan: 'Agency',
    priceMonthly: 499,
    priceAnnually: 299,
    privileges: [
      '10 users',
      '10 advertisers',
      '5000 Monthly AI Data Credits',
      'Built-in 275+ integrations',
      'Premium advertising',
      'Persona builder',
      'Cookieless Tracking',
      'Geofencing',
      'Custom Audiences as an Add-on',
      'Audience Insights as an Add-on',
      'Managed Service as an Add-on',
      'Foot traffic attribution as an Add-on',
    ],
  },
  {
    title: 'Enterprise',
    plan: 'Enterprise',
    priceMonthly: '1500+',
    priceAnnually: '1500+',
    privileges: [
      'Unlimited users',
      'Unlimited advertisers',
      'Unlimited Monthly AI Data Credits',
      'Built-in 275+ integrations',
      'Custom integrations',
      'API and serever-to-server',
      'Custom reporting',
      'Geofencing',
      'Custom Audiences',
      'Audience Insights',
      'Managed Service',
      'Foot traffic attribution',
    ],
    customButtonText: 'CONTACT SALES',
    buttonRedirect: 'https://www.growthchannel.com/request-demo/',
  },
  // {
  //   title: 'Enterprise',
  //   plan: 'enterprise',
  //   price: 15000,
  //   privileges: [
  //     'Unlimited users',
  //     '50 websites',
  //     'Premium advertising',
  //     'Persona builder',
  //     'Cookieless Tracking',
  //     'Geofencing real-time & past events',
  //     'Up to 15 Geofencing requests',
  //     'Custom Audiences up to 50k records',
  //     'Managed Service',
  //     'White Label',
  //     'Audience Insights: up to 50k site visitors',
  //     '15,000 Monthly AI Data Credits',
  //   ],
  // },
];

type PricingFormValues = {
  dataCredits?: number;
};

enum BillingTimeFrame {
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
};

type PlaneTypeTextProps = {
  currentType: BillingTimeFrame,
  type: BillingTimeFrame,
  setType: (type: BillingTimeFrame) => void,
};

const PlaneTypeText = (props: PlaneTypeTextProps) => {
  const { currentType, type, setType } = props;

  const onClick = () => setType(type);

  return <PlanType
    active={currentType === type}
    weight={700}
    size='xs'
    clickable
    onClick={onClick}>
    {type}
  </PlanType>

};

export const Pricing = () => {
  const [billingInformationIsOpen, setBillingInformationIsOpen] = useState<boolean>(false);
  const [billingTimeFrame, setBillingTimeFrame] = useState<BillingTimeFrame>(BillingTimeFrame.ANNUALLY);
  const workspace = useWorkspace();
  const { watch, control } = useForm<PricingFormValues>({
    defaultValues: {
      dataCredits: 1000,
    },
  });
  const values = watch();
  const reloadUser = useReloadUser();
  const [updateWorkspace, { isLoading }] = useUpdateWorkspaceMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const [plan, setPlan] = useState<string>();
  const nav = useNavigate();
  const confirm = useConfirm();

  const onSubmit = async (plan?: string) => {
    const confirmMessage = `After this action you will be ${plan ? (
      `subscribed to chosen plan`
    ) : (
      `charged $${Number((values.dataCredits * 0.2).toFixed(2))}`
    )}`;
    if (!await confirm({ title: confirmMessage })) return;
    setPlan(plan);
    try {
      await updateWorkspace({
        id: workspace?.id,
        plan: plan,
        billing_time_frame: billingTimeFrame,
        data_credits: plan ? undefined : values.dataCredits,
      }).unwrap();
      showSuccessToast(plan ? 'Subscribe successfully' : 'Top up data credits successfully');
      reloadUser();
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      if (errorMessage === 'Please setup your Ad Wallet payment method') {
        showErrorToast('Fill out the payment information and try again!');
        setBillingInformationIsOpen(true);
      }
      else {
        showErrorToast(getApiErrorMessage(error));
      }
    }
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex justify="space-between" align="center" clickable onClick={() => nav(-1)}>
          <Icon type="back" color="primary" />
          <Text size="md" color="primary" weight={600}>
            Back
          </Text>
        </Flex>
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Spacing size="5rem" />
      <Flex justify="center">
        <Text size="xl" weight={700}>
          <Text as="span">Choose your </Text>
          <Text as="span" color="primary">
            growth plan
          </Text>
        </Text>
      </Flex>
      <Spacing size="3rem" />
      <Flex justify='center' gap='lg'>
        <PlaneTypeText currentType={billingTimeFrame} type={BillingTimeFrame.MONTHLY} setType={setBillingTimeFrame} />
        <PlaneTypeText currentType={billingTimeFrame} type={BillingTimeFrame.ANNUALLY} setType={setBillingTimeFrame} />
      </Flex>
      <Spacing size="3rem" />
      <Flex justify="center" gap="md">
        {PRICING_PLANS.map((item) => {
          const active = workspace?.plan === item.plan && workspace.plan_time_frame === billingTimeFrame.toLowerCase();
          return (
          <div key={item.plan}>
              <PriceItem active={active}>
                <PriceItemHeader active={active}>{item.title}</PriceItemHeader>
              <Spacing size="xl" />
              <Text size="xl" weight={600} align="center">
                {`$${billingTimeFrame === BillingTimeFrame.ANNUALLY ? item.priceAnnually : item.priceMonthly}`}
                <Text as="span" size="md">
                  /mo
                </Text>
              </Text>
              <Spacing size="xl" />
              {item.privileges.map((privilege) => (
                <TextWithPadding key={privilege} size="sm">
                  {privilege}
                </TextWithPadding>
              ))}
              <Spacing size="xl" />
              <Flex justify="center">
                <Button
                  various="primary"
                  shadow
                  width="16rem"
                  isLoading={plan === item.plan && isLoading}
                    disabled={active}
                  onClick={() => {
                    if (!item.buttonRedirect) {
                      onSubmit(item.plan);
                    } else {
                      window.open(item.buttonRedirect, '_blank');
                    }
                  }}
                >
                    {active ? (
                    'CURRENT PLAN'
                  ) : (
                    item.customButtonText || 'SUBSCRIBE'
                  )}
                </Button>
              </Flex>
              <Spacing size="xxl" />
            </PriceItem>
          </div>
          )
        })}
        <div>
          <PriceItem>
            <PriceItemHeader>Data credits</PriceItemHeader>
            <Spacing size="xl" />
            <Text size="xl" weight={600} align="center">
              ${Number((values.dataCredits * 0.2).toFixed(2))}
            </Text>
            <TextWithPadding size="sm" align="center">
              <Text>Top up data credits</Text>
              <Spacing size="lg" />
              <Flex justify="center">
                <ControlledInput name="dataCredits" control={control} width="16rem" />
              </Flex>
            </TextWithPadding>
            <Spacing />
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>250</Text>
                <Text>Persona profile</Text>
              </Flex>
            </TextWithPadding>
            <Spacing size="xl" />
            <Flex justify="center">
              <Button
                various="primary"
                shadow
                width="16rem"
                isLoading={plan === undefined && isLoading}
                onClick={() => {
                  onSubmit();
                }}
              >
                TOP UP
              </Button>
            </Flex>
            <Spacing size="xxl" />
          </PriceItem>
        </div>
      </Flex>
      <SetBillingInformationModal
        isOpen={billingInformationIsOpen}
        agencyId={workspace?.agency_id}
        advertiserId={workspace?.advertiser_id}
        onClose={() => {
          setBillingInformationIsOpen(false);
        }}
        onSuccess={() => {
          setBillingInformationIsOpen(false);
        }}
        noInitialCharge
      />
    </PageTemplate>
  );
};

const PriceItem = styled.div<{ active?: boolean }>`
  width: 28rem;
  border-radius: 1rem;
  overflow: hidden;
  background: white;

  input {
    text-align: center;
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 0.2rem ${props.theme.color.primary};
        `
      : css`
          border: solid 0.2rem ${props.theme.color.bgGray};
        `}
`;

const PriceItemHeader = styled.div<{ active?: boolean }>`
  font-size: 1.8rem;
  text-align: center;
  line-height: 5rem;
  font-weight: 600;

  ${(props) =>
    props.active
      ? css`
          color: white;
          background: ${props.theme.color.primary};
        `
      : css`
          background: #f2f2f2;
        `}
`;

const PlanType = styled(Text) <{ active: boolean }>`
  ${(props) =>
    props.active
      ? css`
          color: ${TOKENS.color.primary};
          text-decoration: underline;
        `
      : css`
          color: ${TOKENS.color.gray};
        `}
`;

const TextWithPadding = styled(Text)`
  padding: 0.6rem 2.4rem;
`;
