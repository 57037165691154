import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from 'src/components/Toasts';
import { nanoid } from 'nanoid';

export type ToastsState = ToastProps[];

const INITIAL_STATE: ToastsState = [];

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState: INITIAL_STATE,
  reducers: {
    showToast: (state, action: PayloadAction<Omit<ToastProps, 'id'>>) => {
      return [...state, { ...action.payload, id: nanoid() }];
    },
    removeToast: (state, action: PayloadAction<string>) => {
      return state.filter((toast) => toast.id !== action.payload);
    },
  },
});
