import { useForm } from 'react-hook-form';
import { Button, ControlledTextArea, Form, FormAction, FormItem, Input } from 'src/components/Form';
import { PageTemplate } from 'src/components/Template';
import { useRole, useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { useEffect, useState } from 'react';
import { getApiErrorMessage } from 'src/utils';

const { useSecurityConfigQuery, useUpdateSecurityConfigMutation, useClearIpExclusionMutation } = growthApi;

type SecurityFormValues = {
  ip_exclusion_whitelist?: string;
};

export const Security = () => {
  const { whiteLabelId } = useRole();
  const { data, isLoading } = useSecurityConfigQuery({
    white_label_id: whiteLabelId,
  });
  const [updateSecurityConfig, { isLoading: isSubmitting }] = useUpdateSecurityConfigMutation();
  const [clearIpExclusion, { isLoading: isClearing }] = useClearIpExclusionMutation();
  const [clearEmail, setClearEmail] = useState<string>('');
  const { control, reset, handleSubmit } = useForm<SecurityFormValues>();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (data) {
      reset(data.data);
    }
  }, [data, reset]);

  const onSubmit = async (values: SecurityFormValues) => {
    try {
      await updateSecurityConfig(values).unwrap();
      showSuccessToast('Save successfully');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onClearIpExclusion = async () => {
    try {
      await clearIpExclusion({
        email: clearEmail,
      }).unwrap();
      showSuccessToast('Clear ip exclusion successfully');
      setClearEmail('');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Text size="xxl" weight={700}>
        Security
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        <FormItem label="IP Exclusion Whitelist">
          <ControlledTextArea
            name="ip_exclusion_whitelist"
            control={control}
            rows={6}
            placeholder="Enter emails, separated by commas."
          />
        </FormItem>
        <FormAction disableBack isSubmitting={isSubmitting} submitText="SAVE" onSubmit={handleSubmit(onSubmit)} />
      </Form>
      <Spacing size="xl" />
      <Text size="lg" weight={600}>
        Clear Ip Exclusion
      </Text>
      <Spacing />
      <Flex gap="md" width="70%">
        <Input
          value={clearEmail}
          onChange={(event) => setClearEmail(event.currentTarget.value)}
          placeholder="Enter email"
        />
        <Button various="secondary" width="16rem" onClick={onClearIpExclusion} isLoading={isClearing}>
          CLEAR
        </Button>
      </Flex>
    </PageTemplate>
  );
};
