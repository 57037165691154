import { Column, DataTable, MAX_PAGE_SIZE } from 'src/components/DataTable';
import { Action, Button, ControlledInput, Tab, Tabs } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { growthApi } from 'src/services';
import { useDataTable, useDebouncedValue, useToast } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { fileToBase64, formatSize, formatDate, getApiErrorMessage } from 'src/utils';
import { Link } from 'react-router-dom';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

const {
  useCustomAudiencesQuery,
  useLazyCustomAudiencesQuery,
  useCustomAudienceActivationsQuery,
  useLazyActivateCustomAudienceInsightQuery,
  useLazyCustomAudienceActivationsQuery,
  useCreateCustomAudienceActivationMutation,
} = growthApi;

enum CustomAudiencesTab {
  Insight = 'insight',
  Activation = 'activation',
}

type InsightsFormValues = {
  search?: string;
};

const CUSTOM_AUDIENCES_TAB: Tab[] = [
  { label: 'Insight', value: CustomAudiencesTab.Insight },
  { label: 'Activation', value: CustomAudiencesTab.Activation },
];

export const CustomAudiences = () => {
  const [customAudiencesDataSearch, setCustomAudiencesDataSearch] = useState([]);
  const [isSearchFetching, setIsSearchFetching] = useState(true);

  const {
    data: customAudiencesData,
    isFetching: customAudiencesIsFetching,
    error: customAudiencesError,
  } = useCustomAudiencesQuery({});

  const {
    data: activationsData,
    isFetching: activationsIsFetching,
    error: activationsError,
    refetch: activationsRefetch,
  } = useCustomAudienceActivationsQuery({});

  const [customAudiences] = useLazyCustomAudiencesQuery({});
  const [activateInsight] = useLazyActivateCustomAudienceInsightQuery({});
  const [customAudienceActivations] = useLazyCustomAudienceActivationsQuery({});
  const [createCustomAudienceActivation, { isLoading: createCustomAudienceActivationIsLoading }] =
    useCreateCustomAudienceActivationMutation();

  const { watch, control } = useForm<InsightsFormValues>();
  const values = watch();
  const debouncedValue = useDebouncedValue({ value: values.search, wait: 500 });

  const insightsColumns: Column[] = [
    {
      header: 'Name',
      accessor: 'name',
      render: (value, row, level) => (
        <Flex align="center" gap="xs">
          <Spacing horizontal size={`${(level - 1) * 1.6}rem`} />
          {row.is_dir && (
            <FlexItem shrink={0}>
              <Icon
                size="sm"
                color="primary"
                type={
                  insightsIsExpendLoading(row.path)
                    ? 'loading'
                    : insightsIsExpend(row.path)
                    ? 'caretDown'
                    : 'caretRight'
                }
                clickable
                onClick={() => insightsTriggerExpend(row.path)}
              />
            </FlexItem>
          )}
          <Text>{value}</Text>
        </Flex>
      ),
    },
    {
      header: 'Updated At',
      accessor: 'updated_at',
      render: formatDate,
      width: '16rem',
      sortable: true,
    },
    {
      header: 'Size',
      accessor: 'size',
      render: formatSize,
    },
    {
      header: '',
      accessor: '_action',
      render: (_: any, row: any) => {
        return (
          <Flex align="center" gap="md">
            <FlexItem>
              <Link to={`/admin/custom-audiences/view?key=${row.path}`}>
                <Action>View</Action>
              </Link>
            </FlexItem>
            <FlexItem>
              {!row.is_dir && (
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_GROWTH_API_BASE_URL}dsp/custom-audiences/download?key=${row.path}&filename=${row.name}`}
                  rel="noopener noreferrer"
                >
                  <Action>Download</Action>
                </a>
              )}
            </FlexItem>
            <FlexItem>
              {!row.is_dir && <Action onClick={async () => await onActivateInsight(row.path)}>Activate</Action>}
            </FlexItem>
          </Flex>
        );
      },
      width: '6rem',
    },
  ];

  const activationsColumns: Column[] = [
    {
      header: 'Name',
      accessor: 'name',
      render: (value, row, level) => (
        <Flex align="center" gap="xs">
          <Spacing horizontal size={`${(level - 1) * 1.6}rem`} />
          {row.is_dir && (
            <FlexItem shrink={0}>
              <Icon
                size="sm"
                color="primary"
                type={
                  activationsIsExpendLoading(row.path)
                    ? 'loading'
                    : activationsIsExpend(row.path)
                    ? 'caretDown'
                    : 'caretRight'
                }
                clickable
                onClick={() => activationsTriggerExpend(row.path)}
              />
            </FlexItem>
          )}
          <Text>{value}</Text>
        </Flex>
      ),
    },
    {
      header: 'Size',
      accessor: 'size',
      render: formatSize,
    },
  ];

  const {
    dataTableProps: insightsDataTableProps,
    isExpendLoading: insightsIsExpendLoading,
    isExpend: insightsIsExpend,
    triggerExpend: insightsTriggerExpend,
  } = useDataTable({
    idKey: 'path',
    data: customAudiencesData?.data,
    isLoading: customAudiencesIsFetching,
    error: customAudiencesError,
    onExpend: async (id: any) => {
      const result = await customAudiences({ prefix: id }).unwrap();
      return result.data;
    },
    defaultPageSize: MAX_PAGE_SIZE,
    columns: insightsColumns,
  });

  const { dataTableProps: insightsDataTableSearchProps } = useDataTable({
    idKey: 'path',
    data: customAudiencesDataSearch,
    isLoading: isSearchFetching,
    defaultPageSize: MAX_PAGE_SIZE,
    columns: insightsColumns,
  });

  useEffect(() => {
    const fetchCustomAudience = async () => {
      setIsSearchFetching(true);
      const result = await customAudiences({ search: values.search }).unwrap();
      setCustomAudiencesDataSearch(result.data);
      setIsSearchFetching(false);
    };
    if (values.search?.length >= 3) {
      fetchCustomAudience();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const {
    dataTableProps: activationsDataTableProps,
    isExpendLoading: activationsIsExpendLoading,
    isExpend: activationsIsExpend,
    triggerExpend: activationsTriggerExpend,
  } = useDataTable({
    idKey: 'path',
    data: activationsData?.data,
    isLoading: activationsIsFetching,
    error: activationsError,
    onExpend: async (id: any) => {
      const result = await customAudienceActivations({ prefix: id }).unwrap();
      return result.data;
    },
    defaultPageSize: MAX_PAGE_SIZE,
    columns: activationsColumns,
  });

  const [tab, setTab] = useState<CustomAudiencesTab>(CustomAudiencesTab.Insight);
  const { showSuccessToast, showErrorToast } = useToast();

  const onActivateInsight = async (insight_path: string) => {
    try {
      await activateInsight({ key: insight_path }).unwrap();
      showSuccessToast('Custom audience activated successfully!');
      activationsRefetch();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const base64 = await fileToBase64(file);
    try {
      await createCustomAudienceActivation({
        filename: file.name,
        file: base64,
      }).unwrap();
      showSuccessToast('Custom audience activation upload successfully!');
      activationsRefetch();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Custom Audiences
          </Text>
          {tab === CustomAudiencesTab.Insight && (
            <ControlledInput width="30rem" prefix="Search:" control={control} name="search" placeholder="File name" />
          )}
        </Flex>
        {tab === CustomAudiencesTab.Activation && (
          <>
            <Button
              shadow
              width="20rem"
              onClick={() => {
                inputRef?.current?.click();
              }}
              isLoading={createCustomAudienceActivationIsLoading}
            >
              UPLOAD ACTIVATION
            </Button>
            <input hidden ref={inputRef} type="file" onChange={onFileChange} />
          </>
        )}
      </Flex>
      <Spacing size="lg" />
      <Tabs value={tab} onChange={(value: CustomAudiencesTab) => setTab(value)} tabs={CUSTOM_AUDIENCES_TAB} />
      <Spacing size="md" />
      {tab === CustomAudiencesTab.Insight && values.search ? (
        <DataTable {...insightsDataTableSearchProps} />
      ) : tab === CustomAudiencesTab.Activation ? (
        <DataTable {...activationsDataTableProps} />
      ) : (
        <DataTable {...insightsDataTableProps} />
      )}
    </PageTemplate>
  );
};
