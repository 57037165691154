import { useState } from 'react';
import { Tab, Tabs } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { DetectPixel } from './DetectPixel';

enum ToolsTab {
  DetectPixel = 'detect_pixel',
}

const TOOLS_TABS: Tab[] = [{ label: 'Detect pixel', value: ToolsTab.DetectPixel }];

export const Tools = () => {
  const [tab, setTab] = useState(ToolsTab.DetectPixel);

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Tools
          </Text>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Tabs tabs={TOOLS_TABS} value={tab} onChange={setTab} />
      <Spacing />
      {tab === ToolsTab.DetectPixel && <DetectPixel />}
    </PageTemplate>
  );
};
