import { useEffect } from 'react';
import { DEFAULT_THEME } from 'src/constants';
import { useAppSelector } from 'src/store';

export const useWhiteLabel = () => {
  const whiteLabel = useAppSelector((state) => state.user.whiteLabel);

  useEffect(() => {
    if (whiteLabel) {
      // set title & favicon
      document.title = whiteLabel.branding?.meta_title || DEFAULT_THEME.title;
      const faviconElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      const favicon = whiteLabel.branding?.favicon || DEFAULT_THEME.favicon;
      if (faviconElement.href !== favicon) {
        faviconElement.href = favicon;
      }
    }
  }, [whiteLabel]);
};
