import { TOKENS } from 'src/design';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Spacing, Text } from 'src/components/Layout';
import { useWhiteLabelTheme } from 'src/hooks';

type AuthTemplateProps = {
  title: string;
  children?: ReactNode;
};

export const AuthTemplate = (props: AuthTemplateProps) => {
  const { title, children } = props;

  const theme = useWhiteLabelTheme();

  return (
    <AuthContainer>
      <LeftContainer>
        <Logo src={theme.logo} />
        <Text size="xl" weight={600}>
          Easily plan, launch, & optimize your{' '}
          <Text as="span" color="primary">
            programmatic ad campaigns
          </Text>
        </Text>
        <Spacing />
        <Description>{theme.description}</Description>
      </LeftContainer>
      <RightContainer>
        <AuthForm>
          <Title>{title}</Title>
          <TitleDecoration />
          {children}
        </AuthForm>
      </RightContainer>
    </AuthContainer>
  );
};

const AuthContainer = styled.div`
  padding: 0 6rem;
  display: grid;
  align-items: center;
  gap: 4em;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: ${TOKENS.color.bgGray};
`;

const LeftContainer = styled.div`
  width: 60%;
  justify-self: end;
`;

const Logo = styled.img`
  width: 20rem;
  height: 20rem;
`;

const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
`;

const RightContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 1.2rem 2rem;
  display: flex;
  justify-content: center;
`;

const AuthForm = styled.div`
  width: 70%;
  box-sizing: border-box;
  background: white;
  border-radius: 1rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  padding: 2.4rem 3.2rem 3.2rem;
  border-top: solid 1rem ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.color.primary};
  font-weight: 700;
`;

const TitleDecoration = styled.div`
  background: ${(props) => props.theme.color.highlight};
  width: 3rem;
  height: 0.3rem;
  margin: 1.2rem 0 2.4rem;
`;
