import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';

const { usePlatformsQuery } = growthApi;

type AgencySelectProps<T> = ControlledSelectProps<T>;

export const PlatformSelect = <T,>(props: AgencySelectProps<T>) => {
  const { data, isFetching } = usePlatformsQuery({});

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return (
      data?.data?.map((platform) => ({
        label: platform.platform_name,
        value: platform.platform_key,
      })) || []
    );
  }, [isFetching, data]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select DSP" {...props} />;
};
