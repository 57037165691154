import { DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link, useNavigate } from 'react-router-dom';
import { growthApi } from 'src/services';
import { formatAmount, formatCount, formatOnlyDate, formatPercentageWithPrecision } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { pick } from 'lodash';
import { Archive } from 'src/components/Archive';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { Role } from 'src/types';

const { useForecastsQuery } = growthApi;

type PixelsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Forecasts = () => {
  const { canAccessAgency, isViewOnly } = useRole();
  const navigate = useNavigate();
  const { watch, control, setValue, reset } = useForm<PixelsFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });

  const { data, isFetching, error, refetch } = useForecastsQuery(pick(values, ['agency_id', 'advertiser_id']));

  const { dataTableProps } = useDataTable({
    idKey: 'id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: [
      'id',
      'advertiser_id',
      'advertiser_name',
      'audience_id',
      'audience_name',
      'campaign_budget',
      'campaign_start_date',
      'campaign_end_date',
    ],
    defaultSort: { key: 'id', direction: 'desc' },
    sortNumberKeys: ['id', 'campaign_budget', 'cpm_goal'],
    columns: [
      {
        header: 'ID',
        accessor: 'id',
        sortable: true,
      },
      {
        header: 'Advertiser',
        accessor: '_advertiser',
        render: (_, row) => `${row.advertiser_name} - ${row.advertiser_id}`,
      },
      {
        header: 'Audience',
        accessor: '_audience',
        render: (_, row) => (row.audience_name ? `${row.audience_name} - ${row.audience_id}` : '-'),
      },
      { header: 'Campaign Budget', accessor: 'campaign_budget', sortable: true, render: formatAmount },
      { header: 'Start Date', accessor: 'campaign_start_date', sortable: true, render: formatOnlyDate },
      { header: 'End Date', accessor: 'campaign_end_date', sortable: true, render: formatOnlyDate },
      {
        header: 'CPM Goal',
        accessor: 'cpm_goal',
        sortable: true,
        render: formatAmount,
      },
      {
        header: 'Max Data CPM',
        accessor: 'max_data_cpm',
        render: formatAmount,
      },
      {
        header: 'Impressions',
        accessor: 'cpm_impressions',
        render: formatCount,
      },
      {
        header: 'Uniques',
        accessor: 'cpm_uniques',
        render: formatCount,
      },
      {
        header: 'SOV',
        accessor: 'cpm_sov',
        // add enough precision
        render: (value) => formatPercentageWithPrecision(value, 10),
      },
      {
        header: 'Total Impressions',
        accessor: 'total_impressions',
        render: formatCount,
      },
      {
        header: 'Total Uniques',
        accessor: 'total_uniques',
        render: formatCount,
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (_, row) => (
          <Flex gap="md">
            <Action onClick={() => onLaunch(row)}>Launch</Action>
            <Archive type="forecast" typeId={row.id} onSuccess={() => refetch()} />
          </Flex>
        ),
      },
    ],
  });

  const onLaunch = (data: any) => {
    const campaign = {
      agency_id: data.agency_id,
      advertiser_id: data.advertiser_id,
      audience_id: data.audience_id,
      budget_amount: data.campaign_budget,
      schedule_start_date: new Date(data.campaign_start_date),
      schedule_end_date: new Date(data.campaign_end_date),
    };
    navigate('/activate/campaigns/new', {
      state: {
        campaign,
      },
    });
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Forecasts
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency: "
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => setValue('advertiser_id', null)}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput width="22rem" prefix="Search: " name="search" control={control} placeholder="Keyword" />
        </Flex>
        {!isViewOnly && (
          <Flex gap="xxl">
            <Link to="/activate/forecasts/new">
              <Button width="16rem" shadow>
                NEW FORECAST
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
    </PageTemplate>
  );
};
