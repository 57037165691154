import { CITIES, STATES } from 'src/constants';
import { ControlledSelectOption } from '../Form';

type InsightFilter = {
  label: string;
  name: string;
  options: ControlledSelectOption[];
};

const PROBABILITY_OPTIONS = [
  {
    label: '0% - 10%',
    value: '0% - 10%',
  },
  {
    label: '11% - 20%',
    value: '11% - 20%',
  },
  {
    label: '21% - 30%',
    value: '21% - 30%',
  },
  {
    label: '31% - 40%',
    value: '31% - 40%',
  },
  {
    label: '41% - 50%',
    value: '41% - 50%',
  },
  {
    label: '51% - 60%',
    value: '51% - 60%',
  },
  {
    label: '61% - 70%',
    value: '61% - 70%',
  },
  {
    label: '71% - 80%',
    value: '71% - 80%',
  },
  {
    label: '81% - 90%',
    value: '81% - 90%',
  },
  {
    label: '91% - 100%',
    value: '91% - 100%',
  },
];

export const INSIGHT_FILTERS: InsightFilter[] = [
  {
    label: 'State',
    name: 'state',
    options: STATES.map((value) => ({ label: value, value })),
  },
  {
    label: 'City',
    name: 'city',
    options: CITIES.map((value) => ({ label: value, value })),
  },
  {
    label: 'Gender',
    name: 'gender',
    options: [
      {
        label: 'male',
        value: 'male',
      },
      {
        label: 'female',
        value: 'female',
      },
    ],
  },
  {
    label: 'Marital status',
    name: 'martialStatus',
    options: [
      {
        label: 'married',
        value: 'married',
      },
      {
        label: 'not married',
        value: 'not married',
      },
    ],
  },
  {
    label: 'Dwelling type',
    name: 'dwellingType',
    options: [
      {
        label: 'single family',
        value: 'single family',
      },
      {
        label: 'multi family',
        value: 'multi family',
      },
    ],
  },
  {
    label: 'Education level',
    name: 'educationLevel',
    options: [
      {
        label: 'high school',
        value: 'high school',
      },
      {
        label: 'college',
        value: 'college',
      },
      {
        label: 'graduate',
        value: 'graduate',
      },
      {
        label: 'vocational',
        value: 'vocational',
      },
    ],
  },
  {
    label: 'Household income',
    name: 'homeownerIncome',
    options: [
      {
        label: 'under $15000',
        value: 'under $15000',
      },
      {
        label: '$15000 - $24999',
        value: '$15000 - $24999',
      },
      {
        label: '$25000 - $34999',
        value: '$25000 - $34999',
      },

      {
        label: '$35000 - $49999',
        value: '$35000 - $49999',
      },
      {
        label: '$50000 - $74999',
        value: '$50000 - $74999',
      },
      {
        label: '$75000 - $99999',
        value: '$75000 - $99999',
      },
      {
        label: '$100000 - $149999',
        value: '$100000 - $149999',
      },
      {
        label: '$150000 - $159999',
        value: '$150000 - $159999',
      },
      {
        label: '$160000 - $164999',
        value: '$160000 - $164999',
      },
      {
        label: '$165000 - $199999',
        value: '$165000 - $199999',
      },
      {
        label: '$200000 - $249999',
        value: '$200000 - $249999',
      },
      {
        label: '$250000+',
        value: '$250000+',
      },
    ],
  },
  {
    label: 'Household size',
    name: 'householdSize',
    options: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
      {
        label: '6',
        value: '6',
      },
      {
        label: '7',
        value: '7',
      },
      {
        label: '8',
        value: '8',
      },
      {
        label: '9',
        value: '9',
      },
    ],
  },
  {
    label: 'Homeowner status',
    name: 'homeownerStatus',
    options: [
      {
        label: 'homeowner',
        value: 'homeowner',
      },
      {
        label: 'renter',
        value: 'renter',
      },
    ],
  },
  {
    label: 'Networth',
    name: 'netWorth',
    options: [
      {
        label: 'less than $50000',
        value: 'less than $50000',
      },
      {
        label: '$50000 - $99999',
        value: '$50000 - $99999',
      },
      {
        label: '$100000 - $249999',
        value: '$100000 - $249999',
      },

      {
        label: '$250000 - $499999',
        value: '$250000 - $499999',
      },
      {
        label: '$500000+',
        value: '$500000+',
      },
    ],
  },
  {
    label: 'Household presence',
    name: 'householdPresence',
    options: [
      {
        label: 'children',
        value: 'children',
      },
      {
        label: 'single adult',
        value: 'single adult',
      },
      {
        label: 'young adult',
        value: 'young adult',
      },
      {
        label: 'senior adult',
        value: 'senior adult',
      },
      {
        label: 'veteran',
        value: 'veteran',
      },
    ],
  },
  {
    label: 'Estimated age',
    name: 'estimatedAge',
    options: [
      {
        label: '18-24',
        value: '18-24',
      },
      {
        label: '25-29',
        value: '25-29',
      },
      {
        label: '30-34',
        value: '30-34',
      },
      {
        label: '35-39',
        value: '35-39',
      },
      {
        label: '40-44',
        value: '40-44',
      },
      {
        label: '45-49',
        value: '45-49',
      },
      {
        label: '50-54',
        value: '50-54',
      },
      {
        label: '55-59',
        value: '55-59',
      },
      {
        label: '60-64',
        value: '60-64',
      },
      {
        label: '65-69',
        value: '65-69',
      },
      {
        label: '70+',
        value: '70+',
      },
    ],
  },
  {
    label: "Buyer's spectator",
    name: 'buyerSpectator',
    options: [
      {
        label: 'football',
        value: 'football',
      },
      {
        label: 'baseball',
        value: 'baseball',
      },
      {
        label: 'basketball',
        value: 'basketball',
      },
      {
        label: 'hockey',
        value: 'hockey',
      },
      {
        label: 'sports tv',
        value: 'sports tv',
      },
      {
        label: 'auto',
        value: 'auto',
      },
      {
        label: 'soccer',
        value: 'soccer',
      },
    ],
  },
  {
    label: "Buyer's consideration",
    name: 'buyerConsideration',
    options: [
      {
        label: 'women apparel',
        value: 'women apparel',
      },
      {
        label: 'mail orders',
        value: 'mail orders',
      },
      {
        label: 'mail order responder',
        value: 'mail order responder',
      },
      {
        label: 'online products',
        value: 'online products',
      },
      {
        label: 'books and magazines',
        value: 'books and magazines',
      },
      {
        label: 'men apparel',
        value: 'men apparel',
      },
      {
        label: 'young women apparel',
        value: 'young women apparel',
      },
      {
        label: 'cosmetic products',
        value: 'cosmetic products',
      },
      {
        label: 'children products',
        value: 'children products',
      },
      {
        label: 'gardening products',
        value: 'gardening products',
      },
      {
        label: 'health and beauty products',
        value: 'health and beauty products',
      },
      {
        label: 'photography',
        value: 'photography',
      },
      {
        label: 'religious material',
        value: 'religious material',
      },
      {
        label: 'children apparel',
        value: 'children apparel',
      },
      {
        label: 'big and tall men apparel',
        value: 'big and tall men apparel',
      },
      {
        label: 'petite women apparel',
        value: 'petite women apparel',
      },
      {
        label: 'plus women apparel',
        value: 'plus women apparel',
      },
      {
        label: 'young men apparel',
        value: 'young men apparel',
      },
      {
        label: 'audio books and music',
        value: 'audio books and music',
      },
      {
        label: 'auto',
        value: 'auto',
      },
      {
        label: 'high end appliances',
        value: 'high end appliances',
      },
      {
        label: 'hunting and shooting',
        value: 'hunting and shooting',
      },
      {
        label: 'jewelry',
        value: 'jewelry',
      },
      {
        label: 'lifestyles',
        value: 'lifestyles',
      },
      {
        label: 'luggage',
        value: 'luggage',
      },
      {
        label: 'sports',
        value: 'sports',
      },
      {
        label: 'value priced merchandise',
        value: 'value priced merchandise',
      },
    ],
  },
  {
    label: "Buyer's interest",
    name: 'buyerInterest',
    options: [
      {
        label: 'domestic travel',
        value: 'domestic travel',
      },
      {
        label: 'international travel',
        value: 'international travel',
      },
      {
        label: 'travel',
        value: 'travel',
      },
      {
        label: 'working women',
        value: 'working women',
      },
      {
        label: 'collector',
        value: 'collector',
      },
      {
        label: 'career',
        value: 'career',
      },
      {
        label: 'children',
        value: 'children',
      },
      {
        label: 'coins',
        value: 'coins',
      },
      {
        label: 'collectibles',
        value: 'collectibles',
      },
      {
        label: 'sports',
        value: 'sports',
      },
      {
        label: 'cooking',
        value: 'cooking',
      },
      {
        label: 'cruise',
        value: 'cruise',
      },
      {
        label: 'dog',
        value: 'dog',
      },
      {
        label: 'walking',
        value: 'walking',
      },
      {
        label: 'health medical',
        value: 'health medical',
      },
      {
        label: 'home furnishings',
        value: 'home furnishings',
      },
      {
        label: 'hunting',
        value: 'hunting',
      },
      {
        label: 'parenting',
        value: 'parenting',
      },
      {
        label: 'pets',
        value: 'pets',
      },
      {
        label: 'knitting',
        value: 'knitting',
      },
      {
        label: 'nascar',
        value: 'nascar',
      },
      {
        label: 'natural foods',
        value: 'natural foods',
      },
      {
        label: 'sweepstakes',
        value: 'sweepstakes',
      },
      {
        label: 'camping',
        value: 'camping',
      },
      {
        label: 'lifestyles',
        value: 'lifestyles',
      },
      {
        label: 'military',
        value: 'military',
      },
      {
        label: 'computing home office',
        value: 'computing home office',
      },
      {
        label: 'gourmet cooking',
        value: 'gourmet cooking',
      },
      {
        label: 'crafts',
        value: 'crafts',
      },
      {
        label: 'diet',
        value: 'diet',
      },
      {
        label: 'food wines',
        value: 'food wines',
      },
      {
        label: 'home improvement',
        value: 'home improvement',
      },
      {
        label: 'grandchildren',
        value: 'grandchildren',
      },
      {
        label: 'photography',
        value: 'photography',
      },
      {
        label: 'arts',
        value: 'arts',
      },
      {
        label: 'arts antiques',
        value: 'arts antiques',
      },
      {
        label: 'auto',
        value: 'auto',
      },
      {
        label: 'aviation',
        value: 'aviation',
      },
      {
        label: 'board games',
        value: 'board games',
      },
      {
        label: 'sailing',
        value: 'sailing',
      },
      {
        label: 'cat',
        value: 'cat',
      },
      {
        label: 'antiques',
        value: 'antiques',
      },
      {
        label: 'stamps',
        value: 'stamps',
      },
      {
        label: 'art collectibles',
        value: 'art collectibles',
      },
      {
        label: 'education',
        value: 'education',
      },
      {
        label: 'aerobic',
        value: 'aerobic',
      },
      {
        label: 'running',
        value: 'running',
      },
      {
        label: 'fishing',
        value: 'fishing',
      },
      {
        label: 'gambling',
        value: 'gambling',
      },
      {
        label: 'golf',
        value: 'golf',
      },
      {
        label: 'home gardening',
        value: 'home gardening',
      },
      {
        label: 'diy',
        value: 'diy',
      },
      {
        label: 'house plants',
        value: 'house plants',
      },
      {
        label: 'career improvement',
        value: 'career improvement',
      },
      {
        label: 'christian family',
        value: 'christian family',
      },
      {
        label: 'equestrian',
        value: 'equestrian',
      },
      {
        label: 'motorcycling',
        value: 'motorcycling',
      },
      {
        label: 'musical instruments',
        value: 'musical instruments',
      },
      {
        label: 'other pet',
        value: 'other pet',
      },
      {
        label: 'scuba diving',
        value: 'scuba diving',
      },
      {
        label: 'self improvement',
        value: 'self improvement',
      },
      {
        label: 'snow skiing',
        value: 'snow skiing',
      },
      {
        label: 'tennis',
        value: 'tennis',
      },
      {
        label: 'woodworking',
        value: 'woodworking',
      },
    ],
  },
  {
    label: "Buyer's entertainment",
    name: 'buyerEntertainment',
    options: [
      {
        label: 'pc games',
        value: 'pc games',
      },
      {
        label: 'videos games',
        value: 'videos games',
      },
      {
        label: 'computers',
        value: 'computers',
      },
      {
        label: 'music home stereo',
        value: 'music home stereo',
      },
      {
        label: 'gaming',
        value: 'gaming',
      },
      {
        label: 'dvds',
        value: 'dvds',
      },
      {
        label: 'tv movies',
        value: 'tv movies',
      },
      {
        label: 'music player',
        value: 'music player',
      },
      {
        label: 'music listener',
        value: 'music listener',
      },
      {
        label: 'movies',
        value: 'movies',
      },
      {
        label: 'tv',
        value: 'tv',
      },
      {
        label: 'satellite',
        value: 'satellite',
      },
      {
        label: 'theater',
        value: 'theater',
      },
      {
        label: 'music',
        value: 'music',
      },
    ],
  },
  {
    label: "Buyer's donor type",
    name: 'buyerDonorType',
    options: [
      {
        label: 'charitable',
        value: 'charitable',
      },
      {
        label: 'political',
        value: 'political',
      },
      {
        label: 'children',
        value: 'children',
      },
      {
        label: 'health',
        value: 'health',
      },
      {
        label: 'political conser',
        value: 'political conser',
      },
      {
        label: 'community',
        value: 'community',
      },
      {
        label: 'religious',
        value: 'religious',
      },
      {
        label: 'veteran',
        value: 'veteran',
      },
      {
        label: 'contribution',
        value: 'contribution',
      },
      {
        label: 'mail order',
        value: 'mail order',
      },
      {
        label: 'animal',
        value: 'animal',
      },
      {
        label: 'arts',
        value: 'arts',
      },
      {
        label: 'wildlife',
        value: 'wildlife',
      },
      {
        label: 'environment',
        value: 'environment',
      },
      {
        label: 'international aid',
        value: 'international aid',
      },
      {
        label: 'political liberal',
        value: 'political liberal',
      },
      {
        label: 'other',
        value: 'other',
      },
    ],
  },
  {
    label: 'Conservative Party Probability',
    name: 'Conservative Party Probability',
    options: PROBABILITY_OPTIONS,
  },
  {
    label: 'Democrat Party Probability',
    name: 'Democrat Party Probability',
    options: PROBABILITY_OPTIONS,
  },
  {
    label: 'Independent Party Probability',
    name: 'Independent Party Probability',
    options: PROBABILITY_OPTIONS,
  },
  {
    label: 'Liberal Party Probability',
    name: 'Liberal Party Probability',
    options: PROBABILITY_OPTIONS,
  },
  {
    label: 'Libertarian Party Probability',
    name: 'Libertarian Party Probability',
    options: PROBABILITY_OPTIONS,
  },
  {
    label: 'Republican Party Probability',
    name: 'Republican Party Probability',
    options: PROBABILITY_OPTIONS,
  },
];
