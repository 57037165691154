import { Button, Input } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { getApiErrorMessage } from 'src/utils';
import { useAppDispatch, userSlice } from 'src/store';

const { useUpdateProfileMutation } = growthApi;

type ChangeCompanyNameValues = {
  company_name: string;
};

export const ChangeCompanyName = () => {
  const [updateProfile] = useUpdateProfileMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<ChangeCompanyNameValues>();

  const values = watch();
  const isDisabled = !values.company_name;

  const onSubmit = async (values: ChangeCompanyNameValues) => {
    try {
      const { data } = await updateProfile(values).unwrap();
      dispatch(userSlice.actions.updateUser(data));
      showSuccessToast("Company name changed successfully!");
      navigate('/settings/profile')
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Change Company Name">
      <Description>Enter company name</Description>
      <Spacing size="3rem" />
      <Input {...register('company_name')} placeholder="Enter company name" icon="users" />
      <Spacing size="3rem" />
      <Button disabled={isDisabled} isLoading={isSubmitting} onClick={handleSubmit(onSubmit)}>
        Submit
      </Button>
      <Spacing size="2.4rem" />
      <Link to="/settings/profile">Back</Link>
    </AuthTemplate>
  );
};

const Description = styled.div`
  color: ${(props) => props.theme.color.grayLight};
  font-size: 1.2rem;
`;