import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  ControlledCard,
  ControlledCheckBoxGroup,
  ControlledCheckboxGroupOption,
  ControlledInput,
  Form,
  FormItem,
} from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { useRole, useToast, useWhiteLabelTheme } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmount, getApiErrorMessage, imageUrl } from 'src/utils';
import styled from 'styled-components';

const { useUpdateAdWalletPaymentMethodMutation } = growthApi;

enum PaymentTerm {
  TermsAndConditions,
  FirstCharge,
}

type BindingCreditCardFormValues = {
  customer?: {
    name?: string;
    email?: string;
    address?: {
      line1?: string;
    };
  };
  card?: any;
  paymentTerms: PaymentTerm[];
};

export const BindingCreditCard = () => {
  const [searchParams] = useSearchParams();
  const agencyId = searchParams.get('agency_id');
  const advertiserId = searchParams.get('advertiser_id');
  const { whiteLabelId } = useRole();
  const { watch, control, handleSubmit } = useForm<BindingCreditCardFormValues>();
  const [updateAdWalletPaymentMethod, { isLoading }] = useUpdateAdWalletPaymentMethodMutation();
  const { showErrorToast } = useToast();
  const theme = useWhiteLabelTheme();
  const [isBindingSuccess, setIsBindingSuccess] = useState<boolean>(false);
  const values = watch();

  const disabled = !values.paymentTerms || values.paymentTerms.length < 2;

  const onSubmit = async (values: BindingCreditCardFormValues) => {
    try {
      await updateAdWalletPaymentMethod({
        ...values,
        agency_id: agencyId,
        advertiser_id: advertiserId,
        white_label_id: whiteLabelId,
      }).unwrap();
      setIsBindingSuccess(true);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const PAYMENT_TERM_OPTIONS: ControlledCheckboxGroupOption[] = [
    {
      label: (
        <Text size="xs">
          I understand & accept the{' '}
          <a target="_blank" href={theme.termsLink} rel="noreferrer">
            <Text as="span" color="primary">
              Terms and Conditions
            </Text>
          </a>
          .
        </Text>
      ),
      value: PaymentTerm.TermsAndConditions,
    },
    {
      label: (
        <Text size="xs">
          I understand & accept that upon successfully adding a payment method, I will be charged a deposit of{' '}
          <Text as="span" weight={600}>
            {formatAmount(theme.initialCharge)}
          </Text>
        </Text>
      ),
      value: PaymentTerm.FirstCharge,
    },
  ];

  return (
    <Container>
      <LogoContainer>
        <img src={theme.logo} alt="logo" />
      </LogoContainer>
      <Spacing />
      <BindingCreditCardForm>
        <Text size="lg" weight={600}>
          Binding Your Credit Card
        </Text>
        <Spacing size="sm" />
        <Text size="sm" color="gray">
          We will properly safeguard your credit card information.
        </Text>
        <Spacing size="xxl" />
        {isBindingSuccess ? (
          <Flex direction="column" gap="md" justify="center" align="center">
            <Icon type="on" color="success" size="xxxl" />
            <Text weight={600}>Your credit card is binding successfully!</Text>
          </Flex>
        ) : (
          <>
            <Form>
              <FormItem label="Name">
                <ControlledInput name="customer.name" control={control} placeholder="Enter name" />
              </FormItem>
              <FormItem label="Email">
                <ControlledInput name="customer.email" control={control} placeholder="Enter email" />
              </FormItem>
              <FormItem label="Address">
                <ControlledInput name="customer.address.line1" control={control} placeholder="Enter address" />
              </FormItem>
              <FormItem label="Card">
                <ControlledCard name="card" control={control} />
              </FormItem>
              <FormItem label="Payment Terms">
                <ControlledCheckBoxGroup
                  name="paymentTerms"
                  control={control}
                  options={PAYMENT_TERM_OPTIONS}
                  vertical
                />
              </FormItem>
            </Form>
            <Spacing size="xxl" />
            <Button width="40rem" shadow disabled={disabled} isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
              SUBMIT
            </Button>
            <Spacing />
            <StripeImageContainer>
              <img src={imageUrl('stripe-badge-transparent.png')} alt="stripe" />
            </StripeImageContainer>
          </>
        )}
      </BindingCreditCardForm>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  background: ${TOKENS.color.bgGray};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem 0;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 9rem;
    height: 9rem;
  }
`;

const StripeImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 12rem;
  }
`;

const BindingCreditCardForm = styled.div`
  width: 50%;
  box-sizing: border-box;
  background: white;
  border-radius: 1rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  padding: 2.4rem 3.2rem 3.2rem;
  border-top: solid 1rem ${(props) => props.theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
