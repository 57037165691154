import { TOKENS } from 'src/design';
import { growthApi } from 'src/services';
import styled from 'styled-components';

const { useCreativePreviewQuery } = growthApi;

type CreativePreviewProps = {
  creativeName?: string;
  advertiserId?: number;
  maxWidth?: string;
};

const PREVIEW_REG = /\.(jpg|jpeg|png|gif)$/;

export const CreativePreview = (props: CreativePreviewProps) => {
  const { creativeName, advertiserId, maxWidth } = props;

  const disablePreview = !PREVIEW_REG.test(creativeName);

  const { data, isLoading } = useCreativePreviewQuery(
    {
      creative_name: creativeName,
      advertiser_id: advertiserId,
    },
    {
      skip: disablePreview,
    },
  );

  if (disablePreview) {
    return null;
  }

  return isLoading ? (
    <ImagePlaceholder />
  ) : data?.data ? (
    <CreativePreviewImage maxWidth={maxWidth} src={data.data} />
  ) : null;
};

const ImagePlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background: ${TOKENS.color.bgGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreativePreviewImage = styled.img<{ maxWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  aspect-ratio: 1/1;
  object-fit: contain;
  background: ${TOKENS.color.bgGray};
`;
