import { TOKENS } from "src/design";
import { Flex, Spacing, Text } from 'src/components/Layout';
import styled from "styled-components";

import { useAppSelector } from "src/store";
import useNotificationsToggle from "src/hooks/useNotificationsToggle";
import { Icon } from "../Icon";
import { Action, Checkbox } from "../Form";
import { growthApi } from "src/services";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useToast } from "src/hooks";
import { getApiErrorMessage, imageUrl } from "src/utils";
import { Loading } from "../Loading";

const {
  useGetNotificationsQuery,
  useLazyReadNotificationQuery,
  useLazyReadAllNotificationQuery,
} = growthApi;
const formatDate = (date: string) => format(new Date(date), 'MMM do yyyy');

type Notification = {
  id: number,
  text: string,
}

const Notification = (props: Notification) => {
  const [isChecked, setIsChecked] = useState(false);

  const { showErrorToast } = useToast();
  const [readNotification] = useLazyReadNotificationQuery();

  const { id, text } = props;

  const onReadClick = async () => {
    if (!isChecked) {
      setIsChecked(true);
      try {
        await readNotification({ id: id }).unwrap();
      }
      catch (error) {
        setIsChecked(false);
        showErrorToast(getApiErrorMessage(error));
      }
    }
  }

  return (
    <NotificationContainer>
      <Flex gap="lg">
        <Checkbox value={isChecked} onChange={onReadClick} />
        <Text weight={500} size="xs" breakWord dangerouslySetInnerHTML={{ __html: text }} />
      </Flex>
      <Spacing size="xl" />
    </NotificationContainer>
  )
}

type NotificationGroupProps = {
  group_date: string,
  notifications: Notification[],
};

const NotificationsGroup = (props: NotificationGroupProps) => {
  const { group_date, notifications } = props;
  return (
    <>
      <Text size="xl" weight={400}>
        {formatDate(group_date)}
      </Text>
      <Spacing size="lg" />
      {notifications.map(notification =>
        <Notification {...notification} />)}
      <Spacing size="lg" />
    </>
  )
}


export const NotificationSideBar = () => {
  const showNotifications = useAppSelector((state) => state.notificationsSidebarSlice);
  const toggleNotifications = useNotificationsToggle();

  const [readAllQuery] = useLazyReadAllNotificationQuery();
  const { data, refetch, isLoading } = useGetNotificationsQuery({});

  useEffect(() => {
    if (showNotifications) {
      refetch();
    }
  }, [showNotifications])

  const readAll = async () => {
    await readAllQuery({}).unwrap();
    await refetch().unwrap();
  }

  return showNotifications && (
    <>
      <TransparentContent onClick={() => toggleNotifications()} />
      <SidebarContainer>
        <CancelButtonContainer>
          <Icon type='back' size="4rem" clickable onClick={() => toggleNotifications()} />
        </CancelButtonContainer>
        <Flex justify="space-between" align="end">
          <Text size="xl" weight={700}>
            Notifications
          </Text>
          <Action onClick={readAll}>
            <Text size="sm">mark all as read</Text>
          </Action>
        </Flex>
        <Spacing size="xl" />
        {isLoading ? (
          <Flex direction="column" justify="center" align="center" height="60%" gap="lg">
            <Loading />
          </Flex>
        ) : (
          Object.keys(data?.data || {}).length ? (
            Object.keys(data?.data).map(notificationGroupKey =>
              <NotificationsGroup
                group_date={notificationGroupKey}
                notifications={data.data[notificationGroupKey]}
              />
              )
            ) : (
              <>
                  <Flex direction="column" justify="center" align="center" height="60%" gap="lg">
                    <img width='40rem' alt='checked' src={imageUrl('checked.svg')} />
                    <Text weight={700}>You're all caught up!</Text>
                  </Flex>
                </>
              )
        )}
      </SidebarContainer >
    </>
  )

}

const TransparentContent = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background:rgba(255,255,255,0.5);
  z-index: 899;
`;

const SidebarContainer = styled.div`
  position: fixed;
  height: 96%;
  right 1.5rem;
  width: 30%;
  border-radius: 1.5rem;
  background-color: ${TOKENS.color.white};
  z-index: 900;
  box-sizing: border-box;
  padding: 2.5rem;
  box-shadow: ${TOKENS.shadow.default};
  overflow-y:scroll;
  overflow-x:hidden;
`;

const CancelButtonContainer = styled.div`
  text-align:right;
`;

const NotificationContainer = styled.div`
  word-wrap: break-word;
`;