import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  ControlledFile,
  ControlledInput,
  ControlledTextArea,
  Form,
  FormItem,
  Tab,
  Tabs,
} from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useToast, useWhiteLabelTheme } from 'src/hooks';
import { growthApi } from 'src/services';
import { fileToBase64, getApiErrorMessage } from 'src/utils';

const {
  useWhiteLabelQuery,
  useUpdateWhiteLabelMutation,
  useLazyConnectWhiteLabelQuery,
  useLazyRefreshConnectWhiteLabelQuery,
  useLazyFinishConnectWhiteLabelQuery,
} = growthApi;

const TABS: Tab[] = [
  { label: 'Branding', value: 'branding' },
  { label: 'Payment', value: 'payment' },
];

enum OnBoardingState {
  NONE = 'none',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

type WhiteLabelFormValues = {
  custom_domain?: string;
  branding?: {
    meta_title?: string;
    meta_description?: string;
    favicon?: File;
    logo?: File;
    favicon_url?: string;
    logo_url?: string;
    color_primary?: string;
    color_primary_deep?: string;
    color_highlight?: string;
    pending_access_message?: string;
    terms_link?: string;
    support_email?: string;
  };
  payment?: {
    markup?: string;
    initial_charge?: string;
  };
};

export const WhiteLabel = () => {
  const { data, isLoading, refetch } = useWhiteLabelQuery({});
  const { watch, reset, control, handleSubmit } = useForm<WhiteLabelFormValues>();
  const values = watch();
  const [connectWhiteLabel] = useLazyConnectWhiteLabelQuery();
  const [refreshOnboarding] = useLazyRefreshConnectWhiteLabelQuery();
  const [finishOnboarding] = useLazyFinishConnectWhiteLabelQuery();
  const [updateWhiteLabel, { isLoading: isUpdateWhiteLabelLoading }] = useUpdateWhiteLabelMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const [tab, setTab] = useState<string>('branding');
  const [isConnectStripeDsisabled, setIsConnectStripeDsisabled] = useState(true);
  const theme = useWhiteLabelTheme();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (data?.data) {
      reset({
        ...data.data,
        branding: {
          ...data.data.branding,
          logo: undefined,
          favicon: undefined,
          logo_url: data.data.branding?.logo,
          favicon_url: data.data.branding?.favicon,
        },
      });
    }
    setIsConnectStripeDsisabled(!Boolean(data?.data?.custom_domain));
  }, [data?.data, reset, isConnectStripeDsisabled]);

  useEffect(() => {
    const done = Number(searchParams.get('done'));
    const refresh = Number(searchParams.get('refresh'));
    if (refresh) {
      setTab(TABS[1].value);
    } else if (done) {
      const finishAndRefetch = async () => {
        await finishOnboarding({}).unwrap();
        showSuccessToast('Onboarding finished');
        refetch();
      };
      try {
        finishAndRefetch();
      } catch (error) {
        showErrorToast(getApiErrorMessage(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getOnBoardingState = () => {
    if (data?.data?.onboarding_link) {
      return OnBoardingState.IN_PROGRESS;
    } else if (data?.data?.payment?.stripe_account_id) {
      return OnBoardingState.DONE;
    }
    return OnBoardingState.NONE;
  };

  const onConnectClick = async () => {
    try {
      const result = await connectWhiteLabel({}).unwrap();
      showSuccessToast('Stripe account created, please proceed with onboarding');
      window.open(result.data.on_boarding_link, '_blank').focus();
      refetch();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const continueOnboarding = async () => {
    try {
      const result = await refreshOnboarding({}).unwrap();
      window.open(result.data, '_blank').focus();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onSubmit = async (values: WhiteLabelFormValues) => {
    try {
      await updateWhiteLabel({
        ...values,
        branding: {
          ...values.branding,
          logo: values.branding?.logo ? await fileToBase64(values.branding.logo) : undefined,
          favicon: values.branding?.favicon ? await fileToBase64(values.branding.favicon) : undefined,
        },
      }).unwrap();
      showSuccessToast('Save White Label successfully, please access the custom domain to see the effect');
      refetch();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onResetStyle = () => {
    reset({
      branding: {},
    });
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Text size="xxl" weight={700}>
        White Label
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        <FormItem label="Custom domain" required>
          <Flex direction="column" gap="sm">
            <ControlledInput name="custom_domain" control={control} placeholder="dsp.example.com" />
            <Text size="xs">You will receive an email in few minutes to setup your custom domain DNS records.</Text>
            <Text size="xs">
              We have a limitation on changing DNS records for the next 30 minutes. Otherwise, please{' '}
              <Text as="span" color="primary">
                <a href={`mailto: ${theme.supportEmail}`}>send a message to support</a>
              </Text>{' '}
              if you need help with DNS settings.
            </Text>
          </Flex>
        </FormItem>
        <FormItem>
          <Tabs value={tab} onChange={setTab} tabs={TABS} />
        </FormItem>
        {tab === 'branding' && (
          <>
            <FormItem label="Meta title">
              <ControlledInput name="branding.meta_title" control={control} placeholder="Enter your site meta title" />
            </FormItem>
            <FormItem label="Meta description">
              <ControlledTextArea
                rows={3}
                name="branding.meta_description"
                control={control}
                placeholder="Enter your site meta description"
              />
            </FormItem>
            <FormItem label="Favicon">
              <ControlledFile
                name="branding.favicon"
                filename={values.branding?.favicon_url}
                control={control}
                notes={
                  <Flex direction="column" justify="start" gap="sm" width="100%">
                    <Text size="xs">.jpg, .png only (100KB max)</Text>
                    <Text size="xs">
                      <Text as="span" weight={500}>
                        Dimensions:
                      </Text>{' '}
                      128x128, 256x256
                    </Text>
                  </Flex>
                }
              />
            </FormItem>
            <FormItem label="Logo">
              <ControlledFile
                name="branding.logo"
                filename={values.branding?.logo_url}
                control={control}
                notes={
                  <Flex direction="column" justify="start" gap="sm" width="100%">
                    <Text size="xs">.jpg, .png only (500KB max)</Text>
                    <Text size="xs">
                      <Text as="span" weight={500}>
                        Dimensions:
                      </Text>{' '}
                      256x256, 512x512
                    </Text>
                  </Flex>
                }
              />
            </FormItem>
            <FormItem label="Primary color">
              <ControlledInput name="branding.color_primary" control={control} placeholder="#00A57C" />
            </FormItem>
            <FormItem label="Primary deep color">
              <ControlledInput name="branding.color_primary_deep" control={control} placeholder="#008866" />
            </FormItem>
            <FormItem label="Highlight color">
              <ControlledInput name="branding.color_highlight" control={control} placeholder="#F1B532" />
            </FormItem>
            <FormItem label="Pending access message">
              <ControlledTextArea
                name="branding.pending_access_message"
                control={control}
                placeholder="Enter pending access message"
                rows={3}
              />
            </FormItem>
            <FormItem label="Terms & Conditions link">
              <ControlledInput
                name="branding.terms_link"
                control={control}
                placeholder="https://dsp.example.com/terms-conditions-link"
              />
            </FormItem>
            <FormItem label="Support email">
              <ControlledInput name="branding.support_email" control={control} placeholder="Enter support email" />
            </FormItem>
          </>
        )}
        {tab === 'payment' && (
          <>
            {getOnBoardingState() === OnBoardingState.NONE && (
              <>
                <Button width="50%" onClick={onConnectClick} disabled={isConnectStripeDsisabled}>
                  Create Stripe Account
                </Button>
                {isConnectStripeDsisabled && (
                  <Text color="red" size="xs">
                    *fill custom domain and save
                  </Text>
                )}
              </>
            )}
            {getOnBoardingState() === OnBoardingState.IN_PROGRESS && (
              <Button width="50%" onClick={continueOnboarding}>
                Continue onboarding
              </Button>
            )}
            {getOnBoardingState() === OnBoardingState.DONE && (
              <>
                <FormItem label="Markup %">
                  <ControlledInput name="payment.markup" control={control} placeholder="Enter markup" suffix="%" />
                </FormItem>
                <FormItem label="Initial charge to verify new credit card $">
                  <ControlledInput
                    name="payment.initial_charge"
                    control={control}
                    placeholder="Enter initial charge amount"
                    prefix="$"
                  />
                </FormItem>
              </>
            )}
          </>
        )}
      </Form>
      <Spacing size="xxl" />
      <Flex gap="xl">
        <Button width="18rem" shadow onClick={handleSubmit(onSubmit)} isLoading={isUpdateWhiteLabelLoading}>
          SAVE CHANGES
        </Button>
        <Button various="secondary" width="18rem" onClick={onResetStyle}>
          RESET BRANDING
        </Button>
      </Flex>
    </PageTemplate>
  );
};
