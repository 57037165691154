import { useMemo } from 'react';
import { useAppSelector } from 'src/store';
import { DEFAULT_THEME } from 'src/constants';
import { merge } from 'lodash';

export const useWhiteLabelTheme = () => {
  const whiteLabel = useAppSelector((state) => state.user.whiteLabel);

  const theme = useMemo(() => {
    const whiteLabelTheme = {
      title: whiteLabel?.branding?.meta_title || undefined,
      description: whiteLabel?.branding?.meta_description || undefined,
      favicon: whiteLabel?.branding?.favicon || undefined,
      logo: whiteLabel?.branding?.logo || undefined,
      color: {
        primary: whiteLabel?.branding?.color_primary || undefined,
        primaryDeep: whiteLabel?.branding?.color_primary_deep || undefined,
        highlight: whiteLabel?.branding?.color_highlight || undefined,
      },
      pendingAccessMessage: whiteLabel?.branding?.pending_access_message || undefined,
      termsLink: whiteLabel?.branding?.terms_link || undefined,
      supportEmail: whiteLabel?.branding?.support_email || undefined,
      markup: whiteLabel?.payment?.markup || undefined,
      initialCharge: whiteLabel?.payment?.initial_charge || undefined,
    };
    return merge(DEFAULT_THEME, whiteLabelTheme);
  }, [whiteLabel]);

  return theme;
};
