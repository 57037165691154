import { Button, Input } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { getApiErrorMessage } from 'src/utils';

const { useChangeEmailMutation } = growthApi;

type ChangeEmailFormValues = {
  new_email: string;
};

export const ChangeEmail = () => {
  const [changeEmail] = useChangeEmailMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<ChangeEmailFormValues>();

  const values = watch();
  const isDisabled = !values.new_email;

  const onSubmit = async (values: ChangeEmailFormValues) => {
    try {
      const apiResult = await changeEmail(values).unwrap();
      showSuccessToast(apiResult.message);
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Change Email">
      <Description>Enter your new email and we'll send the link on how to change it</Description>
      <Spacing size="3rem" />
      <Input {...register('new_email')} placeholder="Enter new email" icon="email" />
      <Spacing size="3rem" />
      <Button disabled={isDisabled} isLoading={isSubmitting} onClick={handleSubmit(onSubmit)}>
        Submit
      </Button>
      <Spacing size="2.4rem" />
      <Login to="/login">Login</Login>
    </AuthTemplate>
  );
};

const Description = styled.div`
  color: ${(props) => props.theme.color.grayLight};
  font-size: 1.2rem;
`;

const Login = styled(Link)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.primary};
  border-bottom: solid 0.2rem ${(props) => props.theme.color.primary};
  text-decoration: none;
`;
