import { imageUrl } from 'src/utils';
import { Flex, Text } from '../Layout';

type InfoProps = {
  message: string;
};

export const ReportingInfo = (props: InfoProps) => {
  const { message } = props;

  return (
    <Flex height="50rem" direction="column" justify="center" align="center" gap="xl">
      <img alt="info" src={imageUrl('info.svg')} />
      <Text size="sm">{message}</Text>
    </Flex>
  );
};
