import { useForm, useFormContext } from 'react-hook-form';
import { FormItem } from 'src/components/Form';
import { useEffect, useMemo } from 'react';
import { PixelSelect } from 'src/components/PixelSelect';
import { growthApi } from 'src/services';
import { keyBy } from 'lodash';
import { AudienceRule } from 'src/types';
import { formatArray } from 'src/utils';

const { usePixelsQuery } = growthApi;

export const CUSTOM_RULE_PIXELS_ID = 25;

type PixelsFormValues = {
  pixels?: number[];
};

type CustomRuleTypePixelsProps = {
  rule?: AudienceRule;
  agencyId?: number;
  advertiserId?: number;
};

export const CustomRuleTypePixels = (props: CustomRuleTypePixelsProps) => {
  const { rule, agencyId, advertiserId } = props;

  const { control, watch, reset } = useForm<PixelsFormValues>({});
  const { data: pixelsData } = usePixelsQuery({ agency_id: agencyId, advertiser_id: advertiserId });
  const { setValue: setParentValue } = useFormContext();
  const values = watch();

  useEffect(() => {
    if (rule) {
      reset({
        pixels: formatArray(rule.rule_value),
      });
    }
  }, [reset, rule]);

  const pixelsByUuid = useMemo(() => {
    return keyBy(pixelsData?.data, 'uuid');
  }, [pixelsData?.data]);

  useEffect(() => {
    setParentValue('rule_value', values.pixels);
    setParentValue('rule_value_name', values.pixels?.map((pixelId) => pixelsByUuid?.[pixelId]?.pixel_name)?.join(','));
  }, [setParentValue, values.pixels, pixelsByUuid]);

  return (
    <>
      <FormItem label="Pixels">
        <PixelSelect name="pixels" agencyId={agencyId} advertiserId={advertiserId} control={control} multiple />
      </FormItem>
    </>
  );
};
