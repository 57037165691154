import { PageTemplate } from 'src/components/Template';
import { DataTable, DataTableExport } from 'src/components/DataTable';
import { growthApi } from 'src/services';
import { formatAmount } from 'src/utils';
import { ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { format } from 'date-fns-tz';
import styled from 'styled-components';
import { TOKENS } from 'src/design';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { sumBy } from 'lodash';
import { ReportingInfo } from 'src/components/ReportingInfo';
import { Role } from 'src/types';

const { useTransactionsQuery, useCampaignPerformanceQuery } = growthApi;

type FinanceReportFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  date_from?: Date;
  date_to?: Date;
  search?: string;
  billing_date?: Date;
  billing_advertiser_id?: number;
};

export const FinanceReport = () => {
  const { canAccessAgency } = useRole();
  const { control, watch, setValue, reset } = useForm<FinanceReportFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });

  const isNotSelectAgencyAdvertiser = !values.agency_id || !values.advertiser_id;

  const {
    data: transactionData,
    isFetching: isTransactionFetching,
    error: transactionError,
  } = useTransactionsQuery(
    {
      agency_id: values.agency_id,
      advertiser_id: values.advertiser_id,
      date_from: values.date_from ? format(values.date_from, 'yyyy-MM-dd') : undefined,
      date_to: values.date_to ? format(values.date_to, 'yyyy-MM-dd') : undefined,
    },
    {
      skip: isNotSelectAgencyAdvertiser,
    },
  );

  const {
    data: reportDayData,
    isFetching: isReportDayFetching,
    error: reportDayError,
  } = useCampaignPerformanceQuery({
    agency_id: values.agency_id,
    advertiser_id: values.advertiser_id,
    date_from: values.date_from ? format(values.date_from, 'yyyy-MM-dd') : undefined,
    date_to: values.date_to ? format(values.date_to, 'yyyy-MM-dd') : undefined,
    breakout: 'day',
  });

  const financeReportData = useMemo(() => {
    const financeReportMap = new Map();
    if (transactionData?.data && reportDayData?.data) {
      // set spend, markup
      for (const report of reportDayData.data) {
        const day = format(new Date(report.start_date), 'yyyy-MM-dd');
        financeReportMap.set(day, {
          advertiser_name: report.advertiser_name,
          day,
          admin_spend: Number(report.total_spend),
          admin_our_markup: Number(report.total_spend_our_markup) - Number(report.total_spend),
          admin_wl_markup: Number(report.total_spend_markup) - Number(report.total_spend_our_markup),
          wl_spend: Number(report.total_spend_our_markup),
          wl_markup: Number(report.total_spend_markup) - Number(report.total_spend_our_markup),
          charge: 0,
        });
      }
      // set charge
      for (const transaction of transactionData.data) {
        if (transaction.type !== 'charge') {
          continue;
        }
        const day = format(new Date(transaction.transaction_time), 'yyyy-MM-dd');
        const oldValue = financeReportMap.get(day);
        financeReportMap.set(day, {
          advertiser_name: transaction.advertiser_name,
          day,
          admin_spend: oldValue?.admin_spend || 0,
          admin_our_markup: oldValue?.admin_our_markup || 0,
          admin_wl_markup: oldValue?.admin_wl_markup || 0,
          wl_spend: oldValue?.wl_spend || 0,
          wl_markup: oldValue?.wl_spend || 0,
          charge: (oldValue?.charge || 0) + Number(transaction.amount || 0),
        });
      }
    }
    return Array.from(financeReportMap.values());
  }, [transactionData, reportDayData]);

  const { dataTableProps, dataTableExportProps } = useDataTable({
    name: 'finance-report',
    data: financeReportData,
    isLoading: isTransactionFetching || isReportDayFetching,
    error: transactionError || reportDayError,
    search: values.search,
    searchKeys: ['day', 'advertiser_name'],
    defaultSort: {
      key: 'day',
      direction: 'desc',
    },
    sortNumberKeys: ['admin_spend', 'admin_our_markup', 'admin_wl_markup', 'wl_spend', 'wl_markup', 'charge'],
    enableTotal: true,
    columns: [
      { header: 'Day', accessor: 'day', sortable: true, totalRender: () => 'Total' },
      {
        header: 'Advertiser',
        accessor: 'advertiser_name',
        render: (value) => value ?? '-',
      },
      // for admin
      {
        header: 'Spend',
        accessor: 'admin_spend',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'admin_spend')),
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      {
        header: 'Our Markup',
        accessor: 'admin_our_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'admin_our_markup')),
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      {
        header: 'WL Markup',
        accessor: 'admin_wl_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'admin_wl_markup')),
        sortable: true,
        when: (user) => user.role === Role.Admin,
      },
      // for wl
      {
        header: 'Spend',
        accessor: 'wl_spend',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'wl_spend')),
        sortable: true,
        when: (user) => user.role === Role.WhiteLabel,
      },
      {
        header: 'Markup',
        accessor: 'wl_markup',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'wl_markup')),
        sortable: true,
        when: (user) => user.role === Role.WhiteLabel,
      },
      {
        header: 'Charge',
        accessor: 'charge',
        render: formatAmount,
        totalRender: (data) => formatAmount(sumBy(data, 'charge')),
        sortable: true,
      },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="xxl">
          <Text size="xxl" weight={700}>
            Finance Report
          </Text>
        </Flex>
      </Flex>
      <Spacing size="lg" />
      <Flex gap="md">
        {canAccessAgency && (
          <>
            <AgencySelect
              prefix="Agency:"
              name="agency_id"
              control={control}
              withAll
              width="22rem"
              onValueChange={() => {
                setValue('advertiser_id', undefined);
              }}
            />
            <AdvertiserSelect
              agencyId={values.agency_id}
              prefix="Advertiser:"
              name="advertiser_id"
              control={control}
              withAll
              width="22rem"
            />
          </>
        )}
        <ControlledDatePicker
          prefix="From:"
          name="date_from"
          control={control}
          placeholder="Start Date"
          width="22rem"
        />
        <ControlledDatePicker prefix="To:" name="date_to" control={control} placeholder="End Date" width="22rem" />
      </Flex>
      <Spacing size="lg" />
      <TableContainer>
        <Flex align="center" justify="space-between">
          <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
          <Flex gap="lg" align="center">
            <Link to="/admin/transactions">
              <Text color="primary" weight={600} size="sm">
                Transactions
              </Text>
            </Link>
            <DataTableExport {...dataTableExportProps} />
          </Flex>
        </Flex>
        <Spacing />
        {isNotSelectAgencyAdvertiser ? (
          <ReportingInfo message="Please select agency and advertiser to see the report" />
        ) : (
          <DataTable {...dataTableProps} />
        )}
      </TableContainer>
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
