import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { FormAction } from 'src/components/Form/FormAction';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { usePersistentAgencyAdvertiser, useRole, useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { AgencySelect } from 'src/components/AgencySelect';
import { useMemo } from 'react';
import { DraftSupport } from 'src/components/DraftSupport';
import { AudienceSelect } from 'src/components/AudienceSelect.tsx';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { ChannelSelect } from 'src/components/ChannelSelect';
import { GeoSelect } from 'src/components/GeoSelect';

const { useCreateOrUpdateForecastMutation } = growthApi;

type ForecastFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  audience_id?: number;
  campaign_budget?: number;
  campaign_start_date?: Date;
  campaign_end_date?: Date;
  cpm_goal?: number;
  channels?: string[];
  geo?: string[];
};

export const ForecastEdit = () => {
  const { canAccessAgency, whiteLabelId } = useRole();
  const { agencyId, advertiserId } = usePersistentAgencyAdvertiser();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const [createOrUpdateForecast, { isLoading }] = useCreateOrUpdateForecastMutation();
  const defaultValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
    }),
    [advertiserId, agencyId],
  );
  const { watch, handleSubmit, control, setValue, reset } = useForm<ForecastFormValues>({
    defaultValues,
  });

  const values = watch();

  const onSubmit = async (values: ForecastFormValues) => {
    try {
      await createOrUpdateForecast({
        ...values,
        channels: values.channels?.join(','),
        geo: values.geo?.join(','),
        white_label_id: whiteLabelId,
      }).unwrap();
      showSuccessToast('Create forecast successfully');
      localStorage.removeItem('forecast_draft');
      navigate('/activate/forecasts');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate>
      <Text size="xxl" weight={700}>
        Create Forecast
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        {canAccessAgency && (
          <>
            <FormItem label="Agency" required>
              <AgencySelect
                name="agency_id"
                control={control}
                onValueChange={() => {
                  setValue('advertiser_id', null);
                }}
              />
            </FormItem>
            <FormItem label="Advertiser" required>
              <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
            </FormItem>
          </>
        )}
        <FormItem label="Audience" required>
          <AudienceSelect
            name="audience_id"
            agencyId={values.agency_id}
            advertiserId={values.advertiser_id}
            control={control}
          />
        </FormItem>
        <FormItem label="Campaign Budget" required>
          <ControlledInput
            type="number"
            prefix="$"
            name="campaign_budget"
            control={control}
            placeholder="Enter campaign budget"
          />
        </FormItem>
        <Flex width="100%" gap="md">
          <FlexItem grow={1}>
            <FormItem label="Campaign Start Date" required>
              <ControlledDatePicker
                name="campaign_start_date"
                control={control}
                placeholder="Select campaign start date"
                showTimeSelect
              />
            </FormItem>
          </FlexItem>
          <FlexItem grow={1}>
            <FormItem label="Campaign End Date" required>
              <ControlledDatePicker
                name="campaign_end_date"
                control={control}
                placeholder="Select campaign end date"
                showTimeSelect
              />
            </FormItem>
          </FlexItem>
        </Flex>
        <FormItem label="CPM Goal" required>
          <ControlledInput type="number" prefix="$" name="cpm_goal" control={control} placeholder="Enter cpm goal" />
        </FormItem>
        <FormItem label="Channels" required>
          <ChannelSelect name="channels" control={control} multiple />
        </FormItem>
        <FormItem label="Geo" required>
          <GeoSelect name="geo" control={control} multiple />
        </FormItem>
        <FormAction onSubmit={handleSubmit(onSubmit)} isSubmitting={isLoading} />
      </Form>
      <DraftSupport
        enabled
        draftKey="forecast_draft"
        defaultValues={defaultValues}
        values={values}
        onUseDraft={(draft) => {
          if (draft.campaign_start_date) {
            draft.campaign_start_date = new Date(draft.campaign_start_date);
          }
          if (draft.campaign_end_date) {
            draft.campaign_end_date = new Date(draft.campaign_end_date);
          }
          reset(draft);
        }}
      />
    </PageTemplate>
  );
};
