import {
  ControlledInput,
  ControlledRadioGroup,
  ControlledSelect,
  ControlledSelectOption,
  Form,
  FormItem,
} from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { useEffect } from 'react';
import { AudienceGroup, AudienceRule, AudienceRuleResponseType } from 'src/types';
import { GROUP_INCLUSION_OPTIONS, RULE_MULTI_LOGIC_OPTIONS, RULE_YES_NO_OPTIONS } from 'src/constants';
import { CustomRuleType } from './CustomRuleType';
import { isArray } from 'lodash';
import { OVERRIDE_RULES } from './constants';

const { useAudiencesDatasetQuery } = growthApi;

type EditTargetingRuleFormValues = AudienceRule;

type EditTargetingRuleModalProps = {
  isOpen: boolean;
  groups?: AudienceGroup[];
  rule?: AudienceRule;
  typeDisabled?: boolean;
  groupDisabled?: boolean;
  agencyId?: number;
  advertiserId?: number;
  onSuccess: (values: AudienceRule) => void;
  onClose: () => void;
};

export const EditTargetingRuleModal = (props: EditTargetingRuleModalProps) => {
  const { isOpen, groups, rule, typeDisabled, groupDisabled, agencyId, advertiserId, onSuccess, onClose } = props;

  const useFormReturns = useForm<EditTargetingRuleFormValues>();
  const { control, watch, handleSubmit, setValue, reset } = useFormReturns;

  useEffect(() => {
    if (isOpen) {
      if (rule) {
        reset(rule);
      } else {
        reset({
          rule_inclusion_type: 'include',
          audience_group_id: 0,
          rule_type_id: 0,
          rule_category_id: 0,
          rule_sub_type_id: 0,
          rule_value: null,
          multi_value_grouping_type: 'or',
        });
      }
    }
  }, [isOpen, rule, reset]);

  const values = watch();

  const disabled = isArray(values.rule_value) ? values.rule_value.length === 0 : !values.rule_value;

  const { data: ruleCategories, isLoading: isRuleCategoriesLoading } = useAudiencesDatasetQuery({
    dataset: 'rule_types',
  });
  const { data: orgRuleTypes, isFetching: isRuleTypesLoading } = useAudiencesDatasetQuery(
    { dataset: 'rules', type: values.rule_category_id },
    { skip: !values.rule_category_id },
  );
  const ruleTypes = useMemo(() => {
    if (OVERRIDE_RULES[values.rule_category_id]) {
      return { data: OVERRIDE_RULES[values.rule_category_id] };
    }
    return orgRuleTypes;
  }, [orgRuleTypes, values.rule_category_id]);

  const selectedCategory = useMemo(() => {
    return ruleCategories?.data?.find((item: any) => item.id === values.rule_category_id);
  }, [values.rule_category_id, ruleCategories?.data]);
  const selectedRuleType = useMemo(() => {
    return ruleTypes?.data?.find((item: any) => item.id === values.rule_type_id);
  }, [values.rule_type_id, ruleTypes?.data]);

  const { data: ruleValues, isFetching: isRuleValuesLoading } = useAudiencesDatasetQuery(
    { dataset: 'rule_values', type: values.rule_type_id },
    {
      skip:
        !values.rule_type_id ||
        [AudienceRuleResponseType.Text, AudienceRuleResponseType.Custom].includes(selectedRuleType?.rule_response_type),
    },
  );

  const groupOptions: ControlledSelectOption[] = useMemo(() => {
    return [
      { label: 'Group1', value: 0 },
      ...(groups?.map((group) => ({
        label: group.name!,
        value: group.group_id!,
      })) || []),
    ];
  }, [groups]);
  const ruleCategoryOptions: ControlledSelectOption[] = useMemo(() => {
    return (
      ruleCategories?.data?.map((ruleType: any) => ({
        label: ruleType.name,
        value: ruleType.id,
      })) || []
    );
  }, [ruleCategories?.data]);
  const ruleTypeOptions: ControlledSelectOption[] = useMemo(() => {
    return (
      ruleTypes?.data?.map((ruleType: any) => ({
        label: ruleType.name,
        value: ruleType.id,
      })) || []
    );
  }, [ruleTypes?.data]);
  const ruleValueOptions: ControlledSelectOption[] = useMemo(() => {
    return (
      ruleValues?.data.map((ruleType: any) => ({
        label: ruleType.name,
        value: ruleType.id,
      })) || []
    );
  }, [ruleValues?.data]);

  const onSubmit = (values: AudienceRule) => {
    onSuccess({
      ...values,
      rule_type_id_name: selectedCategory?.name,
      rule_type_name: selectedRuleType?.name,
      rule_response_type: selectedRuleType?.rule_response_type,
    });
  };

  return (
    <Modal
      title={`${rule ? 'Edit' : 'New'} Targeting Rule`}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      disabled={disabled}
    >
      <FormProvider {...useFormReturns}>
        <Form>
          {!typeDisabled && values.audience_group_id === 0 && (
            <FormItem label="Type">
              <ControlledRadioGroup name="rule_inclusion_type" control={control} options={GROUP_INCLUSION_OPTIONS} />
            </FormItem>
          )}
          {!groupDisabled && (
            <FormItem label="Group">
              <ControlledSelect
                name="audience_group_id"
                control={control}
                options={groupOptions}
                placeholder="Select group"
                onValueChange={(value) => {
                  if (value !== 0) {
                    const group = groups?.find((group) => group.group_id === value);
                    setValue('rule_inclusion_type', group?.group_inclusion_type);
                  }
                }}
              />
            </FormItem>
          )}
          <FormItem label="Category">
            <ControlledSelect
              name="rule_category_id"
              control={control}
              options={ruleCategoryOptions}
              isLoading={isRuleCategoriesLoading}
              placeholder="Select category"
              onValueChange={() => {
                setValue('rule_type_id', 0);
                setValue('rule_value', '');
                setValue('rule_value_name', []);
              }}
            />
          </FormItem>
          {Boolean(values.rule_category_id) && (
            <FormItem label="Type">
              <ControlledSelect
                name="rule_type_id"
                control={control}
                options={ruleTypeOptions}
                isLoading={isRuleTypesLoading}
                placeholder="Select type"
                onValueChange={() => {
                  setValue('rule_value', '');
                  setValue('rule_value_name', []);
                }}
              />
            </FormItem>
          )}
          {Boolean(values.rule_type_id && selectedRuleType?.rule_response_type) && (
            <>
              {selectedRuleType.rule_response_type !== AudienceRuleResponseType.Custom && (
                <FormItem label="Value">
                  {selectedRuleType.rule_response_type === AudienceRuleResponseType.Text && (
                    <ControlledInput
                      name="rule_value"
                      control={control}
                      placeholder={selectedRuleType.placeholder_text}
                    />
                  )}
                  {selectedRuleType.rule_response_type === AudienceRuleResponseType.SingleSelect && (
                    <ControlledSelect
                      name="rule_value"
                      control={control}
                      placeholder="Select value"
                      isLoading={isRuleValuesLoading}
                      options={ruleValueOptions}
                      onValueChange={(value) => {
                        setValue(
                          'rule_value_name',
                          ruleValues?.data.filter((item: any) => value === item.id),
                        );
                      }}
                    />
                  )}
                  {selectedRuleType.rule_response_type === AudienceRuleResponseType.MultiSelect && (
                    <ControlledSelect
                      name="rule_value"
                      control={control}
                      placeholder="Select value"
                      isLoading={isRuleValuesLoading}
                      options={ruleValueOptions}
                      multiple
                      onValueChange={(value) => {
                        setValue(
                          'rule_value_name',
                          ruleValues?.data.filter((item: any) => value.includes(item.id)),
                        );
                      }}
                    />
                  )}
                  {selectedRuleType.rule_response_type === AudienceRuleResponseType.YesNo && (
                    <ControlledRadioGroup name="rule_value" control={control} options={RULE_YES_NO_OPTIONS} />
                  )}
                </FormItem>
              )}
              {selectedRuleType.rule_response_type === AudienceRuleResponseType.Custom && (
                <CustomRuleType
                  agencyId={agencyId}
                  advertiserId={advertiserId}
                  ruleTypeId={values.rule_type_id!}
                  rule={rule}
                />
              )}
              <FormItem label="Multi Value Logic">
                <ControlledRadioGroup
                  name="multi_value_grouping_type"
                  control={control}
                  options={RULE_MULTI_LOGIC_OPTIONS}
                />
              </FormItem>
            </>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};
