import { useEffect, useRef } from 'react';
import { growthApi } from 'src/services';
import { useAppDispatch, useAppSelector, userSlice } from 'src/store';

const { useStatusQuery } = growthApi;

export const useStatus = () => {
  const { accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const isLoaded = useRef(false);

  const { data: status } = useStatusQuery(
    {
      access_token: accessToken,
      domain: window.location.hostname,
    },
    { skip: isLoaded.current },
  );

  useEffect(() => {
    if (status?.data) {
      // auto login & logout
      if (status.data?.user) {
        dispatch(userSlice.actions.login(status.data));
      } else {
        dispatch(userSlice.actions.logout());
      }
      dispatch(userSlice.actions.setWhiteLabel(status.data.whiteLabel));
      isLoaded.current = true;
    }
  }, [dispatch, status?.data]);

  return { isLoaded: isLoaded.current };
};
