import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { jsonToCSV } from 'react-papaparse';
import {
  Action,
  ControlledFile,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  Form,
  FormItem,
} from 'src/components/Form';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { exportCSV, getApiErrorMessage } from 'src/utils';
import { useConfirm } from 'src/hooks';
import { Modal } from 'src/components/Modal';
import { Flex, Spacing, Text } from 'src/components/Layout';

const { useCampaignFileCreateMutation, useCampaignFileUpdateMutation, useCheckDoubleUploadMutation } = growthApi;

enum BulkUploadType {
  Create = 'create',
  Update = 'update',
}

const BULK_UPLOAD_TYPE_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Create',
    value: BulkUploadType.Create,
  },
  {
    label: 'Update',
    value: BulkUploadType.Update,
  },
];

type BulkUploadFormValues = {
  type: BulkUploadType;
  file: File;
};

type CampaignBulkUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CampaignBulkUploadModal = (props: CampaignBulkUploadModalProps) => {
  const { isOpen, onClose } = props;

  const { control, watch, reset } = useForm<BulkUploadFormValues>({
    defaultValues: {
      type: BulkUploadType.Create,
    },
  });
  const values = watch();
  const isConfirmDisabled = !values.file;
  const [errors, setErrors] = useState([]);
  const [campaignFileCreate, { isLoading: isCreateFileLoading }] = useCampaignFileCreateMutation();
  const [campaignFileUpdate, { isLoading: isUpdateFileLoading }] = useCampaignFileUpdateMutation();
  const [checkDoubleUpload, { isLoading: isCheckLoading }] = useCheckDoubleUploadMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const confirm = useConfirm();

  const isLoading = isCreateFileLoading || isUpdateFileLoading || isCheckLoading;

  const onFileUpload = async () => {
    const { file } = values;
    if (!file.name.includes('.csv')) {
      showErrorToast('File format should be .csv');
      reset({ file: null });
      return;
    }
    let formData = new FormData();
    formData.append('file', file);
    try {
      // check if file with same file name was previously uploaded
      const checkResult = await checkDoubleUpload(formData).unwrap();
      if (checkResult?.data) {
        const confirmMessage = checkResult.data as never;
        const result = await confirm({ title: confirmMessage });
        if (!result) {
          return;
        }
      }
      const action = values.type === BulkUploadType.Create ? campaignFileCreate : campaignFileUpdate;
      const result = await action(formData).unwrap();
      const { errors } = result.data;
      if (errors?.length) {
        setErrors(errors);
      } else {
        const { data } = result.data;
        showSuccessToast(`${data.campaigns_processed} Campaigns send successfully!`);
        onClose();
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
    reset({ file: null });
  };

  const downloadTemplate = () => {
    const templateFields = [
      'Agency ID',
      'Advertiser ID',
      'Campaign Group',
      'Campaign Name',
      'Campaign Start Date',
      'Campaign End Date',
      'Custom Flight day',
      'Monthly Budget',
      'Daily Cap',
      'Frequency',
      'Max CPM',
      'Pacing',
      'Conversion Pixel',
      'Objective',
      'Audience Name',
      'Audience Targeting Pixel',
      'Channels',
      'Geo Targeting (country/state/city)',
      'Creative Start Dates',
      '',
      '',
      '',
      '',
      'Creative name',
      'Creative Sync Link',
      'Creative Click-Through URL',
      'New Creative Refresh Day',
    ];
    const isCreate = values.type === BulkUploadType.Create;
    if (!isCreate) {
      templateFields.unshift('Campaign ID');
    }
    exportCSV(
      isCreate ? 'create_template.csv' : 'update_template.csv',
      jsonToCSV({
        fields: templateFields,
        data: templateFields.map((i) => ''),
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Bulk Upload"
      onConfirm={onFileUpload}
      isLoading={isLoading}
      disabled={isConfirmDisabled}
      onClose={onClose}
    >
      <Form>
        <FormItem required label="Bulk Upload Type">
          <ControlledRadioGroup name="type" control={control} options={BULK_UPLOAD_TYPE_OPTIONS} />
        </FormItem>
        <FormItem required label="Choose File">
          <>
            {errors.map((error) => (
              <Text size="sm" color="red">
                {error}
              </Text>
            ))}
            <ControlledFile name="file" control={control} />
            <Spacing />
            <Flex justify="center" gap="lg">
              <Action onClick={() => downloadTemplate()}>
                <Text size="sm">Download Template</Text>
              </Action>
            </Flex>
          </>
        </FormItem>
      </Form>
    </Modal>
  );
};
