import { PageTemplate } from 'src/components/Template';
import { Text, Spacing, Flex, FlexItem } from 'src/components/Layout';
import { Button, ControlledSelect, ControlledSelectOption } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useEffect } from 'react';
import { UserBanner } from 'src/components/UserBanner';
import { ReportingCampaign } from './ReportingCampaign';
import { ReportingSites } from './ReportingSites';
import { ReportingAuctionResults } from './ReportingAuctionResults';
import {
  DateRange,
  DATE_RANGE_CUSTOM_OPTIONS,
  parseDateRange,
  REPORTING_PREVIOUS_PERIOD_OPTIONS,
  parsePreviousDateRange,
  PreviousPeriod,
} from 'src/utils';
import { ReportingCreatives } from './ReportingCreatives';
import { ReportingGeo } from './ReportingGEO';
import { ReportingApps } from './ReportingApps';
import { useReportingPersistentFilters, useRole } from 'src/hooks';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { ReportingPacing } from './ReportingPacing';
import { ReportingCampaignSelectInHouse } from 'src/components/ReportingCampaignSelect';
import { CampaignGroupSelect } from 'src/components/CampaignGroupSelect';

enum ReportingType {
  Campaigns = 'campaigns',
  Sites = 'sites',
  Apps = 'apps',
  Geo = 'geo',
  Creatives = 'creatives',
  AuctionResults = 'auction-results',
  Pacing = 'pacing',
}

export type ReportingFormValues = {
  type?: ReportingType;
  agencyId?: number;
  advertiserId?: number;
  campaignGroup?: number;
  campaignId?: number;
  timeRange?: DateRange;
  dateFrom?: Date;
  dateTo?: Date;
  uiTimeRange?: DateRange;
  customDateFrom?: Date;
  customDateTo?: Date;
  previousPeriod?: PreviousPeriod;
  customPeriodStart?: Date;
  customPeriodEnd?: Date;
  previousDateFrom?: Date;
  previousDateTo?: Date;
};

const REPORTING_TYPE_OPTIONS: ControlledSelectOption[] = [
  { label: 'Campaign', value: ReportingType.Campaigns },
  { label: 'Sites', value: ReportingType.Sites },
  { label: 'Apps', value: ReportingType.Apps },
  { label: 'Geo', value: ReportingType.Geo },
  { label: 'Creatives', value: ReportingType.Creatives },
  { label: 'Auction Results', value: ReportingType.AuctionResults },
  { label: 'Pacing', value: ReportingType.Pacing },
];

export const Reporting = () => {
  const { canAccessAgency } = useRole();
  const { control, watch, setValue, reset } = useForm<ReportingFormValues>({
    defaultValues: {
      agencyId: null,
      advertiserId: null,
      campaignGroup: null,
      campaignId: null,
      type: ReportingType.Campaigns,
      previousPeriod: null,
    },
  });
  const values = watch();

  useReportingPersistentFilters({ values, setValues: reset });

  useEffect(() => {
    const { perviousDateFrom, perviousDateTo } = parsePreviousDateRange({
      previousPeriod: values.previousPeriod,
      customPeriodStart: values.customPeriodStart,
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
    });
    setValue('previousDateFrom', perviousDateFrom);
    setValue('previousDateTo', perviousDateTo);
    setValue('customPeriodEnd', perviousDateTo);
  }, [setValue, values.customPeriodStart, values.dateFrom, values.dateTo, values.previousPeriod]);

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex gap="lg" align="center">
          <Text size="xxl" weight={700}>
            Reporting
          </Text>
          <ControlledSelect
            name="type"
            control={control}
            options={REPORTING_TYPE_OPTIONS}
            prefix="Type:"
            placeholder="Select type"
            width="22rem"
          />
        </Flex>
        <UserBanner />
      </Flex>
      <Spacing size="md" />
      <Flex width="100%" gap="md">
        {canAccessAgency && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Agency</Text>
                <AgencySelect
                  name="agencyId"
                  control={control}
                  withAll
                  onValueChange={() => {
                    setValue('advertiserId', null);
                    setValue('campaignId', null);
                  }}
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Advertiser</Text>
                <AdvertiserSelect
                  name="advertiserId"
                  control={control}
                  agencyId={values.agencyId}
                  withAll
                  onValueChange={() => {
                    setValue('campaignId', null);
                  }}
                />
              </Flex>
            </FlexItem>
          </>
        )}
        <FlexItem grow={1}>
          <Flex direction="column" gap="sm">
            <Text size="xs">Campaign Group</Text>
            <CampaignGroupSelect
              name="campaignGroup"
              control={control}
              agencyId={values.agencyId}
              advertiserId={values.advertiserId}
              withAll
            />
          </Flex>
        </FlexItem>
        {values.type !== ReportingType.Pacing && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Campaign</Text>
                <ReportingCampaignSelectInHouse
                  name="campaignId"
                  control={control}
                  agencyId={values.agencyId}
                  advertiserId={values.advertiserId}
                  withAll
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Date Range</Text>
                <ControlledSelect
                  name="uiTimeRange"
                  control={control}
                  options={DATE_RANGE_CUSTOM_OPTIONS}
                  onValueChange={(timeRange: DateRange) => {
                    if (timeRange !== DateRange.Custom) {
                      const { dateTo, dateFrom } = parseDateRange(timeRange);
                      setValue('timeRange', timeRange);
                      setValue('dateTo', dateTo);
                      setValue('dateFrom', dateFrom);
                    }
                  }}
                />
                {values.uiTimeRange === DateRange.Custom && (
                  <Flex gap="sm" align="center">
                    <FlexItem grow={1}>
                      <ControlledDatePicker name="customDateFrom" control={control} placeholder="Date from" />
                    </FlexItem>
                    <FlexItem grow={1}>
                      <ControlledDatePicker name="customDateTo" control={control} placeholder="Date to" />
                    </FlexItem>
                    <Button
                      size="sm"
                      width="10rem"
                      onClick={() => {
                        setValue('timeRange', DateRange.Custom);
                        setValue('dateFrom', values.customDateFrom);
                        setValue('dateTo', values.customDateTo);
                      }}
                    >
                      Reload
                    </Button>
                  </Flex>
                )}
              </Flex>
            </FlexItem>
            {[
              ReportingType.Campaigns,
              ReportingType.Creatives,
              ReportingType.Sites,
              ReportingType.Apps,
              ReportingType.Geo,
            ].includes(values.type) &&
              values.uiTimeRange !== DateRange.AllTime && (
                <FlexItem grow={1}>
                  <Flex direction="column" gap="sm">
                    <Text size="xs">Previous period</Text>
                    <ControlledSelect
                      name="previousPeriod"
                      control={control}
                      options={REPORTING_PREVIOUS_PERIOD_OPTIONS}
                    />
                    {values.previousPeriod === PreviousPeriod.CustomPeriodStart && (
                      <Flex gap="sm">
                        <FlexItem>
                          <ControlledDatePicker name="customPeriodStart" control={control} placeholder="Period start" />
                        </FlexItem>
                        <FlexItem>
                          <ControlledDatePicker
                            name="customPeriodEnd"
                            control={control}
                            placeholder="Period end"
                            disabled
                          />
                        </FlexItem>
                      </Flex>
                    )}
                  </Flex>
                </FlexItem>
              )}
          </>
        )}
      </Flex>
      <Spacing size="lg" />
      {values.type === ReportingType.Campaigns && <ReportingCampaign {...values} />}
      {values.type === ReportingType.Sites && <ReportingSites {...values} />}
      {values.type === ReportingType.Apps && <ReportingApps {...values} />}
      {values.type === ReportingType.Geo && <ReportingGeo {...values} />}
      {values.type === ReportingType.Creatives && <ReportingCreatives {...values} />}
      {values.type === ReportingType.AuctionResults && <ReportingAuctionResults {...values} />}
      {values.type === ReportingType.Pacing && <ReportingPacing {...values} />}
    </PageTemplate>
  );
};
