import { Text } from 'src/components/Layout';
import { isNumber } from 'lodash';
import { formatPercentage } from 'src/utils';

type DiffPercentageProps = {
  color?: any;
  value: any;
  lastValue?: any;
};

export const DiffPercentage = (props: DiffPercentageProps) => {
  const { color, value, lastValue = value } = props;

  const percentage =
    value === 0 || lastValue === 0 || value === '0' || lastValue === '0' || !isNumber(value) || !isNumber(lastValue)
      ? 0
      : (value >= lastValue ? (value - lastValue) / value : (lastValue - value) / lastValue) * 100;

  const percentageColor = percentage === 0 ? 'gray' : value > lastValue ? 'success' : 'error';

  return (
    <Text size="xs" weight={600} color={color ?? percentageColor}>
      {formatPercentage(percentage)}
    </Text>
  );
};
