import ReactSwitch from 'react-switch';
import { TOKENS } from 'src/design';

type SwitchProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export const Switch = (props: SwitchProps) => {
  const { value, onChange, disabled } = props;

  return (
    <ReactSwitch
      width={30}
      height={16}
      onColor={TOKENS.color.primary}
      checkedIcon={false}
      uncheckedIcon={false}
      checked={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
