import { Loading } from 'src/components/Loading';
import { TABLE_MAX_COL_SPAN } from 'src/components/Table';

export const DataTableLoading = () => {
  return (
    <tr>
      <td colSpan={TABLE_MAX_COL_SPAN}>
        <Loading height="9rem" />
      </td>
    </tr>
  );
};
