import { useEffect, useRef, useState } from 'react';
import { Modal } from '../Modal';
import { Flex, Text } from '../Layout';
import { useSearchParams } from 'react-router-dom';

type DraftProps = {
  draftKey: string;
  defaultValues: any;
  values: any;
  handleDraftParams?: (draft: any, draftParams: any) => void;
  onUseDraft?: (draft: any) => void;
  enabled?: boolean;
};

export const DraftSupport = (props: DraftProps) => {
  const { draftKey, defaultValues, values, handleDraftParams, onUseDraft, enabled } = props;

  const [searchParams] = useSearchParams();
  const isUseDraft = searchParams.get('draft');
  const draftParams = searchParams.get('draft_params');

  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const draftSnapshot = useRef<any>(null);

  useEffect(() => {
    if (enabled) {
      const draft = localStorage.getItem(draftKey);
      if (draft && draft !== JSON.stringify(defaultValues)) {
        draftSnapshot.current = draft;
        if (isUseDraft) {
          const draftValues = JSON.parse(draftSnapshot.current);
          handleDraftParams?.(draftValues, JSON.parse(draftParams));
          onUseDraft?.(draftValues);
        } else {
          setIsDraftOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (enabled) {
      localStorage.setItem(draftKey, JSON.stringify(values));
    }
  }, [enabled, draftKey, values]);

  const onClose = () => {
    setIsDraftOpen(false);
    localStorage.setItem(draftKey, JSON.stringify(defaultValues));
  };

  const onConfirm = () => {
    setIsDraftOpen(false);
    onUseDraft?.(JSON.parse(draftSnapshot.current));
  };

  return (
    <Modal
      title="Notice"
      isOpen={isDraftOpen}
      confirmText="YES"
      cancelText="NO"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Flex justify="center">
        <Text size="md">Do you want to use the unsaved changes?</Text>
      </Flex>
    </Modal>
  );
};
