import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { growthApi } from 'src/services';
import { userSlice } from './userSlice';
import { toastsSlice } from './toastsSlice';
import { notificationsSidebarSlice } from './notificationsSidebarSlice';

export const store = configureStore({
  reducer: {
    [growthApi.reducerPath]: growthApi.reducer,
    user: userSlice.reducer,
    toasts: toastsSlice.reducer,
    notificationsSidebarSlice: notificationsSidebarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(growthApi.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
