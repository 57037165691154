import { useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Icon } from '../Icon';
import { Flex, Text } from '../Layout';
import { Input } from './Input';
import { Button } from './Button';

type ControlledEditableListProps<T> = UseControllerProps<T> & {
  title: string;
};

export const ControlledEditableList = <T,>(props: ControlledEditableListProps<T>) => {
  const { title, ...useControllerProps } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string[]>([]);

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  const typedValue = value as string[];

  return (
    <Flex direction="column" gap="md">
      <Flex gap="xs" justify="space-between" align="center">
        <Text weight={600}>{title}</Text>
        {isEdit ? (
          <Flex>
            <Icon
              size="md"
              color="primary"
              type="check"
              clickable
              onClick={() => {
                onChange(editValue);
                setIsEdit(false);
              }}
            />
            <Icon size="md" color="error" type="close" clickable onClick={() => setIsEdit(false)} />
          </Flex>
        ) : (
          <Icon
            size="sm"
            color="gray"
            type="edit"
            clickable
            onClick={() => {
              setEditValue(typedValue);
              setIsEdit(true);
            }}
          />
        )}
      </Flex>
      {isEdit ? (
        <Flex direction="column" gap="sm">
          {editValue.map((value, index) => (
            <Flex key={index} align="center" gap="xs">
              <Input
                value={value}
                onChange={(event) => {
                  const newEditValue = [...editValue];
                  newEditValue[index] = event.currentTarget.value;
                  setEditValue(newEditValue);
                }}
              />
              <Icon
                size="md"
                type="trash"
                color="error"
                clickable
                onClick={() => setEditValue(editValue.filter((_, i) => index !== i))}
              />
            </Flex>
          ))}
          <Button size="sm" various="secondary" width="12rem" onClick={() => setEditValue([...editValue, ''])}>
            ADD
          </Button>
        </Flex>
      ) : (
        <Flex direction="column" gap="sm">
          {typedValue?.map((value, index) => (
            <Text key={index} size="sm">
              {value}
            </Text>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
