import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useRole, useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { Flex } from 'src/components/Layout';
import { useMemo } from 'react';

const { useAddAdWalletCreditBalanceMutation } = growthApi;

type AddMoreFundsModalProps = {
  isOpen: boolean;
  agencyId?: number;
  advertiserId?: number;
  onClose: () => void;
  onSuccess: () => void;
};

type AddMoreFundsFormValues = {
  amount?: number;
};

export const AddMoreFundsModal = (props: AddMoreFundsModalProps) => {
  const { isOpen, agencyId, advertiserId, onClose, onSuccess } = props;

  const { agencyId: userAgencyId, isWhiteLabel, whiteLabelId } = useRole();
  const { control, handleSubmit } = useForm<AddMoreFundsFormValues>();
  const [addAdWalletCreditBalance, { isLoading }] = useAddAdWalletCreditBalanceMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  // if is white label and they set their agency payment, will binding credit card to us
  const finalWhiteLabelId = useMemo(() => {
    return isWhiteLabel && agencyId === userAgencyId && !advertiserId ? undefined : whiteLabelId;
  }, [advertiserId, agencyId, isWhiteLabel, userAgencyId, whiteLabelId]);

  const onSubmit = async (values: AddMoreFundsFormValues) => {
    try {
      await addAdWalletCreditBalance({
        ...values,
        agency_id: agencyId,
        advertiser_id: advertiserId,
        white_label_id: finalWhiteLabelId,
      }).unwrap();
      showSuccessToast('Add more credit successfully');
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add More Credit"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Amount">
          <Flex direction="column" gap="md">
            <ControlledInput name="amount" control={control} placeholder="Enter amount" prefix="$" />
            {/* <Text size="xs">
              We will charge an additional {formatPercentage(finalMarkup)} on top of your ad spend for processing &
              platform fees.
            </Text> */}
          </Flex>
        </FormItem>
      </Form>
    </Modal>
  );
};
