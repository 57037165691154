import { Error } from 'src/components/Error';
import { TABLE_MAX_COL_SPAN } from 'src/components/Table';

type DataTableErrorProps = {
  error: string | string[];
};

export const DataTableError = (props: DataTableErrorProps) => {
  const { error } = props;

  return (
    <tr>
      <td colSpan={TABLE_MAX_COL_SPAN}>
        <Error error={error} height="9rem" />
      </td>
    </tr>
  );
};
