import { ToastProps } from 'src/components/Toasts';
import { toastsSlice, useAppDispatch } from 'src/store';

export const useToast = () => {
  const dispatch = useAppDispatch();

  const showToast = (props: Omit<ToastProps, 'id'>) => {
    dispatch(toastsSlice.actions.showToast(props));
  };

  const showSuccessToast = (message?: string | string[]) => {
    dispatch(
      toastsSlice.actions.showToast({
        type: 'success',
        message,
      }),
    );
  };

  const showErrorToast = (message?: string | string[]) => {
    dispatch(
      toastsSlice.actions.showToast({
        type: 'error',
        message,
      }),
    );
  };

  return { showToast, showSuccessToast, showErrorToast };
};
