import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';

type FormActionProps = {
  submitText?: string;
  isSubmitting?: boolean;
  disabled?: boolean;
  disableBack?: boolean;
  disableSubmit?: boolean;
  enableDraft?: boolean;
  draftDisabled?: boolean;
  onBack?: () => void;
  onSubmit?: () => void;
  onDraftSubmit?: () => void;
};

export const FormAction = (props: FormActionProps) => {
  const {
    submitText,
    isSubmitting,
    disabled,
    disableBack,
    disableSubmit,
    enableDraft,
    draftDisabled,
    onBack,
    onSubmit,
    onDraftSubmit,
  } = props;

  const navigate = useNavigate();

  const defaultOnBack = () => {
    navigate(-1);
  };

  return (
    <FormActionContainer>
      {!disableBack && (
        <Button width="18rem" onClick={onBack ?? defaultOnBack} various="secondary">
          BACK
        </Button>
      )}
      {!disableSubmit && (
        <Button width="18rem" shadow onClick={onSubmit} isLoading={isSubmitting} disabled={disabled}>
          {submitText ?? 'SUBMIT'}
        </Button>
      )}
      {enableDraft && (
        <Button
          width="18rem"
          various="secondary"
          onClick={onDraftSubmit}
          isLoading={isSubmitting}
          disabled={draftDisabled}
        >
          SAVE DRAFT
        </Button>
      )}
    </FormActionContainer>
  );
};

const FormActionContainer = styled.div`
  padding: 2.4rem 0;
  display: flex;
  gap: 3.6rem;
`;
